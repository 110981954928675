/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from "../contexts/UserStoreContext";

export default function Reports() {
     const navigate = useNavigate();
     const {reportsread} = useUserStore();
     
         
  return (
     <>
     <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Reports</span>
            </div>
          </li>
        </ol>
      </nav>
      {reportsread?
          
               <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 lg:gap-4 flex flex-col items-center justify-center ">
                    
                    <Link to='/accountsreport'>
                    <div class="p-2 bg-[#F3B0C3] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <path d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM4 19V5h7v14H4zm9 0V5h7l.001 14H13z"></path><path d="M15 7h3v2h-3zm0 4h3v2h-3z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Accounts Report</h5>
                         </div>
                    </div>
                    </Link>
                    <Link to='/auditreport'>
                    <div class="p-2 bg-[#dba2e1] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <path d="M4 21h9.62a3.995 3.995 0 0 0 3.037-1.397l5.102-5.952a1 1 0 0 0-.442-1.6l-1.968-.656a3.043 3.043 0 0 0-2.823.503l-3.185 2.547-.617-1.235A3.98 3.98 0 0 0 9.146 11H4c-1.103 0-2 .897-2 2v6c0 1.103.897 2 2 2zm0-8h5.146c.763 0 1.448.423 1.789 1.105l.447.895H7v2h6.014a.996.996 0 0 0 .442-.11l.003-.001.004-.002h.003l.002-.001h.004l.001-.001c.009.003.003-.001.003-.001.01 0 .002-.001.002-.001h.001l.002-.001.003-.001.002-.001.002-.001.003-.001.002-.001c.003 0 .001-.001.002-.001l.003-.002.002-.001.002-.001.003-.001.002-.001h.001l.002-.001h.001l.002-.001.002-.001c.009-.001.003-.001.003-.001l.002-.001a.915.915 0 0 0 .11-.078l4.146-3.317c.262-.208.623-.273.94-.167l.557.186-4.133 4.823a2.029 2.029 0 0 1-1.52.688H4v-6zM16 2h-.017c-.163.002-1.006.039-1.983.705-.951-.648-1.774-.7-1.968-.704L12.002 2h-.004c-.801 0-1.555.313-2.119.878C9.313 3.445 9 4.198 9 5s.313 1.555.861 2.104l3.414 3.586a1.006 1.006 0 0 0 1.45-.001l3.396-3.568C18.688 6.555 19 5.802 19 5s-.313-1.555-.878-2.121A2.978 2.978 0 0 0 16.002 2H16zm1 3c0 .267-.104.518-.311.725L14 8.55l-2.707-2.843C11.104 5.518 11 5.267 11 5s.104-.518.294-.708A.977.977 0 0 1 11.979 4c.025.001.502.032 1.067.485.081.065.163.139.247.222l.707.707.707-.707c.084-.083.166-.157.247-.222.529-.425.976-.478 1.052-.484a.987.987 0 0 1 .701.292c.189.189.293.44.293.707z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Audit Report</h5>
                         </div>
                    </div>
                    </Link>
                    <Link to='/customerreport'>
                    <div class="p-2 bg-[#ffffb5] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <path d="M8 12.052c1.995 0 3.5-1.505 3.5-3.5s-1.505-3.5-3.5-3.5-3.5 1.505-3.5 3.5 1.505 3.5 3.5 3.5zM9 13H7c-2.757 0-5 2.243-5 5v1h12v-1c0-2.757-2.243-5-5-5zm11.294-4.708-4.3 4.292-1.292-1.292-1.414 1.414 2.706 2.704 5.712-5.702z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Customer Report</h5>
                         </div>
                    </div>
                    </Link>
                    <Link to='/inventoryreport'>
                    <div class="p-2 bg-[#cbaacb] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <path d="M4 11h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1zm1-6h4v4H5V5zm15-2h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm-1 6h-4V5h4v4zm-9 12a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6zm-5-6h4v4H5v-4zm13-1h-2v2h-2v2h2v2h2v-2h2v-2h-2z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Inventory Report</h5>
                         </div>
                    </div>
                    </Link>
                    <Link to='/laundryreport'>
                    <div class="p-2 bg-[#cce2cb] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <path d="m21.484 7.125-9.022-5a1.003 1.003 0 0 0-.968 0l-8.978 4.96a1 1 0 0 0-.003 1.748l9.022 5.04a.995.995 0 0 0 .973.001l8.978-5a1 1 0 0 0-.002-1.749z"></path><path d="m12 15.856-8.515-4.73-.971 1.748 9 5a1 1 0 0 0 .971 0l9-5-.971-1.748L12 15.856z"></path><path d="m12 19.856-8.515-4.73-.971 1.748 9 5a1 1 0 0 0 .971 0l9-5-.971-1.748L12 19.856z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Laundry Report</h5>
                         </div>
                    </div>
                    </Link>
                    <Link to='/servicereport'>
                    <div  class="p-2 bg-[#ffaea5] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <path d="M5 22h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2h-2a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1H5c-1.103 0-2 .897-2 2v15c0 1.103.897 2 2 2zM5 5h2v2h10V5h2v15H5V5z"></path><path d="m11 13.586-1.793-1.793-1.414 1.414L11 16.414l5.207-5.207-1.414-1.414z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Service Report</h5>
                         </div>
                    </div>
                    </Link>
                    <Link to='/pickupreport'>
                    <div  class="p-2 bg-[#a2c8e1] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <path d="M5 22h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2h-2a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1H5c-1.103 0-2 .897-2 2v15c0 1.103.897 2 2 2zM5 5h2v2h10V5h2v15H5V5z"></path><path d="m11 13.586-1.793-1.793-1.414 1.414L11 16.414l5.207-5.207-1.414-1.414z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Pickup Report</h5>
                         </div>
                    </div>
                    </Link> 
               </div>
          
          :
      <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">You do not have permission to view Reports. Contact your admin for permissions</h3>
          </div>
          <div class="flex">
            <button onClick={()=>navigate("/")} type="button" class="text-green-700 bg-transparent border border-green-700 hover:bg-green-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-green-500 dark:border-green-500 dark:text-green-400 dark:hover:text-white dark:focus:ring-green-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
              OK
            </button>
          </div>
      </div>
      }
     </>
  )
}
