/* eslint-disable react-hooks/exhaustive-deps */
import { child, get, getDatabase, ref as dref, set, update } from "firebase/database";
import { deleteObject, getDownloadURL, getStorage, ref as sref, uploadBytes } from "firebase/storage";
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from "../contexts/UserStoreContext";
import firebaseapp from '../firebase';
import ls from 'localstorage-slim';

export default function Offers() {  
  
  const dbr = getDatabase(firebaseapp);
  const storage = getStorage(firebaseapp);
  const [readAccess,setReadAccess] = useState(true);
  const navigate = useNavigate();
  const dbRef = dref(dbr);
  const { storeID, setStoreID, countryCode, setCountryCode, offerscreate, offersread, offersupdate, offersdelete } = useUserStore();
  const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);
  const hiddenFileInput = React.useRef(null);
  if(!storeID){
    setStoreID(userdata.storeID);
  }

  if(!countryCode){
    setCountryCode(userdata.country);
  }
  const [selectedimage, setSelectedimage] = useState('');
  const [pictureeditor, setPictureeditor] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [replaceModalVisible, setReplaceModalVisible] = useState(false);
  const [spinnerloader, setSpinnerloader] = useState(false);
  const [saveorder, setSaveorder] = useState(false)
  const [count, setCount] = useState(false);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [initialLoader, setInitialLoader] = useState(true);
  const [selectedModalImage, setSelectedModalImage] = useState('');
  const [modalLoader, setModalLoader] = useState(false);
  
  useEffect(() => {
    if(offersread){
      if(initialLoader){
        function loaditems(){
          setInitialLoader(false);
          get(child(dbRef, `${storeID}/offers/${storeID}/`)).then((snapshot) => {
              const flmap = [];
              const orderdata = [1,2,3,4,5];
              if (snapshot.exists()) {
                let index = 0;
                snapshot.forEach(element => {
                index = index+1;
                const order =  element.child('order').val();
                const node = element.key;
                const filename = element.child('filename').val();
                const url = element.child('url').val();
                const visible = element.child('visible').val();
                const key = element.child('key').val();
    
                var orderindex = orderdata.indexOf(order);
                  if (orderindex !== -1) {
                    orderdata.splice(orderindex, 1);
                  }
                  const stringkey = key.toString();
                flmap.push({stringkey, order, node, key, filename, url, visible})
                });
                var i=0;
                for (index; index < 5; index++) {
                  const order = orderdata[i]
                  i++;
                  const key = index+1;
                  const filename = key;
                  const stringkey = key.toString();
                  const node = key;
                  const url = null;
                  const visible = false;
                  flmap.push({stringkey, order, node, key, filename, url, visible});
                }
                let orderedflmap = flmap.sort((a, b) => a.order - b.order);
                setData(orderedflmap);
                setLoader(true);
              }
              else{
                for (let index = 0; index < 5; index++) {
                  const key = index+1;
                  const filename = key;
                  const stringkey = key.toString();
                  const order =  key;
                  const node = key;
                  const url = null;
                  const visible = false;
                  flmap.push({stringkey, order, node, key, filename, url, visible})
                }
                let orderedflmap = flmap.sort((a, b) => a.order - b.order);
                console.log(orderedflmap, "inside else");
                setData(orderedflmap);
                setLoader(true);
              }
              
          }).catch((error)=>{
              console.log(error);
          });
          
        }
        loaditems();

      }
    }else{
      setReadAccess(false);
    }
  }, []);
  
  

  function imageEdit(img){
    setLoader(false);
    setSelectedimage(img);
    setPictureeditor(true);
  }

  function cancelImage(){
    setPictureeditor(false);
    setLoader(true);
  }

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    const reorderData = reorder(
          data,  
          result.source.index,
          result.destination.index
        );
    setCount(true);
    setData(reorderData);
  }

  function saveOrder(){
    setCount(false);
    const updateObject = [];
    data.forEach(element => {
      updateObject.push({
        filename: element.filename,
        key: element.key,
        order: element.order,
        url: element.url,
        visible: element.visible,
      })
      });
      update(dref(dbr, 'offers/'), {
        [storeID] : updateObject
      });
  }
  
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const arraymap = [];
    for (const indexkey in result) {
      if (Object.hasOwnProperty.call(result, indexkey)) {
        const element = result[indexkey];
        const key = element.key;
        const filename = element.filename;
        const stringkey = (element.stringkey).toString();
        const order =  Number(indexkey)+1;
        const node = element.node;
        const url = element.url;
        const visible = element.visible;
        arraymap.push({stringkey, order, node, key, filename, url, visible})
      }
    }
  return arraymap;
  };

  

  function onCheckedChange(itm, index){
    
    const visible = !(itm.visible);
    const node = itm.node;
    console.log(data, "before setData");
    setData((prevState)=>{
      data[index].visible = visible;
      return [...prevState]
    });
    set(dref(dbr, storeID+'/offers/' + storeID+'/'+node), {
      key: itm.key,
      order: itm.order,
      url: itm.url,
      filename: itm.key,
      visible: visible,
    });
    console.log(data, "after setData");
  }

  function deleteModal(){
    setPictureeditor(false);
    setDeleteModalVisible(true);
  }

  function replaceModal(){
    setPictureeditor(false);
    setReplaceModalVisible(true);
  }

  function cancelModal(){
    setReplaceModalVisible(false);
    setDeleteModalVisible(false);
    setPictureeditor(true);
  }

  useEffect(() => {
    if(count)
    setSaveorder(true)
    else
    setSaveorder(false)
  }, [count]);

  function deleteImage(){
      setDeleteModalVisible(false);
      setPictureeditor(false);
      setSpinnerloader(true);
    const img = selectedimage.filename;
    if(selectedimage.url){
      const deleteRef = sref(storage, storeID+'/offers/'+storeID+'/'+img);
    const cdata = [];  
    deleteObject(deleteRef).then(() => {
      set(dref(dbr, storeID+'/offers/' + storeID+'/'+selectedimage.node), {
        key: selectedimage.key,
        order: selectedimage.order,
      }).then(()=>{
        data.forEach(element => {
          
          if(element.node === selectedimage.node){
            
            cdata.push({
              "stringkey": (selectedimage.key).toString(),
              "bg": selectedimage.bg,
              "filename": 0,
              "key": selectedimage.key,
              "node": selectedimage.node,
              "order": selectedimage.order,
              "url": 0
            })
          }
          else{
            cdata.push({
              "bg": element.bg,
              "stringkey": (element.key).toString(),
              "filename": element.filename,
              "key": element.key,
              "node": element.node,
              "order": element.order,
              "url": element.url
            })
          }
        });
        setData(cdata);
        setSpinnerloader(false);
        setLoader(true);
      });
    }).catch((error) => {
      console.log(error);
    });
    }else{
      alert("No image to delete");
      setSpinnerloader(false);
      setLoader(true);
    }
  }

  async function replaceImage(target){
    setReplaceModalVisible(false);
    if (target.files) {
      if (target.files.length !== 0) {
            const file = target.files[0];
            const selectedImage = URL.createObjectURL(file);
            setPictureeditor(false);
        setLoader(false);
        setSpinnerloader(true);
        const img = selectedimage.url;
        const imageRef = sref(storage, `${storeID}/offers/${storeID}/${img}`);
        if(img){
          getDownloadURL(imageRef).then((url)=>{
            imageRef.delete().then(()=>{
            uploadImage(selectedImage);
            }).catch((error)=>{
              console.log(error);
            }) 
          }).catch((error)=>{
            uploadImage(selectedImage);
          })
          }else{
            uploadImage(selectedImage);
          }
      }
    }     
  }

  async function uploadImage(url){
    const key = String(selectedimage.key);
    const response = await fetch(url);
    const blob = await response.blob();
    const childname = storeID+"/offers/"+storeID+"/"

    const storageRef = sref(storage, childname+'/'+key);

    // 'file' comes from the Blob or File API
    uploadBytes(storageRef, blob).then((snapshot) => {
      getURL()
    }).catch((error)=>{
      alert(error);
    });
  }

  async function getURL(){
    const img = selectedimage.key;
    const downloadRef = sref(storage, "/"+storeID+"/offers/"+storeID+"/"+img);
    getDownloadURL(downloadRef)
      .then((url)=>{
      saveData(url);
    })
  }
  
  function saveData(url){
    set(dref(dbr, storeID+'/offers/' + storeID+'/'+selectedimage.node), {
      key: selectedimage.key,
      order: selectedimage.order,
      url: url,
      filename: selectedimage.key,
      visible: true,
    
    }).then(()=>{
      const cdata = [];
      data.forEach(element => {
        if(element.node === selectedimage.node){
          cdata.push({
            "bg": selectedimage.bg,
            "filename": selectedimage.key,
            "node": selectedimage.node,
            "order": selectedimage.order,
            "key": selectedimage.key,
            "visible":true,
            "url": url,
          })
        }
        else{
          cdata.push({
            "bg": element.bg,
            "filename": element.filename,
            "key": element.key,
            "node": element.node,
            "order": element.order,
            "visible":element.visible,
            "url": element.url
          })
        }
      });
      setData(cdata);
      setPictureeditor(false);
      setSpinnerloader(false);
      setLoader(true);  
    });
  }

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  
  function closeModalLoader(){
    setModalLoader(false);
    setLoader(true);
  }

  function openModalImage(url){
    setSelectedModalImage(url);
    setLoader(false);
    setModalLoader(true);
  }

  

  return (
    <div>
      <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Offers</span>
            </div>
          </li>
        </ol>
      </nav>
      {readAccess? 
      <>
      {loader && 
        <div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                
                <div
                  class="relative overflow-x-auto shadow-md sm:rounded-lg"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <caption class="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                      Offers
                      <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">
                      Upload your advertisement banners as image(320 X 100 pixels). Customer view the advertisement in their client app. Drive more business 😊
                      </p>
                  </caption>
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                  <th scope="col" class="py-3 text-center pl-1 pr-2">
                  order
                  </th>
                  <th scope="col" class="py-3 text-center pr-1">
                  Thumbnail
                  </th>
                  <th scope="col" class="py-3 text-center pr-1">
                  Visible
                  </th>
                  <th scope="col" class="py-3 text-center pr-1">
                  Action
                  </th>
                  </tr>
                  </thead>
                  <tbody>
                  {data && data.map((item, index) =>
                    <Draggable draggableId={item.stringkey} key={item.stringkey} index={index}>
                      {(provided) => (
                        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <td class="py-3 flex justify-center pl-1 pr-2 text-gray-200 dark:text-white">
                          <svg class="w-5 h-5 rounded-full text-gray-700 dark:text-gray-400 fill-current" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="black" viewBox="0 0 24 24">
                            <path d="M18 11h-5V6h3l-4-4-4 4h3v5H6V8l-4 4 4 4v-3h5v5H8l4 4 4-4h-3v-5h5v3l4-4-4-4z"></path>
                          </svg>
                          </td>
                          <td class="py-3 text-center pr-2">
                          {item.url?
                           
                            <button onClick={()=>openModalImage(item.url)} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800">
                              <svg aria-hidden="true" class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M19 3H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM5 19V5h14l.002 14H5z"></path><path d="m10 14-1-1-3 4h12l-5-7z"></path></svg>
                            </button>
                           
                            :
                            <>
                              No Image
                            </>
                          }
                          </td>
                          <td class="py-3 text-center pr-2">
                              <label class="relative inline-flex items-center mr-5 cursor-pointer">
                            {offersupdate && <input type="checkbox" value="" class="sr-only peer" checked={item.visible} onChange={()=>onCheckedChange(item, index)} />}
                            <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                            <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Show</span>
                          </label>
                          </td>
                          <td class="py-3 text-center pr-1">
                          {offerscreate && <button onClick={()=>imageEdit(item)} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Edit</button>}
                          </td>
                      </tr>
                      )}
                    </Draggable>)}
                  {provided.placeholder}
                  </tbody>
                  </table>
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {saveorder &&
          <button onClick={() => saveOrder()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Save</button>
          }
       
        </div>
      }
      {modalLoader && 
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          
          <img class="rounded-t-lg" src={selectedModalImage} alt="" />
          
          <div class="flex justify-center w-full">
          <button onClick={()=>closeModalLoader()} type="button" class="text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">Close</button>
          </div>
        </div>
      }
      {pictureeditor &&
        <div>
          <div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div class="flex flex-col items-center pb-10">
              {selectedimage.url ?
                <>
                <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <img class="h-auto max-w-lg rounded-lg" src={selectedimage.url} alt="description" />
                </div>
                
                <div class="flex mt-4 space-x-3 md:mt-6">
                <button onClick={() => cancelImage()} type="button" class="px-3 py-2 text-xs text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                  <svg aria-hidden="true" class="w-5 h-5 mr-2 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path></svg>
                  Cancel
                </button>
                {offersupdate &&
                <button onClick={() => replaceModal()} type="button" class="px-3 py-2 text-xs text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  Replace
                  <svg aria-hidden="true" class="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
                }
                {offersdelete && 
                <button onClick={() => deleteModal()} type="button" class="px-3 py-2 text-xs text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                  Delete
                  <svg aria-hidden="true" class="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
                }
                </div>
                </>
                :
                <>
                <div class="flex items-center justify-center w-full h-48 bg-gray-300 rounded sm:w-96 dark:bg-gray-700">
                    <svg class="w-12 h-12 text-gray-200" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
                </div>
                <div class="flex mt-4 space-x-3 md:mt-6">
                <button onClick={() => cancelImage()} type="button" class="px-3 py-2 text-xs text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                  <svg aria-hidden="true" class="w-5 h-5 mr-2 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path></svg>
                  Cancel
                </button>
                <button onClick={() => replaceModal()} type="button" class="px-3 py-2 text-xs text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  Upload Image
                  <svg aria-hidden="true" class="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
                
                </div>
                </>
              }
      
              
            </div>
          </div>

          {/* {modalImage? <Image style={styles.editimage} source={{uri: modalImage}} />:<text>No image</text>}
          <View style={styles.editimagebutton}>
          <button onClick={() => cancelImage()}>Cancel</button>
          <button onClick={() => setReplaceModalVisible(true)}>Select Image</button>
          <button onClick={() => setDeleteModalVisible(true)}>Delete</button>
          </View>
          <Modal style={styles.editimagemodal}
            visible={deleteModalVisible}
            backdropStyle={styles.backdrop}
            onBackdropPress={() => setDeleteModalVisible(false)}>
            <Card disabled={true}>
              <text category='h6' style={styles.modalheader}>Are you sure you want to delete this image?</text>
              <text category='p2'>Selecting yes will permanantly delete the image!</text>
              <View style={styles.editimagebutton}>
              <button onClick={() => setDeleteModalVisible(false)}>
                Cancel
              </button>
              <button size='small' onClick={() => deleteImage()}>Yes</button>
              </View>
            </Card>
          </Modal>
          <Modal style={styles.editimagemodal}
            visible={replaceModalVisible}
            backdropStyle={styles.backdrop}
            onBackdropPress={() => setReplaceModalVisible(false)}>
            <Card disabled={true}>
              <text category='h6' style={styles.modalheader}>Choose an image</text>
              <text category='p2'>Ads are better viewed at 320X100 pixels</text>
              <View style={styles.editimagebutton}>
              <button onClick={() => setReplaceModalVisible(false)}>
                Cancel
              </button>
              <button size='small' onClick={() => replaceImage()}>OK</button>
              </View>
            </Card>
          </Modal> */}
        </div>      
      }
      {deleteModalVisible &&
        <div id="alert" class="p-4 mb-4 text-red-700 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
              <span class="sr-only">Info</span>
              <h3 class="text-lg font-medium">Delete Image?</h3>
            </div>
            
            <div class="flex">
              <button onClick={()=>deleteImage()} type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
                Delete
              </button>
              <button onClick={()=>cancelModal()} type="button" class="text-red-700 bg-transparent border border-red-700 hover:bg-red-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-500 dark:border-red-500 dark:text-red-400 dark:hover:text-white dark:focus:ring-red-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                Dismiss
              </button>
            </div>
        </div>
      }
      {replaceModalVisible &&
        <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
              <span class="sr-only">Choose an image</span>
              <h3 class="text-lg font-medium">Ads are better viewed at 320X200 pixels</h3>
            </div>
            
            <div class="flex">
              <button onClick={handleClick}  type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
                upload Image
              </button>
              <input ref={hiddenFileInput} onChange={(e) => replaceImage(e.target)} style={{display: 'none'}} class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" type="file"></input>
              <button onClick={()=>cancelModal()} type="button" class="text-red-700 bg-transparent border border-red-700 hover:bg-red-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-500 dark:border-red-500 dark:text-red-400 dark:hover:text-white dark:focus:ring-red-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                Dismiss
              </button>
            </div>
        </div>
      }
      {spinnerloader &&
        <div class="text-center">
          <div role="status">
              <svg aria-hidden="true" class="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-purple-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span class="sr-only">Processing Image...</span>
          </div>
        </div>
      }
      </>
      :
      <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">You do not have permission to view Offers. Contact your admin for permissions</h3>
          </div>
          <div class="flex">
            <button onClick={()=>navigate("/")} type="button" class="text-green-700 bg-transparent border border-green-700 hover:bg-green-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-green-500 dark:border-green-500 dark:text-green-400 dark:hover:text-white dark:focus:ring-green-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
              OK
            </button>
          </div>
      </div>
      }
    </div>
  );
}
