/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUserStore } from "../contexts/UserStoreContext";
import firebaseapp from '../firebase';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore, doc, query, collection, where, getDocs, getDoc  } from "firebase/firestore";
import { createCheckoutSession } from "../stripe/createCheckoutSession";
import moment from 'moment';
import ls from 'localstorage-slim';

export default function Recharge() {
  
  const { storeID, setStoreID, countryCode, setCountryCode } = useUserStore();
  const dbfirestore = getFirestore(firebaseapp);
  const functions = getFunctions(firebaseapp);
  const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);
  const [rechargeLoader, setRechargeLoader] = useState(false);
  const [rechargeLoaderInit, setRechargeLoaderInit] = useState(false)
  const [products, setProducts] = useState([]);
  const [load, setLoad] = useState(true);
  const [expDate, setExpDate] = useState(new Date());
  const [validDays, setValidDays] = useState(false);
  const [loader, setLoader] = useState(false);

  if(!storeID){
    setStoreID(userdata.storeID);
  }

  if(!countryCode){
    setCountryCode(userdata.country);
  }

  

  function recharge() {
    setLoader(false);
    setRechargeLoaderInit(true);
  }

  useEffect(() => {
    if(load){
    async function fetchData() {
      let storeid;
      if(!storeID){
        storeid = userdata.storeID;
        setStoreID(userdata.storeID);
      }else{
        storeid = storeID;
        setStoreID(storeID);
      }
      setLoad(false);
      //change this to store id
      const colRef = collection(dbfirestore, "customers", storeid, 'subscriptions');
      const qry = query(colRef, where('status', 'in', ['trialing', 'active']));
      const querySnapshot = await getDocs(qry);
      if(querySnapshot.size) {
        let dt = [];
        querySnapshot.forEach((doc) => {
          dt = moment(doc.data().current_period_end.toDate());
        });
        const DateNow = moment(new Date());
        const diffDays = dt.diff(DateNow, 'days')+1;
        setExpDate(diffDays);
        setValidDays(true);
        setLoader(true);
      }else{
        setValidDays(false);
        setLoader(true);
      }
      
        
    };
  fetchData();
  }
  }, [load])

  useEffect(() => {
    if(rechargeLoaderInit){
      async function loadProducts(){
        setRechargeLoaderInit(false);
        const q = query(collection(dbfirestore, "products"), where("stripe_metadata_countrycode", "==", countryCode));
        const querySnapshot = await getDocs(q);
        const productarray = [];
        querySnapshot.forEach((doc) => {
          console.log(doc.id, " => ", doc.data(), doc.data().name);
          const name = doc.data().name;
          const price = doc.data().stripe_metadata_price;
          const priceid = doc.data().stripe_metadata_priceid;
          const validity = doc.data().stripe_metadata_validity;
          const country = doc.data().stripe_metadata_country;
          const currencycode = doc.data().stripe_metadata_currencycode;
          productarray.push({name:name, price:price, priceid:priceid, validity:validity, currencycode:currencycode, country:country})

        });

        const productpromisearray = await Promise.all(productarray);
        setProducts(productpromisearray);
        setRechargeLoader(true);
      }
      loadProducts();
    }
  }, [rechargeLoaderInit])
  

  async function customerportal(){
    const docRef = doc(dbfirestore, "customers", storeID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
  
        functionRef({
            returnUrl: `${window.location.origin}/subscription`,
            locale: "auto",
          })
          .then((result) => {
            const data = result.data;
            window.location.assign(data.url);
          });
        
    } else {
        
        console.log('empty');
    }
  
  }

  
  

  return (
    <>
      <nav class="m-5 flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Recharge</span>
            </div>
          </li>
        </ol>
      </nav>
      {loader && 
        <div class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <div class="mt-10 flex flex-col items-center pb-10">
              <h5 class="mt-10 mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Thank you for being our valued customer</h5>
              <p class="mt-5 mb-3 font-normal text-gray-700 dark:text-gray-400">We value your trust and confidence in us and sincerely appreciate you! Your commitment as a customer is much appreciated. We look forward to serving you!</p>
              {validDays? 

              <h5 class="text-xl font-bold dark:text-white">Validity: {expDate} days left</h5>
              :
              <h5 class="text-xl font-bold dark:text-white">
                Thanks for using our solution - we hope we were able to meet your expectations. Just to let you know that your subscription expired and you won't be able to use our service any more. But it's not too late! You can gain immediate access to your saved data and preferences by renewing from recharge. In case you have any issues or questions, don't hesitate to talk to us.
              </h5>
              }
              <div class="flex w-full m-4 justify-around md:mt-6">
              <button onClick={()=>recharge()} class="block max-w-sm p-10 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                Recharge
              </button>
              <button onClick={()=>customerportal()} class="block max-w-sm p-10 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                Manage Subscription
              </button>    
              </div>
          </div>
        </div>
      }
      {rechargeLoader && 
      <div class="grid md:grid-cols-4 gap-6 m-5">
      {products.map((prod, key)=>(
        <div key={key} class="bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
        <h5 class="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">{prod.name}</h5>
        <div class="flex items-baseline text-gray-900 dark:text-white">
            <span class="text-xl font-semibold">{prod.currencycode}</span>
            <span class="text-xl font-semibold tracking-tight">&nbsp; {prod.price}</span>
            <span class="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">/{prod.validity} days</span>
        </div>
        <ul role="list" class="space-y-5 my-7">
            <li class="flex space-x-3">
                <svg aria-hidden="true" class="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-blue-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Check icon</title><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Validity: {prod.validity} days</span>
            </li>
            <li class="flex space-x-3">
                <svg aria-hidden="true" class="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-blue-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Check icon</title><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Price: {prod.currencycode} {prod.price}</span>
            </li>
            <li class="flex space-x-3">
                <svg aria-hidden="true" class="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-blue-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Check icon</title><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">Country: {prod.country}</span>
            </li>
        </ul>
        <button onClick={()=>createCheckoutSession(storeID, prod.priceid)} type="button" class="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-200 dark:focus:ring-blue-900 font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center">Choose plan</button>
        </div>
        
      ))}
      

      </div>
      }
      
    </>
  );
}
 