/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import { child, get, getDatabase, ref as dref, set, update } from "firebase/database";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from "../../contexts/UserStoreContext";
import firebaseapp from '../../firebase';
import ls from 'localstorage-slim';

export default function Shift({navigation, route}) {
  
  const navigate = useNavigate();
  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);
  const [listDataExist, setListDataExist] = useState(false);
  const [staffListInit, setStaffListInit] = useState(true);
  const [listLoader, setListLoader] = useState(false);
  const [listData, setListData] = useState('');
  const [prelimData, setPrelimData] = useState('');
  const todaysDate = new Date();
  const [displayDate, setDisplayDate] = useState(todaysDate);
  const [selectedDay, setSelectedDay] = useState(moment(todaysDate).format('dddd'))
  const [dateDataLoader, setDateDataLoader] = useState(false);
  const [scheduleLoader, setScheduleLoader] = useState(false);
  const [editItem, setEditItem] = useState('');
  const [showModal, setShowModal] = useState(false);
  let selectedDayChecked;
  let selectedDayStartTime;
  let selectedDayEndTime;
  const { storeID, setStoreID, countryCode, setCountryCode, staffupdate } = useUserStore();
  const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);


  if(!storeID){
    setStoreID(userdata.storeID);
  }
    
  if(!countryCode){
    setCountryCode(userdata.country);
  }


  useEffect(() => { 
    
    if(staffListInit){
      setStaffListInit(false);
      function listStaffData(){
        switch(selectedDay) {
          case 'Sunday': 
            selectedDayChecked = 'sunChecked';
            selectedDayStartTime = 'SunStartTime';
            selectedDayEndTime = 'SunEndTime'
            break;
          case 'Monday':
            selectedDayChecked = 'monChecked';
            selectedDayStartTime = 'MonStartTime';
            selectedDayEndTime = 'MonEndTime'
            break;
          case 'Tuesday':
            selectedDayChecked = 'tueChecked';
            selectedDayStartTime = 'TueStartTime';
            selectedDayEndTime = 'TueEndTime'
            break;
          case 'Wednesday':
            selectedDayChecked = 'wedChecked';
            selectedDayStartTime = 'WedStartTime';
            selectedDayEndTime = 'WedEndTime'
            break;
          case 'Thursday':
            selectedDayChecked = 'thuChecked';
            selectedDayStartTime = 'ThuStartTime';
            selectedDayEndTime = 'ThuEndTime'
            break;
          case 'Friday':
            selectedDayChecked = 'friChecked';
            selectedDayStartTime = 'FriStartTime';
            selectedDayEndTime = 'FriEndTime'
            break;
          case 'Saturday':
            selectedDayChecked = 'satChecked';
            selectedDayStartTime = 'SatStartTime';
            selectedDayEndTime = 'SatEndTime'
            break;
        }
        get(child(dbRef, `${storeID}/staff/${storeID}/`)).then(async (snapshot) => {
          const listdata = [];
          
          if (snapshot.exists()) {
            snapshot.forEach(element => {
              const key = element.key;
              const FriEndTime = element.child('FriEndTime').val(); 
              const FriStartTime = element.child('FriStartTime').val(); 
              const MonEndTime = element.child('MonEndTime').val(); 
              const MonStartTime = element.child('MonStartTime').val(); 
              const SatEndTime = element.child('SatEndTime').val(); 
              const SatStartTime = element.child('SatStartTime').val(); 
              const SunEndTime = element.child('SunEndTime').val(); 
              const SunStartTime = element.child('SunStartTime').val(); 
              const ThuEndTime = element.child('ThuEndTime').val(); 
              const ThuStartTime = element.child('ThuStartTime').val(); 
              const TueEndTime = element.child('TueEndTime').val(); 
              const TueStartTime = element.child('TueStartTime').val(); 
              const WedEndTime = element.child('WedEndTime').val(); 
              const WedStartTime = element.child('WedStartTime').val(); 
              const address = element.child('address').val(); 
              const email = element.child('email').val(); 
              const friChecked = element.child('friChecked').val(); 
              const monChecked = element.child('monChecked').val(); 
              const phonenumber = element.child('phonenumber').val();
              const satChecked = element.child('satChecked').val(); 
              const staffName = element.child('staffName').val(); 
              const sunChecked = element.child('sunChecked').val(); 
              const thuChecked = element.child('thuChecked').val(); 
              const tueChecked = element.child('tueChecked').val(); 
              const wedChecked = element.child('wedChecked').val(); 
              const selDayChecked = element.child(selectedDayChecked).val();
              const selDayStartTime = element.child(selectedDayStartTime).val();
              const selDayEndTime = element.child(selectedDayEndTime).val(); 
              
              listdata.push({
                key:key,
                FriEndTime: FriEndTime, 
                FriStartTime:FriStartTime, 
                MonEndTime:MonEndTime, 
                MonStartTime:MonStartTime, 
                SatEndTime:SatEndTime, 
                SatStartTime:SatStartTime, 
                SunEndTime:SunEndTime, 
                SunStartTime:SunStartTime, 
                ThuEndTime:ThuEndTime, 
                ThuStartTime:ThuStartTime, 
                TueEndTime:TueEndTime, 
                TueStartTime:TueStartTime, 
                WedEndTime:WedEndTime, 
                WedStartTime:WedStartTime, 
                address:address, 
                email:email, 
                friChecked:friChecked, 
                monChecked:monChecked, 
                phonenumber:phonenumber,
                satChecked:satChecked, 
                staffName:staffName, 
                sunChecked:sunChecked, 
                thuChecked:thuChecked, 
                tueChecked:tueChecked, 
                wedChecked:wedChecked, 
                selectedDayChecked:selDayChecked,
                selectedDayStartTime:selDayStartTime,
                selectedDayEndTime:selDayEndTime,
                })
            });
            
            const completedListData = await Promise.all(listdata);
            const dataExist = true;
            return [dataExist, completedListData];
            
          }
          else if(!snapshot.exists()){
            const dataExist = false;
            return [dataExist, 0];
            
          }
          
        }).then((dataExist)=>{
          if(dataExist[0]){
            setPrelimData(dataExist[1]);
            setDateDataLoader(true);
          }else{
            setListDataExist(false)
            setListLoader(true);
          }
        }).catch((error)=>{
          console.log(error);
        });
     }
     listStaffData();
    }
  }, [staffListInit]);

  function dateChange(dt){
    setSelectedDay(moment(dt).format('dddd'))
    setListDataExist(true);
    setDisplayDate(dt);
    setDateDataLoader(true);
  }

  useEffect(() => { 
    if(dateDataLoader){
      setDateDataLoader(false);
      function listStaffData(){
        setListDataExist(false);
        setListLoader(false);
        const refdate = moment(displayDate).format('DDMMMYYYY')
        get(child(dbRef, `${storeID}/shift/${storeID}/${refdate}`)).then(async (snapshot) => {
          const cdata = [];
          if (snapshot.exists()) {
            snapshot.forEach(element => {
              const key = element.key;
              const leave = element.child('leave').val(); 
              const startShifttime = element.child('selectedDayStartTime').val();
              const endShifttime = element.child('selectedDayStartTime').val();
              const startedShiftTime = element.child('startedShiftTime').val();
              const endedShiftTime = element.child('endedShiftTime').val();
              const holiday = element.child('selectedDayChecked').val();
              let stShifttime, enShifttime, stdShifttime, entShifttime, sdaychecked;
              
              if(holiday){
                sdaychecked = true;
              }else{
                sdaychecked = null;
              }
              
              
              if(startShifttime){
                stShifttime = moment(startShifttime).format('hh:mm a');
              }else{
                stShifttime = null
              }

              if(endShifttime){
                enShifttime = moment(endShifttime).format('hh:mm a');
              }else{
                enShifttime = null
              }

              if(startedShiftTime){
                stdShifttime = startedShiftTime;
              }else{
                stdShifttime = null
              }

              if(endedShiftTime){
                entShifttime = endedShiftTime;
              }else{
                entShifttime = null
              }

              cdata.push({
                key : key,
                leave: leave,
                selectedDayStartTime: stShifttime,
                selectedDayEndTime: enShifttime,
                startedShiftTime: stdShifttime,
                endedShiftTime: entShifttime,
                selectedDayChecked: sdaychecked,
              })
            });
            const completedListData = await Promise.all(cdata);
            
            Object.keys(completedListData).forEach((k) => {
              completedListData[k].selectedDayStartTime == null && delete completedListData[k].selectedDayStartTime
              completedListData[k].selectedDayEndTime == null && delete completedListData[k].selectedDayEndTime
              completedListData[k].startedShiftTime == null && delete completedListData[k].startedShiftTime
              completedListData[k].endedShiftTime == null && delete completedListData[k].endedShiftTime
              completedListData[k].selectedDayChecked == null && delete completedListData[k].selectedDayChecked
            
            })
            
            const map = new Map();
            prelimData.forEach(item => map.set(item.key, item));
            completedListData.forEach(item => map.set(item.key, {...map.get(item.key), ...item}));
            const mergeData = Array.from(map.values());
            const dataExist = true;
            return [dataExist, mergeData];
            
          }
          else{
            const dataExist = false;
            return [dataExist, 0];
          }
          
        }).then((dataExist)=>{
          if(dataExist[0]){
            setListData(dataExist[1]);
            setListDataExist(true);
            setListLoader(true);
          }else{

            const changedDateData = [];
            for (const key in prelimData) {
              if (Object.hasOwnProperty.call(prelimData, key)) {
                const element = prelimData[key];
                switch(selectedDay) {
                  case 'Sunday': 
                    selectedDayChecked = element.sunChecked;
                    selectedDayStartTime = element.SunStartTime;
                    selectedDayEndTime = element.SunEndTime
                    break;
                  case 'Monday':
                    selectedDayChecked = element.monChecked;
                    selectedDayStartTime = element.MonStartTime;
                    selectedDayEndTime = element.MonEndTime
                    break;
                  case 'Tuesday':
                    selectedDayChecked = element.tueChecked;
                    selectedDayStartTime = element.TueStartTime;
                    selectedDayEndTime = element.TueEndTime
                    break;
                  case 'Wednesday':
                    selectedDayChecked = element.wedChecked;
                    selectedDayStartTime = element.WedStartTime;
                    selectedDayEndTime = element.WedEndTime
                    break;
                  case 'Thursday':
                    selectedDayChecked = element.thuChecked;
                    selectedDayStartTime = element.ThuStartTime;
                    selectedDayEndTime = element.ThuEndTime
                    break;
                  case 'Friday':
                    selectedDayChecked = element.friChecked;
                    selectedDayStartTime = element.FriStartTime;
                    selectedDayEndTime = element.FriEndTime
                    break;
                  case 'Saturday':
                    selectedDayChecked = element.satChecked;
                    selectedDayStartTime = element.SatStartTime;
                    selectedDayEndTime = element.SatEndTime
                    break;
                }
                changedDateData.push({
                key:element.key,
                FriEndTime: element.FriEndTime, 
                FriStartTime:element.FriStartTime, 
                MonEndTime:element.MonEndTime, 
                MonStartTime:element.MonStartTime, 
                SatEndTime:element.SatEndTime, 
                SatStartTime:element.SatStartTime, 
                SunEndTime:element.SunEndTime, 
                SunStartTime:element.SunStartTime, 
                ThuEndTime:element.ThuEndTime, 
                ThuStartTime:element.ThuStartTime, 
                TueEndTime:element.TueEndTime, 
                TueStartTime:element.TueStartTime, 
                WedEndTime:element.WedEndTime, 
                WedStartTime:element.WedStartTime, 
                address:element.address, 
                email:element.email, 
                friChecked:element.friChecked, 
                monChecked:element.monChecked, 
                phonenumber:element.phonenumber,
                satChecked:element.satChecked, 
                staffName:element.staffName, 
                sunChecked:element.sunChecked, 
                thuChecked:element.thuChecked, 
                tueChecked:element.tueChecked, 
                wedChecked:element.wedChecked, 
                selectedDayChecked:selectedDayChecked,
                selectedDayStartTime:selectedDayStartTime,
                selectedDayEndTime:selectedDayEndTime,
                })

                
              }
            }

            
            
            setListData(changedDateData);
            setListDataExist(true)
            setListLoader(true);
          }
        }).catch((error)=>{
          console.log(error);
        });
      }
     listStaffData();
    }
  }, [dateDataLoader]);


  function detailStaff(item){
    
    setEditItem(item);
    setListLoader(false);
    setScheduleLoader(true);
  }

  function cancelHolidayOrLeave(){
    setShowModal(false);
    setScheduleLoader(true);
  }

  function applyHolidayOrLeave(){
    setScheduleLoader(false);
    setShowModal(true);
  }

  function applyLeave(){
    setShowModal(false);
    const key = editItem.key;
    const refdate = moment(displayDate).format('DDMMMYYYY')
    set(dref(dbr, storeID+'/shift/' + storeID+'/'+refdate+'/'+key), {
      leave: true,
      selectedDayChecked: true,
    }).then(()=>{
      setScheduleLoader(false);
      setEditItem({...editItem, leave:true, selectedDayChecked:true});
      setScheduleLoader(true);
    });
  }

  function applyHoliday(){
    setShowModal(false);
    const key = editItem.key;
    const refdate = moment(displayDate).format('DDMMMYYYY')
    set(dref(dbr, storeID+'/shift/' + storeID+'/'+refdate+'/'+key), {
      selectedDayChecked: false,
      leave: false
    }).then(()=>{
      setScheduleLoader(false);
      setEditItem({...editItem, leave:false, selectedDayChecked:false});
      setScheduleLoader(true);
    });
  }

  function cancelHoliday(){
    const key = editItem.key;
    const refdate = moment(displayDate).format('DDMMMYYYY')
    set(dref(dbr, storeID+'/shift/' + storeID+'/'+refdate+'/'+key), {
      selectedDayChecked: true,
    }).then(()=>{
      setScheduleLoader(false);
      setEditItem({...editItem, selectedDayChecked:true});
      setScheduleLoader(true);
    });
  }

  function cancelLeave(){
    const key = editItem.key;
    const refdate = moment(displayDate).format('DDMMMYYYY')
    set(dref(dbr, storeID+'/shift/' + storeID+'/'+refdate+'/'+key), {
      selectedDayChecked: true,
    }).then(()=>{
      setScheduleLoader(false);
      setEditItem({...editItem, selectedDayChecked:true, leave:false});
      setScheduleLoader(true);
    });
  }

  function endShift(dt){
    setScheduleLoader(false);
    const key = editItem.key;
    const refdate = moment(displayDate).format('DDMMMYYYY')
    update(dref(dbr, storeID+'/shift/' + storeID+'/'+refdate+'/'+key), {
      endedShiftTime: dt,
    }).then(()=>{
      setEditItem({...editItem, endedShiftTime:dt});
      setScheduleLoader(true);
    });

  }

  function startShift(dt){
    setScheduleLoader(false);
    const key = editItem.key;
    console.log(editItem, "editItem");
    const refdate = moment(displayDate).format('DDMMMYYYY')
    update(dref(dbr, storeID+'/shift/' + storeID+'/'+refdate+'/'+key), {

      key : key,
      leave: false,
      startedShiftTime: dt,
    }).then(()=>{
      
      setEditItem({...editItem, startedShiftTime:dt});
      setScheduleLoader(true);
    });
  }

  function cancelSchedule(){
    setScheduleLoader(false);
    setStaffListInit(true);
  }

  return (
    <div>
      <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <Link to='/staff' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              Staff
            </Link>
            </div>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Shift</span>
            </div>
          </li>
        </ol>
      </nav>
      {listLoader &&
      <>
      {listDataExist? 
      <>
        <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  selected={displayDate} onChange={(date) => dateChange(date)} dateFormat="d MMM yyyy"/>
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                  <th scope="col" class="py-3 px-6">
                      Name
                  </th>
                  <th scope="col" class="py-3 px-6">
                      Status
                  </th>
                  <th scope="col" class="py-3 px-6">
                      Action
                  </th>
              </tr>
          </thead>
          <tbody>
          {listData.map((item, key)=>(
            <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  <p>{item.staffName}</p>
                  {item.phonenumber.map((ph, ckey)=>(
                  <p key={ckey}>{ph.value}</p>
                  ))}
                </th>
                <td class="py-4 px-6">
                {!item.selectedDayChecked && item.leave &&
                  <p class="dark:text-white">Holiday</p>
                }
                {!item.selectedDayChecked && !item.leave &&
                  <p class="font-light text-gray-500 dark:text-gray-400">Holiday</p>
                }
                {item.selectedDayChecked && item.leave &&
                  <p class="font-light text-gray-500 dark:text-gray-400">On Leave</p>
                }
                {item.selectedDayChecked && !item.leave &&
                  <>
                  {item.selectedDayStartTime && 
                    <>
                    <p class="font-light text-gray-500 dark:text-gray-400">Working Hours</p>
                    <p class="font-bold text-black-500 dark:text-gray-400">{moment(item.selectedDayStartTime).format('hh:mm a')} - {moment(item.selectedDayEndTime).format('hh:mm a')}</p>
                    {item.startedShiftTime? 
                    <>
                      {item.endedShiftTime? 
                        <>
                        <p class="mt-5 font-light text-gray-500 dark:text-gray-400">Shift Hours</p>
                        <p class="font-light text-gray-500 dark:text-gray-400">{moment(item.startedShiftTime).format('hh:mm a')} : {moment(item.endedShiftTime).format('hh:mm a')}</p>
                        </>
                      :
                        <p class="font-light text-gray-500 dark:text-gray-400">{moment(item.startedShiftTime).format('hh:mm a')} - Working...</p>
                      }
                    </>
                    :
                    <p class="font-light text-gray-500 dark:text-gray-400">Shift not started</p>
                    }
                    </>
                  }
                  </>
                  
                }
                </td>
                
                <td class="py-4 px-6">
                  {staffupdate && 
                  <button onClick={()=>detailStaff(item)} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center mr-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-blue-800">
                    Edit
                  </button>
                  }
                </td>
            </tr>
          ))}    
          </tbody>
        </table>
      </>
      :
      <div id="alert" class="p-4 mb-4 text-yellow-700 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800" role="alert">
        <div class="flex items-center">
          <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
          <h3 class="text-lg font-medium">You have not added staff</h3>
        </div>
        <div class="mt-5 flex items-center">
          <button onClick={()=>navigate("/staff")} type="button" class="text-yellow-700 bg-transparent border border-yellow-700 hover:bg-yellow-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-yellow-500 dark:border-yellow-500 dark:text-yellow-400 dark:hover:text-white dark:focus:ring-yellow-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
            ok
          </button>
        </div>
      </div>
      }
      </>
      }
      {scheduleLoader &&
        <div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <div class="mb-5 flex justify-end">
        <button onClick={()=>cancelSchedule()} type="button" class="py-2.5 px-2.5 me-2 mb-2 text-sm font-medium text-gray-900 hover:text-red-700 dark:text-red-400">
          <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
        </svg>
          <span class="sr-only">Icon description</span>
        </button>
        </div>
          <div class="mt-3 mb-5 flex justify-center px-4 pt-4"><p class="text-gray-500 dark:text-gray-400">{moment(displayDate).format('DD/MMM/YYYY')}</p></div>
          <div class="flex flex-col items-center pb-10">
              <h5 class="mb-3 mb-1 text-xl font-medium text-gray-900 dark:text-white">{editItem.staffName}</h5>
              {editItem.phonenumber.map((ph, ckey)=>(
              <p key={ckey} class="text-sm text-gray-500 dark:text-gray-400">{ph.value}</p>
              ))}
               
              {editItem.selectedDayChecked && !editItem.leave &&
              <>
              {editItem.selectedDayStartTime &&
                <>
                <p class="mt-5 text-sm text-gray-500 dark:text-gray-400">Working Hours: {moment(editItem.selectedDayStartTime).format('hh:mm a')} - {moment(editItem.selectedDayEndTime).format('hh:mm a')}</p>
                {editItem.startedShiftTime?
                <>
                  {editItem.endedShiftTime &&
                    <>
                    <div class="mt-5">
                    <label htmlFor="success" class="block mb-2 text-sm font-medium text-green-700 dark:text-green-500">Shift Start Time:</label>
                    <DatePicker class="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500" selected={moment(editItem.startedShiftTime).toDate()} onChange={(date) => startShift(date)} timeIntervals={5} dateFormat="h:mm aa" showTimeSelect showTimeSelectOnly/>
                    </div>
                    <div class="mt-5">
                    <label htmlFor="success" class="block mb-2 text-sm font-medium text-green-700 dark:text-green-500">Shift End Time:</label>
                    <DatePicker class="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500" selected={moment(editItem.endedShiftTime).toDate()} onChange={(date) => endShift(date)} timeIntervals={5} dateFormat="h:mm aa" showTimeSelect showTimeSelectOnly/>
                    </div>
                    </>
                  }
                  {!editItem.endedShiftTime &&
                    <>
                    <div class="mt-5">
                    <label htmlFor="success" class="block mb-2 text-sm font-medium text-green-700 dark:text-green-500">Shift Started Time:</label>
                    <DatePicker class="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500" selected={moment(editItem.startedShiftTime).toDate()} onChange={(date) => startShift(date)} timeIntervals={5} dateFormat="h:mm aa" showTimeSelect showTimeSelectOnly/>
                    </div>
                    <div class="mt-5">
                    <label htmlFor="success" class="block mb-2 text-sm font-medium text-green-700 dark:text-green-500">Working now. End Shift?</label>
                    <DatePicker class="bg-red-50 border border-red-500 text-red-900 dark:text-red-400 placeholder-red-700 dark:placeholder-red-500 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-red-500"  selected={todaysDate} onChange={(date) => endShift(date)} timeIntervals={5} dateFormat="h:mm aa" showTimeSelect showTimeSelectOnly/>
                    </div>
                    </>
                  }

                </>
                :
                <div class="mt-5">
                <label htmlFor="success" class="block mb-2 text-sm font-medium text-green-700 dark:text-green-500">Change Shift Start Time: </label>
                <DatePicker class="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500" selected={todaysDate} onChange={(date) => startShift(date)} timeIntervals={5} dateFormat="h:mm aa" showTimeSelect showTimeSelectOnly/>
                </div>   
                }
                <button onClick={()=>applyHolidayOrLeave()} type="button" class="mt-5 text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">Apply Leave or Holiday</button>
                </>
              }
              </>
              }
              
              {editItem.selectedDayChecked && editItem.leave &&
                <button onClick={()=>cancelLeave()} type="button" class="mt-5 text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">Cancel Leave</button>
              }
              {!editItem.selectedDayChecked &&
                <button onClick={()=>cancelHoliday()} type="button" class="mt-5 text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">Cancel Holiday</button>
              }
          </div>
        </div>
      }
      {showModal &&
        <div id="alert" class="p-4 mb-4 text-yellow-700 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <h3 class="text-lg font-medium">Hold on!</h3>
          </div>
          <div class="mt-2 mb-4 text-sm">
            Are you sure you want to apply Leave or Holiday?
          </div>
          <div class="flex">
          <button onClick={()=>applyLeave()} type="button" class="text-white bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Leave
            </button>
            <button onClick={()=>applyHoliday()} type="button" class="text-white bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Holiday
            </button>
            <button onClick={()=>cancelHolidayOrLeave()} type="button" class="text-yellow-700 bg-transparent border border-yellow-700 hover:bg-yellow-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-yellow-500 dark:border-yellow-500 dark:text-yellow-400 dark:hover:text-white dark:focus:ring-yellow-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
              Dismiss
            </button>
          </div>
        </div>
      }
    </div>
  );
}
