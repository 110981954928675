import React, { useEffect, useState} from 'react';
import firebaseapp from '../firebase';
import { getFirestore, query, collection, where, getDocs, doc, setDoc } from "firebase/firestore"; 
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from "../contexts/UserStoreContext";
import ls from 'localstorage-slim';
import { getMessaging, isSupported, getToken, onMessage } from "firebase/messaging";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useUserAuth } from "../contexts/UserAuthContext";

function Landing({route, navigation}){
     const { userCategory, storeID, setStoreValidity } = useUserStore();
     const dbstore = getFirestore(firebaseapp);
     const auth = getAuth();
     const { user } = useUserAuth();
     const [loader, setLoader] = useState(false);
     const [initialLoader, setInitialLoader] = useState(true);
     const [loginLoader, setLoginLoader] = useState('');
     const [staffLoader, setStaffLoader] = useState(false);
     const [load, setLoad] = useState(true);
     const messaging = async() => await isSupported() && getMessaging(firebaseapp);
     const VAPID_KEY = "BJQFaosnus3DmTBBXKfHA1QePQlpSpiCKWBoa__A8FvdSlj-DkEWQg6OcLzrJYJOyBabMC5EzUZ9_fgtduzmh7U";
     const FCM_TOKEN_COLLECTION = "fcmTokens"
     let uid;  
     const navigate = useNavigate();

     onAuthStateChanged(auth, (userexist) => {
          if (userexist) {
               uid = userexist.uid;
          } 
     });
     
     
     
     useEffect(() => {
     if(load){
     console.log("inside landing");
     setLoad(false);
     async function fetchData() { 
     const colRef = collection(dbstore, "customers", storeID, 'subscriptions');
     const docSnap = await getDocs(colRef);
     console.log(docSnap.size, '42');
     if(docSnap.size) {
          const qry = query(colRef, where('status', 'in', ['trialing', 'active']));
          const querySnapshot = await getDocs(qry);
          if (querySnapshot.empty) {
               console.log("44");
               setInitialLoader(false);
               if(userCategory === "admin"){
               setLoader(true);
               }
               else{
               setStaffLoader(true);
               }
          }else{
          let dt;
          console.log("54");
          querySnapshot.forEach((doc) => {
          if(doc.id){
          dt =  doc.data().current_period_end.toDate();
          }
          });
          ls.set("val", dt, { encrypt: true })
          setStoreValidity(dt);
          saveMessagingDeviceToken();
          navigate('/',{ replace: true })
          } 
     } else {  
          setInitialLoader(false);
          const timer = setTimeout(() => {
          setLoad(true);
          }, 1000);
          setLoginLoader(true);
          return () => clearTimeout(timer);
     }
     };
     fetchData();
     }
     }, [dbstore, load, navigate, setStoreValidity, storeID, userCategory])
     
     async function requestNotificationsPermission(){
          const permission = await Notification.requestPermission();
          if(permission === 'granted'){
          await saveMessagingDeviceToken(uid)
          }else{
               console.log("change this in UI");
          }
     }

     async function saveMessagingDeviceToken(){
          console.log(user, "landing inside savemessagedeovetoken");
          let loginID;
          if(user.email){
               loginID = user.email;
          }else{
               loginID = user.phoneNumber;
          }
          const msg = await messaging();
          const fcmtoken = await getToken(msg, {vapidkey:VAPID_KEY})
          if(fcmtoken){
               const tokenRef = doc(dbstore, FCM_TOKEN_COLLECTION, uid);
               await setDoc(tokenRef, { fcmtoken,  loginID, Messenger: 'FCM'})

               onMessage(msg, (message) =>{
                    console.log(
                         'New Foreground messaging from Firebase Messaging!.',
                         message.notification
                    )
                    new Notification(message.notification.title, {body: message.notification.body})
               })
               
          }else{
               requestNotificationsPermission();
          }
     }

  return (
    <>
    {initialLoader &&
     <div class="text-center">
          <div role="status">
               <svg aria-hidden="true" class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
               </svg>
               <span class="sr-only">Creating New Store with 30 days trial period...</span>
          </div>
          </div>
    }
    {loader && 
     <section class="bg-white dark:bg-gray-900">
          <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 grid lg:grid-cols-2 gap-8 lg:gap-16">
               <div class="flex flex-col justify-center">
                    <h3 class="mb-4 text-xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">Subscription Expired</h3>
                    <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">Please recharge to continue..</p>
                    <Link to='/recharge'>
                         Manage Account
                    </Link>
               </div>
          </div>
     </section>     
    }
    {loginLoader && 
     <div class="text-center">
          <div role="status">
               <svg aria-hidden="true" class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
               </svg>
               <span class="sr-only">Enabling 30 days trial period...</span>
          </div>
          </div> 
    }
    {staffLoader &&
    
<section class="bg-white dark:bg-gray-900">
    <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16">
        <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">Sorry, Access to application is temporarily not allowed</h1>
        <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400">Contact your administrator</p>
     </div>
</section>


    }
    </>
  );
};


   
export default Landing;