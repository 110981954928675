//@ts-ignore
/* eslint-disable react-hooks/exhaustive-deps */
import { child, get, getDatabase, ref as dref, update } from "firebase/database";
import "font-awesome/css/font-awesome.min.css";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from "../contexts/UserStoreContext";
import firebaseapp from '../firebase';
import ls from 'localstorage-slim';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import {QRCodeSVG} from 'qrcode.react';
import { render, Printer, QRCode, Br, Line, Row, Cut } from 'react-thermal-printer';

export default function Laundry() {
  
  
  const [customerData] = useState('');
  const navigate = useNavigate();
  const [readAccess,setReadAccess] = useState(true);
  const { storeID, setStoreID, countryCode, setCountryCode, laundryread, laundryupdate } = useUserStore();
  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);
  const [editpickupstaffloginID, setEditpickupstaffloginID] = useState('')
  const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);
  if(!storeID){
    setStoreID(userdata.storeID);
  }

  if(!countryCode){
    setCountryCode(userdata.country);
  }
  const [initialLoader, setInitialLoader] = useState(true)
  const [listDataExist, setListDataExist] = useState(false)
  const [listLoader, setListLoader] = useState(false)
  const pickupTime = new Date();
  const [listData, setListData] = useState('');
  const [editLoader, setEditLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editIndex, setEditIndex] = useState(0);
  const [editNotes, setEditNotes] = useState('')
  const [editCustomerLoader, setEditCustomerLoader] = useState(false)          
  const [editItemValue, setEditItemValue] = useState({key: '', label: '', value:''})
  const [serviceSelectedValue, setServiceSelectedValue] = useState({key: '', label: '', value:''})
  const [editSelected, setEditSelected] = useState([]) 
  const [editServiceSelected, setEditServiceSelected] = useState([])
  const [editCustomerData, setEditCustomerData] = useState([])
  const [editKey, setEditKey] = useState('');
  const [editStaff, setEditStaff] = useState('');
  const [editStaffID, setEditStaffID] = useState('');
  const [editAddress, setEditAddress] = useState([]);
  const [editLaundryNotes, setEditLaundryNotes] = useState('')
  const [completeModal, setCompleteModal] = useState(false)
  const [editReferenceNumber, setEditReferenceNumber] = useState('')
  const [SKU, setSKU] = useState('');
  const [barcodeloader, setBarcodeloader] = useState(false);
  const [filterListData, setFilterListData] = useState('');
  const [query, setQuery] = useState('')
  const [modalview, setModalview] = useState(false)
  const [barcodenumbers, setBarcodenumbers] = useState(1)

  useEffect(() => { 
    if(laundryread){
      if(initialLoader){
        function listCollectionData(){
          setInitialLoader(false);
          get(child(dbRef, `${storeID}/laundry/${storeID}/`)).then(async (datasnapshot) => {
            const listdata = [];
            if (datasnapshot.exists()) {
              
              datasnapshot.forEach(snapshot => {
                snapshot.forEach(element => {
                  const key = element.key;
                  const cdata = element.child('customerData').val(); 
                  const paddress = element.child('pickupAddress').val(); 
                  const pnotes = element.child('pickupNotes').val()|| null; 
                  const pstaff = element.child('pickupStaff').val(); 
                  const ptime = element.child('pickupTime').val(); 
                  const status = element.child('status').val(); 
                  const addressIndex = element.child('addressIndex').val();
                  const itemValue = element.child('itemValue').val() || null;
                  const options = element.child('options').val() || [];
                  const pstaffID = element.child('pstaffID').val();
                  const pickupstaffloginID = element.child('pickupstaffloginID').val();
                  const selected = element.child('selected').val() || [];
                  const serviceOptions = element.child('serviceOptions').val() || [];
                  const serviceSelected = element.child('serviceSelected').val() || [];
                  const serviceselectedvalue = element.child('serviceSelectedValue').val() || [];
                  const editlaundrynotes = element.child('editLaundryNotes').val() || '';
                  const referenceNumber = element.child('referenceNumber').val() || '';
                  listdata.push({
                    key:key,
                    cdata: cdata, 
                    paddress:paddress, 
                    pnotes:pnotes, 
                    pstaff:pstaff, 
                    ptime:ptime, 
                    status:status,
                    referenceNumber:referenceNumber,
                    addressIndex:addressIndex,
                    itemValue:itemValue,
                    options: options,
                    pstaffID:pstaffID,
                    pickupstaffloginID:pickupstaffloginID,
                    selected:selected,
                    serviceOptions:serviceOptions,
                    serviceSelected:serviceSelected,
                    serviceselectedvalue:serviceselectedvalue,
                    editLaundryNotes:editlaundrynotes
                    })
  
                });
                
              });
              
              const completedListData = await Promise.all(listdata);
              const dataExist =  true;
              return [dataExist, completedListData];
            }
            else{
              const dataExist =  false;
              return [dataExist, 0];
            }
            
          }).then((dataExist)=>{
            if(dataExist[0]){
              setListData(dataExist[1]);
              setFilterListData(dataExist[1]);
              setListDataExist(true)
              setListLoader(true);
            }else{
              setListDataExist(false)
              setListLoader(true);
            }
          }).catch((error)=>{
            console.log(error);
          });
      }
      listCollectionData();
      }
    }else{
      setReadAccess(false);
    }
  }, [initialLoader]);

  function editCollection(item){
    console.log(item);
    setEditReferenceNumber(item.referenceNumber);
    setEditKey(item.key)
    setEditIndex(item.addressIndex);
    setEditAddress(item.paddress);
    setEditNotes(item.pnotes);
    setEditStaff(item.pstaff)
    setEditStaffID(item.pstaffID)
    setEditpickupstaffloginID(item.pickupstaffloginID);
    setEditItemValue(item.itemValue);
    setEditCustomerData(item.cdata);
    setListLoader(false);
    setEditSelected(item.selected);
    setEditServiceSelected(item.serviceSelected);

    if(item.serviceselectedvalue){
      console.log(item.serviceselectedvalue, "154");
      setServiceSelectedValue(item.serviceselectedvalue)
    }else{
      console.log(item.serviceselectedvalue, "157");
      const arry = []
      for (const key in item.serviceSelected) {
        if (Object.hasOwnProperty.call(item.serviceSelected, key)) {
          const element = item.serviceSelected[key];
          arry.push({key:key, label:element, value:false})
        }
      }
      setServiceSelectedValue(arry)
    }
    
    setEditLaundryNotes(item.editLaundryNotes);
    setEditCustomerLoader(true);
    setEditLoader(true);
  }

  

  const handleCheck = (event, index) => {
    console.log(event.target.value, index);
    let newArr = [...serviceSelectedValue];
    if (event.target.checked) {
    newArr[index]["value"] = true; 
    setServiceSelectedValue(newArr);
    }else{
      newArr[index]["value"] = false
      setServiceSelectedValue(newArr);
    }
  };

  function cancelEditLaundry(){
    setEditLoader(false);
    setListLoader(true);
  }

  function saveEditLaundry(){
    const key = editKey;
    const customerkey = editCustomerData.customerID;
    update(dref(dbr, storeID+'/laundry/' + storeID+'/'+customerkey+'/'+key), {
      editLaundryNotes:editLaundryNotes,
      serviceSelectedValue:serviceSelectedValue,
      status: "processing"
    }).then(()=>{
      setEditLoader(false);
      setShowModal(true);
    })
  
  }

  function goToLaundryList(){
    setShowModal(false);
    setCompleteModal(false);
    setInitialLoader(true);
  }

  function CompleteEditLaundry(){
    const key = editKey;
    const selectedAddress = editCustomerData.address[editIndex];
    

    const postData = {
      key:editKey,
      pickupAddress: selectedAddress,
      pickupTime: moment(pickupTime).toISOString(),
      pickupStaff: editStaff,
      pstaffID: editStaffID,
      pickupstaffloginID:editpickupstaffloginID,
      pickupNotes: editNotes,
      customerData:editCustomerData,
      addressIndex:editIndex,
      status: "new",
      referenceNumber:editReferenceNumber,
      selected:editSelected,
      itemValue:editItemValue,
      serviceSelected:editServiceSelected,
      editLaundryNotes:editLaundryNotes,
    }

    const deliveryData = {
      key:editKey,
      pickupAddress: selectedAddress,
      pickupTime: moment(pickupTime).toISOString(),
      pickupStaff: editStaff,
      pstaffID: editStaffID,
      pickupstaffloginID:editpickupstaffloginID,
      pickupNotes: editNotes,
      referenceNumber:editReferenceNumber,
      customerData:editCustomerData,
      addressIndex:editIndex,
      status: false,
      selected:editSelected,
      itemValue:editItemValue,
      serviceSelected:editServiceSelected,
      editLaundryNotes:editLaundryNotes,
    }

    const serData = {
      key:editKey,
      pickupAddress: selectedAddress,
      pickupTime: moment(pickupTime).toISOString(),
      pickupStaff: editStaff,
      pstaffID: editStaffID,
      pickupstaffloginID:editpickupstaffloginID,
      pickupNotes: editNotes,
      customerData:editCustomerData,
      referenceNumber:editReferenceNumber,
      addressIndex:editIndex,
      status: "completed",
      selected:editSelected,
      itemValue:editItemValue,
      serviceSelected:editServiceSelected,
      editLaundryNotes:editLaundryNotes,
    }
    const customerkey = editCustomerData.customerID;
    const updates = {};
    updates['/'+storeID+'/laundry/'+storeID+'/'+customerkey+'/'+key] = serData;
    updates['/'+storeID+'/delivery/'+storeID+'/'+key] = deliveryData;
    updates['/'+storeID+'/billing/'+storeID+'/'+customerkey+'/'+key] = postData;

    update(dref(dbr), updates).then(()=>{
      setEditLoader(false);
      setCompleteModal(true);
    })
  }

  function openBarcode(){
    setListLoader(false);
    setBarcodeloader(true);
  }
  
function closeBarcode(){
    setBarcodeloader(false);
    setListLoader(true);
  }

  function sucessBarcode(code){
    setSKU(code);
    setQuery(code);
    handleSearch(query);
    setBarcodeloader(false);
    setListLoader(true);
  }

  function handleSearch(e){
    setQuery(e);
    const elow = e.toLowerCase();
    const fData = listData.filter(item => {
      const min = moment(item.ptime).format("hh:mm a")
      const dt = moment(item.ptime).format("DD MMM")
      const yr = moment(item.ptime).format("YYYY")
      
      return (
        (item.status && item.status.toLowerCase().includes(elow)) ||
        (item.referenceNumber && item.referenceNumber.toLowerCase().includes(elow)) ||
        (item.paddress && item.paddress.address.toLowerCase().includes(elow)) ||
        (item.pstaff && item.pstaff.toLowerCase().includes(elow)) ||
        (item.cdata && item.cdata.fullName.toLowerCase().includes(elow)) ||
        (item.cdata && item.cdata.phoneNumber.toLowerCase().includes(elow)) ||
        (min && min.toLowerCase().includes(elow)) ||
        (dt && dt.toLowerCase().includes(elow)) ||
        (yr && yr.toLowerCase().includes(elow))

        );
    }
    );
    setFilterListData(fData);
  };

  async function print(){
    setModalview(false);
    setEditLoader(true);
    let qrArray = [];
    const data = await render(
      <Printer type="epson">
        {qrArray}
        <Cut />
      </Printer>
    );
    for (let index = 0; index < barcodenumbers; index++) {
      qrArray.push(<><QRCode align="center" content={editReferenceNumber} /><Br /></>);
    }
    const port = await window.navigator.serial.requestPort();
    await port.open({ baudRate: 9600 });

    const writer = port.writable?.getWriter();

    if (writer != null) {
      await writer.write(data);
      writer.releaseLock();
    }
  }

  function openmodal(){
    setEditLoader(false)
    setModalview(true)
  }

  function closemodal(){
    setModalview(false)
    setEditLoader(true)
  }
  
  return (
    <>
      <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Laundry</span>
            </div>
          </li>
        </ol>
      </nav>
      {readAccess? 
      <>
      {listLoader && 
        <>
          {listDataExist? 
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
              <div class="flex items-center justify-between">
              <p class="mb-5 ml-5 text-xl text-gray-900 dark:text-white">Laundry List</p>
              </div>
              <div class="relative z-0 mb-6 w-1/2 ml-5 mr-5 group ">
                <input value={query} onChange={(e)=>handleSearch(e.target.value)} type="text" name="sku" id="sku" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                <button onClick={()=>openBarcode()} type="button" class="absolute top-0 right-0 p-2.5 text-sm font-medium text-black dark:text-white bg-grey-700 rounded-r-lg border border-grey-700 hover:bg-grey-800 focus:ring-4 focus:outline-none focus:ring-grey-300 dark:bg-grey-600 dark:hover:bg-grey-700 dark:focus:ring-grey-800"><svg aria-hidden="true" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M6 8h2v8H6zm3 0h1v8H9zm8 0h1v8h-1zm-4 0h3v8h-3zm-2 0h1v8h-1z"></path></svg></button>
                <label htmlFor="sku" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Search</label>
              </div>  
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="py-3 px-6">
                          Date
                      </th>
                      <th scope="col" class="py-3 px-6">
                          Customer
                      </th>
                      <th scope="col" class="py-3 px-6">
                          services
                      </th>
                      <th scope="col" class="py-3 px-6">
                          Status
                      </th>
                      <th scope="col" class="py-3 px-6">
                          Action
                      </th>
                  </tr>
                </thead>
                <tbody>
                  {filterListData.map((item, key)=>(
                    <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <p>{moment(item.ptime).format('DD MMM YYYY')}</p>
                      <p>{moment(item.ptime).format('hh:mm a')}</p>
                      </th>
                      <td class="py-4 px-6">
                      <p>{item.cdata.fullName}</p>
                      <p>{item.cdata.phoneNumber}</p>
                      </td>
                      <td class="py-4 px-6">
                      {item.serviceSelected.map((service, skey)=>(
                        <p key={skey}>{service}</p>
                      ))}
                      </td>
                      <td class="py-4 px-6">
                      <div>
                      <p class="flex items-center">{item.referenceNumber}</p>
                      <div class="flex items-center">
                      {(item.status === "new") &&  <span class="flex w-3 h-3 bg-green-300 rounded-full"></span>}
                      {(item.status === "processing") &&  <span class="flex w-3 h-3 bg-yellow-300 rounded-full"></span>}
                      {(item.status === "completed") &&  <span class="flex w-3 h-3 bg-purple-300 rounded-full"></span>}
                      <span class="ml-2">{item.status}</span> 
                      </div>
                      </div>
                      </td>
                      <td class="py-4 px-6">
                        <button onClick={()=>editCollection(item)} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center mr-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-blue-800">
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}   
                </tbody> 
                </table>     
              </div>
            :
            <div id="alert" class="p-4 mb-4 text-yellow-700 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800" role="alert">
              <div class="flex items-center">
                <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                <h3 class="text-lg font-medium">You do not have laundry</h3>
              </div>
              <div class="mt-2 mb-4 text-sm">
                Create pickup or collection
              </div>
              <div class="flex">
                <button onClick={()=>navigate("/customers")} type="button" class="text-white bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800">
                  <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
                  Yes
                </button>
                <button onClick={()=>navigate("/")} type="button" class="text-yellow-700 bg-transparent border border-yellow-700 hover:bg-yellow-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-yellow-500 dark:border-yellow-500 dark:text-yellow-400 dark:hover:text-white dark:focus:ring-yellow-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                  Dismiss
                </button>
              </div>
            </div>
          } 
        </>
      }
      {barcodeloader && 
        <div>
        <BarcodeScannerComponent
          width={500}
          height={500}
          onUpdate={(err, result) => {
            if (result) sucessBarcode(result.text);
            else console.log(err)
          }}
          torch={false}
          
        />
        {SKU? <p class="text-sm text-gray-900 dark:text-white">Scanned value: {SKU}</p>:<p class="text-sm text-gray-900 dark:text-white">No value scanned...</p>}
        <button onClick={()=>closeBarcode()} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Close</button>
        </div>
      }
      {modalview &&
        <div class="relative p-4 w-full max-w-md max-h-full">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                    Number of barcodes
                </h3>
                <button onClick={()=>closemodal()} type="button" class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
            </div>
            <div class="p-4 md:p-5"> 
              <input value={barcodenumbers} onChange={(e)=>setBarcodenumbers(e.target.value)} type="number" name="number" id="number" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="name@company.com" required />
              <button type="button" onClick={()=>print()} class="w-full mt-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Continue Print</button>
            </div>
          </div>
        </div> 
      }
      {editLoader &&
        <div class="w-full max-w-lg bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          {editCustomerLoader &&
            <div class="flex flex-col ml-5 mr-5 pb-10">
            <div class="grid grid-cols-2 gap-4 mt-5">
              <div>
                <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">{editCustomerData.fullName}</h5>
                <p class="text-sm text-gray-500 dark:text-gray-400">{editAddress.building && <>Building: {editAddress.building}</>} {editAddress.zone && <>Zone: {editAddress.zone}</>}</p>
                {editAddress.street && <p class="text-sm text-gray-500 dark:text-gray-400">Street: {editAddress.street}</p>}
                {editAddress.address && <p class="text-sm text-gray-500 dark:text-gray-400">City & Country: {editAddress.address}</p>}
                <span class="text-sm text-gray-500 dark:text-gray-400">Phonenumber: {editCustomerData.phoneNumber}</span>
                {editCustomerData.alternateNumber.map((ph, index)=>(
                  <p key={index} class="text-sm text-gray-500 dark:text-gray-400">Alternate Phone: {ph.alternateNumber}</p>
                ))}
                {editCustomerData.email && <span class="text-sm text-gray-500 dark:text-gray-400">{customerData.email}</span>}
                <p class="text-sm text-gray-500 dark:text-gray-400">{editReferenceNumber}</p>
                <p class="text-sm text-gray-500 dark:text-gray-400">{moment(pickupTime).format("DD MMM YYYY hh:mm a")}</p>
              
              </div>
              {editReferenceNumber &&
                <div class="items-center align-center">
            <QRCodeSVG 
              size={70}
              style={{ marginBottom:10 }}
              value={editReferenceNumber}
              />
            <button onClick={()=>openmodal()} type="button" class="text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">Print</button>
                </div>
              }
            </div>    
            <label htmlFor="message" class="block mt-2 text-sm font-medium text-gray-900 dark:text-white">Notes</label>
            <p class="text-sm text-gray-500 dark:text-gray-400">{editNotes}</p>
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <caption class="text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            <label htmlFor="message" class="block mt-2 text-sm font-medium text-gray-900 dark:text-white">Dress Items</label>
            </caption>
            <thead>
            <tr>
              <th class="py-2">
                  Dress
              </th>
              <th class="py-2">
                  Quantity
              </th>
            </tr>
            </thead>
            <tbody>
            {editItemValue.map((item, index)=>(
            <tr key={index} class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
            <td class="py-2">
            {item.label}
            </td>  
            <td class="py-2">
            {item.value}
            </td>
            </tr>
            ))}   
            </tbody>
            </table>
            <label htmlFor="message" class="block mt-5 mb-2 text-sm font-medium text-gray-900 dark:text-white">Services</label>
            {editServiceSelected.map((service, index)=>(
              <div key={index} class="flex items-center mb-4">
              <kbd class="px-2 py-1.5 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500">{service}</kbd>
              </div>
            ))}
            <label htmlFor="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Notes</label>
            <textarea value={editLaundryNotes} onChange={(e)=>setEditLaundryNotes(e.target.value)} id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-purple-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-purple-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-purple-500"></textarea>
            <div class="flex mt-4 space-x-3 md:mt-6">
            <button onClick={()=>cancelEditLaundry()} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
            
            {laundryupdate && <button onClick={()=>saveEditLaundry()} type="button" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900">Save</button>}
            {laundryupdate && <button onClick={()=>CompleteEditLaundry()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Complete</button>}
            </div>
            </div>
          }
        </div>
      }
      {showModal && 
        <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Laundry Service updated successfully</h3>
          </div>
          
          <div class="flex">
            <button onClick={()=>navigate("/")} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Home
            </button>
            <button onClick={()=>goToLaundryList()} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Laundry List
            </button>
            
          </div>
        </div>
      }
      {completeModal && 
        <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Laundry Service completed successfully</h3>
          </div>
          
          <div class="flex">
            <button onClick={()=>navigate("/")} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Home
            </button>
            <button onClick={()=>goToLaundryList()} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Laundry List
            </button>
            <button onClick={()=>navigate("/invoice")}  type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Billing
            </button>
            
            
          </div>
        </div>
      }
      </>
      :
      <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">You do not have permission to view Laundry. Contact your admin for permissions</h3>
          </div>
          <div class="flex">
            <button onClick={()=>navigate("/")} type="button" class="text-green-700 bg-transparent border border-green-700 hover:bg-green-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-green-500 dark:border-green-500 dark:text-green-400 dark:hover:text-white dark:focus:ring-green-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
              OK
            </button>
          </div>
      </div>
      }
    </>
  );
}
 