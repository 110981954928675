import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/libs/boxicons-2.1.1/css/boxicons.min.css';
import ProtectedRoute from './components/ProtectedRoute';
import { ThemeProvider } from './contexts/ThemeContext';
import { UserAuthContextProvider } from "./contexts/UserAuthContext";
import { UserStoreContextProvider } from "./contexts/UserStoreContext";
import MainLayout from './layout/MainLayout';
import Accounts from './pages/Accounts';
import Collection from './pages/Collection';
import Customers from './pages/Customers';
import Delivery from './pages/Delivery';
import Help from './pages/Help';
import Home from './pages/Home';
import Inventory from './pages/Inventory';
import InventoryAudit from './pages/inventory/InventoryAudit';
import InventoryList from './pages/inventory/InventoryList';
import InventoryNewitem from './pages/inventory/InventoryNewitem';
import InventoryOrder from './pages/inventory/InventoryOrder';
import InventorySettings from './pages/inventory/InventorySettings';
import InventoryUpdate from './pages/inventory/InventoryUpdate';
import InventoryVendor from './pages/inventory/InventoryVendor';
import Invoice from './pages/Invoice';
import Laundry from './pages/Laundry';
import Login from './pages/login';
import Landing from './pages/Landing';
import Offers from './pages/Offers';
import Package from './pages/Package';
import Pickup from './pages/Pickup';
import Pricing from './pages/Pricing';
import Recharge from './pages/Recharge';
import Reports from './pages/Reports';
import AccountsReport from './pages/Reports/AccountsReport';
import AuditReport from "./pages/Reports/AuditReport";
import CustomerReport from "./pages/Reports/CustomerReport";
import InventoryReport from "./pages/Reports/InventoryReport";
import LaundryReport from "./pages/Reports/LaundryReport";
import PickupReport from "./pages/Reports/PickupReport";
import ServiceReport from "./pages/Reports/ServiceReport";
import Settings from './pages/settings';
import Laundryitems from "./pages/Settings/Laundryitems";
import Laundryservices from "./pages/Settings/Laundryservices";
import Pickupstaff from "./pages/Settings/Pickupstaff";
import Store from './pages/Settings/Store';
import Staff from './pages/Staff';
import Employees from './pages/Staff/Employees';
import Permission from './pages/Staff/Permission';
import Shift from './pages/Staff/Shift';
import Task from './pages/Staff/Task';
import './scss/App.scss';

function App() { 
    return (
    <BrowserRouter>
        <ThemeProvider>
            <UserAuthContextProvider>
            <UserStoreContextProvider>
                <Routes>
                    <Route path="Login" element={<Login />} />
                    <Route path="/landing" element={<Landing />} />
                    <Route path="/Recharge" element={<ProtectedRoute><Recharge /></ProtectedRoute>}></Route>
                    
                    <Route path="/" element={<ProtectedRoute><MainLayout /></ProtectedRoute>}>
                        
                        <Route index element={<ProtectedRoute><Home /></ProtectedRoute>} />
                        <Route path="accounts" element={<ProtectedRoute><Accounts/></ProtectedRoute>} />
                        <Route path="customers" element={<ProtectedRoute><Customers/></ProtectedRoute>} />
                        <Route path="delivery" element={<ProtectedRoute><Delivery/></ProtectedRoute>} />
                        <Route path="help" element={<ProtectedRoute><Help/></ProtectedRoute>} />
                        <Route path="inventory" element={<ProtectedRoute><Inventory/></ProtectedRoute>} />
                        <Route path="inventorySettings" element={<ProtectedRoute><InventorySettings/></ProtectedRoute>} />
                        <Route path="inventoryList" element={<ProtectedRoute><InventoryList/></ProtectedRoute>} />
                        <Route path="inventoryUpdate" element={<ProtectedRoute><InventoryUpdate/></ProtectedRoute>} />
                        <Route path="inventoryAudit" element={<ProtectedRoute><InventoryAudit/></ProtectedRoute>} />
                        <Route path="inventoryNewitem" element={<ProtectedRoute><InventoryNewitem/></ProtectedRoute>} />
                        <Route path="inventoryVendor" element={<ProtectedRoute><InventoryVendor/></ProtectedRoute>} />
                        <Route path="inventoryOrder" element={<ProtectedRoute><InventoryOrder/></ProtectedRoute>} />
                        <Route path="invoice" element={<ProtectedRoute><Invoice/></ProtectedRoute>} />
                        <Route path="laundry" element={<ProtectedRoute><Laundry/></ProtectedRoute>} />
                        <Route path="offers" element={<ProtectedRoute><Offers/></ProtectedRoute>} />
                        <Route path="package" element={<ProtectedRoute><Package/></ProtectedRoute>} />
                        <Route path="collection" element={<ProtectedRoute><Collection/></ProtectedRoute>} />
                        <Route path="pickup" element={<ProtectedRoute><Pickup/></ProtectedRoute>} />
                        <Route path="pricing" element={<ProtectedRoute><Pricing/></ProtectedRoute>} />
                        <Route path="reports" element={<ProtectedRoute><Reports/></ProtectedRoute>} />
                        <Route path="accountsreport" element={<ProtectedRoute><AccountsReport/></ProtectedRoute>} />
                        <Route path="auditreport" element={<ProtectedRoute><AuditReport/></ProtectedRoute>} />
                        <Route path="customerreport" element={<ProtectedRoute><CustomerReport/></ProtectedRoute>} />
                        <Route path="inventoryreport" element={<ProtectedRoute><InventoryReport/></ProtectedRoute>} />
                        <Route path="laundryreport" element={<ProtectedRoute><LaundryReport/></ProtectedRoute>} />
                        <Route path="servicereport" element={<ProtectedRoute><ServiceReport/></ProtectedRoute>} />
                        <Route path="pickupreport" element={<ProtectedRoute><PickupReport/></ProtectedRoute>} />
                        <Route path="staff" element={<ProtectedRoute><Staff/></ProtectedRoute>} />
                        <Route path="employees" element={<ProtectedRoute><Employees/></ProtectedRoute>} />
                        <Route path="shift" element={<ProtectedRoute><Shift/></ProtectedRoute>} />
                        <Route path="task" element={<ProtectedRoute><Task/></ProtectedRoute>} />
                        <Route path="permission" element={<ProtectedRoute><Permission/></ProtectedRoute>} />
                        <Route path="store" element={<ProtectedRoute><Store/></ProtectedRoute>} />
                        <Route path="laundryitems" element={<ProtectedRoute><Laundryitems/></ProtectedRoute>} />
                        <Route path="laundryservices" element={<ProtectedRoute><Laundryservices/></ProtectedRoute>} />
                        <Route path="pickupstaff" element={<ProtectedRoute><Pickupstaff/></ProtectedRoute>} />
                        <Route path="settings" element={<ProtectedRoute><Settings/></ProtectedRoute>} />
                    </Route>
                </Routes>
            </UserStoreContextProvider>
            </UserAuthContextProvider>
        </ThemeProvider>
    </BrowserRouter>
    )
}

export default App
