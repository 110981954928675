/* eslint-disable react-hooks/exhaustive-deps */
import { child, get, getDatabase, ref as dref, set, update } from "firebase/database";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from "../contexts/UserStoreContext";
import firebaseapp from '../firebase';
import ls from 'localstorage-slim';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import {QRCodeSVG} from 'qrcode.react';
import { render, Printer, QRCode, Br, Line, Row, Cut } from 'react-thermal-printer';

export default function Delivery() {
  
    
  const navigate = useNavigate();
  const [readAccess,setReadAccess] = useState(true);
  const { storeID, setStoreID, countryCode, setCountryCode, deliveryread } = useUserStore();
  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);
  const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);
  if(!storeID){
    setStoreID(userdata.storeID);
  }

  if(!countryCode){
    setCountryCode(userdata.country);
  }
  const [initialLoader, setInitialLoader] = useState(true)
  const [listDataExist, setListDataExist] = useState(false)
  const [listLoader, setListLoader] = useState(false)
  const [listData, setListData] = useState('');
  const [editItem, setEditItem] = useState('');
  const [editLoader, setEditLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editIndex, setEditIndex] = useState(0);
  const [editDate, setEditDate] = useState(new Date());
  const [editNotes, setEditNotes] = useState('')
  const [stateIndex, setStateIndex] = useState(0);
  const [showCollectionModal, setShowCollectionModal] = useState(false);
  const [editDeliverystaff, setEditDeliverystaff] = useState('')
  const [editDeliverystaffID, setEditDeliverystaffID] = useState('')
  const noDeliverystaff = false
  const [resassignstaff, setResassignstaff] = useState('')
  const [reassingmodal, setReassingmodal] = useState(false)
  const [rindex, setRindex] = useState('')
  const [editItemValue, setEditItemValue] = useState([])
  const [editLaundryNotes, setEditLaundryNotes] = useState('')
  const [editReferenceNumber, setEditReferenceNumber] = useState('')
  const [SKU, setSKU] = useState('');
  const [barcodeloader, setBarcodeloader] = useState(false);
  const [filterListData, setFilterListData] = useState('');
  const [query, setQuery] = useState('')
  const [modalview, setModalview] = useState(false)
  const [barcodenumbers, setBarcodenumbers] = useState(1)

  useEffect(() => { 
    if(deliveryread){
      if(initialLoader){
        function listDeliveryData(){
          setInitialLoader(false);
          get(child(dbRef, `${storeID}/delivery/${storeID}/`)).then(async (snapshot) => {
            const listdata = [];
            if (snapshot.exists()) {
              snapshot.forEach(element => {
                const key = element.key;
                const cdata = element.child('customerData').val(); 
                const paddress = element.child('pickupAddress').val(); 
                const pnotes = element.child('pickupNotes').val(); 
                const pstaff = element.child('pickupStaff').val(); 
                const pstaffID = element.child('pickupStaffID').val(); 
                const pickupstaffloginID = element.child('pickupstaffloginID').val(); 
                const ptime = element.child('pickupTime').val(); 
                const status = element.child('status').val(); 
                const addressIndex = element.child('addressIndex').val();
                const editLaundryNotes = element.child('editLaundryNotes').val() || '';
                const itemValue = element.child('itemValue').val() || null;
                const referenceNumber = element.child('referenceNumber').val() || null;
                listdata.push({
                  key:key,
                  cdata: cdata, 
                  paddress:paddress, 
                  pnotes:pnotes, 
                  pstaff:pstaff, 
                  ptime:ptime, 
                  status:status,
                  pstaffID:pstaffID,
                  pickupstaffloginID:pickupstaffloginID,
                  addressIndex:addressIndex,
                  editLaundryNotes:editLaundryNotes,
                  itemValue:itemValue,
                  referenceNumber:referenceNumber
                  })

              });
              const completedListData = await Promise.all(listdata);
              const dataExist =  true;
              return [dataExist, completedListData];;
            }
            else{
              const dataExist =  false;
              return [dataExist, 0];;
            }
            
          }).then((dataExist)=>{
            if(dataExist[0]){
              setListData(dataExist[1]);
              setFilterListData(dataExist[1]);
              setListDataExist(true)
              setListLoader(true);
            }else{
              setListDataExist(false)
              setListLoader(true);
            }
          }).catch((error)=>{
            console.log(error);
          });
      }
      listDeliveryData();
      }
    }else{
      setReadAccess(false);
    }
  }, [initialLoader]);

  function showmap(lat, long){
    console.log(lat, long);
  }

  function showDeliveryList(){
    setShowModal(false);
    setShowCollectionModal(false);
    setInitialLoader(true);
  }

  function editDelivery(item){
    if(item.status === "new"){
      setStateIndex(0);
    }else if(item.status === "assigned"){
      setStateIndex(1)
    }else if(item.status === "picked"){
      setStateIndex(2)
    }else{
      setStateIndex(3)
    }
    setEditIndex(item.addressIndex);
    setEditReferenceNumber(item.referenceNumber);
    setEditDate(moment(item.ptime).toDate());
    setEditNotes(item.pnotes || '');
    setEditDeliverystaff(item.pstaff);
    setEditDeliverystaffID(item.pstaffID)
    setEditItemValue(item.itemValue);
    setEditLaundryNotes(item.editLaundryNotes || '');
    setEditItem(item);
    setListLoader(false);
    setEditLoader(true);
  }

  
  function cancelEditDelivery(){
    setEditLoader(false);
    setListLoader(true);
  }
  
  

  function submitEditDelivery(){
    
    let editStatus;
    if(stateIndex === 0){
      editStatus = 'new'
    }else if(stateIndex === 1){
      editStatus = 'assigned'
    }else if(stateIndex === 2){
      editStatus = 'picked'
    }else{
      editStatus = 'delivered'
    }
    const selectedAddress = editItem.cdata.address[editIndex];
    const key = editItem.key;

    const pickupUpdate = update(dref(dbr, storeID+'/delivery/' + storeID+'/'+key), {
      pickupAddress: selectedAddress,
      pickupTime: moment(editDate).toISOString(),
      pickupNotes: editNotes,
      addressIndex:editIndex,
      status: editStatus
    })

    const collectionCreate = set(dref(dbr, storeID+'/collection/' + storeID+'/'+key), {
      pickupAddress: selectedAddress,
      pickupTime: moment(editDate).toISOString(),
      pickupStaff: editDeliverystaff,
      pickupStaffID: editDeliverystaffID,
      pickupstaffloginID:editItem.pickupstaffloginID,
      pickupNotes: editNotes,
      addressIndex:editIndex,
      status: "new",
      customerData: editItem.cdata,
      key: key
    })

    if(stateIndex === 3){
      const finalUpdate = [pickupUpdate, collectionCreate];
      const finalPromise = Promise.all(finalUpdate)
      finalPromise.then(()=>{
        setListLoader(false);
        setEditLoader(false);
        setShowCollectionModal(true);
      })
    }else{
      pickupUpdate.then(()=>{
        setListLoader(false);
        setEditLoader(false);
        setShowModal(true);
      })
    }

  }

  

  function reassignStaff(){
    get(child(dbRef, `${storeID}/pickupstaff/${storeID}/pickupstaff`)).then(async (snapshot) => {
      const listdata = [];
      snapshot.forEach(element => {
        const key = element.key;
        const staffName = element.val();
        listdata.push({
          key:key,
          staffName:staffName
        });
      });
      const completedListData = await Promise.all(listdata);
      setEditLoader(false);
      setResassignstaff(completedListData);
      setReassingmodal(true);
      
      
    }).catch((error)=>{
      console.log(error);
    });
    setEditLoader(false);
  }

  function cancelReassign(){
    setReassingmodal(false);
    setEditLoader(true);
  }

  function submitReassign(){
    const newArr = [...resassignstaff]
    const pstaff = newArr[rindex].staffName
    const psaffid = newArr[rindex].key
    const pstaffloginID = newArr[rindex].loginID
    const key = editItem.key;
    update(dref(dbr, storeID+'/delivery/' + storeID+'/'+key), {
      pickupStaff: pstaff,
      pstaffID: psaffid,
      pstaffloginID:pstaffloginID
    }).then(()=>{
      setReassingmodal(false);
      setListLoader(false);
        setEditLoader(false);
        setShowCollectionModal(true);
    })
  }

  function openBarcode(){
    setListLoader(false);
    setBarcodeloader(true);
  }
  
function closeBarcode(){
    setBarcodeloader(false);
    setListLoader(true);
  }

  function sucessBarcode(code){
    setSKU(code);
    setQuery(code);
    handleSearch(query);
    setBarcodeloader(false);
    setListLoader(true);
  }

  function handleSearch(e){
    setQuery(e);
    const elow = e.toLowerCase();
    const fData = listData.filter(item => {
      const min = moment(item.ptime).format("hh:mm a")
      const dt = moment(item.ptime).format("DD MMM")
      const yr = moment(item.ptime).format("YYYY")
      
      return (
        (item.status && item.status.toLowerCase().includes(elow)) ||
        (item.referenceNumber && item.referenceNumber.toLowerCase().includes(elow)) ||
        (item.paddress && item.paddress.address.toLowerCase().includes(elow)) ||
        (item.pstaff && item.pstaff.toLowerCase().includes(elow)) ||
        (item.cdata && item.cdata.fullName.toLowerCase().includes(elow)) ||
        (item.cdata && item.cdata.phoneNumber.toLowerCase().includes(elow)) ||
        (min && min.toLowerCase().includes(elow)) ||
        (dt && dt.toLowerCase().includes(elow)) ||
        (yr && yr.toLowerCase().includes(elow))

        );
    }
    );
    setFilterListData(fData);
  };

  async function print(){
    setModalview(false);
    setEditLoader(true);
    let qrArray = [];
    const data = await render(
      <Printer type="epson">
        {qrArray}
        <Cut />
      </Printer>
    );
    for (let index = 0; index < barcodenumbers; index++) {
      qrArray.push(<><QRCode align="center" content={editItem.referenceNumber} /><Br /></>);
    }
    const port = await window.navigator.serial.requestPort();
    await port.open({ baudRate: 9600 });

    const writer = port.writable?.getWriter();

    if (writer != null) {
      await writer.write(data);
      writer.releaseLock();
    }
  }

  function openmodal(){
    setEditLoader(false)
    setModalview(true)
  }

  function closemodal(){
    setModalview(false)
    setEditLoader(true)
  }

  return (
    <>
    <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Delivery</span>
            </div>
          </li>
        </ol>
      </nav>
      {readAccess? 
      <>
    {listLoader && 
        <>
          {listDataExist? 
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
              <div class="flex items-center justify-between">
              <p class="mb-5 ml-5 text-xl text-gray-900 dark:text-white">Delivery List</p>
              </div>
              <div class="relative z-0 mb-6 w-1/2 ml-5 mr-5 group ">
                <input value={query} onChange={(e)=>handleSearch(e.target.value)} type="text" name="sku" id="sku" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                <button onClick={()=>openBarcode()} type="button" class="absolute top-0 right-0 p-2.5 text-sm font-medium text-black dark:text-white bg-grey-700 rounded-r-lg border border-grey-700 hover:bg-grey-800 focus:ring-4 focus:outline-none focus:ring-grey-300 dark:bg-grey-600 dark:hover:bg-grey-700 dark:focus:ring-grey-800"><svg aria-hidden="true" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M6 8h2v8H6zm3 0h1v8H9zm8 0h1v8h-1zm-4 0h3v8h-3zm-2 0h1v8h-1z"></path></svg></button>
                <label htmlFor="sku" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Search</label>
              </div>
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="py-3 px-6">
                          Date
                      </th>
                      <th scope="col" class="py-3 px-6">
                          Customer
                      </th>
                      <th scope="col" class="py-3 px-6">
                          Ref Number
                      </th>
                      <th scope="col" class="py-3 px-6">
                          staff & Status
                      </th>
                      <th scope="col" class="py-3 px-6">
                          Action
                      </th>
                  </tr>
                </thead>
                <tbody>
                  {filterListData.map((item, key)=>(
                    <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <p>{moment(item.ptime).format('DD MMM YYYY')}</p>
                      <p>{moment(item.ptime).format('hh:mm a')}</p>
                      </th>
                      <td class="py-4 px-6">
                      <p>{item.cdata.fullName}</p>
                      <p>{item.cdata.phoneNumber}</p>
                      </td>
                      <td class="py-4 px-6">
                      {item.referenceNumber}
                      </td>
                      <td class="py-4 px-6">
                        {item.status ?
                        <>
                      <p>{item.pstaff}</p>
                      <div>
                      <div class="flex items-center">
                      {(item.status === "picked") && <span class="flex w-3 h-3 bg-green-500 rounded-full"></span>}
                      {(item.status === "new") && <span class="flex w-3 h-3 bg-red-500 rounded-full"></span>}
                      {(item.status === "assigned") && <span class="flex w-3 h-3 bg-blue-500 rounded-full"></span>}
                      {(item.status === "delivered") &&  <span class="flex w-3 h-3 bg-yellow-300 rounded-full"></span>}
                      <span class="ml-2">{item.status}</span> 
                      </div>
                      </div>
                      </>:
                      <p class="text-sm text-gray-900 dark:text-white">Unbilled</p>
                        }
                      </td>
                      <td class="py-4 px-6">
                      {item.status &&
                        <button onClick={()=>editDelivery(item)} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center mr-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-blue-800">
                          Edit
                        </button>
                      }
                      </td>
                    </tr>
                  ))}   
                </tbody> 
                </table>     
              </div>
            :
            <div id="alert" class="p-4 mb-4 text-yellow-700 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800" role="alert">
              <div class="flex items-center">
                <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                <h3 class="text-lg font-medium">You do not have any delivery</h3>
              </div>
              
              <div class="flex">
                
                <button onClick={()=>navigate("/")} type="button" class="text-yellow-700 bg-transparent border border-yellow-700 hover:bg-yellow-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-yellow-500 dark:border-yellow-500 dark:text-yellow-400 dark:hover:text-white dark:focus:ring-yellow-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                  OK
                </button>
              </div>
            </div>
          } 
        </>
      }
      {barcodeloader && 
      <div>
      <BarcodeScannerComponent
        width={500}
        height={500}
        onUpdate={(err, result) => {
          if (result) sucessBarcode(result.text);
          else console.log(err)
        }}
        torch={false}
        
      />
      {SKU? <p class="text-sm text-gray-900 dark:text-white">Scanned value: {SKU}</p>:<p class="text-sm text-gray-900 dark:text-white">No value scanned...</p>}
      <button onClick={()=>closeBarcode()} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Close</button>
      </div>
    }
      {noDeliverystaff && 
        <div id="alert" class="p-4 mb-4 text-yello-700 border border-yello-300 rounded-lg bg-yello-50 dark:bg-gray-800 dark:text-yello-400 dark:border-yello-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Please add Delivery staff</h3>
          </div>
          <div class="mt-2 mb-4 text-sm">
            Do you wish to create new delivery staff?
          </div>
          <div class="flex">
            <button onClick={()=>navigate("/pickupstaff")} type="button" class="text-white bg-yello-700 hover:bg-yello-800 focus:ring-4 focus:outline-none focus:ring-yello-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-yello-600 dark:hover:bg-yello-700 dark:focus:ring-yello-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Yes
            </button>
          </div>
        </div>
      }
      
      {showModal && 
        <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Delivery updated successfully</h3>
          </div>
         
          <div class="flex">
            
            <button onClick={()=>showDeliveryList()} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Delivery List
            </button>
            
          </div>
        </div>
      }
      {modalview &&
        <div class="relative p-4 w-full max-w-md max-h-full">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                    Number of barcodes
                </h3>
                <button onClick={()=>closemodal()} type="button" class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
            </div>
            <div class="p-4 md:p-5"> 
              <input value={barcodenumbers} onChange={(e)=>setBarcodenumbers(e.target.value)} type="number" name="number" id="number" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="name@company.com" required />
              <button type="button" onClick={()=>print()} class="w-full mt-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Continue Print</button>
            </div>
          </div>
        </div> 
      }
      {editLoader &&
      <div class="w-full max-w-lg bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <div class="flex flex-col ml-5 mr-5 pb-10">
          <div class="grid grid-cols-2 gap-4 mt-5">
            <div>
              <h5 class="text-xl font-medium text-gray-900 dark:text-white">{editItem.cdata.fullName}</h5>
              <label htmlFor="full_name" class="block text-sm font-medium text-gray-900 dark:text-white">{editReferenceNumber}</label>
              <label htmlFor="full_name" class="block text-sm font-medium text-gray-900 dark:text-white">{moment(editDate).format('DD MMM YYYY hh:mm a')}</label>
              <span class="text-sm text-gray-500 dark:text-gray-400">Phonenumber: {editItem.cdata.phoneNumber}</span>
              {editItem.cdata.alternateNumber.map((ph, index)=>(
                <p key={index} class="text-sm text-gray-500 dark:text-gray-400">Alternate Phone: {ph.alternateNumber}</p>
              ))}
              {editItem.cdata.email && <span class="text-sm text-gray-500 dark:text-gray-400">{editItem.cdata.email}</span>}
              
            </div>
            {editItem.referenceNumber &&
            <div class="items-center align-center">
            <QRCodeSVG 
              size={70}
              style={{ marginBottom:10 }}
              value={editItem.referenceNumber}
              />
            <button onClick={()=>openmodal()} type="button" class="text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">Print</button>
            </div>
            }
          </div>
          <label htmlFor="full_name" class="mt-5 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Assigned Staff</label>
          <label htmlFor="disabled-radio-2" class="text-sm font-medium text-gray-400 dark:text-gray-500">{editDeliverystaff}</label>
          <div class="flex mt-4 space-x-3 md:mt-6">
          <button onClick={()=>{reassignStaff()}} type="button" class="text-red-400 hover:text-white border border-red-400 hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-red-300 dark:text-red-300 dark:hover:text-white dark:hover:bg-red-400 dark:focus:ring-red-900">Re-assign Staff</button>
          </div>
          <label htmlFor="full_name" class="mt-5 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select State</label>
          <div class="flex items-center">
              <input checked={stateIndex === 0} onChange={()=>setStateIndex(1)} id="disabled-radio-2" type="radio" value="" name="disabled-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
              <label htmlFor="disabled-radio-2" class="ml-2 text-sm font-medium text-gray-400 dark:text-gray-500">New</label>
          </div>
          <div class="flex items-center">
              <input checked={stateIndex === 1} onChange={()=>setStateIndex(1)} id="disabled-radio-2" type="radio" value="" name="disabled-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
              <label htmlFor="disabled-radio-2" class="ml-2 text-sm font-medium text-gray-400 dark:text-gray-500">Assigned</label>
          </div>
          <div class="flex items-center">
              <input checked={stateIndex === 2} onChange={()=>setStateIndex(2)} id="disabled-radio-2" type="radio" value="" name="disabled-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
              <label htmlFor="disabled-radio-2" class="ml-2 text-sm font-medium text-gray-400 dark:text-gray-500">Picked</label>
          </div>
          <div class="flex items-center">
              <input checked={stateIndex === 3} onChange={()=>setStateIndex(3)} id="disabled-radio-2" type="radio" value="" name="disabled-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
              <label htmlFor="disabled-radio-2" class="ml-2 text-sm font-medium text-gray-400 dark:text-gray-500">Delivered</label>
          </div>
          
          <label htmlFor="full_name" class="mt-5 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Address</label>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <tbody>
              {editItem.cdata.address.map((adr, index)=>( 
                <tr key={index} class="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <td class="w-4 p-4">
                  <div class="flex items-center">
                    <input checked={editIndex === index} onChange={()=>setEditIndex(index)} id="default-radio-1" type="radio" value="" name="default-radio" class="w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                  </div>
                </td>
                <td class="px-6 py-4">
                {adr.address && <p class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{adr.address}</p>}
                {adr.latitude && <button onClick={()=>showmap(adr.latitude, adr.longitude)} type="button" class="ml-3 mt-3 text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-xs px-3 py-2 text-center mr-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">map</button>}
                </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <caption>
              <label htmlFor="message" class="block mt-2 text-sm font-medium text-gray-900 dark:text-white">Dress Items</label>
              </caption>
              <thead>
              <tr>
                <th scope="col" class="py-2">
                    Dress
                </th>
                <th scope="col" class="py-2">
                    Quantity
                </th>
              </tr>
              </thead>
              <tbody>
              {editItemValue.map((item, index)=>(
              <tr key={index} class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
              <td class="py-2">
              {item.label}
              </td>  
              <td class="py-2">
              {item.value}
              </td>
              </tr>
              ))}   
              </tbody>
              </table>
          <label htmlFor="message" class="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">Notes</label>
          <textarea value={editNotes} onChange={(e)=>setEditNotes(e.target.value)} id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-purple-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-purple-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-purple-500"></textarea>
          <label htmlFor="message" class="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">Service Notes</label>
          <textarea value={editLaundryNotes} onChange={(e)=>setEditLaundryNotes(e.target.value)} id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-purple-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-purple-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-purple-500"></textarea>
          
          <div class="flex mt-4 space-x-3 md:mt-6">
          <button onClick={()=>cancelEditDelivery()} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
          <button onClick={()=>submitEditDelivery()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>
          </div>
        </div>
      </div>
      }
      
      {showCollectionModal && 
        <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Delivery updated successfully</h3>
          </div>
          
          <div class="flex">
            <button onClick={()=>showDeliveryList()} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Delivery List
            </button>
          </div>
        </div>
      }
      {reassingmodal && 
        <div class="w-full max-w-lg bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            
            <div class="flex flex-col ml-5 mr-5 pb-10">
              <h5 class="mt-5 mb-5 text-xl font-medium text-gray-900 dark:text-white">Re-assign Staff</h5>  
              <label htmlFor="full_name" class="mt-5 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Assign Staff</label>
              {resassignstaff.map((staff, ind)=>( 
                <div key={ind} class="flex items-center mb-4">
                  <input checked={rindex === ind} onChange={()=>setRindex(ind)} id="default-radio-2" type="radio" value="" name="default-radio-2" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                  <label htmlFor="default-radio-2" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{staff.staffName}</label>
                </div>
              ))}
              <div class="flex mt-4 space-x-3 md:mt-6">
              <button onClick={()=>cancelReassign()} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
              <button onClick={()=>submitReassign()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>
              </div>
            </div>
        </div>
      }
      </>
      :
      <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">You do not have permission to view Delivery. Contact your admin for permissions</h3>
          </div>
          <div class="flex">
            <button onClick={()=>navigate("/")} type="button" class="text-green-700 bg-transparent border border-green-700 hover:bg-green-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-green-500 dark:border-green-500 dark:text-green-400 dark:hover:text-white dark:focus:ring-green-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
              OK
            </button>
          </div>
      </div>
      }
    </>
  );
}