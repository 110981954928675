/* eslint-disable react-hooks/exhaustive-deps */
import { child, get, getDatabase, push, ref as dref, update } from "firebase/database";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUserStore } from "../../contexts/UserStoreContext";
import firebaseapp from '../../firebase';
import ls from 'localstorage-slim';

export default function InventoryUpdate() {
     
   
     const { storeID, setStoreID, countryCode, setCountryCode, inventoryupdate } = useUserStore();
     const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);
     
     if(!storeID){
          setStoreID(userdata.storeID);
        }
    if(!countryCode){
      setCountryCode(userdata.country);
    }

    const dbr = getDatabase(firebaseapp);
    const dbRef = dref(dbr);
  
    const [listLoader, setlistLoader] = useState(false);
    const [listnotavailable, setListnotavailable] = useState(false)
    const [listavailable, setListavailable] = useState(false)
    const [listData, setlistData] = useState('');
    const [searchString, setSearchString] = useState('')
    const [stockTransferLoader, setStockTransferLoader] = useState(false);
    const [filterlistData, setFilterlistData] = useState('');
    const [selectedItem, setSelectedItem] = useState('');
    const [stockUpdater, setStockUpdater] = useState(false);
    const [stockAvailable, setStockAvailable] = useState(0);
    const [stockTransit, setStockTransit] = useState(0);
    const [stockHistory, setStockHistory] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState('Override');
    const [updateQuantity, setUpdateQuantity] = useState('');
    const [updateDescription, setUpdateDescription] = useState('');
    const [openTab, setOpenTab] = React.useState(true);
    const [warning, setWarning] = useState(false);

    async function handleSearch(e){
      
        const formattedQuery = e.toLowerCase();
        const filteredData = listData.filter(user => {
          return contains(user, formattedQuery);
        });
        setFilterlistData(filteredData);
        setSearchString(e)
    };

    const contains = ({brand, category, itemname, sku}, query) => {
      if ((brand||'').toLowerCase().includes(query) || (category||'').toLowerCase().includes(query) ||
      (itemname||'').toLowerCase().includes(query) ||  (sku||'').toLowerCase().includes(query) )
      {
        return true;
      }
      return false;
    };

      useEffect(() => { 
        function listloadData(){
        get(child(dbRef, `${storeID}/inventory/${storeID}/items`)).then(async (snapshot) => {
            const listdata = [];
            if (snapshot.exists()) {
              snapshot.forEach(element => {
                const key = element.key;
                const brand = element.child('brand').val();
                const itemname = element.child('itemname').val();
                const location = element.child('location').val();
                const sku = element.child('sku').val();
                const category = element.child('category').val();
                const size = element.child('size').val();
                const sizeunit = element.child('sizeunit').val();
                const unit = element.child('units').val();
                const unitunit = element.child('unitunit').val();
                
                
                listdata.push({
                  key : key,
                  brand : brand,
                  itemname : itemname,
                  location : location,
                  sku : sku,
                  category:category,
                  size:size,
                  sizeunit:sizeunit,
                  unit:unit,
                  unitunit:unitunit

                })
                
              });
              const datasnaps = await Promise.all(listdata);
              const dataExist = true;
              return [dataExist, datasnaps];
              
            }
            else{
              const dataExist = false;
              return [dataExist, 0];
              
            }
          }).then((dataExist)=>{
            if(dataExist[0]){
              setListnotavailable(false);
              setListavailable(true);
              setlistData(dataExist[1]);
              setFilterlistData(dataExist[1]);
              setlistLoader(true);
    
            }else{
              setListavailable(false);
              setListnotavailable(true);
            }
    
          }).catch((error)=>{
            console.log(error);
          });
       }
       listloadData();
      
      
      }, []);
    
      function selectedItm(itm){
       console.log(itm, "selecteditem");
        setSelectedIndex(0);
        setSelectedOptionIndex(0);
        setUpdateQuantity('');
        setUpdateDescription('')
        setSelectedItem(itm);
        const key = itm.key;
        get(child(dbRef, `${storeID}/inventory/${storeID}/stock/${key}`)).then(async (datasnapshot) => {
          const updatelist = [];
          var stockinhand = 0;
          var stockintransit = 0;
          if(datasnapshot.exists()){
            const stock = datasnapshot.child('stock').val();
            const history = datasnapshot.child('history').val();
            stockinhand = stock.stock;
            stockintransit = stock.transit;
            setStockAvailable(stockinhand);
            setStockTransit(stockintransit);
           
            for (const key in history) {
              if (Object.hasOwnProperty.call(history, key)) {
                
                
                const historyactivity = history[key].activity;
                const historydescription = history[key].description;
                const htime = history[key].time;
                const historyvalue = history[key].value;
                const historytime = moment(htime).format('DD MMM YYYY, h:mm a');
                const day = moment(htime).format('DD');
                const month = moment(htime).format('MMM');
                const year = moment(htime).format('YYYY');
                
                updatelist.push({
                  id: key,
                  historyactivity: historyactivity,
                  historydescription: historydescription,
                  historytime: historytime,
                  day: day,
                  month:month,
                  year:year,
                  historyvalue:historyvalue
                })
              }
            }
    
            const dataUpdate = await Promise.all(updatelist);
            setStockHistory(dataUpdate);
            setlistLoader(false);
            setStockUpdater(true);
            
          }else{
            setStockHistory({});
            setlistLoader(false);
            setStockUpdater(true);
            
          }

        });
    
      }

      function changeSelectedIndex(indexNumber){
        if(indexNumber === 0){
          setSelectedIndex(0);
          setStockTransferLoader(false);
        }else if(indexNumber === 1){
          setSelectedIndex(1);
          setStockTransferLoader(false);
        }else{
          setSelectedIndex(2);
          setStockTransferLoader(true);
        }
      }
    
      function submitUpdate(){
        const pid = push(child(dref(dbr), 'inventory')).key;
        const timeanddate = moment(new Date()).toISOString();
        let commonObject;
        let historyObject;
        if(selectedIndex === 0){

          var st;
          if(stockTransit){
            st = stockTransit;
          }else{
            st = 0;
          }
          
          if((selectedOptionIndex === 0) || (selectedOptionIndex === "Override")){
            console.log("selectedOptionIndex", selectedOptionIndex);
            commonObject = {
              key: selectedItem.key || null,
              itemname: selectedItem.itemname || null,
              sku: selectedItem.sku || null,
              brand: selectedItem.brand || null,
              location: selectedItem.location || null,
              stock: updateQuantity,
              transit: st,
            }
            historyObject = {
              value: updateQuantity || null,
              description: updateDescription || null,
              time: timeanddate || null,
              activity: 'Stock Override' || null
            }
           
          }
          else if((selectedOptionIndex === 1) || (selectedOptionIndex === "Subtract")){
            var usval = (parseInt(stockAvailable))-(parseInt(updateQuantity));
            
            commonObject = {
              key: selectedItem.key || null,
              itemname: selectedItem.itemname || null,
              sku: selectedItem.sku || null,
              brand: selectedItem.brand || null,
              location: selectedItem.location || null,
              stock: usval || null,
              transit: st,
            }
            historyObject = {
              value: updateQuantity || null,
              description: updateDescription || null,
              time: timeanddate || null,
              activity: 'Stock Subtract' || null
            }
    
          }
          else{
            var uaval = parseInt(stockAvailable) + parseInt(updateQuantity);
            commonObject = {
              key: selectedItem.key || null,
              itemname: selectedItem.itemname || null,
              sku: selectedItem.sku || null,
              brand: selectedItem.brand || null,
              location: selectedItem.location || null,
              stock: uaval || null,
              transit: st,
            }
            historyObject = {
              value: updateQuantity || null,
              description: updateDescription || null,
              time: timeanddate || null,
              activity: 'Stock Add' || null
            }
    
          }
        }
        else{
          console.log(selectedOptionIndex);
          let sa;
          if(stockAvailable){
            sa = stockAvailable
          }else{
            sa = 0;
          }
          if((selectedOptionIndex === 0) || (selectedOptionIndex === "Override")){
            
            commonObject = {
              key: selectedItem.key || null,
              itemname: selectedItem.itemname || null,
              sku: selectedItem.sku || null,
              brand: selectedItem.brand || null,
              location: selectedItem.location || null,
              transit: updateQuantity || null,
              stock: sa
            }
    
            historyObject = {
              value: updateQuantity || null,
              description: updateDescription || null,
              time: timeanddate || null,
              activity: 'Transit Override' || null,
              
            }
    
          }
          else if((selectedOptionIndex === 1) || (selectedOptionIndex === "Subtract")){
            var ustval = parseInt(stockTransit) - parseInt(updateQuantity);
            
            commonObject = {
              key: selectedItem.key || null,
              itemname: selectedItem.itemname || null,
              sku: selectedItem.sku || null,
              brand: selectedItem.brand || null,
              location: selectedItem.location || null,
              transit: ustval || null,
              stock: sa
            }
            historyObject = {
              value: updateQuantity || null,
              description: updateDescription || null,
              time: timeanddate || null,
              activity: 'Transit Subtract' || null
            }
    
          }
          else{
            var uatval = parseInt(stockTransit) + parseInt(updateQuantity);
            
            commonObject = {
              key: selectedItem.key || null,
              itemname: selectedItem.itemname || null,
              sku: selectedItem.sku || null,
              brand: selectedItem.brand || null,
              location: selectedItem.location || null,
              transit: uatval || null,
              stock: sa
            }
            historyObject = {
              value: uatval || null,
              description: updateDescription || null,
              time: timeanddate || null,
              activity: 'Transit add' || null
            }
            
          }
        }
        
        const key = selectedItem.key;
        const updateOne = update(dref(dbr, storeID+'/inventory/' + storeID+'/stock/'+key), {
          stock:commonObject
        });

        const updateTwo = update(dref(dbr, storeID+'/inventory/' + storeID+'/stock/'+key+'/history'), {
          [pid]:historyObject
        });

        const pMany = [updateOne, updateTwo];

        const finalPromise = Promise.all(pMany);
        finalPromise.then(()=>{
            setStockUpdater(false);
            setlistLoader(true);
        })      
      }

      function submitStockReceived(){
        setWarning(false);
        if((parseInt(stockTransit)) >= (parseInt(updateQuantity))){
        const pid = push(child(dref(dbr), 'inventory')).key;
        const timeanddate = moment(new Date()).toISOString();
        
        var stockValue = (parseInt(stockAvailable))+(parseInt(updateQuantity));
        var transitValue = (parseInt(stockTransit))-(parseInt(updateQuantity));
            
        let  commonObject = {
              key: selectedItem.key || null,
              itemname: selectedItem.itemname || null,
              sku: selectedItem.sku || null,
              brand: selectedItem.brand || null,
              location: selectedItem.location || null,
              stock: stockValue,
              transit: transitValue,
            }

         let historyObject = {
              value: updateQuantity || null,
              description: updateDescription || null,
              time: timeanddate || null,
              activity: 'Stock Received and updated' || null
            }

            const key = selectedItem.key;
            
            const updateOne = update(dref(dbr, storeID+'/inventory/' + storeID+'/stock/'+key), {
              stock:commonObject
            });
    
            const updateTwo = update(dref(dbr, storeID+'/inventory/' + storeID+'/stock/'+key+'/history'), {
              [pid]:historyObject
            });
    
            const pMany = [updateOne, updateTwo];
    
            const finalPromise = Promise.all(pMany);
            finalPromise.then(()=>{
                setSelectedIndex(0);
                setStockTransferLoader(false);
                setStockUpdater(false);
                setlistLoader(true);
            }) 

          }else{
            setWarning(true);
          }

      }

      function cancelupdate(){
        setStockUpdater(false);
        setlistLoader(true);
      }
         
  return (
    <>
    <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <Link to='/inventory' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              Inventory
            </Link>
            </div>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Inventory Update</span>
            </div>
          </li>
        </ol>
      </nav>
    {listLoader &&
      <>
      {listnotavailable && 
        <div class="w-full p-4 text-center bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                <h5 class="mb-2 text-3xl font-bold text-gray-900 dark:text-white">You do not have any items</h5>
                <p class="mb-5 text-base text-gray-500 sm:text-lg dark:text-gray-400">Asset management improves productivity</p>
            </div>
      }
      {listavailable &&
        <>
          
          <label htmlFor="simple-search" class="sr-only">Search</label>
          <div class="relative w-full">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
              </div>
              <input value={searchString} onChange={(e) => handleSearch(e.target.value)} type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" />
          </div>
          {Object.keys(filterlistData).length !== 0?
            <>
            {filterlistData.map((item, key)=>(
              <div key={key} class="mt-5 w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div class="flex justify-end px-4 pt-4">
                  <span class="text-sm text-gray-500 dark:text-gray-400">{item.sku}</span>
                  </div>
                  <div class="flex flex-col items-center pb-10">
                      <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">{item.itemname}</h5>
                      {item.location? <span class="text-sm text-gray-500 dark:text-gray-400">{item.location}</span>
                      :
                      <span class="text-sm text-gray-500 dark:text-gray-400">Location undefined</span>}
                      {item.brand? <span class="text-sm text-gray-500 dark:text-gray-400">Brand: {item.brand}</span>
                      :
                      <span class="text-sm text-gray-500 dark:text-gray-400">Brand: Undefined</span>}
                      
                      <div class="flex mt-4 space-x-3 md:mt-6">
                      <p class="text-sm text-gray-500 dark:text-gray-400">Category:
                      {item.category ? <span class="text-sm text-gray-500 dark:text-gray-400"> {item.category}</span>
                      :
                      <span class="text-sm text-gray-500 dark:text-gray-400">Category undefined</span>}
                      </p>
                      {item.size? <span class="text-sm text-gray-500 dark:text-gray-400">Size: {item.size} {item.sizeunit}</span>
                      :
                      <span class="text-sm text-gray-500 dark:text-gray-400">Size: N/A</span>
                      }
                      {item.unit?
                <span class="text-sm text-gray-500 dark:text-gray-400">Unit:{item.unit} {item.unitunit}</span>
                :
                <span class="text-sm text-gray-500 dark:text-gray-400">Unit: N/A</span>}
                      </div>
                        <div class="flex mt-4 space-x-3 md:mt-6">
                          {inventoryupdate && <button onClick={()=>selectedItm(item)} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Update</button>}
                        </div>
                  </div>
              </div>
              ))}
            </>:
            <>
            <div class="w-full p-4 text-center bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                  <p class="mb-5 text-base text-gray-500 sm:text-lg dark:text-gray-400">You do not have any items with your search. Modify search..</p>
              </div>

            </>
          }
          
        </>
      }
      
      </>
    }
    {stockUpdater &&
      <>
      <div class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <ul class="flex flex-wrap -mb-px">
              <li class="mr-2">
                  <button onClick={()=>setOpenTab(true)} class={(openTab? "inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg dark:text-blue-500 dark:border-blue-500":"inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300")}>Stock Update</button>
              </li>
              <li class="mr-2">
                  <button onClick={()=>setOpenTab(false)} class={(!openTab? "inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg dark:text-blue-500 dark:border-blue-500":"inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300")}>Stock History</button>
              </li>
          </ul>
      </div>
      {openTab?
        <div class="mt-5 w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div class="flex justify-end px-4 pt-4">
            <span class="text-sm text-gray-500 dark:text-gray-400">{selectedItem.sku}</span>
            </div>
            <div class="flex flex-col items-center pb-10">
                <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">{selectedItem.itemname}</h5>
                {selectedItem.location? <span class="text-sm text-gray-500 dark:text-gray-400">{selectedItem.location}</span>
                :
                <span class="text-sm text-gray-500 dark:text-gray-400">Location undefined</span>}
                {selectedItem.brand? <span class="text-sm text-gray-500 dark:text-gray-400">Brand: {selectedItem.brand}</span>
                :
                <span class="text-sm text-gray-500 dark:text-gray-400">Brand: Undefined</span>}
                
                <div class="flex mt-4 space-x-3 md:mt-6">
                <p class="text-sm text-gray-500 dark:text-gray-400">Category:
                {selectedItem.category ? <span class="text-sm text-gray-500 dark:text-gray-400"> {selectedItem.category}</span>
                :
                <span class="text-sm text-gray-500 dark:text-gray-400">Category undefined</span>}
                </p>
                {selectedItem.size? <span class="text-sm text-gray-500 dark:text-gray-400">Size: {selectedItem.size} {selectedItem.sizeunit}</span>
                :
                <span class="text-sm text-gray-500 dark:text-gray-400">Size: N/A</span>
                }
                {selectedItem.unit?
          <span class="text-sm text-gray-500 dark:text-gray-400">Unit:{selectedItem.unit} {selectedItem.unitunit}</span>
          :
          <span class="text-sm text-gray-500 dark:text-gray-400">Unit: N/A</span>}
                </div>
                
                <div class="flex mt-5" >
                  <div class="flex items-center mr-4">
                      <input checked={selectedIndex === 0} onChange={()=>changeSelectedIndex(0)} id="inline-radio" type="radio" value="0" name="inline-radio-group" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                      <label htmlFor="inline-radio" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Stock : {stockAvailable}</label>
                  </div>
                  <div class="flex items-center mr-4">
                      <input checked={selectedIndex === 1} onChange={()=>changeSelectedIndex(1)} id="inline-2-radio" type="radio" value="1" name="inline-radio-group" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                      <label htmlFor="inline-2-radio" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Transit: {stockTransit}</label>
                  </div>
                  <div class="flex items-center mr-4">
                      <input checked={selectedIndex === 2} onChange={()=>changeSelectedIndex(2)} id="inline-2-radio" type="radio" value="1" name="inline-radio-group" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                      <label htmlFor="inline-2-radio" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Transit to Stock</label>
                  </div>
                  
              </div>
            {stockTransferLoader?
            <>
            <label htmlFor="action" class="mt-5 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Stock Received</label>
            <div class="flex items-center mt-5">
              <div>
              <label htmlFor="text" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Quantity</label>
              <input value={updateQuantity} onChange={(e) => setUpdateQuantity(e.target.value)} type="text" id="Quantity" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Quantity" required />
              </div>
            </div>
            <div class="flex items-center mt-5">
            <div>
            <label htmlFor="Description" class="block mb-2 text-sm font-small text-gray-900 dark:text-white">Description</label>
            <textarea value={updateDescription}  onChange={(e) => setUpdateDescription(e.target.value)} id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Description"></textarea>
            </div>
            </div>
            {warning && 
            <div id="toast-warning" class="flex items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
                <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                    <span class="sr-only">Warning icon</span>
                </div>
                <div class="ml-3 text-sm font-normal">Quatity should be equal or below to transit stock</div>
                <button onClick={()=>setWarning(false)} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-warning" aria-label="Close">
                    <span class="sr-only">Close</span>
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
            </div>
            }
            <div class="flex mt-4 md:mt-6 justify-around w-full">
              <button class="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={()=>cancelupdate()} type="button">
                    Cancel</button>
              <button onClick={()=>submitStockReceived()} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>
            </div>
            </>:<>  
            <label htmlFor="action" class="mt-5 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select action</label>
            <select value={selectedOptionIndex} required onChange={e => setSelectedOptionIndex(e.target.value)} id="action" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <option value='Override'>Override</option>
              <option value='Subtract'>Subtract</option>
              <option value="Add">Add</option>
            </select>
            <div class="flex items-center mt-5">
              <div>
              <label htmlFor="text" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Quantity</label>
              <input value={updateQuantity} onChange={(e) => setUpdateQuantity(e.target.value)} type="text" id="Quantity" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Quantity" required />
              </div>
            </div>
            <div class="flex items-center mt-5">
            <div>
            <label htmlFor="Description" class="block mb-2 text-sm font-small text-gray-900 dark:text-white">Description</label>
            <textarea value={updateDescription}  onChange={(e) => setUpdateDescription(e.target.value)} id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Description"></textarea>
            </div>
            </div>
            <div class="flex mt-4 md:mt-6 justify-around w-full">
              <button class="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={()=>cancelupdate()} type="button">
                    Cancel</button>
              <button onClick={()=>submitUpdate()} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>
            </div>
            </>
            }
        </div>
        </div>
        :
      <div>
      {Object.keys(stockHistory).length ? 
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <caption class="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            Item History
        </caption>
        
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                    DATE
                </th>
                <th scope="col" class="px-8 py-3">
                    Activity
                </th>
                <th scope="col" class="px-6 py-3">
                    Value
                </th>
                
            </tr>
        </thead>
        <tbody>
          {stockHistory.map((item, index)=>(
            <tr key={index} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {item.day}/{item.month}/{item.year}
                </th>
                
                
                <td class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                    <div class="pl-3">
                        <div class="text-base font-semibold">{item.historyactivity}</div>
                        <div class="font-normal text-gray-500">{item.historydescription}</div>
                    </div>  
                </td>
                 
                
                <td class="px-6 py-4">
                {item.historyvalue}
                </td>
            </tr>
          ))}
        </tbody>
      </table>
      :
      <div class="w-full p-4 text-center bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
        <p class="mb-5 text-base text-gray-500 sm:text-lg dark:text-gray-400">item do not have history</p>
      </div>
      }
      </div>
      }

      </>
      }
    </>
  )
}
