/* eslint-disable react-hooks/exhaustive-deps */ 
import { child, get, getDatabase, ref as dref, set } from "firebase/database";
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from "../../contexts/UserStoreContext";
import firebaseapp from '../../firebase';
import ls from 'localstorage-slim';
import filter from "lodash.filter";

export default function Pickupstaff({navigation, route}) {
  
  const navigate = useNavigate();
  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);
  const [listDataExist, setListDataExist] = useState(false)
  const [pickupstaffListInit, setPickupstaffListInit] = useState(true)
  const [listLoader, setListLoader] = useState(false)
  const [newPickupstaffInit, setNewPickupstaffInit] = useState(false)
  const [newPickupstaffLoader, setNewPickupstaffLoader] = useState(false)
  const [staffListData, setStaffListData] = useState('');
  const [listData, setListData] = useState('');
  const [flatList, setFlatList] = useState([]);
  const { storeID, setStoreID, countryCode, setCountryCode, settingscreate, settingsdelete } = useUserStore();
  const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);
  const [newModal, setNewModal] = useState(false)
  const [selectedStaff, setSelectedStaff] = useState([])
  const [filterListData, setFilterListData] = useState('');
  const [query, setQuery] = useState('')

  if(!storeID){
      setStoreID(userdata.storeID);
    }
    
  if(!countryCode){
    setCountryCode(userdata.country);
  }

  
  useEffect(() => { 
    if(pickupstaffListInit){
      function listtaskData(){
        get(child(dbRef, `${storeID}/pickupstaff/${storeID}/pickupstaff`)).then(async (snapshot) => {
          const listdata = [];
          if (snapshot.exists()) {
            snapshot.forEach(element => {
              const key = element.key;
              const staffName = element.child('staffName').val();
              const loginID = element.child('loginID').val();
              listdata.push({
                key:key,
                staffName:staffName,
                loginID:loginID
              })
              
            });
            const completedListData = await Promise.all(listdata);
            const dataExist = true;
            return [dataExist, completedListData];
          }
          else{
            const dataExist = false;
            return [dataExist, 0];
          }
          
        }).then((dataExist)=>{
          if(dataExist[0]){
            setListData(dataExist[1]);
            setFlatList(dataExist[1]);
            setFilterListData(dataExist[1]);
            setListDataExist(true);
            setListLoader(true);
          }else{
            setListDataExist(false)
            setListLoader(true);
          }
        }).catch((error)=>{
          console.log(error);
        });
     }
     listtaskData();
    }
  }, [pickupstaffListInit]);
  
  useEffect(() => { 
    if(newPickupstaffInit){
      function listStaffData(){
        setNewPickupstaffInit(false);
        get(child(dbRef, `${storeID}/staff/${storeID}/`)).then(async (snapshot) => {
          const listdata = [];
          if (snapshot.exists()) {
            snapshot.forEach(element => {
              const key = element.key;
              const staffName = element.child('staffName').val();
              const email = element.child('email').val() || null;
              let exists = false;
              for (const okey in flatList) {
                if (Object.hasOwnProperty.call(flatList, okey)) {
                  const element = flatList[okey];
                  if(element.key === key){
                    console.log(element.key, key);
                    exists = true
                  } 
                }
              }
              listdata.push({
                key:key,
                staffName:staffName, 
                email:email,
                checked:exists,
              })  
            });
            const completedListData = await Promise.all(listdata);
            const dataExist = true;
            return [dataExist, completedListData];  
          }
          else{
            const dataExist = false;
            return [dataExist, 0];
          }
          
        }).then((dataExist)=>{
          if(dataExist[0]){
            
            const staffArray = dataExist[1];
            setStaffListData(staffArray);
            setListLoader(false);
            setNewPickupstaffLoader(true);
            
          }else{
            setListLoader(false);
            setNewModal(true);
            
          }
        }).catch((error)=>{
          console.log(error);
        });
     }
     listStaffData();
    }
  }, [newPickupstaffInit]);

  function cancelNewStaff(){
    setNewPickupstaffInit(false);
    setNewPickupstaffLoader(false);
    setListLoader(true);
  }
 

  function submitNewStaff(){
    const pickupstaff = [];
    for (const key in staffListData) {
      if (Object.hasOwnProperty.call(staffListData, key)) {
        const element = staffListData[key];
        const staffkey = element.key;
        if(selectedStaff.includes(staffkey)){
          pickupstaff[staffkey] = {staffName: element.staffName, loginID:element.email}
        }
        
      }
    }
    
    set(dref(dbr, storeID+'/pickupstaff/'+storeID), {
      pickupstaff:pickupstaff
    }).then(()=>{
      setPickupstaffListInit(false);
      setNewPickupstaffLoader(false);
      setPickupstaffListInit(true);
    })
  }

  function deleteStaff(item){
    setListLoader(false);
    setPickupstaffListInit(false);
    const key = item.key
    set(dref(dbr, storeID+'/pickupstaff/' + storeID+'/pickupstaff/'+key), {
      key:null
    }).then(()=>{
      
      setPickupstaffListInit(true);
    })
  }
  
  const handleCheck = (event, index) => {
    console.log(event.target.value, "177");
    let newArr = [...staffListData];
    
    var updatedList = [...selectedStaff];
    if (event.target.checked) {
      newArr[index]["checked"] = true
      updatedList = [...selectedStaff, event.target.value];
    } else {
      newArr[index]["checked"] = false
      updatedList.splice(selectedStaff.indexOf(event.target.value), 1);
    }
    setStaffListData(newArr);
    setSelectedStaff(updatedList);
  };

  function handleSearch(e){
    setQuery(e);
    const elow = e.toLowerCase();
    const fData = listData.filter(item => {
      
      return (
        (item.staffName && item.staffName.toLowerCase().includes(elow)));
    }
    );
    setFilterListData(fData);
  };
  
  return (
    <div>
      <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <Link to='/Settings' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              Settings
            </Link>
            </div>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Pickup Staff</span>
            </div>
          </li>
        </ol>
      </nav>
      {listLoader &&
        <>
          {listDataExist? 
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
              <div class="flex items-center justify-between">
              <p class="mb-5 text-xl text-gray-900 dark:text-white">Pickup Staff List</p>
              {settingscreate && <button onClick={()=>setNewPickupstaffInit(true)} type="button" class="text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">Add Staff</button>}
              </div>
              <div class="relative z-0 mb-6 w-1/2 ml-5 mr-5 group ">
                <input value={query} onChange={(e)=>handleSearch(e.target.value)} type="text" name="sku" id="sku" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                <label htmlFor="sku" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Search</label>
              </div>
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                          <th scope="col" class="py-3 px-6">
                              Staff Name
                          </th>
                          <th scope="col" class="py-3 px-6">
                              Action
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                  {filterListData.map((item, key)=>(
                      <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                          <td class="py-4 px-6">
                            <div class="text-base font-semibold">{item.staffName}</div>
                          </td>
                          <td class="py-4 px-6">
                            {settingsdelete && <button onClick={()=>deleteStaff(item)} type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center mr-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-blue-800">
                              Delete
                            </button>
                            }
                          </td>
                      </tr>
                  ))}    
                  </tbody>
              </table>
            </div>
            :
            <div id="alert" class="p-4 mb-4 text-yellow-700 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800" role="alert">
                  <div class="flex items-center">
                    <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                    <h3 class="text-lg font-medium">You have not added Pickup Staff</h3>
                  </div>
                  <div class="mt-2 mb-4 text-sm">
                  Do you wish to add a pickup staff?
                  </div>
                  <div class="flex">
                    {settingscreate && <button onClick={()=>setNewPickupstaffInit(true)} type="button" class="mt-5 text-white bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800">
                      <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
                      Yes
                    </button>
                    }
                  </div>
            </div>
          }
        </>
      }
      {newPickupstaffLoader &&
        <div class="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
          <h5 class="text-xl font-medium text-gray-900 dark:text-white">Add Pickup Staff</h5>
          <div class="mt-5 mb-5" >
            {staffListData.map((staff, index)=>( 
              <div key={index} class="flex items-center mr-4">
                <input checked={staff.checked} value={staff.key} onChange={(event)=>handleCheck(event, index)} id="inline-radio" type="checkbox" name="inline-radio-group" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                <label htmlFor="inline-radio" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{staff.staffName}</label>
              </div>
            ))}
          </div>
          <div class="flex mt-4 space-x-3 md:mt-6">
            <button onClick={()=>cancelNewStaff()} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
            <button onClick={()=>submitNewStaff()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>
          </div>
        </div>
      }
      {newModal && 
        <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">You have not added staff. Add all your staffs and then add pickup staff</h3>
          </div>
          
          <div class="flex">
            <button onClick={()=>navigate("/staff")} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              OK
            </button>
            
            
          </div>
        </div>
      }
    </div>
  );
}
