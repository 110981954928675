/* eslint-disable react-hooks/exhaustive-deps */ 
import React, { useEffect, useState } from 'react';
import { child, get, getDatabase, push, ref as dref, set, update } from "firebase/database";
import { deleteObject, getDownloadURL, getStorage, ref as sref, uploadBytes } from "firebase/storage";
import moment from 'moment';
import PhoneInput, {parsePhoneNumber} from 'react-phone-number-input';
import { Link } from 'react-router-dom';
import { useUserStore } from "../../contexts/UserStoreContext";
import firebaseapp from '../../firebase';
import ls from 'localstorage-slim';
import DatePicker from "react-datepicker";

export default function Store() {
  
  const { storeID, setStoreID, countryCode, setCountryCode, settingscreate, settingsupdate, settingsdelete,} = useUserStore();
  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);
  const storage = getStorage(firebaseapp);
  const [initialLoader, setInitialLoader] = useState(true);
  const[loader, setLoader] = useState(false);
  const [userdataexist, setUserdataexist] = useState(false);
  const [storeData, setStoreData] = useState([]);
  const [newStoreLoader, setNewStoreLoader] = useState(false);
  const [selectedStoreLoader, setSelectedStoreLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedStoreKey, setSelectedStoreKey] = useState("");
  let userID = storeID;
  const countryID = countryCode;
  const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);
  const geolocationAPI = navigator.geolocation;
  const [filterListData, setFilterListData] = useState('');
  const [query, setQuery] = useState('')
  if(!storeID){
    setStoreID(userdata.storeID);
    userID = userdata.storeID;
  }

  if(!countryCode){
    setCountryCode(userdata.country);
  }

  const [storeName, setStoreName] = useState("");
  const [address, setAddress] = useState("");
  const [sunChecked, setSunChecked] = useState(false);
  const [monChecked, setMonChecked] = useState(false);
  const [tueChecked, setTueChecked] = useState(false);
  const [wedChecked, setWedChecked] = useState(false);
  const [thuChecked, setThuChecked] = useState(false);
  const [friChecked, setFriChecked] = useState(false);
  const [satChecked, setSatChecked] = useState(false);
  const [sunStartTime, setSunStartTime] = useState(new Date());
  const [sunEndTime, setSunEndTime] = useState(new Date());
  const [monStartTime, setMonStartTime] = useState(new Date());
  const [monEndTime, setMonEndTime] = useState(new Date());
  const [tueStartTime, setTueStartTime] = useState(new Date());
  const [tueEndTime, setTueEndTime] = useState(new Date());
  const [wedStartTime, setWedStartTime] = useState(new Date());
  const [wedEndTime, setWedEndTime] = useState(new Date());
  const [thuStartTime, setThuStartTime] = useState(new Date());
  const [thuEndTime, setThuEndTime] = useState(new Date());
  const [friStartTime, setFriStartTime] = useState(new Date());
  const [friEndTime, setFriEndTime] = useState(new Date());
  const [satStartTime, setSatStartTime] = useState(new Date());
  const [satEndTime, setSatEndTime] = useState(new Date());
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [services, setservices] = useState([{key: 0, value: 0}]);
  const [billHeader, setBillHeader] = useState([{key: "", value: 0}]);
  const [phonenumber, setphonenumber] = useState([{key: 0, value: 0}]);
  const [location, setLocation] = useState("");
  const [latitude, setLatitude]= useState("");
  const [longitude, setLongitude]= useState("");
  const [source, setSource] = useState("");
  const [initialSource, setInitialSource] = useState("");

  useEffect(() => {
    if(initialLoader){
      setInitialLoader(false);
      function loadItems() {
        get(child(dbRef, `${storeID}/storeprofile/${userID}/`)).then((storesnapshot) => {
            if(storesnapshot.exists()){
            const storeArray = [];
            storesnapshot.forEach(snapshot => {
              const storeKey = snapshot.key;
              const imageexist = snapshot.val().profilepicture
              let iconLoader;
              if(imageexist){
                iconLoader = false;
              }
              else{
                iconLoader = true;
              }
            const storeName = snapshot.val().StoreName;
            const Address = snapshot.val().Address;
            const SunChecked = snapshot.val().SunChecked;
            const MonChecked = snapshot.val().MonChecked;
            const TueChecked = snapshot.val().TueChecked;
            const WedChecked = snapshot.val().WedChecked;
            const ThuChecked = snapshot.val().ThuChecked;
            const FriChecked = snapshot.val().FriChecked;
            const SatChecked = snapshot.val().SatChecked;
            const SunStartTime = moment(snapshot.val().SunStartTime).toDate();
            const SunEndTime = moment(snapshot.val().SunEndTime).toDate();
            const MonStartTime = moment(snapshot.val().MonStartTime).toDate();
            const MonEndTime = moment(snapshot.val().MonEndTime).toDate();
            const TueStartTime = moment(snapshot.val().TueStartTime).toDate();
            const TueEndTime = moment(snapshot.val().TueEndTime).toDate();
            const WedStartTime = moment(snapshot.val().WedStartTime).toDate();
            const WedEndTime = moment(snapshot.val().WedEndTime).toDate();
            const ThuStartTime = moment(snapshot.val().ThuStartTime).toDate();
            const ThuEndTime = moment(snapshot.val().ThuEndTime).toDate();
            const FriStartTime = moment(snapshot.val().FriStartTime).toDate();
            const FriEndTime = moment(snapshot.val().FriEndTime).toDate();
            const SatStartTime = moment(snapshot.val().SatStartTime).toDate();
            const SatEndTime = moment(snapshot.val().SatEndTime).toDate();
            const Email = snapshot.val().Email;
            const Website = snapshot.val().Website;
            const services = snapshot.val().Services;
            const billheader = snapshot.val().Billheader;
            const phonenumber = snapshot.val().Phonenumber;
            const Location = snapshot.val().Location;
            const Latitude = snapshot.val().Latitude;
            const Longitude = snapshot.val().Longitude;
            const ProfileURL = snapshot.val().profileURL;
            
            storeArray.push({
              storeKey:storeKey, 
              imageexist:imageexist, 
              iconLoader:iconLoader,
              StoreName : storeName,
              Address : Address, 
              SunChecked : SunChecked,
              MonChecked : MonChecked, 
              TueChecked : TueChecked, 
              WedChecked : WedChecked, 
              ThuChecked : ThuChecked, 
              FriChecked : FriChecked, 
              SatChecked : SatChecked, 
              SunStartTime : SunStartTime, 
              SunEndTime : SunEndTime, 
              MonStartTime : MonStartTime, 
              MonEndTime : MonEndTime, 
              TueStartTime : TueStartTime, 
              TueEndTime : TueEndTime, 
              WedStartTime : WedStartTime, 
              WedEndTime : WedEndTime,
              ThuStartTime : ThuStartTime,
              ThuEndTime : ThuEndTime, 
              FriStartTime : FriStartTime, 
              FriEndTime : FriEndTime, 
              SatStartTime : SatStartTime, 
              SatEndTime : SatEndTime, 
              Email : Email,  
              Website : Website, 
              services : services, 
              billheader: billheader,
              phonenumber : phonenumber, 
              Location : Location, 
              Latitude : Latitude, 
              Longitude : Longitude, 
              ProfileURL: ProfileURL,
            })  
            });
    
            // const promiseStoreData = Promise.all(storeArray);
            setStoreData(storeArray);
            setFilterListData(storeArray);
            setUserdataexist(true);
            setLoader(true);
          }
          else{
            setUserdataexist(false);
            setLoader(true);
          }
        }); 
      }
      loadItems();
    }
    
  }, [initialLoader])

  function onFloatinActionClick(){
    setLoader(false);
    setNewStoreLoader(true);
  }

  const addcHandler = ()=>{
    const _phonenumber = [...phonenumber];
    const kl = Object.keys(_phonenumber).length+1;
    _phonenumber.push({key: kl, value: ''});
    setphonenumber(_phonenumber);
  }

  const addHandler = ()=>{
    const _services = [...services];
    const kl = Object.keys(_services).length+1;
    _services.push({key: kl, value: 0});
    setservices(_services);
  }

  const addbHandler = ()=>{
    const _billheader = [...billHeader];
    const kl = Object.keys(_billheader).length+1;
    _billheader.push({key: kl, value: 0});
    setBillHeader(_billheader);
  }

  
  
  const deleteHandler = (key)=>{
    const _services = services.filter((input,index) => index !== key);
    setservices(_services);
  }

  const deletebHandler = (key)=>{
    const _billheader = billHeader.filter((input,index) => index !== key);
    setBillHeader(_billheader);
  }

  

  const deletecHandler = (ckey)=>{
    const _phonenumber = phonenumber.filter((cinput,index) => index !== ckey);
    setphonenumber(_phonenumber);
  }

  const inputHandler = (text, key)=>{
    const _services = [...services];
    _services[key].value = text;
    _services[key].key   = key;
    setservices(_services);
    
  }

  const inputbHandler = (text, key)=>{
    const _billheader = [...billHeader];
    _billheader[key].value = text;
    _billheader[key].key   = key;
    setBillHeader(_billheader);
    
  }
  

  const inputcHandler = (text, key)=>{
    const _phonenumber = [...phonenumber];
    _phonenumber[key].value = text;
    _phonenumber[key].key   = key;
    setphonenumber(_phonenumber);
    
  }

  function inputaLocationHandler(){
    if (!geolocationAPI) {
      console.log("Geolocation API is not available in your browser!")
    } else {
      geolocationAPI.getCurrentPosition((position) => {
        const { coords } = position;
        setLatitude(coords.latitude);
        setLongitude(coords.longitude);
        setLocation(coords.latitude, coords.longitude);
      }, (error) => {
        console.log(error, "Something went wrong getting with your position!")
      })
    }
  }
  

  async function onSubmit( ){
    const newStoreKey = push(child(dref(dbr), "storeprofile")).key;
    const SunStartTime = moment(sunStartTime).toISOString();
    const MonStartTime = moment(monStartTime).toISOString();
    const TueStartTime = moment(tueStartTime).toISOString();
    const WedStartTime = moment(wedStartTime).toISOString();
    const ThuStartTime = moment(thuStartTime).toISOString();
    const FriStartTime = moment(friStartTime).toISOString();
    const SatStartTime = moment(satStartTime).toISOString();
    const SunEndTime = moment(sunEndTime).toISOString();
    const MonEndTime = moment(monEndTime).toISOString();
    const TueEndTime = moment(tueEndTime).toISOString();
    const WedEndTime = moment(wedEndTime).toISOString();
    const ThuEndTime = moment(thuEndTime).toISOString();
    const FriEndTime = moment(friEndTime).toISOString();
    const SatEndTime = moment(satEndTime).toISOString();
    const StoreName = storeName;
    const Address = address;
    const Email = email;
    const Website = website;
    const Services = services;
    const Billheader = billHeader;
    const Latitude = latitude;
    const Longitude = longitude;
    const Location = location;
    const SunChecked = sunChecked;
    const MonChecked = monChecked;
    const TueChecked = tueChecked;
    const WedChecked = wedChecked;
    const ThuChecked = thuChecked;
    const FriChecked = friChecked;
    const SatChecked = satChecked;
    let anumber = [];
    let keynum = 1;
    if(phonenumber.length){
      for (const key in phonenumber) {
        if (Object.hasOwnProperty.call(phonenumber, key)) {
          const element = phonenumber[key];
          anumber.push({key: keynum, value: parsePhoneNumber(element.value).nationalNumber, code:parsePhoneNumber(element.value).countryCallingCode, country:parsePhoneNumber(element.value).country})
          keynum = keynum+1;
        }
      }
    }
    else{
      anumber.push({key: '1', value: ''})
    }
    if(source){
      
    const response = await fetch(source);
    const blob = await response.blob();
    const storageRef = sref(storage, userID+'/storeprofile/'+userID+'/'+newStoreKey);
    // 'file' comes from the Blob or File API
    uploadBytes(storageRef, blob).then(()=>{
      getDownloadURL(storageRef).then((url)=>{
        console.log(url);
        const profileURL = url;
        
        set(dref(dbr, userID +"/storeprofile/" + userID +"/"+ newStoreKey), {
          SunStartTime,
          MonStartTime,
          TueStartTime,
          WedStartTime,
          ThuStartTime,
          FriStartTime,
          SatStartTime,
          SunEndTime,
          MonEndTime,
          TueEndTime,
          WedEndTime,
          ThuEndTime,
          FriEndTime,
          SatEndTime,
          StoreName,
          Address,
          Email,
          Website, 
          Services,
          Billheader,
          Phonenumber:anumber,
          Latitude,
          Longitude,
          Location,
          SunChecked,
          MonChecked,
          TueChecked,
          WedChecked,
          ThuChecked,
          FriChecked,
          SatChecked,
          profileURL
        }).then(()=>{
          setSource("");
          setStoreName("");
          setAddress("");
          setSunChecked(false);
          setMonChecked(false);
          setTueChecked(false);
          setWedChecked(false);
          setThuChecked(false);
          setFriChecked(false);
          setSatChecked(false);
          setSunStartTime(new Date());
          setSunEndTime(new Date());
          setMonStartTime(new Date());
          setMonEndTime(new Date());
          setTueStartTime(new Date());
          setTueEndTime(new Date());
          setWedStartTime(new Date());
          setWedEndTime(new Date());
          setThuStartTime(new Date());
          setThuEndTime(new Date());
          setFriStartTime(new Date());
          setFriEndTime(new Date());
          setSatStartTime(new Date());
          setSatEndTime(new Date());
          setEmail("");
          setWebsite("");
          setservices([{key: 0, value: 0}]);
          setBillHeader([{key: '', value: 0}]);
          setphonenumber([{key: 0, value: 0}]);
          setLocation("");
          setLatitude("");
          setLongitude("");
          setNewStoreLoader(false);
          setInitialLoader(true);
        }).catch((error)=>{
          console.log(error);
        });
        
      }).catch((error)=>{
        console.log(error);
      })
      
    }).catch((error)=>{
      console.log(error);
    });

    }
    else{
      console.log("inside else");
      const profileURL = false;
      set(dref(dbr, userID +"/storeprofile/" + userID +"/"+ newStoreKey), {
          SunStartTime,
          MonStartTime,
          TueStartTime,
          WedStartTime,
          ThuStartTime,
          FriStartTime,
          SatStartTime,
          SunEndTime,
          MonEndTime,
          TueEndTime,
          WedEndTime,
          ThuEndTime,
          FriEndTime,
          SatEndTime,
          StoreName,
          Address,
          Email,
          Website, 
          Services,
          Billheader,
          Phonenumber:anumber,
          Latitude,
          Longitude,
          Location,
          SunChecked,
          MonChecked,
          TueChecked,
          WedChecked,
          ThuChecked,
          FriChecked,
          SatChecked,
          profileURL
        }).then(()=>{
          setSource("");
          setStoreName("");
          setAddress("");
          setSunChecked(false);
          setMonChecked(false);
          setTueChecked(false);
          setWedChecked(false);
          setThuChecked(false);
          setFriChecked(false);
          setSatChecked(false);
          setSunStartTime(new Date());
          setSunEndTime(new Date());
          setMonStartTime(new Date());
          setMonEndTime(new Date());
          setTueStartTime(new Date());
          setTueEndTime(new Date());
          setWedStartTime(new Date());
          setWedEndTime(new Date());
          setThuStartTime(new Date());
          setThuEndTime(new Date());
          setFriStartTime(new Date());
          setFriEndTime(new Date());
          setSatStartTime(new Date());
          setSatEndTime(new Date());
          setEmail("");
          setWebsite("");
          setservices([{key: 0, value: 0}]);
          setBillHeader([{key: '', value: 0}]);
          setphonenumber([{key: 0, value: 0}]);
          setLocation("");
          setLatitude("");
          setLongitude("");
          setNewStoreLoader(false);
          setInitialLoader(true);
        }).catch((error)=>{
          console.log(error);
        });
    }
    
    
    
  }

  async function onUpdate(){

    const SunStartTime = moment(sunStartTime).toISOString();
    const MonStartTime = moment(monStartTime).toISOString();
    const TueStartTime = moment(tueStartTime).toISOString();
    const WedStartTime = moment(wedStartTime).toISOString();
    const ThuStartTime = moment(thuStartTime).toISOString();
    const FriStartTime = moment(friStartTime).toISOString();
    const SatStartTime = moment(satStartTime).toISOString();
    const SunEndTime = moment(sunEndTime).toISOString();
    const MonEndTime = moment(monEndTime).toISOString();
    const TueEndTime = moment(tueEndTime).toISOString();
    const WedEndTime = moment(wedEndTime).toISOString();
    const ThuEndTime = moment(thuEndTime).toISOString();
    const FriEndTime = moment(friEndTime).toISOString();
    const SatEndTime = moment(satEndTime).toISOString();
    const StoreName = storeName;
    const Address = address;
    const Email = email;
    const Website = website;
    const Services = services;
    const Billheader = billHeader;
    const Latitude = latitude;
    const Longitude = longitude;
    const Location = location;
    const SunChecked = sunChecked;
    const MonChecked = monChecked;
    const TueChecked = tueChecked;
    const WedChecked = wedChecked;
    const ThuChecked = thuChecked;
    const FriChecked = friChecked;
    const SatChecked = satChecked;
    let anumber = [];
    let keynum = 1;
    if(phonenumber.length){
      for (const key in phonenumber) {
        if (Object.hasOwnProperty.call(phonenumber, key)) {
          const element = phonenumber[key];
          anumber.push({key: keynum, value: parsePhoneNumber(element.value).nationalNumber, code:parsePhoneNumber(element.value).countryCallingCode, country:parsePhoneNumber(element.value).country})
          keynum = keynum+1;
        }
      }
    }
    else{
      anumber.push({key: '1', value: ''})
    }
    if(source && source !== initialSource){
      
    const response = await fetch(source);
    const blob = await response.blob();
    const storageRef = sref(storage, userID+'/storeprofile/'+userID+'/'+selectedStoreKey);
    // 'file' comes from the Blob or File API
    uploadBytes(storageRef, blob).then(()=>{
      getDownloadURL(storageRef).then((url)=>{
        const profileURL = url;
        update(dref(dbr, userID +"/storeprofile/" + userID +"/"+ selectedStoreKey), {
          SunStartTime,
          MonStartTime,
          TueStartTime,
          WedStartTime,
          ThuStartTime,
          FriStartTime,
          SatStartTime,
          SunEndTime,
          MonEndTime,
          TueEndTime,
          WedEndTime,
          ThuEndTime,
          FriEndTime,
          SatEndTime,
          StoreName,
          Address,
          Email,
          Website, 
          Services,
          Billheader,
          Phonenumber:anumber,
          Latitude,
          Longitude,
          Location,
          SunChecked,
          MonChecked,
          TueChecked,
          WedChecked,
          ThuChecked,
          FriChecked,
          SatChecked,
          profileURL
        }).then(()=>{
          setSource("");
          setStoreName("");
          setAddress("");
          setSunChecked(false);
          setMonChecked(false);
          setTueChecked(false);
          setWedChecked(false);
          setThuChecked(false);
          setFriChecked(false);
          setSatChecked(false);
          setSunStartTime(new Date());
          setSunEndTime(new Date());
          setMonStartTime(new Date());
          setMonEndTime(new Date());
          setTueStartTime(new Date());
          setTueEndTime(new Date());
          setWedStartTime(new Date());
          setWedEndTime(new Date());
          setThuStartTime(new Date());
          setThuEndTime(new Date());
          setFriStartTime(new Date());
          setFriEndTime(new Date());
          setSatStartTime(new Date());
          setSatEndTime(new Date());
          setEmail("");
          setWebsite("");
          setservices([{key: 0, value: 0}]);
          setBillHeader([{key: '', value: 0}]);
          setphonenumber([{key: 0, value: 0}]);
          setLocation("");
          setLatitude("");
          setLongitude("");
          setSelectedStoreLoader(false);
          setNewStoreLoader(false);
          setInitialLoader(true);
        }).catch((error)=>{
          console.log(error);
        });
        
      }).catch((error)=>{
        console.log(error);
      })
      
    }).catch((error)=>{
      console.log(error);
    });

    }
    else{
    
    update(dref(dbr, userID+"/storeprofile/" + userID +"/"+ selectedStoreKey), {
      SunStartTime,
      MonStartTime,
      TueStartTime,
      WedStartTime,
      ThuStartTime,
      FriStartTime,
      SatStartTime,
      SunEndTime,
      MonEndTime,
      TueEndTime,
      WedEndTime,
      ThuEndTime,
      FriEndTime,
      SatEndTime,
      StoreName,
      Address,
      Email,
      Website, 
      Services,
      Billheader,
      Phonenumber:anumber,
      Latitude,
      Longitude,
      Location,
      SunChecked,
      MonChecked,
      TueChecked,
      WedChecked,
      ThuChecked,
      FriChecked,
      SatChecked,
      
    }).then(()=>{
      setSource("");
      setInitialSource("");
      setStoreName("");
      setAddress("");
      setSunChecked(false);
      setMonChecked(false);
      setTueChecked(false);
      setWedChecked(false);
      setThuChecked(false);
      setFriChecked(false);
      setSatChecked(false);
      setSunStartTime(new Date());
      setSunEndTime(new Date());
      setMonStartTime(new Date());
      setMonEndTime(new Date());
      setTueStartTime(new Date());
      setTueEndTime(new Date());
      setWedStartTime(new Date());
      setWedEndTime(new Date());
      setThuStartTime(new Date());
      setThuEndTime(new Date());
      setFriStartTime(new Date());
      setFriEndTime(new Date());
      setSatStartTime(new Date());
      setSatEndTime(new Date());
      setEmail("");
      setWebsite("");
      setservices([{key: 0, value: 0}]);
      setBillHeader([{key: '', value: 0}]);
      setphonenumber([{key: 0, value: 0}]);
      setLocation("");
      setLatitude("");
      setLongitude("");
      setSelectedStoreLoader(false);
      setNewStoreLoader(false);
      setInitialLoader(true);
    });
    }

  }

  function cancelNewStoreLoader(value){
    setStoreName("");
    setAddress("");
    setSunChecked(false);
    setMonChecked(false);
    setTueChecked(false);
    setWedChecked(false);
    setThuChecked(false);
    setFriChecked(false);
    setSatChecked(false);
    setSunStartTime(new Date());
    setSunEndTime(new Date());
    setMonStartTime(new Date());
    setMonEndTime(new Date());
    setTueStartTime(new Date());
    setTueEndTime(new Date());
    setWedStartTime(new Date());
    setWedEndTime(new Date());
    setThuStartTime(new Date());
    setThuEndTime(new Date());
    setFriStartTime(new Date());
    setFriEndTime(new Date());
    setSatStartTime(new Date());
    setSatEndTime(new Date());
    setEmail("");
    setWebsite("");
    setservices([{key: 0, value: 0}]);
    setBillHeader([{key: '', value: 0}]);
    setphonenumber([{key: 0, value: 0}]);
    setLocation("");
    setLatitude("");
    setLongitude("");
    setSelectedStoreLoader(false);
    setNewStoreLoader(false);
    setLoader(true);
    
  }

  function selectStore(sdata, index){
    setInitialSource(sdata.ProfileURL);
    setSource(sdata.ProfileURL);
    setStoreName(sdata.StoreName);
    setAddress(sdata.Address);
    setSunChecked(sdata.SunChecked);
    setMonChecked(sdata.MonChecked);
    setTueChecked(sdata.TueChecked);
    setWedChecked(sdata.WedChecked);
    setThuChecked(sdata.ThuChecked);
    setFriChecked(sdata.FriChecked);
    setSatChecked(sdata.SatChecked);
    setSunStartTime(sdata.SunStartTime);
    setSunEndTime(sdata.SunEndTime);
    setMonStartTime(sdata.MonStartTime);
    setMonEndTime(sdata.MonEndTime);
    setTueStartTime(sdata.TueStartTime);
    setTueEndTime(sdata.TueEndTime);
    setWedStartTime(sdata.WedStartTime);
    setWedEndTime(sdata.WedEndTime);
    setThuStartTime(sdata.ThuStartTime);
    setThuEndTime(sdata.ThuEndTime);
    setFriStartTime(sdata.FriStartTime);
    setFriEndTime(sdata.FriEndTime);
    setSatStartTime(sdata.SatStartTime);
    setSatEndTime(sdata.SatEndTime);
    setEmail(sdata.Email);
    setWebsite(sdata.Website);
    if(sdata.services){
      setservices(sdata.services);
    }else{
      setservices([{key: 0, value: 0}]);
    }
    if(sdata.billheader){
      setBillHeader(sdata.billheader);
    }else{
      setBillHeader([{key: '', value: 0}]);
    }

    let anumber = [];
    let keynum = 1;
    if(sdata.phonenumber.length){
      for (const key in sdata.phonenumber) {
        if (Object.hasOwnProperty.call(sdata.phonenumber, key)) {
          const element = sdata.phonenumber[key];
          const numbr = "+"+element.code+element.value;
          anumber.push({key: keynum, value:numbr})
          keynum = keynum+1;
        }
      }
    }
    else{
      anumber.push({key: '1', value: ''})
    }
    setphonenumber(anumber);
    setLocation(sdata.Location);
    setLatitude(sdata.Latitude);
    setLongitude(sdata.Longitude);
    setSelectedStoreKey(sdata.storeKey);
    setLoader(false);
    setSelectedStoreLoader(true);
  }

  function deleteStore(){
    setShowModal(false);
    const desertRef = sref(storage, userID+"/storeprofile/" + userID +"/"+ selectedStoreKey);
    
    const deleteStore = deleteObject(desertRef);
    const deleteDatabase = set(dref(dbr, userID+"/storeprofile/" + userID +"/"+ selectedStoreKey), {
      null:null
    });

    const combineDelete = [deleteStore, deleteDatabase];

    const finalDelete = Promise.all(combineDelete);

    finalDelete.then(()=>{
      setSource("");
      setInitialSource("");
      setStoreName("");
      setAddress("");
      setSunChecked(false);
      setMonChecked(false);
      setTueChecked(false);
      setWedChecked(false);
      setThuChecked(false);
      setFriChecked(false);
      setSatChecked(false);
      setSunStartTime(new Date());
      setSunEndTime(new Date());
      setMonStartTime(new Date());
      setMonEndTime(new Date());
      setTueStartTime(new Date());
      setTueEndTime(new Date());
      setWedStartTime(new Date());
      setWedEndTime(new Date());
      setThuStartTime(new Date());
      setThuEndTime(new Date());
      setFriStartTime(new Date());
      setFriEndTime(new Date());
      setSatStartTime(new Date());
      setSatEndTime(new Date());
      setEmail("");
      setWebsite("");
      setservices([{key: 0, value: 0}]);
      setBillHeader([{key: '', value: 0}]);
      setphonenumber([{key: 0, value: 0}]);
      setLocation("");
      setLatitude("");
      setLongitude("");
      setSelectedStoreLoader(false);
      setNewStoreLoader(false);
      setInitialLoader(true);
    });
  }
      
  function cancelDeletion(){
    setShowModal(false);
    setSelectedStoreLoader(true);
  }

  function deleteStoreConfirmation(){
    setSelectedStoreLoader(false);
    setShowModal(true);
    
  }

  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const newUrl = URL.createObjectURL(file);
        setSource(newUrl);
      }
    }
  };

  function handleSearch(e){
    setQuery(e);
    const elow = e.toLowerCase();
    const fData = storeData.filter(item => {
      return (
        (item.StoreName && item.StoreName.toLowerCase().includes(elow)));
    }
    );
    setFilterListData(fData);
  };

  const openmap = (lat,long) => {
    const url = "http://www.google.com/maps/place/"+lat+","+long
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return ( 
    <div>
      <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <Link to='/Settings' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              Settings
            </Link>
            </div>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Store</span>
            </div>
          </li>
        </ol>
      </nav>
      {loader && 
        <div>
          {userdataexist? 
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
                  <p class="mb-5 text-xl text-gray-900 dark:text-white">Stores</p>
                  <div class="relative z-0 mb-6 w-1/2 ml-5 mr-5 group ">
                <input value={query} onChange={(e)=>handleSearch(e.target.value)} type="text" name="sku" id="sku" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                <label htmlFor="sku" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Search</label>
              </div>
                  <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                              <th scope="col" class="py-3 px-6">
                                  Store
                              </th>
                              <th scope="col" class="py-3 px-6">
                                  Action
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                      {filterListData.map((cdata, key)=>(
                          <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                              <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                <p>{cdata.StoreName}</p>
                              </th>
                              <td class="py-4 px-6">
                              <button onClick={()=>selectStore(cdata, key)} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center mr-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-blue-800">
                                Edit
                              </button>
                                  
                              </td>
                          </tr>
                      ))}    
                      </tbody>
                  </table>
              </div>
            :
            <div class="max-w-lg p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">You have not created stores!</h5>
          <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Create store profile</p>
        </div>
        }
        
       {settingscreate && <button onClick={()=>onFloatinActionClick()} type="button" class="mt-5 ml-5 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Create New Store</button>}
        
        </div>
      }
      {newStoreLoader &&
        <>
          <div class="mt-2 mb-2 text-lg">New Store</div>
          {source? <img class="h-auto rounded-lg mx-auto max-w-xs items-center justify-center" src={source} alt="logo" />
          :
          <div role="status" class=" items-center justify-center space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center">
            <div class="flex items-center justify-center w-full h-48 bg-gray-300 rounded sm:w-96 dark:bg-gray-700">
                <svg class="w-12 h-12 text-gray-200" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
            </div>
          </div>
          }
          
          <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">Upload Store Image</label>
          <input onChange={(e) => handleCapture(e.target)} id="file_input" type="file" accept="image/*" capture="environment" class="mb-5 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" />

          <div class="relative z-0 w-full mb-6 group">
            <input value={storeName} onChange={(e)=>setStoreName(e.target.value)} type="text" name="Store Name" id="Store Name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
            <label htmlFor="Store Name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Store Name</label>
          </div>
          <div class="relative z-0 mb-6 w-full group">
              <input value={address} onChange={(e)=>setAddress(e.target.value)} type="text" name="address" id="address" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "/>
              <label htmlFor="address" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Address</label>
          </div>

          <div class="grid md:grid-cols-2 md:gap-6">
          <div class="relative z-0 mb-6 w-full group">
              <input value={email} onChange={(e)=>setEmail(e.target.value)} type="text" name="email" id="email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "/>
              <label htmlFor="email" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email</label>
          </div>
          <div class="relative z-0 mb-6 w-full group">
              <input value={website} onChange={(e)=>setWebsite(e.target.value)} type="text" name="website" id="website" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "/>
              <label htmlFor="website" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Website</label>
          </div>
          </div>
          <div class="relative z-0 mb-6 w-full group">
          {phonenumber.map((cinput, ckey)=>(
          <div key={ckey} class="relative z-0 mb-6 w-full group">
          <label htmlFor="website" class="text-sm text-gray-500 dark:text-gray-400">Phone Number</label>
          <PhoneInput
            id="businessUserPhoneNumber"
            class="block py-2.5 ps-6 pe-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            international
            withDarkTheme
            placeholder="Store Phonenumber"
            defaultCountry={countryID}
            value={cinput.value}
            onChange={(e)=>inputcHandler(e, ckey)}
            />
          <button onClick = {()=> deletecHandler(ckey)} type="button" class="absolute top-5 right-0 p-2.5 text-red-700 hover:text-purple font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:text-red-500 dark:hover:text-purple"><svg aria-hidden="true" class="w-5 h-5" fill="#ffffff" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path></svg></button>
          </div>
          ))}
          <button onClick={()=>addcHandler()} type="button" class="mb-6 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Add Another Number</button>
          
          </div>
          <p class="text-xl text-gray-900 dark:text-white">Working Hours</p>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          
            <table class="mt-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                        Day
                    </th>
                    <th scope="col" class="px-6 py-3">
                        From
                    </th>
                    <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                        To
                    </th>
                </tr>
              </thead>
              <tbody>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" value="" class="sr-only peer" checked={sunChecked} onChange={()=>setSunChecked(sunChecked => !sunChecked)}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Sunday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                    {sunChecked? 
                      <DatePicker
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        selected={sunStartTime}
                        onChange={(date) => setSunStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                  <td class="px-6 py-4">
                    {sunChecked? 
                      <DatePicker
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        selected={sunEndTime}
                        onChange={(date) => setSunEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" value="" class="sr-only peer" checked={monChecked} onChange={()=>setMonChecked(monChecked => !monChecked)}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Monday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {monChecked? 
                    <DatePicker
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        selected={monStartTime}
                        onChange={(date) => setMonStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {monChecked?   
                    <DatePicker
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        selected={monEndTime}
                        onChange={(date) => setMonEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" value="" class="sr-only peer" checked={tueChecked} onChange={()=>setTueChecked(tueChecked => !tueChecked)}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Tuesday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {tueChecked?   
                    <DatePicker
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        selected={tueStartTime}
                        onChange={(date) => setTueStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {tueChecked?   
                    <DatePicker
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        selected={tueEndTime}
                        onChange={(date) => setTueEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" value="" class="sr-only peer" checked={wedChecked} onChange={()=>setWedChecked(wedChecked => !wedChecked)}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Wednesday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {wedChecked? <DatePicker
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        selected={wedStartTime}
                        onChange={(date) => setWedStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {wedChecked? 
                  <DatePicker
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        selected={wedEndTime}
                        onChange={(date) => setWedEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" value="" class="sr-only peer" checked={thuChecked} onChange={()=>setThuChecked(thuChecked => !thuChecked)}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Thursday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {thuChecked? 
                    <DatePicker
                        selected={thuStartTime}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        onChange={(date) => setThuStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {thuChecked? 
                    <DatePicker
                        selected={thuEndTime}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        onChange={(date) => setThuEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" value="" class="sr-only peer" checked={friChecked} onChange={()=>setFriChecked(friChecked => !friChecked)}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Friday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {friChecked? 
                    <DatePicker
                        selected={friStartTime}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        onChange={(date) => setFriStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {friChecked? 
                    <DatePicker
                        selected={friEndTime}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        onChange={(date) => setFriEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" value="" class="sr-only peer" checked={satChecked} onChange={()=>setSatChecked(satChecked => !satChecked)}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Saturday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {satChecked? 
                    <DatePicker
                        selected={satStartTime}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        onChange={(date) => setSatStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {satChecked? 
                    <DatePicker
                        selected={satEndTime}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        onChange={(date) => setSatEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="mt-5 text-xl text-gray-900 dark:text-white">Service Offered</p>
          <div class="relative z-0 mb-6 w-full group">
          
          {services.map((cinput, ckey)=>(
          <div key={ckey} class="relative z-0 mb-6 w-full group">
          <input name='alternatenumber' value={cinput.services}  onChange={(e)=>inputHandler(e.target.value, ckey)} type="text" id="search-dropdown" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"/>
          <button onClick = {()=> deleteHandler(ckey)} type="button" class="absolute top-2 right-0 p-2.5 text-red-700 hover:text-purple font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:text-red-500 dark:hover:text-purple"><svg aria-hidden="true" class="w-5 h-5" fill="#ffffff" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path></svg></button>
          </div>
          ))}
          <button onClick={()=>addHandler()} type="button" class="mb-6 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Add Another Service</button>
          
          </div>

          <p class="mt-5 text-xl text-gray-900 dark:text-white">Bill Header</p>
          <div class="relative z-0 mb-6 w-full group">
          
          {billHeader.map((cinput, ckey)=>(
          <div key={ckey} class="relative z-0 mb-6 w-full group">
          <input name='alternatenumber' value={cinput.services}  onChange={(e)=>inputbHandler(e.target.value, ckey)} type="text" id="search-dropdown" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"/>
          <button onClick = {()=> deletebHandler(ckey)} type="button" class="absolute top-2 right-0 p-2.5 text-red-700 hover:text-purple font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:text-red-500 dark:hover:text-purple"><svg aria-hidden="true" class="w-5 h-5" fill="#ffffff" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path></svg></button>
          </div>
          ))}
          <button onClick={()=>addbHandler()} type="button" class="mb-6 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Add Another Line</button>
          
          </div>

          <p class="mt-5 text-xl text-gray-900 dark:text-white">Store Location</p>
          <button onClick={()=>inputaLocationHandler()} type="button" class="mt-5 mr-5 inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Location
            
          </button>
          {latitude && <button onClick={()=> openmap(latitude, longitude)} type="button" class="ml-2 text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Map</button>}
          
          <div class="relative z-0 mb-6 mt-6 w-full group">
          <button onClick={()=>onSubmit()} type="button" class="mt-2 mr-2 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>
          <button onClick={()=>cancelNewStoreLoader("new")} type="button" class="mt-2 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
          </div>
         
          
        </>
      }
      {selectedStoreLoader &&
        <>
          <div class="mt-2 mb-2 text-lg">Store Details</div>
          {source? <img class="h-auto rounded-lg mx-auto max-w-xs" src={source} alt="logo" />
          :
          <div class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
              <span class="font-medium text-gray-600 dark:text-gray-300">Store Image</span>
          </div>
          }
          <input
            accept="image/*"
            id="icon-button-file"
            type="file"
            capture="environment"
            onChange={(e) => handleCapture(e.target)}
          />
          
          <div class="mt-5 relative z-0 w-full mb-6 group">
            <input value={storeName} onChange={(e)=>setStoreName(e.target.value)} type="text" name="Store Name" id="Store Name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
            <label htmlFor="Store Name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Store Name</label>
          </div>
          <div class="relative z-0 mb-6 w-full group">
              <input value={address} onChange={(e)=>setAddress(e.target.value)} type="text" name="address" id="address" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "/>
              <label htmlFor="address" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Address</label>
          </div>

          <div class="grid md:grid-cols-2 md:gap-6">
          <div class="relative z-0 mb-6 w-full group">
              <input value={email} onChange={(e)=>setEmail(e.target.value)} type="text" name="email" id="email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "/>
              <label htmlFor="email" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email</label>
          </div>
          <div class="relative z-0 mb-6 w-full group">
              <input value={website} onChange={(e)=>setWebsite(e.target.value)} type="text" name="website" id="website" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "/>
              <label htmlFor="website" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Website</label>
          </div>
            
            
          </div>
          <div class="relative z-0 mb-6 w-full group">
          {phonenumber.map((cinput, ckey)=>(
          <div key={ckey} class="relative z-0 mb-6 w-full group">
          <PhoneInput
            id="businessUserPhoneNumber"
            class="block py-2.5 ps-6 pe-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            international
            withDarkTheme
            placeholder="Store Phonenumber"
            defaultCountry={countryID}
            value={cinput.value}
            onChange={(e)=>inputcHandler(e, ckey)}
            />
          <button onClick = {()=> deletecHandler(ckey)} type="button" class="absolute top-5 right-0 p-2.5 text-red-700 hover:text-purple font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:text-red-500 dark:hover:text-purple"><svg aria-hidden="true" class="w-5 h-5" fill="#ffffff" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path></svg></button>
          </div>
          ))}
          <button onClick={()=>addcHandler()} type="button" class="mb-6 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Add Another Number</button>
          
          </div>
          <p class="text-xl text-gray-900 dark:text-white">Working Hours</p>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          
            <table class="mt-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                        Day
                    </th>
                    <th scope="col" class="px-6 py-3">
                        From
                    </th>
                    <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                        To
                    </th>
                </tr>
              </thead>
              <tbody>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" value="" class="sr-only peer" checked={sunChecked} onChange={()=>setSunChecked(sunChecked => !sunChecked)}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Sunday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                    {sunChecked? 
                      <DatePicker
                        selected={sunStartTime}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        onChange={(date) => setSunStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />
                    :<></>}
                  </td>
                  <td class="px-6 py-4">
                    {sunChecked? 
                      <DatePicker
                        selected={sunEndTime}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        onChange={(date) => setSunEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />
                    :<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" value="" class="sr-only peer" checked={monChecked} onChange={()=>setMonChecked(monChecked => !monChecked)}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Monday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {monChecked? 
                    <DatePicker
                        selected={monStartTime}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        onChange={(date) => setMonStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />
                  :<></>}
                  </td>
                  <td class="px-6 py-4">
                  {monChecked?  
                    <DatePicker
                        selected={monEndTime}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        onChange={(date) => setMonEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" value="" class="sr-only peer" checked={tueChecked} onChange={()=>setTueChecked(tueChecked => !tueChecked)}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Tuesday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {tueChecked?   
                    <DatePicker
                        selected={tueStartTime}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        onChange={(date) => setTueStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {tueChecked?   
                    <DatePicker
                        selected={tueEndTime}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        onChange={(date) => setTueEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" value="" class="sr-only peer" checked={wedChecked} onChange={()=>setWedChecked(wedChecked => !wedChecked)}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Wednesday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {wedChecked? 
                    <DatePicker
                        selected={wedStartTime}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        onChange={(date) => setWedStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {wedChecked? 
                    <DatePicker
                        selected={wedEndTime}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        onChange={(date) => setWedEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" value="" class="sr-only peer" checked={thuChecked} onChange={()=>setThuChecked(thuChecked => !thuChecked)}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Thursday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {thuChecked? 
                    <DatePicker
                        selected={thuStartTime}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        onChange={(date) => setThuStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {thuChecked? 
                    <DatePicker
                        selected={thuEndTime}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        onChange={(date) => setThuEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" value="" class="sr-only peer" checked={friChecked} onChange={()=>setFriChecked(friChecked => !friChecked)}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Friday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {friChecked? 
                    <DatePicker
                        selected={friStartTime}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        onChange={(date) => setFriStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {friChecked? 
                    <DatePicker
                        selected={friEndTime}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        onChange={(date) => setFriEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" value="" class="sr-only peer" checked={satChecked} onChange={()=>setSatChecked(satChecked => !satChecked)}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Saturday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {satChecked? 
                    <DatePicker
                        selected={satStartTime}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        onChange={(date) => setSatStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {satChecked? 
                    <DatePicker
                        selected={satEndTime}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        onChange={(date) => setSatEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                      />:<></>}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="mt-5 text-xl text-gray-900 dark:text-white">Print Headers</p>
          <div class="relative z-0 mb-6 w-full group">
          
          {billHeader.map((cinput, ckey)=>(
          <div key={ckey} class="relative z-0 mb-6 w-full group">
          <input name='alternatenumber' value={cinput.value}  onChange={(e)=>inputbHandler(e.target.value, ckey)} type="text" id="search-dropdown" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"/>
          <button onClick = {()=> deletebHandler(ckey)} type="button" class="absolute top-2 right-0 p-2.5 text-red-700 hover:text-purple font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:text-red-500 dark:hover:text-purple"><svg aria-hidden="true" class="w-5 h-5" fill="#ffffff" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path></svg></button>
          </div>
          ))}
          <button onClick={()=>addbHandler()} type="button" class="mb-6 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Add Another Line</button>
          
          </div>

          <p class="mt-5 text-xl text-gray-900 dark:text-white">Service Offered</p>
          <div class="relative z-0 mb-6 w-full group">
          
          {services.map((cinput, ckey)=>(
          <div key={ckey} class="relative z-0 mb-6 w-full group">
          <input name='alternatenumber' value={cinput.value}  onChange={(e)=>inputHandler(e.target.value, ckey)} type="text" id="search-dropdown" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"/>
          <button onClick = {()=> deleteHandler(ckey)} type="button" class="absolute top-2 right-0 p-2.5 text-red-700 hover:text-purple font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:text-red-500 dark:hover:text-purple"><svg aria-hidden="true" class="w-5 h-5" fill="#ffffff" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path></svg></button>
          </div>
          ))}
          <button onClick={()=>addHandler()} type="button" class="mb-6 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Add Another Service</button>
          
          </div>





          <p class="mt-5 text-xl text-gray-900 dark:text-white">Store Location</p>
          <button onClick={()=>inputaLocationHandler()} type="button" class="mt-5 mr-5 inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Location
            
          </button>
          {latitude && <button onClick={()=> openmap(latitude, longitude)} type="button" class="ml-2 text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Map</button>}
          
          
          <div class="relative z-0 mb-6 mt-6 w-full group">
          {settingsupdate && <button onClick={()=>onUpdate()} type="button" class="mt-2 mr-2 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>}
          <button onClick={()=>cancelNewStoreLoader("update")} type="button" class="mt-2 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
          {settingsdelete && <button onClick={()=>deleteStoreConfirmation()} type="button" class="mt-2 text-red-900 bg-white border border-red-300 focus:outline-none hover:bg-red-100 focus:ring-4 focus:ring-red-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-800 dark:text-white dark:border-red-600 dark:hover:bg-red-700 dark:hover:border-red-600 dark:focus:ring-red-700">Delete</button>}
          </div>
         


          
          
          
          
        </>
      }
      {showModal && 
        <div id="alert" class="p-4 mb-4 text-red-700 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Delete Store?</h3>
          </div>
          
          <div class="flex">
            <button onClick={()=>deleteStore()} type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Delete
            </button>
            <button onClick={()=>cancelDeletion()} type="button" class="text-red-700 bg-transparent border border-red-700 hover:bg-red-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-500 dark:border-red-500 dark:text-red-400 dark:hover:text-white dark:focus:ring-red-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
              Dismiss
            </button>
          </div>
        </div>
        
      }
    </div>
  )
}
