import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: "AIzaSyDep97WxLdAJpPd2F8W4HhqexzyunYjaAQ",
  authDomain: "laundry-1c0e2.firebaseapp.com",
  databaseURL: "https://laundry-1c0e2-default-rtdb.firebaseio.com",
  projectId: "laundry-1c0e2",
  storageBucket: "laundry-1c0e2.appspot.com",
  messagingSenderId: "545031320690",
  appId: "1:545031320690:web:fa5a534bbef9661cd27eeb",
  measurementId: "G-EHRBEB1NXK"
};

const firebaseapp = initializeApp(firebaseConfig);

export default firebaseapp;