/* eslint-disable react-hooks/exhaustive-deps */
import { child, get, getDatabase, push, ref as dref, set } from "firebase/database";
import React, { useEffect, useState } from 'react';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from "../../contexts/UserStoreContext";
import firebaseapp from '../../firebase';
import ls from 'localstorage-slim';

export default function InventoryNewitem() {
     
  
  const [loader, setLoader] = useState(true);
  const { storeID, setStoreID, countryCode, setCountryCode, inventorycreate} = useUserStore();
  const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);
  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);
  const [SKU, setSKU] = useState('');
  const [itemName, setItemName] = useState('');
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [inputUnit, setInputUnit] = useState('');
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [inputSize, setInputSize] = useState('');
  const [selectedSize, setSelectedSize] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [barcodeloader, setBarcodeloader] = useState(false);
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate();
  const torchOn = false;

  

  if(!storeID){
    setStoreID(userdata.storeID);
  }

  if(!countryCode){
    setCountryCode(userdata.country);
  }

  useEffect(() => { 
    function loadData(){
        get(child(dbRef, `${storeID}/inventory/${storeID}/settings`)).then((snapshot) => {
              if (snapshot.exists()) {
                  const allBrands = snapshot.child('brands').val();
                  const allCategory = snapshot.child('category').val();
                  const allUnit = snapshot.child('unit').val();
                  const allLocation = snapshot.child('location').val();
                  const allSize = snapshot.child('size').val();
                  
                  if(allBrands){
                        const brandsArr = allBrands.split(",");
                        const ArrayBrands = [];
                        for (const key in brandsArr) {
                            if (Object.hasOwnProperty.call(brandsArr, key)) {
                                  const name = brandsArr[key];
                                  const id = key;
                                  ArrayBrands.push({name:name,id:id})
                            }
                        }
                        setBrandList(ArrayBrands);
                  }

                  if(allCategory){
                        const categoryArr = allCategory.split(",");
                        const ArrayCategory = [];
                        for (const key in categoryArr) {
                            if (Object.hasOwnProperty.call(categoryArr, key)) {
                                  const name = categoryArr[key];
                                  const id = key;
                                  ArrayCategory.push({name:name,id:id})
                            }
                        }
                        setCategoryList(ArrayCategory);
                  }

                  if(allUnit){
                        const countingArr = allUnit.split(",");
                        const ArrayCounting = [];
                        for (const key in countingArr) {
                            if (Object.hasOwnProperty.call(countingArr, key)) {
                                  const name = countingArr[key];
                                  const id = key;
                                  ArrayCounting.push({name:name,id:id})
                            }
                        }
                        setUnitList(ArrayCounting);
                  }

                  if(allLocation){
                        const locationArr = allLocation.split(",");
                        const ArrayLocation = [];
                        for (const key in locationArr) {
                            if (Object.hasOwnProperty.call(locationArr, key)) {
                                  const name = locationArr[key];
                                  const id = key;
                                  ArrayLocation.push({name:name,id:id})
                            }
                        }
                        setLocationList(ArrayLocation);
                  }
                  if(allSize){
                        const sizeArr = allSize.split(",");
                        const ArraySize = [];
                        for (const key in sizeArr) {
                            if (Object.hasOwnProperty.call(sizeArr, key)) {
                                  const name = sizeArr[key];
                                  const id = key;
                                  ArraySize.push({name:name,id:id})
                            }
                        }
                        setSizeList(ArraySize);
                  }
              }
        });
    }
    loadData();
  }, []);

  function submitNewItem(){
    console.log("submit new item");
    let {sku, brand, itemname, location, category,
        size, sizeunit, unit, unitunit} = ''
    
    if(SKU !== 'undefined' && SKU.length){
        sku = SKU;
    }

    if(selectedBrand !== 'undefined' && selectedBrand.length){
        brand = selectedBrand;
    }

    if(itemName !== 'undefined' && itemName.length){
        itemname  = itemName
    }
    
    if(selectedLocation.name !== 'undefined' && selectedLocation.length){
        location = selectedLocation
    }

    if(selectedCategory !== 'undefined' && selectedCategory.length){
        category = selectedCategory
    }

    if(inputSize !== 'undefined' && inputSize.length){
        size = inputSize;
    }

    if(selectedSize !== 'undefined' && selectedSize.length){
        sizeunit = selectedSize
    }

    if(inputUnit !== 'undefined' && inputUnit.length){
        unit =  inputUnit
    }

    if(selectedUnits !== 'undefined' && selectedUnits.length){
        unitunit = selectedUnits
    }

    if(itemname.length){
        const pushid = push(child(dref(dbr), 'inventory')).key;
        set(dref(dbr, storeID+'/inventory/'+storeID+"/items/"+pushid), {
          sku:sku || null, 
          itemname: itemname || null, 
          brand:brand || null, 
          location:location || null, 
          category:category || null, 
          size: size || null, 
          sizeunit: sizeunit || null,
          units: unit || null, 
          unitunit: unitunit || null, 
        }).then(()=>{
            newItem();
            setLoader(false);
            setShowModal(true);
        }).catch((error)=>{
          console.log(error);
        });
    }
    else{
      console.log("Item name should not be empty");
    }

  }

  function newItem(){
    setSKU('');
    setItemName('');
    setSelectedLocation([]);
    setSelectedBrand([]);
    setInputUnit('');
    setLocationList([]);
    setSelectedUnits([]);
    setInputSize('');
    setSelectedSize([]);
    setSelectedCategory([]);
    setInputUnit('');
    setSelectedUnits([]);
    setBarcodeloader(false)
    setLoader(true);
  }

  function openBarcode(){
    setLoader(false);
    setBarcodeloader(true);
  }

  function closeBarcode(){
    setBarcodeloader(false);
    setLoader(true);
  }

  function sucessBarcode(code){
    setSKU(code);
    setBarcodeloader(false);
    setLoader(true);
  }

  function goNewitem(){
    setShowModal(false);
    setLoader(true);
  }
      
  return (
    <div>
      <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <Link to='/inventory' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              Inventory
            </Link>
            </div>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Inventory New Item</span>
            </div>
          </li>
        </ol>
      </nav>
    {barcodeloader && 
      <div>
      <BarcodeScannerComponent
        width={500}
        height={500}
        onUpdate={(err, result) => {
          if (result) sucessBarcode(result.text);
          else console.log(err)
        }}
        torch={torchOn}
        
      />
      {SKU? <p class="text-sm text-gray-900 dark:text-white">Scanned value: {SKU}</p>:<p class="text-sm text-gray-900 dark:text-white">No value scanned...</p>}
      <button onClick={()=>closeBarcode()} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Close</button>
      </div>
    }
    {loader && 
    <>
      <div class="mt-2 mb-2 text-lg">New Item</div>
      <div class="grid md:gap-6">
        <div class="relative z-0 mb-6 w-full group ">
          <input value={SKU} onChange={(e)=>setSKU(e.target.value)} type="text" name="sku" id="sku" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
          <button onClick={()=>openBarcode()} type="button" class="absolute top-0 right-0 p-2.5 text-sm font-medium text-black dark:text-white bg-grey-700 rounded-r-lg border border-grey-700 hover:bg-grey-800 focus:ring-4 focus:outline-none focus:ring-grey-300 dark:bg-grey-600 dark:hover:bg-grey-700 dark:focus:ring-grey-800"><svg aria-hidden="true" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M6 8h2v8H6zm3 0h1v8H9zm8 0h1v8h-1zm-4 0h3v8h-3zm-2 0h1v8h-1z"></path></svg></button>
          <label htmlFor="sku" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">SKU</label>
        </div>
        <div class="relative z-0 mb-6 w-full group">
          <input value={itemName} onChange={(e)=>setItemName(e.target.value)} type="text" name="Item Name" id="Item Name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
          <label htmlFor="Item Name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Item Name</label>
        </div>
        <div class="relative z-0 mb-6 w-full group">
        
        <label htmlFor="Brand" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Brand</label>
        <select onChange={e => setSelectedBrand(e.target.value)} id="Brand" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option >select brand</option>
          {brandList.map((item, index)=>(
          <option key={index} value={item.name}>{item.name}</option>
          ))}
        </select>
        </div>
        <div class="relative z-0 mb-6 w-full group">
        <label htmlFor="Item Location" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Item Location</label>
        <select onChange={e => setSelectedLocation(e.target.value)} id="Brand" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option >select Location</option>
          {locationList.map((item, index)=>(
          <option key={index} value={item.name}>{item.name}</option>
          ))}
        </select>
        </div>
        <div class="relative z-0 mb-6 w-full group">
        <label htmlFor="Category" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Category</label>
        <select onChange={e => setSelectedCategory(e.target.value)} id="Brand" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option >Select Category</option>
          {categoryList.map((item, index)=>(
          <option key={index} value={item.name}>{item.name}</option>
          ))}
        </select>
        </div>
        <div class="relative z-0 mb-6 w-full group">
          <label htmlFor="size" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Size</label>
          <div class="flex">
          <input onChange={(e)=>setInputSize(e.target.value)} type="text" id="small-input" class="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
          
          <select onChange={e => setSelectedSize(e.target.value)} id="Brand" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <option >Select Size</option>
            {sizeList.map((item, index)=>(
            <option key={index} value={item.name}>{item.name}</option>
            ))}
          </select>
        </div>
          <label htmlFor="Unit" class="mt-5 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Unit</label>
          <div class="flex">
          <input onChange={(e)=>setInputUnit(e.target.value)} type="text" id="small-input" class="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
          <select onChange={e => setSelectedUnits(e.target.value)} id="Unit" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <option >Select Units</option>
            {unitList.map((item, index)=>(
            <option key={index} value={item.name}>{item.name}</option>
            ))}
          </select>
        </div>
        </div>
        <div class="flex mt-4 space-x-3 md:mt-6">
        {inventorycreate &&
        <button onClick={()=>submitNewItem()} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800">
            Submit
        </button>
        }
        </div>
      </div>
    </>
    }
    {showModal && 
        <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Item created successfully</h3>
          </div>
          <div class="mt-2 mb-4 text-sm">
            Do you wish to create another item?
          </div>
          <div class="flex">
            <button onClick={()=>goNewitem()} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Yes
            </button>
            <button onClick={()=>navigate("/inventory")} type="button" class="text-green-700 bg-transparent border border-green-700 hover:bg-green-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-green-500 dark:border-green-500 dark:text-green-400 dark:hover:text-white dark:focus:ring-green-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
              Dismiss
            </button>
          </div>
        </div>
      }
    </div>
    
  )
}
