/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from "../contexts/UserStoreContext";

export default function Staff() {
     
  
  
  const navigate = useNavigate();
  const {staffread} = useUserStore();

  return (
     <>
     <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Staff</span>
            </div>
          </li>
        </ol>
      </nav>
     {staffread? 
     <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 lg:gap-4 flex flex-col items-center justify-center ">
               
                    <Link to='/employees'>
                    <div class="p-2 bg-[#F3B0C3] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <path d="M20 2H8a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm-6 2.5a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zM19 15H9v-.25C9 12.901 11.254 11 14 11s5 1.901 5 3.75V15z"></path><path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Employees</h5>
                         </div>
                    </div>
                    </Link>
                    <Link to='/shift'>
                    <div class="p-2 bg-[#a2e1db] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <path d="m20.145 8.27 1.563-1.563-1.414-1.414L18.586 7c-1.05-.63-2.274-1-3.586-1-3.859 0-7 3.14-7 7s3.141 7 7 7 7-3.14 7-7a6.966 6.966 0 0 0-1.855-4.73zM15 18c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z"></path><path d="M14 10h2v4h-2zm-1-7h4v2h-4zM3 8h4v2H3zm0 8h4v2H3zm-1-4h3.99v2H2z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Shift</h5>
                         </div>
                    </div>
                    </Link>
                    <Link to='/task'>
                    <div class="p-2 bg-[#cbaacb] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <path d="M5 22h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2h-2a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1H5c-1.103 0-2 .897-2 2v15c0 1.103.897 2 2 2zM5 5h2v2h10V5h2v15H5V5z"></path><path d="m11 13.586-1.793-1.793-1.414 1.414L11 16.414l5.207-5.207-1.414-1.414z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Task</h5>
                         </div>
                    </div>
                    </Link>
                    <Link to='/permission'>
                    <div class="p-2 bg-[#cce2cb] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <path d="M7 17a5.007 5.007 0 0 0 4.898-4H14v2h2v-2h2v3h2v-3h1v-2h-9.102A5.007 5.007 0 0 0 7 7c-2.757 0-5 2.243-5 5s2.243 5 5 5zm0-8c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Permission</h5>
                         </div>
                    </div>
                    </Link>
                    
                    
     </div>:
      <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">You do not have permission to view Staff. Contact your admin for permissions</h3>
          </div>
          <div class="flex">
            <button onClick={()=>navigate("/")} type="button" class="text-green-700 bg-transparent border border-green-700 hover:bg-green-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-green-500 dark:border-green-500 dark:text-green-400 dark:hover:text-white dark:focus:ring-green-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
              OK
            </button>
          </div>
      </div>
      }
     </>
     
  )
}