import firebaseapp from '../firebase';
import getStripe from './initializeStripe.ts';
import { doc, addDoc, getFirestore, collection, onSnapshot } from "firebase/firestore"; 

const dbfirestore = getFirestore(firebaseapp);

export async function createCheckoutSession(storeid, priceid){
    
     const checkoutSessionRef = await addDoc(collection(dbfirestore, "customers", storeid, 'checkout_sessions'), {
      price:  priceid,
      success_url: window.location.origin,
      cancel_url: window.location.origin
      });

     onSnapshot(checkoutSessionRef, (snap) => {
      const { error, url } = snap.data();
      console.log(snap.data(), "inside checkout onsnapshot", error, url);
      if(error){
        console.log(error.message);
      }
      if (url) {
        window.location.assign(url);
      }
    });
}