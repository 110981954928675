/* eslint-disable react-hooks/exhaustive-deps */
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, doc, getFirestore, query, where, onSnapshot  } from "firebase/firestore";
import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from "../contexts/UserStoreContext";
import { useUserAuth } from "../contexts/UserAuthContext";
import firebaseapp from '../firebase';
import moment from 'moment';
import ls from 'localstorage-slim';
import { ThemeContext } from '../contexts/ThemeContext';

export default function Home() {
     const dbfirestore = getFirestore(firebaseapp);
     const [loader, setLoader] = useState(false);
     const [stValidity, setStValidity] = useState(false);
     const [load, setLoad] = useState(false);
     const { user } = useUserAuth();
     const navigate = useNavigate();
     const {setBusinessName, businessName, storeID, setStoreID, setUserLanguage, setCountryCode, setUserPhoneNumber, setUserEmail, setLanguageCode, setAccountscreate, setCustomerscreate, setDeliverycreate, setInventorycreate,  setSettingscreate, setCollectioncreate, setInvoicecreate, setLaundrycreate, setOfferscreate, setPckagecreate, setPickupcreate, setPricingcreate, setRechargecreate, setReportscreate, setStaffcreate, setStorecreate,  accountsread, setAccountsread,  customersread, setCustomersread,  settingsread, setSettingsread, collectionread, setCollectionread,  deliveryread, setDeliveryread,  inventoryread, setInventoryread,  invoiceread, setInvoiceread,  laundryread, setLaundryread,  offersread, setOffersread,  pckageread, setPckageread,  pickupread, setPickupread,  pricingread, setPricingread,  rechargeread, setRechargeread,  reportsread, setReportsread,  staffread, setStaffread, setStoreread, setAccountsupdate, setCustomersupdate, setSettingsupdate, setCollectionupdate, setDeliveryupdate, setInventoryupdate, setInvoiceupdate, setLaundryupdate, setOffersupdate, setPckageupdate, setPickupupdate, setPricingupdate, setRechargeupdate, setReportsupdate, setStaffupdate, setStoreupdate, setAccountsdelete, setCustomersdelete, setSettingsdelete, setCollectiondelete, setDeliverydelete, setInventorydelete, setInvoicedelete, setLaundrydelete, setOffersdelete, setPckagedelete, setPickupdelete, setPricingdelete, setRechargedelete, setReportsdelete, setStaffdelete, setStoredelete, setStoreValidity} = useUserStore();
     const {theme } = useContext(ThemeContext);
     

     useEffect(() => { 
          if(user){
               console.log(theme, "theme 26 home");
               async function checkData(){
                    const dt = ls.get("val", { decrypt: true });
                    const validityDate = moment(dt);
                    var validity = moment(new Date()).isSameOrBefore(validityDate);
                    
                         if (!validity) {
                              setStValidity(false);
                         }else{
                              setStValidity(true);
                         } 
                         if(user.email){
                              const email = user.email;
                              onSnapshot(doc(dbfirestore, "staff", email), (doc) => {
                              console.log("error before store id");
                              setStoreID(doc.data().storeID);
                              setBusinessName(doc.data().businessName);
                              setUserLanguage(doc.data().userLanguage); 
                              setCountryCode(doc.data().countryCode); 
                              setUserPhoneNumber(doc.data().userPhoneNumber); 
                              setUserEmail(doc.data().userEmail);
                              setLanguageCode(doc.data().languageCode); 
                              setAccountscreate(doc.data().accountscreate); 
                              setCustomerscreate(doc.data().customerscreate); 
                              setDeliverycreate(doc.data().deliverycreate);
                              setSettingscreate(doc.data().settingscreate); 
                              setCollectioncreate(doc.data().collectioncreate); 
                              setInventorycreate(doc.data().inventorycreate); 
                              setInvoicecreate(doc.data().invoicecreate); 
                              setLaundrycreate(doc.data().laundrycreate); 
                              setOfferscreate(doc.data().offerscreate); 
                              setPckagecreate(doc.data().pckagecreate); 
                              setPickupcreate(doc.data().pickupcreate); 
                              setPricingcreate(doc.data().pricingcreate); 
                              setRechargecreate(doc.data().rechargecreate); 
                              setReportscreate(doc.data().reportscreate); 
                              setStaffcreate(doc.data().staffcreate); 
                              setStorecreate(doc.data().storecreate); 
                              setAccountsread(doc.data().accountsread); 
                              setCustomersread(doc.data().customersread); 
                              setDeliveryread(doc.data().deliveryread); 
                              setSettingsread(doc.data().settingsread);
                              setCollectionread(doc.data().collectionread);
                              setInventoryread(doc.data().inventoryread); 
                              setInvoiceread(doc.data().invoiceread); 
                              setLaundryread(doc.data().laundryread); 
                              setOffersread(doc.data().offersread); 
                              setPckageread(doc.data().pckageread); 
                              setPickupread(doc.data().pickupread);
                              setPricingread(doc.data().pricingread);  
                              setRechargeread(doc.data().rechargeread); 
                              setReportsread(doc.data().reportsread); 
                              setStaffread(doc.data().staffread); 
                              setStoreread(doc.data().storeread); 
                              setAccountsupdate(doc.data().accountsupdate); 
                              setCustomersupdate(doc.data().customersupdate); 
                              setDeliveryupdate(doc.data().deliveryupdate);
                              setSettingsupdate(doc.data().settingsupdate);
                              setCollectionupdate(doc.data().collectionupdate);
                              setInventoryupdate(doc.data().inventoryupdate); 
                              setInvoiceupdate(doc.data().invoiceupdate);
                              setLaundryupdate(doc.data().laundryupdate); 
                              setOffersupdate(doc.data().offersupdate); 
                              setPckageupdate(doc.data().pckageupdate); 
                              setPickupupdate(doc.data().pickupupdate); 
                              setPricingupdate(doc.data().pricingupdate); 
                              setRechargeupdate(doc.data().rechargeupdate); 
                              setReportsupdate(doc.data().reportsupdate);
                              setStaffupdate(doc.data().staffupdate); 
                              setStoreupdate(doc.data().storeupdate); 
                              setAccountsdelete(doc.data().accountsdelete); 
                              setCustomersdelete(doc.data().customersdelete); 
                              setDeliverydelete(doc.data().deliverydelete); 
                              setSettingsdelete(doc.data().settingsdelete);
                              setCollectiondelete(doc.data().collectiondelete);
                              setInventorydelete(doc.data().inventorydelete); 
                              setInvoicedelete(doc.data().invoicedelete);
                              setLaundrydelete(doc.data().laundrydelete); 
                              setOffersdelete(doc.data().offersdelete); 
                              setPckagedelete(doc.data().pckagedelete);
                              setPickupdelete(doc.data().pickupdelete);
                              setPricingdelete(doc.data().pricingdelete);
                              setRechargedelete(doc.data().rechargedelete); 
                              setReportsdelete(doc.data().reportsdelete); 
                              setStaffdelete(doc.data().staffdelete); 
                              setStoredelete(doc.data().storedelete);
                                   
                              const userinfo = JSON.stringify({
                                   uid: doc.data().uid,
                                   languageCode:doc.data().selectedLanguage,
                                   phoneNumber:doc.data().phoneNumber,
                                   storeID:doc.data().storeID, 
                                   businessName:doc.data().businessName,
                                   countryCode:doc.data().countryCode,  
                                   accountscreate:doc.data().accountscreate,
                                   customerscreate:doc.data().customerscreate,
                                   deliverycreate:doc.data().deliverycreate,
                                   settingscreate:doc.data().settingscreate,
                                   collectioncreate:doc.data().collectioncreate,
                                   inventorycreate:doc.data().inventorycreate,
                                   invoicecreate:doc.data().invoicecreate,
                                   laundrycreate:doc.data().laundrycreate,
                                   offerscreate:doc.data().offerscreate,
                                   pckagecreate:doc.data().pckagecreate,
                                   pickupcreate:doc.data().pickupcreate,
                                   pricingcreate:doc.data().pricingcreate,
                                   rechargecreate:doc.data().rechargecreate,
                                   reportscreate:doc.data().reportscreate,
                                   staffcreate:doc.data().staffcreate,
                                   storecreate:doc.data().storecreate,
                                   accountsread:doc.data().accountsread,
                                   customersread:doc.data().customersread,
                                   deliveryread:doc.data().deliveryread,
                                   settingsread:doc.data().settingsread,
                                   collectionread:doc.data().collectionread,
                                   inventoryread:doc.data().inventoryread,
                                   invoiceread:doc.data().invoiceread,
                                   laundryread:doc.data().laundryread,
                                   offersread:doc.data().offersread,
                                   pckageread:doc.data().pckageread,
                                   pickupread:doc.data().pickupread,
                                   pricingread:doc.data().pricingread,
                                   rechargeread:doc.data().rechargeread,
                                   reportsread:doc.data().reportsread,
                                   staffread:doc.data().staffread,
                                   storeread:doc.data().storeread,
                                   accountsupdate:doc.data().accountsupdate,
                                   customersupdate:doc.data().customersupdate,
                                   deliveryupdate:doc.data().deliveryupdate,
                                   settingsupdate:doc.data().settingsupdate,
                                   collectionupdate:doc.data().collectionupdate,
                                   inventoryupdate:doc.data().inventoryupdate,
                                   invoiceupdate:doc.data().invoiceupdate,
                                   laundryupdate:doc.data().laundryupdate,
                                   offersupdate:doc.data().offersupdate,
                                   pckageupdate:doc.data().pckageupdate,
                                   pickupupdate:doc.data().pickupupdate,
                                   pricingupdate:doc.data().pricingupdate,
                                   rechargeupdate:doc.data().rechargeupdate,
                                   reportsupdate:doc.data().reportsupdate,
                                   staffupdate:doc.data().staffupdate,
                                   storeupdate:doc.data().storeupdate,
                                   accountsdelete:doc.data().accountsdelete,
                                   customersdelete:doc.data().customersdelete,
                                   deliverydelete:doc.data().deliverydelete,
                                   settingsdelete:doc.data().settingsdelete,
                                   collectiondelete:doc.data().collectiondelete,
                                   inventorydelete:doc.data().inventorydelete,
                                   invoicedelete:doc.data().invoicedelete,
                                   laundrydelete:doc.data().laundrydelete,
                                   offersdelete:doc.data().offersdelete,
                                   pckagedelete:doc.data().pckagedelete,
                                   pickupdelete:doc.data().pickupte,
                                   pricingdelete:doc.data().pricingdelete,
                                   rechargedelete:doc.data().rechargedelete,
                                   reportsdelete:doc.data().reportsdelete,
                                   staffdelete:doc.data().staffdelete,
                                   storedelete:doc.data().storedelete
                              })
                              ls.set("userinfo", userinfo, { encrypt: true })
                              
                              });
                              setLoader(true);
                         }else{
                              const userinfo = ls.get("userinfo", { decrypt: true });
                              const userVal = JSON.parse(userinfo);
                              console.log(userVal, "userVal");
                              if(userVal){
                              setStoreID(userVal.storeID);
                              setBusinessName(userVal.businessName);
                              setUserLanguage(userVal.userLanguage); 
                              setCountryCode(userVal.countryCode); 
                              setUserPhoneNumber(userVal.userPhoneNumber); 
                              setUserEmail(userVal.userEmail);
                              setLanguageCode(userVal.languageCode); 
                              setAccountscreate(userVal.accountscreate); 
                              setCustomerscreate(userVal.customerscreate); 
                              setDeliverycreate(userVal.deliverycreate);
                              setSettingscreate(userVal.settingscreate); 
                              setCollectioncreate(userVal.collectioncreate); 
                              setInventorycreate(userVal.inventorycreate); 
                              setInvoicecreate(userVal.invoicecreate); 
                              setLaundrycreate(userVal.laundrycreate); 
                              setOfferscreate(userVal.offerscreate); 
                              setPckagecreate(userVal.pckagecreate); 
                              setPickupcreate(userVal.pickupcreate); 
                              setPricingcreate(userVal.pricingcreate); 
                              setRechargecreate(userVal.rechargecreate); 
                              setReportscreate(userVal.reportscreate); 
                              setStaffcreate(userVal.staffcreate); 
                              setStorecreate(userVal.storecreate); 
                              setAccountsread(userVal.accountsread); 
                              setCustomersread(userVal.customersread); 
                              setDeliveryread(userVal.deliveryread); 
                              setSettingsread(userVal.settingsread);
                              setCollectionread(userVal.collectionread);
                              setInventoryread(userVal.inventoryread); 
                              setInvoiceread(userVal.invoiceread); 
                              setLaundryread(userVal.laundryread); 
                              setOffersread(userVal.offersread); 
                              setPckageread(userVal.pckageread); 
                              setPickupread(userVal.pickupread);
                              setPricingread(userVal.pricingread);  
                              setRechargeread(userVal.rechargeread); 
                              setReportsread(userVal.reportsread); 
                              setStaffread(userVal.staffread); 
                              setStoreread(userVal.storeread); 
                              setAccountsupdate(userVal.accountsupdate); 
                              setCustomersupdate(userVal.customersupdate); 
                              setDeliveryupdate(userVal.deliveryupdate);
                              setSettingsupdate(userVal.settingsupdate);
                              setCollectionupdate(userVal.collectionupdate);
                              setInventoryupdate(userVal.inventoryupdate); 
                              setInvoiceupdate(userVal.invoiceupdate);
                              setLaundryupdate(userVal.laundryupdate); 
                              setOffersupdate(userVal.offersupdate); 
                              setPckageupdate(userVal.pckageupdate); 
                              setPickupupdate(userVal.pickupupdate); 
                              setPricingupdate(userVal.pricingupdate); 
                              setRechargeupdate(userVal.rechargeupdate); 
                              setReportsupdate(userVal.reportsupdate);
                              setStaffupdate(userVal.staffupdate); 
                              setStoreupdate(userVal.storeupdate); 
                              setAccountsdelete(userVal.accountsdelete); 
                              setCustomersdelete(userVal.customersdelete); 
                              setDeliverydelete(userVal.deliverydelete); 
                              setSettingsdelete(userVal.settingsdelete);
                              setCollectiondelete(userVal.collectiondelete);
                              setInventorydelete(userVal.inventorydelete); 
                              setInvoicedelete(userVal.invoicedelete);
                              setLaundrydelete(userVal.laundrydelete); 
                              setOffersdelete(userVal.offersdelete); 
                              setPckagedelete(userVal.pckagedelete);
                              setPickupdelete(userVal.pickupdelete);
                              setPricingdelete(userVal.pricingdelete);
                              setRechargedelete(userVal.rechargedelete); 
                              setReportsdelete(userVal.reportsdelete); 
                              setStaffdelete(userVal.staffdelete); 
                              setStoredelete(userVal.storedelete);
                              }  
                              setLoader(true);
                         }
                    
                    
                    
                    
               }
               checkData();

          }
          
     }, [user])

     
     useEffect(() => {
          if(load){
               setLoad(false);
               async function fetchData() {  
               //change this to store id
               
               const colRef = collection(dbfirestore, "customers", storeID, 'subscriptions');
               const qry = query(colRef, where('status', 'in', ['trialing', 'active']));
               const querySnapshot = await getDocs(qry);
               console.log("storeID", storeID, "querySnapshot", querySnapshot);
               if (querySnapshot.empty) {
                 setStValidity(false);   
               }else{
               
               let val =false;
               querySnapshot.forEach((doc) => {
               if(doc.id){
                    var validity = moment(new Date()).isSameOrBefore(doc.data().current_period_end.toDate());
                    
                         if (!validity) {
                              val = false;
                         }else{
                              ls.set("val", doc.data().current_period_end.toDate(), { encrypt: true })
                              setStoreValidity(doc.data().current_period_end.toDate());
                              val = true;
                         } 
                    
               }
               });
               setStValidity(val);
               
               } 
           
           
           };
           fetchData();
           }
        }, [load])
         
  return (
     <>
          {stValidity? 
               <>
               
                    {loader ?  
                         <div class="bg-white dark:bg-slate-800 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 lg:gap-4 flex flex-col items-center justify-center ">
                              {customersread && 
                              <Link to='/customers'>
                                   <div class="p-2 bg-[#F3B0C3] rounded-md shadow-xl w-70 items-center justify-center ">
                                        <div class="flex flex-col items-center pb-10">
                                             <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                                             <path d="M12 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"></path>
                                             </svg>
                                             <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Customers</h5>
                                        </div>
                                   </div>
                              </Link>
                              }
                              {pickupread &&
                              <Link to="/pickup" state={["Home", false]} >
                                   <div class="p-2 bg-[#dad7eb] rounded-md shadow-xl w-70 items-center justify-center ">
                                        <div class="flex flex-col items-center pb-10">
                                             <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                                             <path d="m12 3.879-7.061 7.06 2.122 2.122L12 8.121l4.939 4.94 2.122-2.122z"></path><path d="m4.939 17.939 2.122 2.122L12 15.121l4.939 4.94 2.122-2.122L12 10.879z"></path>
                                             </svg>
                                             <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Pickup</h5>
                                        </div>
                                   </div>
                              </Link>
                              }
                              {collectionread &&
                              <Link to='/collection' state={["Home", false]}>
                                   <div class="p-2 bg-[#cce2cb] rounded-md shadow-xl w-70 items-center justify-center ">
                                        <div class="flex flex-col items-center pb-10">
                                             <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                                             <path d="M21.822 7.431A1 1 0 0 0 21 7H7.333L6.179 4.23A1.994 1.994 0 0 0 4.333 3H2v2h2.333l4.744 11.385A1 1 0 0 0 10 17h8c.417 0 .79-.259.937-.648l3-8a1 1 0 0 0-.115-.921zM17.307 15h-6.64l-2.5-6h11.39l-2.25 6z"></path><circle cx="10.5" cy="19.5" r="1.5"></circle><circle cx="17.5" cy="19.5" r="1.5"></circle>
                                             </svg>
                                             <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Collection</h5>
                                        </div>
                                   </div>
                              </Link>
                              }
                              {laundryread && 
                              <Link to='/laundry'>
                                   <div class="p-2 bg-[#ffd8be] rounded-md shadow-xl w-70 items-center justify-center ">
                                        <div class="flex flex-col items-center pb-10">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                                             <path d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zm0 2 .001 4H4V5h16zM4 19v-8h16.001l.001 8H4z"></path><path d="M14 6h2v2h-2zm3 0h2v2h-2z"></path>
                                             </svg>
                                             <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Laundry</h5>
                                        </div>
                                   </div>
                              </Link>
                              }
                              {deliveryread && 
                              <Link to='/delivery'>
                                   <div class="p-2 bg-[#ECD5E3] rounded-md shadow-xl w-70 items-center justify-center ">
                                        <div class="flex flex-col items-center pb-10">
                                             <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                                             <path d="m20.145 8.27 1.563-1.563-1.414-1.414L18.586 7c-1.05-.63-2.274-1-3.586-1-3.859 0-7 3.14-7 7s3.141 7 7 7 7-3.14 7-7a6.966 6.966 0 0 0-1.855-4.73zM15 18c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z"></path><path d="M14 10h2v4h-2zm-1-7h4v2h-4zM3 8h4v2H3zm0 8h4v2H3zm-1-4h3.99v2H2z"></path>
                                             </svg>
                                             <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Delivery</h5>
                                        </div>
                                   </div>
                              </Link>
                              }
                              {invoiceread && 
                              <Link to='/invoice'>
                                   <div class="p-2 bg-[#a2e1db] rounded-md shadow-xl w-70 items-center justify-center ">
                                        <div class="flex flex-col items-center pb-10">
                                             <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                                             <path d="M21 4H3a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1zm-1 11a3 3 0 0 0-3 3H7a3 3 0 0 0-3-3V9a3 3 0 0 0 3-3h10a3 3 0 0 0 3 3v6z"></path><path d="M12 8c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"></path>
                                             </svg>
                                             <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Invoice</h5>
                                        </div>
                                   </div>
                              </Link>
                              }
                              {accountsread && 
                              <Link to='/accounts'>
                                   <div class="p-2 bg-[#ffdbcc] rounded-md shadow-xl w-70 items-center justify-center ">
                                        <div class="flex flex-col items-center pb-10">
                                             <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                                             <path d="M19 2H5c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM5 20V4h14l.001 16H5z">
                                             </path>
                                             <path d="M7 12h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zM7 6h10v4H7zm4 10h2v2h-2zm4-4h2v6h-2z">
                                             </path>
                                             </svg>
                                             <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Accounts</h5>
                                        </div>
                                   </div>
                              </Link>
                              }
                              {pricingread && 
                              <Link to='/pricing'>
                                   <div class="p-2 bg-[#d4f0f0] rounded-md shadow-xl w-70 items-center justify-center ">
                                        <div class="flex flex-col items-center pb-10">
                                             <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                                             <path d="M11.707 2.293A.997.997 0 0 0 11 2H6a.997.997 0 0 0-.707.293l-3 3A.996.996 0 0 0 2 6v5c0 .266.105.52.293.707l10 10a.997.997 0 0 0 1.414 0l8-8a.999.999 0 0 0 0-1.414l-10-10zM13 19.586l-9-9V6.414L6.414 4h4.172l9 9L13 19.586z"></path><circle cx="8.353" cy="8.353" r="1.647"></circle>
                                             </svg>
                                             <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Pricing</h5>
                                        </div>
                                   </div>
                              </Link>
                              }
                              {offersread && 
                              <Link to='/offers'>
                                   <div class="p-2 bg-[#ffffb5] rounded-md shadow-xl w-70 items-center justify-center ">
                                        <div class="flex flex-col items-center pb-10">
                                             <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                                             <path d="M20 7h-1.209A4.92 4.92 0 0 0 19 5.5C19 3.57 17.43 2 15.5 2c-1.622 0-2.705 1.482-3.404 3.085C11.407 3.57 10.269 2 8.5 2 6.57 2 5 3.57 5 5.5c0 .596.079 1.089.209 1.5H4c-1.103 0-2 .897-2 2v2c0 1.103.897 2 2 2v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7c1.103 0 2-.897 2-2V9c0-1.103-.897-2-2-2zm-4.5-3c.827 0 1.5.673 1.5 1.5C17 7 16.374 7 16 7h-2.478c.511-1.576 1.253-3 1.978-3zM7 5.5C7 4.673 7.673 4 8.5 4c.888 0 1.714 1.525 2.198 3H8c-.374 0-1 0-1-1.5zM4 9h7v2H4V9zm2 11v-7h5v7H6zm12 0h-5v-7h5v7zm-5-9V9.085L13.017 9H20l.001 2H13z"></path>
                                             </svg>
                                             <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Offers</h5>
                                        </div>
                                   </div>
                              </Link>
                              }
                              {pckageread && 
                              <Link to='/package'>
                                   <div class="p-2 bg-[#cbaacb] rounded-md shadow-xl w-70 items-center justify-center ">
                                        <div class="flex flex-col items-center pb-10">
                                             <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                                             <path d="M22 8a.76.76 0 0 0 0-.21v-.08a.77.77 0 0 0-.07-.16.35.35 0 0 0-.05-.08l-.1-.13-.08-.06-.12-.09-9-5a1 1 0 0 0-1 0l-9 5-.09.07-.11.08a.41.41 0 0 0-.07.11.39.39 0 0 0-.08.1.59.59 0 0 0-.06.14.3.3 0 0 0 0 .1A.76.76 0 0 0 2 8v8a1 1 0 0 0 .52.87l9 5a.75.75 0 0 0 .13.06h.1a1.06 1.06 0 0 0 .5 0h.1l.14-.06 9-5A1 1 0 0 0 22 16V8zm-10 3.87L5.06 8l2.76-1.52 6.83 3.9zm0-7.72L18.94 8 16.7 9.25 9.87 5.34zM4 9.7l7 3.92v5.68l-7-3.89zm9 9.6v-5.68l3-1.68V15l2-1v-3.18l2-1.11v5.7z"></path>
                                             </svg>
                                             <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Package</h5>
                                        </div>
                                   </div>
                              </Link>
                              }
                              {inventoryread && 
                              <Link to='/inventory'>
                                   <div class="p-2 bg-[#c6dbda] rounded-md shadow-xl w-70 items-center justify-center ">
                                        <div class="flex flex-col items-center pb-10">
                                             <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                                             <path d="M19 4h-3V2h-2v2h-4V2H8v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zM5 20V7h14V6l.002 14H5z"></path><path d="M7 9h10v2H7zm0 4h5v2H7z"></path>
                                             </svg>
                                             <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Inventory</h5>
                                        </div>
                                   </div>
                              </Link>
                              }
                              
                              {staffread && 
                              <Link to='/staff'>
                                   <div class="p-2 bg-[#cce2cb] rounded-md shadow-xl w-70 items-center justify-center ">
                                        <div class="flex flex-col items-center pb-10">
                                             <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                                             <path d="M20 2H8a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm-6 2.5a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zM19 15H9v-.25C9 12.901 11.254 11 14 11s5 1.901 5 3.75V15z"></path><path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8z"></path>
                                             </svg>
                                             <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Staff</h5>
                                        </div>
                                   </div>
                              </Link>
                              }
                              {settingsread &&
                              <Link to='/Settings'>
                                   <div class="p-2 bg-[#abdee6] rounded-md shadow-xl w-70 items-center justify-center ">
                                        <div class="flex flex-col items-center pb-10">
                                             <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                                             <path d="M12 16c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.084 0 2 .916 2 2s-.916 2-2 2-2-.916-2-2 .916-2 2-2z"></path><path d="m2.845 16.136 1 1.73c.531.917 1.809 1.261 2.73.73l.529-.306A8.1 8.1 0 0 0 9 19.402V20c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-.598a8.132 8.132 0 0 0 1.896-1.111l.529.306c.923.53 2.198.188 2.731-.731l.999-1.729a2.001 2.001 0 0 0-.731-2.732l-.505-.292a7.718 7.718 0 0 0 0-2.224l.505-.292a2.002 2.002 0 0 0 .731-2.732l-.999-1.729c-.531-.92-1.808-1.265-2.731-.732l-.529.306A8.1 8.1 0 0 0 15 4.598V4c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v.598a8.132 8.132 0 0 0-1.896 1.111l-.529-.306c-.924-.531-2.2-.187-2.731.732l-.999 1.729a2.001 2.001 0 0 0 .731 2.732l.505.292a7.683 7.683 0 0 0 0 2.223l-.505.292a2.003 2.003 0 0 0-.731 2.733zm3.326-2.758A5.703 5.703 0 0 1 6 12c0-.462.058-.926.17-1.378a.999.999 0 0 0-.47-1.108l-1.123-.65.998-1.729 1.145.662a.997.997 0 0 0 1.188-.142 6.071 6.071 0 0 1 2.384-1.399A1 1 0 0 0 11 5.3V4h2v1.3a1 1 0 0 0 .708.956 6.083 6.083 0 0 1 2.384 1.399.999.999 0 0 0 1.188.142l1.144-.661 1 1.729-1.124.649a1 1 0 0 0-.47 1.108c.112.452.17.916.17 1.378 0 .461-.058.925-.171 1.378a1 1 0 0 0 .471 1.108l1.123.649-.998 1.729-1.145-.661a.996.996 0 0 0-1.188.142 6.071 6.071 0 0 1-2.384 1.399A1 1 0 0 0 13 18.7l.002 1.3H11v-1.3a1 1 0 0 0-.708-.956 6.083 6.083 0 0 1-2.384-1.399.992.992 0 0 0-1.188-.141l-1.144.662-1-1.729 1.124-.651a1 1 0 0 0 .471-1.108z"></path>
                                             </svg>
                                             <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Settings</h5>
                                        </div>
                                   </div>
                              </Link>
                              }
                              {reportsread && 
                              <Link to='/reports'>
                                   <div class="p-2 bg-[#f6eac2] rounded-md shadow-xl w-70 items-center justify-center ">
                                        <div class="flex flex-col items-center pb-10">
                                             <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                                             <path d="M3 3v17a1 1 0 0 0 1 1h17v-2H5V3H3z"></path><path d="M15.293 14.707a.999.999 0 0 0 1.414 0l5-5-1.414-1.414L16 12.586l-2.293-2.293a.999.999 0 0 0-1.414 0l-5 5 1.414 1.414L13 12.414l2.293 2.293z"></path>
                                             </svg>
                                             <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Reports</h5>
                                        </div>
                                   </div>
                              </Link>
                              }
                              {rechargeread && 
                              <Link to='/recharge'>
                                   <div class="p-2 bg-[#8fcaca] rounded-md shadow-xl w-70 items-center justify-center ">
                                        <div class="flex flex-col items-center pb-10">
                                             <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                                             <path d="M20 3H5C3.346 3 2 4.346 2 6v12c0 1.654 1.346 3 3 3h15c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM5 19c-.552 0-1-.449-1-1V6c0-.551.448-1 1-1h15v3h-6c-1.103 0-2 .897-2 2v4c0 1.103.897 2 2 2h6.001v3H5zm15-9v4h-6v-4h6z"></path>
                                             </svg>
                                             <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Recharge</h5>
                                        </div>
                                   </div>
                              </Link>
                              }
                              <Link to='/help'>
                                   <div class="p-2 bg-[#ffaea5] rounded-md shadow-xl w-70 items-center justify-center ">
                                        <div class="flex flex-col items-center pb-10">
                                             <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                                             <path d="M12 6a3.939 3.939 0 0 0-3.934 3.934h2C10.066 8.867 10.934 8 12 8s1.934.867 1.934 1.934c0 .598-.481 1.032-1.216 1.626a9.208 9.208 0 0 0-.691.599c-.998.997-1.027 2.056-1.027 2.174V15h2l-.001-.633c.001-.016.033-.386.441-.793.15-.15.339-.3.535-.458.779-.631 1.958-1.584 1.958-3.182A3.937 3.937 0 0 0 12 6zm-1 10h2v2h-2z"></path><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path>
                                             </svg>
                                             <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Help</h5>
                                        </div>
                                   </div>
                              </Link>
                    </div>:
                    <div class="bg-white dark:bg-slate-800 text-center">
                         <div role="status">
                              <svg class="inline mr-2 w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-purple-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                   <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                   <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                              </svg>
                              <span class="sr-only">Loading...</span>
                         </div>
                    </div>
                    }
               </>
               :
               <section class="bg-white dark:bg-slate-800">
               <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16">
                    <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">Sorry, Access to application is temporarily not allowed</h1>
                    <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400">But don’t worry, you can still access account along with your data.</p>
                    <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400">All you have to do is choose one of our plans in the recharge section, and you’ll get instant access to your account again.</p>
                    <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400">Incase you do not have access to recharge, contact your administrator</p>
                    <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400">If recharged by you or your administartor, Click access button to regain access</p>
                    
                    <div class="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
                    <button onClick={()=>navigate("/recharge")} type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                         Recharge
                         <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                         <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                         </svg>
                    </button>
                    <button onClick={()=>setLoad(true)} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                    Access
                    <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                    </button>
                    </div>
               </div>
               </section>
          }
     </>
     
  )
}
