/* eslint-disable react-hooks/exhaustive-deps */
import { child, get, getDatabase, ref as dref } from "firebase/database";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import { useUserStore } from "../../contexts/UserStoreContext";
import firebaseapp from '../../firebase';
import ls from 'localstorage-slim';

export default function AuditReport({ route, navigation }) {
  
  const { storeID, setStoreID, countryCode, setCountryCode } = useUserStore();
  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);
  const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);
  if(!storeID){
    setStoreID(userdata.storeID);
  }

  if(!countryCode){
    setCountryCode(userdata.country);
  }
  const[loader, setLoader] = useState(false);
  const [dataExist, setDataExist] = useState(false);
  const [itempressedInit, setItempressedInit] = useState(false);
  
  const [audits, setAudits] = useState('');
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedAuditItems, setSelectedAuditItems] = useState('');
  const [auditLoader, setAuditLoader] = useState(false);

  useEffect(() => {
      function loadItems() {
        get(child(dbRef, `${storeID}/inventory/${storeID}/audit`)).then(async (snapshot) => {
          if(snapshot.exists()){
            const allaudits = [];
            snapshot.forEach(element => {
              const key = element.key;
              const auditdate = moment(element.child('auditdate').val()).format('DD/MMM/YYYY');
              const auditstatus = element.child('auditstatus').val();
              const items = element.child('items').val();
              allaudits.push({key:key, auditdate:auditdate, auditstatus:auditstatus, items:items});
            });
            const alldata = await Promise.all(allaudits);
            const dataExist = true;
            return [dataExist, alldata];
            
          }
          else{
            const dataExist = false;
            return [dataExist, 0];
            
          }
        }).then((dataExist)=>{
          if(dataExist[0]){
            setAudits(dataExist[1]);
            setDataExist(true);
            setLoader(true);
          }else{
            setDataExist(false);
            setLoader(true);
          }
        }).catch((error)=>{
          console.log(error);
        });
      }
    loadItems();
  }, [])

  function itemPressed(itm){
    setSelectedItem(itm);
    setItempressedInit(true);
  }

  

  useEffect(() => {
    if(itempressedInit){
      setItempressedInit(false);
      function loadItems() {
        const audititems = selectedItem.items;
        const items = [];
        for (const key in audititems) {
          if (Object.hasOwnProperty.call(audititems, key)) {
            const element = audititems[key];
            const itemname = element.itemname;
            const brand = element.brand;
            const stock = element.stock;
            const physicalquantity = element.physicalquantity;
            const remark = element.remark;
            const difference = element.difference;
            items.push({key:key, itemname:itemname, brand:brand, stock:stock, physicalquantity:physicalquantity, remark:remark, difference:difference });
          }
        }
        setSelectedAuditItems(items);
        setDataExist(false);
        setLoader(false);
        setAuditLoader(true);
      }
      loadItems();
    }
  }, [itempressedInit])

  function backtoAudit(){
    setAuditLoader(false);
    setDataExist(true);
    setLoader(true);
  }
  

  return (
    <>
    <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <Link to='/reports' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              Reports
            </Link>
            </div>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Audit Report</span>
            </div>
          </li>
        </ol>
      </nav>
      {loader &&
        <section class="bg-white dark:bg-gray-900">
          {dataExist?
            <>
            <div class="flex w-full items-center justify-between bg-white dark:text-white dark:bg-gray-800">
              <div class="p-5 text-lg font-semibold text-center text-gray-900 bg-white dark:text-white dark:bg-gray-800">
              List of Audits
              </div>      
            </div>
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                          <th scope="col" class="px-6 py-3">
                              Audit Date
                          </th>
                          <th scope="col" class="px-6 py-3">
                              Audit Status
                          </th>
                          <th scope="col" class="px-6 py-3">
                              Action
                          </th>
                      </tr>
                </thead>
                <tbody>
                {audits.map((item, key)=>(
                    <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td class="px-6 py-4">
                      {item.auditdate}
                      </td>
                      <td class="px-6 py-4">
                      {item.auditstatus? <p>Completed</p>:<p>Incomplete</p>}
                      </td>
                      <td class="px-6 py-4">
                        <button onClick={()=>itemPressed(item)} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800">
                          View
                          <svg aria-hidden="true" class="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                      </td>
                    </tr>
                ))}    
                </tbody>
              </table>
            </div>
            </>
            :
            <div id="alert" class="p-4 mb-4 text-yellow-700 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800" role="alert">
              <div class="flex items-center">
                <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                <h3 class="text-lg font-medium">You have not yet audited your stock.</h3>
              </div>
              <div class="mt-2 mb-4 text-sm">
                Auditing helps in improving productivity and managing assets
              </div>
            </div>
          }
        </section>
      }
      {auditLoader &&
        <>
          <div class="flex items-center justify-between">
            <div class="p-5 text-lg font-semibold text-center text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            Audit Date: {selectedItem.auditdate}
            </div>
            <button onClick={()=>backtoAudit()} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
            </div> 
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" class="px-6 py-3">
                          Product
                        </th>
                        <th scope="col" class="px-6 py-3">
                          System Qty
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Physical Qty
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Difference
                        </th>
                      </tr>
                </thead>
                <tbody>
                {selectedAuditItems.map((item, key)=>(
                    <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td class="px-6 py-4">
                      {item.itemname}
                      </td>
                      <td class="px-6 py-4">
                      {item.stock}
                      </td>
                      <td class="px-6 py-4">
                      {item.physicalquantity}
                      </td>
                      <td class="px-6 py-4">
                      <p>{item.difference}</p>
                      {item.remark? <p>{item.remark}</p>:<p>No Remarks</p>}
                      </td>
                    </tr>
                ))}    
                </tbody>
              </table>
            </div>
            </>
      }
    </>
  );
}
 
