/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import { useUserStore } from "../../contexts/UserStoreContext";
import firebaseapp from '../../firebase';
import { getDatabase, ref as dref, child, get, update, set } from "firebase/database";
import { Link} from 'react-router-dom'

export default function InventorySettings() {
  
  const { storeID, setStoreID, countryCode, setCountryCode, inventorycreate, inventoryupdate, inventorydelete } = useUserStore();
  

const dbr = getDatabase(firebaseapp);
const dbRef = dref(dbr);
const items = ([
  { name: 'Brands', lname:'brands', key: 1, icon:'tag',  code: 'color-success-transparent-200', textcolor: 'text-basic-color'},
  { name: 'Stock Location', lname:'location', key: 2, icon:'map-marker-alt',  code: 'color-success-transparent-200', textcolor: 'text-basic-color'},
  { name: 'Category', icon:'list',  lname:'category', key: 3, code: 'color-success-transparent-200', textcolor: 'text-basic-color'},
  { name: 'Size', icon:'tape',  lname:'size', key: 5, code: 'color-success-transparent-200', textcolor: 'text-basic-color'},
  { name: 'Unit', lname:'unit', key: 6, icon:'boxes',  code: 'color-success-transparent-200', textcolor: 'text-basic-color'},
]);

const brands = "Surf, Tide, Henko"
const category = 'Detergent'
const location = '';
const size = 'grams,milligrams,nos,box,pack,MilliLiter,Liter'
const unit = 'pack,box,unit,nos,Liter'
const [loader, setLoader] = useState(false);
  const [selectLoader, setSelectLoader] = useState(false);
  const [selectedParam, setSelectedParam] = useState('');
  const [selectedParamHeader, setSelectedParamHeader] = useState('');
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedFilterValue, setSelectedFilterValue] = useState([]);
  const [query, setQuery] = useState('');
  const [newLoader, setNewLoader] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [editParameterValue, setEditParameterValue] = useState('');
  const [deleteParameterValue, setDeleteParameterValue] = useState('');
  

  useEffect(() => { 
    function loadData(){
      get(child(dbRef, `${storeID}/inventory/${storeID}/`)).then(async (snapshot) => {
        //step 1 data check
        if (snapshot.exists()) {
          setLoader(true);
        }
        else{
          set(dref(dbr, storeID+'/inventory/'+storeID+'/settings/'), {
            brands: brands || null,
            location:location || null,
            category: category || null,
            size:size || null,
            unit:unit || null, 
          }).then(()=>{
            setLoader(true);
          })
        }
      }).catch((error)=>{
          console.log(error);
      });
    }
    loadData();
  }, [])

  function handleSearch(e){
    const filteredData = selectedValue.filter(
      user => {
        return (
          user.toLowerCase().includes(e.toLowerCase()) 
        );
      }
    );
    setSelectedFilterValue(filteredData);
    setQuery(e);
  };

  function openItemParameter(itemname){
    console.log("itemname", itemname);
    setSelectedParam(itemname);
    get(child(dbRef, `${storeID}/inventory/${storeID}/settings`)).then((snapshot) => {
      
      const str = snapshot.child(itemname).val();
      if(itemname === 'brands'){
        let selectedArr;
        if(str){
          selectedArr = str.split(",");
        }else{
          selectedArr = [];
        }
        setSelectedValue(selectedArr);
        setSelectedFilterValue(selectedArr);
        setSelectedParamHeader('Brands');
        setLoader(false);
        setSelectLoader(true);
      }
      if(itemname === 'category'){
        let selectedArr;
        if(str){
          selectedArr = str.split(",");
        }else{
          selectedArr = [];
        }
        setSelectedValue(selectedArr);
        setSelectedFilterValue(selectedArr);
        setSelectedParamHeader('Category');
        setLoader(false);
        setSelectLoader(true);
      }
      else if(itemname === 'location'){
        let selectedArr;
        if(str){
          selectedArr = str.split(",");
        }else{
          selectedArr = [];
        }
        setSelectedValue(selectedArr);
        setSelectedFilterValue(selectedArr);
        setSelectedParamHeader('Stock Location');
        setLoader(false);
        setSelectLoader(true);
      }
      else if(itemname === 'size'){
        let selectedArr;
        if(str){
          selectedArr = str.split(",");
        }else{
          selectedArr = [];
        }
        setSelectedValue(selectedArr);
        setSelectedFilterValue(selectedArr);
        setSelectedParamHeader('Size');
        setLoader(false);
        setSelectLoader(true);
      }
      else if(itemname === 'unit'){
        let selectedArr;
        if(str){
          selectedArr = str.split(",");
        }else{
          selectedArr = [];
        }
        setSelectedValue(selectedArr);
        setSelectedFilterValue(selectedArr);
        setSelectedParamHeader('Unit');
        setLoader(false);
        setSelectLoader(true);
      }
    }).catch((error)=>{
      console.log(error);
    })
  }

  function addnewitem(){
    let svalue = selectedValue;
    let queryLowercase = query.toLowerCase();
    const selectedValuelowercase = selectedValue.map(name => name.toLowerCase());
    let itemPresent = selectedValuelowercase.includes(queryLowercase);
    if(query.length){
      if(itemPresent){
        alert('Value already exist');
      }
      else{
        setNewLoader(false);
        svalue.push(query);
        setSelectedFilterValue(svalue);
        setSelectedValue(svalue);
        let newvalue = svalue.toString();
        if(newvalue.length){
          update(dref(dbr, storeID+'/inventory/'+storeID+'/settings/'), {
            [selectedParam]: newvalue
          }).then(()=>{
            setQuery('');
            setNewLoader(true);
          })
        }
        else{
          alert(
            'Value should not be empty'
          )
        }
      }
    }
    else{
      alert(
        'Value should not be empty'
      )
    }
  }

  function cancelnewitem(){
    setNewLoader(false);
    setSelectLoader(true);
  }

  

  function backtoLoader(){
    setSelectLoader(false);
    setLoader(true);
  }


 

  function cancelEdit(){
    setEditLoader(false);
    setSelectLoader(true);
  }

  function deleteitem(){
    const stringselectedValue = selectedValue.toString();
    const replacestring = deleteParameterValue+',';
    var updateValue = stringselectedValue.replace(replacestring,'');
    if(updateValue.length){
      update(dref(dbr, storeID+'/inventory/'+storeID+'/settings'), {
        [selectedParam]: updateValue
      }).then(()=>{
        setEditLoader(false);
        setSelectLoader(false);
        setNewLoader(false);
        setLoader(true);
      })

    }
    else{
      alert('value should not be empty');
    }
  }

  function edititem(){
    const stringselectedValue = selectedValue.toString();
    var updateValue = stringselectedValue.replace(deleteParameterValue, editParameterValue);
    if(updateValue.length){
      set(dref(dbr, storeID+'/inventory/'+storeID+'/settings/'), {
        [selectedParam]: updateValue
      }).then(()=>{
        setEditLoader(false);
        setSelectLoader(false);
        setNewLoader(false);
        setLoader(true);
      })
    }
    else{
      alert('value should not be empty');
    }
  }

  function onFloatinActionClick(){
    console.log("float actin click");
    setSelectLoader(false);
    setNewLoader(true);
  }

  function editParameter(item){
    setEditParameterValue(item)
    setDeleteParameterValue(item)
    setSelectLoader(false);
    setEditLoader(true);
  }

  return (
    <>
    <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <Link to='/inventory' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              Inventory
            </Link>
            </div>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Inventory Settings</span>
            </div>
          </li>
        </ol>
      </nav>
    {loader && 
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <caption class="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            Inventory Settings
            
        </caption>
          <tbody>
          {items.map((item, index)=>(
            <tr key={index} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td class="px-6 py-6">
                    {item.name}
                </td>
                <td>
                  <button onClick={() => openItemParameter(item.lname)} type="button" class="px-6 py-6 mr-3 mr-2 text-purple-700 border border-purple-700 hover:bg-purple-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-purple-500 dark:text-purple-500 dark:hover:text-white dark:focus:ring-purple-800">
                  <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                  <span class="sr-only">Icon description</span>
                  </button>
                </td>
             
                
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    }
    {selectLoader &&
      <div class="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
        <div class="flex justify-end px-4 pt-4">
        <button onClick={()=>backtoLoader()} type="button" class="text-red-700 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:focus:ring-red-800">
        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path></svg>
        </button>
        </div>
        <div class="flex space-x-3 ">
          <h5 class="mb-5 text-xl font-medium text-gray-900 dark:text-white">Edit {selectedParamHeader}</h5>
          <button onClick={()=>onFloatinActionClick()} type="button" class="mb-5 text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800">
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path>
          </svg>
            <span class="sr-only">Icon description</span>
          </button>
            
        </div>
        
        <div class="relative">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
        </div>
        <input onChange={e => handleSearch(e.target.value)} type="search" id="search" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" />
        </div>
        
        <table class="mt-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <tbody>
          {selectedFilterValue.map((item, index)=>(
            <tr key={index} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td class="px-6 py-6">
                  {item}
                </td>
              <td>
              <button onClick={()=>editParameter(item)} type="button" class="mr-3 mr-2 text-purple-700 border border-purple-700 hover:bg-purple-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-purple-500 dark:text-purple-500 dark:hover:text-white dark:focus:ring-purple-800">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                <span class="sr-only">Icon description</span>
              </button>
              </td>
            </tr>
          ))}
          </tbody>
          
        </table>
        <div class="fixed right-6 bottom-6 group">
            <div id="speed-dial-menu-default" class="flex flex-col items-center hidden mb-4 space-y-2">
                <button onClick={()=>onFloatinActionClick()} type="button" data-tooltip-target="tooltip-share" data-tooltip-placement="left" class="flex justify-center items-center w-[52px] h-[52px] text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 dark:border-gray-600 shadow-sm dark:hover:text-white dark:text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none dark:focus:ring-gray-400">
                    <svg class="w-6 h-6 -ml-px " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z"></path></svg>
                </button>
            </div> 
          </div>
      </div>
    } 
    {newLoader && 
      
      <div class="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
        <div class="flex justify-end px-4 pt-4">
        <button onClick={()=>cancelnewitem()} type="button" class="text-red-700 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:focus:ring-red-800">
        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path></svg>
        </button>
        </div>
        <div class="flex space-x-3 ">
          <h5 class="mb-5 text-xl font-medium text-gray-900 dark:text-white">Add New {selectedParamHeader}</h5>
        </div>
        
        <div class="relative w-full">
            <input onChange={e => handleSearch(e.target.value)} type="search" id="search-dropdown" class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border-l-gray-100 border-l-2 border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-purple-500" placeholder="Search" />
            {inventorycreate && 
            <button onClick={()=>addnewitem()} type="button" class="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-purple-700 rounded-r-lg border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"><svg aria-hidden="true" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path>
            </svg>
            </button>
            }
        </div>
        
        <dl class="mt-5 max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
          {selectedFilterValue.map((item, index)=>(
            <div key={index} class="flex flex-col pb-3">
              <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">{item}</dt>
          </div>
          ))}
          </dl>
      </div>
    }
    {editLoader && 
      <div class="w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
        <h5 class="mb-5 text-xl font-medium text-gray-900 dark:text-white">Edit/Delete {selectedParamHeader}</h5>
        <div class="relative">
        
        <input onChange={e => setEditParameterValue(e.target.value)} value={editParameterValue} type="search" id="search" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" />
        </div>
        <div class="flex mt-4 space-x-3 md:mt-6">
            <button onClick={()=>cancelEdit()} type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
              <svg aria-hidden="true" class="w-5 h-5 mr-2 -ml-1" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
              </svg>
                Cancel
            </button>
            {inventoryupdate &&
            <button onClick={()=>edititem()} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800">
              Edit
              <svg aria-hidden="true" class="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z"></path></svg>
            </button>
            }
            {inventorydelete &&
            <button type="button" onClick={()=>deleteitem()}  class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
              Delete
              <svg aria-hidden="true" class="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6 7H5v13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7H6zm4 12H8v-9h2v9zm6 0h-2v-9h2v9zm.618-15L15 2H9L7.382 4H3v2h18V4z"></path></svg>
            </button>
            }
        </div>
      </div>
    }
    </>
  )  

}