/* eslint-disable react-hooks/exhaustive-deps */
import { child, get, getDatabase, push, ref as dref, set, update } from "firebase/database";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import { useUserStore } from "../../contexts/UserStoreContext";
import firebaseapp from '../../firebase';
import ls from 'localstorage-slim';

export default function Task({navigation, route}) {
  
  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);
  const [listDataExist, setListDataExist] = useState(false)
  const [taskListInit, setTaskListInit] = useState(true)
  const [listLoader, setListLoader] = useState(false)
  const [newTaskInit, setNewTaskInit] = useState(false)
  const [newTaskLoader, setNewTaskLoader] = useState(false)
  const [staffListData, setStaffListData] = useState('');
  const [listData, setListData] = useState('');
  const [staffListDataExist, setStaffListDataExist] = useState(false);
  const todaysDate = new Date();
  const [taskDate, setTaskDate] = useState(todaysDate);
  const [editTaskDate, setEditTaskDate] = useState(todaysDate);
  const [descriptionValue, setDescriptionValue] = useState('');
  const [titleValue, setTitleValue] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [editItem, setEditItem] = useState('');
  const [editLoader, setEditLoader] = useState(false);
  const { storeID, setStoreID, countryCode, setCountryCode, staffupdate, staffcreate, staffdelete } = useUserStore();
  const usinfo = ls.get('userinfo ', { decrypt: true });
  const userdata = JSON.parse(usinfo);
  const [deleteLoader, setDeleteLoader] = useState(false)
  if(!storeID){
      setStoreID(userdata.storeID);
    }
    
  if(!countryCode){
    setCountryCode(userdata.country);
  }

  
  useEffect(() => { 
    if(taskListInit){
      function listtaskData(){
        get(child(dbRef, `${storeID}/task/${storeID}/`)).then(async (snapshot) => {
          const listdata = [];
          if (snapshot.exists()) {
            snapshot.forEach(dataelement => {
              dataelement.forEach(element => {
                const key = element.key;
                const taskDate = element.child('date').val();
                const description = element.child('description').val(); 
                const staffkey = element.child('staffkey').val();
                const staffname = element.child('staffname').val(); 
                const status = element.child('status').val();
                const title = element.child('title').val();
                const phonenumber = element.child('phonenumber').val();
                const staffID = element.child('staffID').val();
                
                listdata.push({
                  key:key,
                  date:taskDate, 
                  description:description, 
                  staffkey:staffkey,
                  staffname:staffname,
                  status:status,
                  title:title,
                  phonenumber:phonenumber,
                  staffID:staffID
                })
                
              });
            });
            const completedListData = await Promise.all(listdata);
            const dataExist = true;
            return [dataExist, completedListData];
          }
          else{
            const dataExist = false;
            return [dataExist, 0];
          }
          
        }).then((dataExist)=>{
          if(dataExist[0]){
            setListData(dataExist[1]);
            setListDataExist(true);
            setListLoader(true);
          }else{
            setListDataExist(false)
            setListLoader(true);
          }
        }).catch((error)=>{
          console.log(error);
        });
     }
     listtaskData();
    }
  }, [taskListInit]);
  

  useEffect(() => { 
    if(newTaskInit){
      function listStaffData(){
        setNewTaskInit(false);
        setTitleValue("");
        setDescriptionValue('');
        get(child(dbRef, `${storeID}/staff/${storeID}/`)).then(async (snapshot) => {
          const listdata = [];
          if (snapshot.exists()) {
            snapshot.forEach(element => {
              const key = element.key;
              const address = element.child('address').val(); 
              const email = element.child('email').val(); 
              const phonenumber = element.child('phonenumber').val();
              const staffName = element.child('staffName').val(); 
              listdata.push({
                key:key,
                address:address, 
                email:email, 
                phonenumber:phonenumber,
                staffName:staffName, 
                })
            });
            const completedListData = await Promise.all(listdata);
            const dataExist = true;
            return [dataExist, completedListData];
            
          }
          else{
            const dataExist = false;
            return [dataExist, 0];
          }
          
        }).then((dataExist)=>{
          if(dataExist[0]){
            setStaffListData(dataExist[1]);
            setStaffListDataExist(true)
            setListLoader(false);
            setNewTaskLoader(true);
            
          }else{
            setListLoader(false);
            setStaffListDataExist(false);
            setNewTaskLoader(true);
            
          }
        }).catch((error)=>{
          console.log(error);
        });
     }
     listStaffData();
    }
  }, [newTaskInit]);

  function cancelNewTask(){
    setNewTaskInit(false);
    setNewTaskLoader(false);
    setListLoader(true);
  }

  function submitNewTaskWithAssignment(){
    
    if(selectedIndex === 0){
      submitNewTaskWithoutAssignment();
    }else{
      if(titleValue.length && descriptionValue.length){
        setNewTaskLoader(false);
        var si = selectedIndex-1;
        const stffname = staffListData[si].staffName;
        const stffkey = staffListData[si].key;
        const pushId = push(child(dref(dbr), 'task')).key;
        set(dref(dbr, storeID+'/task/' + storeID+"/"+stffkey+"/"+pushId), {
          title:titleValue,
          description:descriptionValue,
          date:moment(taskDate).toISOString(),
          status:'new',
          staffname: stffname,
          staffkey: stffkey,
          staffID:stffkey
        }).then(()=>{
          setTaskListInit(false);
          setNewTaskInit(false);
          setTaskListInit(true);
          // const url = "https://wa.me/91"+phonenumber+"/?text="+encodeURI(titleValue)+'%0A'+'Task Date:'+encodeURI(displayDate)+'%0A'+encodeURI(descriptionValue)
          // Linking.openURL(url);
        })

      }else{
        alert("Title and description should not be empty");
      }
    }
  }

  function submitNewTaskWithoutAssignment(){
    if(titleValue.length && descriptionValue.length){
      setNewTaskLoader(false);
      const pushId = push(child(dref(dbr), 'task')).key;
      set(dref(dbr, storeID+'/task/'+storeID+'/'+storeID+'/'+pushId), {
        title:titleValue,
        description:descriptionValue,
        date:moment(taskDate).toISOString(),
        status:'new',
        staffID:storeID
      }).then(()=>{
        setTaskListInit(false);
        setListDataExist(false)
        setListLoader(false);
        setNewTaskInit(false);
        setTaskListInit(true);
      })
    }else{
      alert("Title and description should not be empty");
    }
    
  }

  function editTask(itm) {
    setEditTaskDate(moment(itm.date).toDate())
    setEditItem(itm);
    setListLoader(false);
    setListDataExist(false);
    setTaskListInit(false);
    setEditLoader(true);
  }

  function cancelEditTask(){
    setEditItem('');
    setTaskListInit(false);
    setEditLoader(false);
    setListDataExist(true);
    setListLoader(true);
  }

  function submitEditTask(){
    
    const key = editItem.key
    const title = editItem.title;
    const description = editItem.description;
    const status = editItem.status;
    const dt = moment(editTaskDate).toISOString();
    const staffID = editItem.staffID

    if(title.length && description.length){
      update(dref(dbr, storeID+'/task/'+storeID+'/'+staffID+'/'+key), {
        title:title,
        description:description,
        status:status,
        date:dt
      }).then(()=>{
        setTaskListInit(false);
        setEditItem('');
        setEditLoader(false);
        setTaskListInit(true);
        // const url = "https://wa.me/91"+phonenumber+"/?text="+encodeURI(title)+'%0A'+'Task Date:'+encodeURI(displayDate)+'%0A'+encodeURI(description)
        // Linking.openURL(url);
      })

    }
    else{
      alert("Title and description should not be empty");

    }
  }

  function deleteEditTask(){
    const key = editItem.key
    const staffID = editItem.staffID
    set(dref(dbr, storeID+'/task/' + storeID+'/'+staffID+'/'+key), {
      title:null
    }).then(()=>{
      setTaskListInit(false);
      setEditItem('');
      setEditLoader(false);
      setDeleteLoader(true);
      // const url = "https://wa.me/91"+phonenumber+"/?text="+encodeURI(title)+'%0A'+'Task Date:'+encodeURI(displayDate)+'%0A'+encodeURI(description)+'%0A'+"Status: Task Deleted"
      // Linking.openURL(url);
    })

    

  }

  function newtaskCreator(){
    setListLoader(false);
    setNewTaskInit(true);
  }

  function continueafterDeletion() {
    setDeleteLoader(false);
    setNewTaskInit(true);
  }

  return (
    <div>
      <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <Link to='/staff' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              Staff
            </Link>
            </div>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Task</span>
            </div>
          </li>
        </ol>
      </nav>
      {listLoader &&
        <>
          {listDataExist? 
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
              <div class="flex items-center justify-between">
              <p class="mb-5 text-xl text-gray-900 dark:text-white">Task List</p>
              {staffcreate && <button onClick={()=>newtaskCreator()} type="button" class="text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">New Task</button>}
              </div>
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                          <th scope="col" class="py-3 px-6">
                              Date
                          </th>
                          <th scope="col" class="py-3 px-6">
                              Task Details
                          </th>
                          <th scope="col" class="py-3 px-6">
                              Status
                          </th>
                          <th scope="col" class="py-3 px-6">
                              Action
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                  {listData.map((item, key)=>(
                      <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                          <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            <p>{moment(item.date).format('MMM/DD/YYYY hh:mm a')}</p>
                          </th>
                          <td class="py-4 px-6">
                            <div class="text-base font-semibold">{item.title}</div>
                            <div class="font-normal text-gray-500">{item.description}</div>
                          </td>
                          <td class="py-4 px-6">
                            {item.staffname? <div class="text-base font-semibold">{item.staffname}</div>:<div class="text-base font-semibold">Self Assigned</div>}
                            <div class="font-normal text-gray-500">Status: {item.status}</div>
                          </td>
                          <td class="py-4 px-6">
                            <button onClick={()=>editTask(item)} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center mr-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-blue-800">
                              Edit
                            </button>
                          </td>
                      </tr>
                  ))}    
                  </tbody>
              </table>
            </div>
            :
            <div id="alert" class="p-4 mb-4 text-yellow-700 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800" role="alert">
                  <div class="flex items-center">
                    <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                    <h3 class="text-lg font-medium">You have not added tasks.</h3>
                  </div>
                  <div class="mt-2 mb-4 text-sm">
                  Tasks helps you in planning, tracking, and reporting. Do you wish to create a task?
                  </div>
                  <div class="flex">
                  {staffcreate && 
                    <button onClick={()=>setNewTaskInit(true)} type="button" class="mt-5 text-white bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800">
                      <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
                      Yes
                    </button>
                  }
                  </div>
            </div>
          }
        </>
      }
      {newTaskLoader &&
        <div class="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
          <h5 class="text-xl font-medium text-gray-900 dark:text-white">New Task</h5>
          <div class="mt-5">
            <label htmlFor="success" class="block mb-2 text-sm font-medium text-green-700 dark:text-green-500">Task Date & Time</label>
            <DatePicker selected={taskDate} onChange={(date) => setTaskDate(date)}  class="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500" timeIntervals={1} dateFormat="MMM d, yyyy h:mm aa" showTimeSelect/>
          </div>
          
          <div>
              <label htmlFor="text" class="mt-3 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Title</label>
              <input value={titleValue} onChange={e => setTitleValue(e.target.value)} type="text" name="title" id="title" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
          </div>
          <div>
              <label htmlFor="description" class=" mt-3 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
              <textarea value={descriptionValue} onChange={e => setDescriptionValue(e.target.value)} id="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Description"></textarea>
          </div>
          {staffListDataExist? 
            <>
              <div class="mt-5 mb-5" >
              <label htmlFor="text" class="mt-3 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Task Assigned</label>
              
                <div class="flex items-center mr-4">
                    <input checked={selectedIndex === 0} onChange={()=>setSelectedIndex(0)} id="inline-radio" type="radio" value="0" name="inline-radio-group" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label htmlFor="inline-radio" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Self</label>
                </div>
                {staffListData.map((staff, index)=>( 
                  <div key={index} class="flex items-center mr-4">
                    <input checked={selectedIndex === (index+1)} onChange={()=>setSelectedIndex(index+1)} id="inline-radio" type="radio" value="0" name="inline-radio-group" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label htmlFor="inline-radio" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{staff.staffName}</label>
                  </div>
                ))}
                
              </div>
              <div class="flex mt-4 space-x-3 md:mt-6">
                <button onClick={()=>cancelNewTask()} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
                <button onClick={()=>submitNewTaskWithAssignment()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>
              </div>
            </>
            :
            <div class="flex mt-4 space-x-3 md:mt-6">
              <button onClick={()=>cancelNewTask()} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
              <button onClick={()=>submitNewTaskWithoutAssignment()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>
            </div>
          } 
        </div>
      }
      {editLoader &&
        <div class="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
          <h5 class="text-xl font-medium text-gray-900 dark:text-white">Edit Task</h5>
          <div class="mt-5">
            <label htmlFor="success" class="block mb-2 text-sm font-medium text-green-700 dark:text-green-500">Task Date & Time</label>
            <DatePicker selected={editTaskDate} onChange={(date) => setEditTaskDate(date)} class="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500"  timeIntervals={1} dateFormat="MMM d, yyyy h:mm aa" showTimeSelect/>
          </div>
          <div>
            <label htmlFor="text" class="mt-3 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Title</label>
            <input value={editItem.title} onChange={e => setEditItem({...editItem, title:e.target.value})} type="text" name="title" id="title" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
          </div>
          <div>
            <label htmlFor="description" class=" mt-3 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
            <textarea value={editItem.description} onChange={e => setEditItem({...editItem, description:e.target.value})} id="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Description"></textarea>
          </div>
          <div>
            <label htmlFor="text" class="mt-3 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Status</label>
            <input value={editItem.status} onChange={e => setEditItem({...editItem, status:e.target.value})} type="text" name="title" id="title" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
          </div>
          <div class="flex mt-4 space-x-3 md:mt-6">
            <button onClick={()=>cancelEditTask()} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
            {staffdelete && <button onClick={()=>deleteEditTask()} type="button" class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Delete</button>}
            {staffupdate && <button onClick={()=>submitEditTask()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>}
          </div>
        </div>
      }
      {deleteLoader &&
        <div id="alert-additional-content-2" class="p-4 mb-4 text-purple-800 border border-purple-300 rounded-lg bg-purple-50 dark:bg-gray-800 dark:text-purple-400 dark:border-purple-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Task Deleted</h3>
          </div>
          <div class="flex">
            <button onClick={()=>continueafterDeletion()} type="button" class="text-white bg-purple-800 hover:bg-purple-900 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              OK
            </button>
          </div>
        </div>
      }
    </div>
  );
}
