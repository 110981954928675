/* eslint-disable react-hooks/exhaustive-deps */
import ReactEcharts from 'echarts-for-react';
import { child, get, getDatabase, ref as dref } from "firebase/database";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import { useUserStore } from "../../contexts/UserStoreContext";
import firebaseapp from '../../firebase';
import ls from 'localstorage-slim';

export default function AccountsReport({ route, navigation }) {
  
  const { storeID, setStoreID, countryCode, setCountryCode } = useUserStore();
  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);
  const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);
  if(!storeID){
    setStoreID(userdata.storeID);
  }

  if(!countryCode){
    setCountryCode(userdata.country);
  }
  const[loader, setLoader] = useState(false);
  const [dataExist, setDataExist] = useState(false);
  const [accountData, setAccountData] = useState('');
  const startOfMonth = moment().startOf('month');
  const endOfMonth   = moment().endOf('month');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [accountDataStat, setAccountDataStat] = useState({avgExpense:0, avgSales:0, totalexpense:0, totalsales:0, maxSales:0, minSales:0, maxExpense:0, minExpense:0});

  useEffect(() => {
      function loadItems() {
        get(child(dbRef, `${storeID}/accounts/${storeID}/`)).then(async (snapshot) => {
          if(snapshot.exists()){
            var totalsales = 0, totalexpense = 0;
            const daysales = [];
            const dayexpense = [];
            const totaldata = [];
            snapshot.forEach(yeardata => {
              const year = yeardata.key;
              yeardata.forEach(monthdata => {
                const month = monthdata.key;
                monthdata.forEach(daydata => {
                  const day = daydata.key;
                  const daytotalsales = daydata.child('incomeTotal').val() || 0;
                  const daytotalexpense = daydata.child('expenseTotal').val() || 0;
                  const date = daydata.child('date').val();
                  if(moment(date).isBetween(startOfMonth, endOfMonth)){
                    totalsales=totalsales+parseInt(daytotalsales);
                    totalexpense=totalexpense+parseInt(daytotalexpense);
                    daysales.push(parseInt(daytotalsales));
                    dayexpense.push(parseInt(daytotalexpense));
                  }
                  totaldata.push({date:date, year:year, month:month, day:day, sales:daytotalsales, expense:daytotalexpense })
                });
              });
            });
           
            const tdata = await Promise.all(totaldata);
            const maxSales =  Math.max.apply(null, daysales) || 0;
            const minSales =  Math.min.apply(null, daysales) || 0;
            const maxExpense =  Math.max.apply(null, dayexpense) || 0;
            const minExpense =  Math.min.apply(null, dayexpense) || 0;
            const sumSales = daysales.reduce((a, b) => a + b, 0) || 0;
            const sumExpense = dayexpense.reduce((a, b) => a + b, 0) || 0;
            const avgSales = (sumSales / daysales.length) || 0;
            const avgExpense = (sumExpense / dayexpense.length) || 0;
            console.log({avgExpense:avgExpense, avgSales:avgSales, totalexpense:sumExpense, totalsales:sumSales, maxSales:maxSales, minSales:minSales, maxExpense:maxExpense, minExpense:minExpense});
            setAccountDataStat({avgExpense:avgExpense, avgSales:avgSales, totalexpense:sumExpense, totalsales:sumSales, maxSales:maxSales, minSales:minSales, maxExpense:maxExpense, minExpense:minExpense})
            const dataExist = true;
            return [dataExist, tdata];
          }
          else{
            const dataExist = false;
            return [dataExist, 0];
            
          }
        }).then((dataExist)=>{
          if(dataExist[0]){
            setAccountData(dataExist[1]);
            setDataExist(true);
            setLoader(true);
          }else{
            setDataExist(false);
            setLoader(true);
          }

        }).catch((error)=>{
          console.log(error);
        });
      }
    loadItems();
  }, [])

  

  function changedDate(){
    
    var totalsales = 0, totalexpense = 0;
    const daysales = [];
    const dayexpense = [];
    accountData.forEach(element => {
      const daytotalsales = element.sales || 0;
      const daytotalexpense = element.expense || 0;
      const date = element.date;
      if(moment(date).isBetween(startDate, endDate)){
        totalsales=totalsales+parseInt(daytotalsales);
        totalexpense=totalexpense+parseInt(daytotalexpense);
        daysales.push(parseInt(daytotalsales));
        dayexpense.push(parseInt(daytotalexpense));
      }
    });
    const maxSales =  Math.max.apply(null, daysales) || 0;
    const minSales =  Math.min.apply(null, daysales) || 0;
    const maxExpense =  Math.max.apply(null, dayexpense) || 0;
    const minExpense =  Math.min.apply(null, dayexpense) || 0;
    const sumSales = daysales.reduce((a, b) => a + b, 0) || 0;
    const sumExpense = dayexpense.reduce((a, b) => a + b, 0) || 0;
    const avgSales = (sumSales / daysales.length) || 0;
    const avgExpense = (sumExpense / dayexpense.length) || 0;
    console.log(avgExpense,"avgExpense",avgSales, "avgSales",sumExpense, "sumExpense", sumSales, "sumSales", maxSales, "maxSales", minSales, "minSales", maxExpense, "maxExpense", );
    setAccountDataStat({avgExpense:avgExpense, avgSales:avgSales, totalexpense:sumExpense, totalsales:sumSales, maxSales:maxSales, minSales:minSales, maxExpense:maxExpense, minExpense:minExpense})
            
  }

  return (
    <>
    <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <Link to='/reports' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              Reports
            </Link>
            </div>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Account Report</span>
            </div>
          </li>
        </ol>
      </nav>
    {loader &&
    
      <section class="bg-white dark:bg-gray-900">
        <div class="w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Accounts Report</h5>
          <div class="flex mt-4 space-x-3 md:mt-6">
          <div>
          <label htmlFor="full_name" class="mt-5 block mb-2 text-sm font-medium text-gray-900 dark:text-white">From</label>
          <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" dateFormat="MMM/d/yyyy" onChange={(date)=>setStartDate(date)} selected={startDate} class="bg-purple-50 border border-purple-500 text-purple-900 dark:text-purple-400 placeholder-purple-700 dark:placeholder-purple-500 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-purple-500" /> 
          </div>
          <div>
          <label htmlFor="full_name" class="mt-5 block mb-2 text-sm font-medium text-gray-900 dark:text-white">To</label>
          <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" dateFormat="MMM/d/yyyy" onChange={(date)=>setEndDate(date)} selected={endDate} class="bg-purple-50 border border-purple-500 text-purple-900 dark:text-purple-400 placeholder-purple-700 dark:placeholder-purple-500 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-purple-500" /> 
          </div>  
          </div>
          <div class="mt-4"><button onClick={()=>changedDate()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Next</button>
          
          </div>
          {dataExist?
          <>
          <div class="flex items-center pb-10">
          <ReactEcharts 
            style={{width: '40%'}}
            option={{
            legend: {},
              tooltip: {},
              dataset: {
                source: [
                  ['product', 'Income', 'Expense'],
                  ['Total', accountDataStat.totalsales, accountDataStat.totalexpense],
                  
                ]
              },
              xAxis: { type: 'category' },
              yAxis: {},
              series: [{ type: 'bar', color: [
              '#50C878'] }, { type: 'bar',color: [
              '#811331'] }]
            }}/>
          <ReactEcharts 
            style={{width: '40%'}}
            option={{
            legend: {},
              tooltip: {},
              dataset: {
                source: [
                  ['product', 'Income', 'Expense'],
                  ['Average', accountDataStat.avgSales, accountDataStat.avgExpense],
                  
                ]
              },
              xAxis: { type: 'category' },
              yAxis: {},
              series: [{ type: 'bar', color: [
              '#50C878'] }, { type: 'bar',color: [
              '#811331'] }]
            }}/>
          </div>
          <div class="flex items-center pb-10">
            
            <ReactEcharts 
            style={{width: '40%'}}
            option={{
            legend: {},
              tooltip: {},
              dataset: {
                source: [
                  ['product', 'Income', 'Expense'],
                  ['Maximum', accountDataStat.maxSales, accountDataStat.maxExpense],
                  
                ]
              },
              xAxis: { type: 'category' },
              yAxis: {},
              series: [{ type: 'bar', color: [
              '#50C878'] }, { type: 'bar',color: [
              '#811331'] }]
            }}/>
           
            <ReactEcharts 
            style={{width: '40%'}}
            option={{
            legend: {},
              tooltip: {},
              dataset: {
                source: [
                  ['product', 'Income', 'Expense'],
                  ['Minimum', accountDataStat.minSales, accountDataStat.minExpense]
                ]
              },
              xAxis: { type: 'category' },
              yAxis: {},
              series: [{ type: 'bar', color: [
              '#50C878'] }, { type: 'bar',color: [
              '#811331'] }]
            }}/>
           
            </div>
          </>
          
          :
          <div id="alert" class="p-4 mb-4 text-yellow-700 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800" role="alert">
              <div class="flex items-center">
                <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                <h3 class="text-lg font-medium">You do not have expense and sales data for the selected period</h3>
              </div>
              <div class="mt-2 mb-4 text-sm">
              Accounts helps you in understandins your finnancials
              </div>
            </div>
          }
        </div>
      </section>

    }
      
    </>
  );
}
 
