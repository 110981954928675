//@ts-ignore
/* eslint-disable react-hooks/exhaustive-deps */ 
import { child, get, getDatabase, ref as dref, update } from "firebase/database";
import "font-awesome/css/font-awesome.min.css";
import moment from 'moment';
import React, {useEffect, useRef, useState } from 'react';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { Link, useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { useUserStore } from "../contexts/UserStoreContext";
import firebaseapp from '../firebase';
import ls from 'localstorage-slim';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { render, Printer, QRCode, Br, Line, Row, Cut } from 'react-thermal-printer';

export default function Invoice() {
  
    
  let componentRef = useRef();
  const navigate = useNavigate();
  const { storeID, setStoreID, countryCode, setCountryCode, invoiceread, invoiceupdate} = useUserStore();
  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);
  const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);
  if(!storeID){
    setStoreID(userdata.storeID);
  }

  if(!countryCode){
    setCountryCode(userdata.country);
  }
  const [initialLoader, setInitialLoader] = useState(true)
  const [listDataExist, setListDataExist] = useState(false)
  const [listLoader, setListLoader] = useState(false)
  const [listData, setListData] = useState('');
  const [editItem, setEditItem] = useState('');
  const [editLoader, setEditLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editDate, setEditDate] = useState(new Date());
  const [editNotes, setEditNotes] = useState('')
  const [editCustomerLoader, setEditCustomerLoader] = useState(false)          
  const [editItemValue, setEditItemValue] = useState({key: '', label: '', value:0, price:0, total:0, discount:false})
  const [editCustomerData, setEditCustomerData] = useState([])
  const [editKey, setEditKey] = useState('');
  const [editSelectedServices, setEditSelectedServices] = useState('')
  const [grandTotal, setGrandTotal] = useState(0);
  const [priceIndex, setPriceIndex] = useState('');
  const [priceModal, setPriceModal] = useState(false);
  const [priceValue, setPriceValue] = useState('');
  const [stores, setStores] = useState('');
  const [selectedStore, setSelectedStore] = useState(0)
  const [editInvoiceDate, setEditInvoiceDate] = useState(moment(new Date()).toISOString());
  const [readAccess,setReadAccess] = useState(true);
  const [editReferenceNumber, setEditReferenceNumber] = useState('')
  const [SKU, setSKU] = useState('');
  const [barcodeloader, setBarcodeloader] = useState(false);
  const [filterListData, setFilterListData] = useState('');
  const [query, setQuery] = useState('')

  useEffect(() => { 
    if(invoiceread){
    if(initialLoader){
      function listCollectionData(){
        setInitialLoader(false);
        get(child(dbRef, `${storeID}/billing/${storeID}/`)).then(async (datasnapshot) => {
          const listdata = [];
          const todaysdate = moment(new Date()).toISOString();
          if (datasnapshot.exists()) {
            datasnapshot.forEach(snapshot => {
              snapshot.forEach(element => {
                const key = element.key;
                const cdata = element.child('customerData').val(); 
                const paddress = element.child('pickupAddress').val(); 
                const pnotes = element.child('pickupNotes').val(); 
                const pstaff = element.child('pickupStaff').val(); 
                const ptime = element.child('pickupTime').val(); 
                const status = element.child('status').val(); 
                const referenceNumber = element.child('referenceNumber').val(); 
                const addressIndex = element.child('addressIndex').val();
                const itemValue = element.child('itemValue').val() || [];
                const options = element.child('options').val() || [];
                const pstaffID = element.child('pstaffID').val();
                const selected = element.child('selected').val() || [];
                const serviceOptions = element.child('serviceOptions').val() || [];
                const serviceSelected = element.child('serviceSelected').val() || [];
                const itemPriceValue = element.child('itemPriceValue').val() || [];
                const stores = element.child('stores').val() || [];
                const printStore = element.child('printStore').val() || [];
                const selectedStore = element.child('selectedStore').val() || 0;
                const invoiceDate = element.child('invoiceDate').val() || todaysdate;
                let total = 0;
                for (const key in itemPriceValue) {
                  if (itemPriceValue.hasOwnProperty.call(itemPriceValue, key)) {
                    const element = itemPriceValue[key];
                    const tot = element.total;
                    total = total+tot;
                  }
                }
                listdata.push({
                  key:key,
                  cdata: cdata, 
                  paddress:paddress, 
                  pnotes:pnotes, 
                  pstaff:pstaff, 
                  ptime:ptime, 
                  status:status,
                  addressIndex:addressIndex,
                  itemValue:itemValue,
                  referenceNumber:referenceNumber,
                  options: options,
                  pstaffID:pstaffID,
                  selected:selected,
                  serviceOptions:serviceOptions,
                  serviceSelected:serviceSelected,
                  itemPriceValue:itemPriceValue,
                  stores:stores,
                  printStore:printStore,
                  selectedStore:selectedStore,
                  total:total,
                  invoiceDate:invoiceDate
                  })
  
              });
            });
            const completedListData = await Promise.all(listdata);
            const dataExist =  true;
            return [dataExist, completedListData];;
          }
          else{
            const dataExist =  false;
            return [dataExist, 0];
          }
          
        }).then((dataExist)=>{
          if(dataExist[0]){
            setListData(dataExist[1]);
            setFilterListData(dataExist[1]);
            setListDataExist(true)
            setListLoader(true);
          }else{
            setListDataExist(false)
            setListLoader(true);
          }
        }).catch((error)=>{
          console.log(error);
        });
     }
     listCollectionData();
    }
    }else{
      setReadAccess(false);
    }
  }, [initialLoader]);

  const addcHandler = ()=>{
    const _phonenumber = [...editItemValue];
    let keyval =_phonenumber.length+1;
    _phonenumber.push({key: keyval, label: '', value:0, price:0, total:0, discount:false});
    
    setEditItemValue(_phonenumber);
  }

  const addDiscountHandler = ()=>{
    const _phonenumber = [...editItemValue];
    let keyval =_phonenumber.length+1;
    _phonenumber.push({key: keyval, label: '', value:0, price:0, total:0, discount:true});
    
    setEditItemValue(_phonenumber);
  }
  
  const deletecHandler = (ckey)=>{
    const _phonenumber = editItemValue.filter((cinput,index) => index !== ckey);
    let total = 0;
    for (const key in _phonenumber) {
      if (Object.hasOwnProperty.call(_phonenumber, key)) {
        const element = _phonenumber[key];
        total = element.total+total
      }
    }
    setGrandTotal(total);
    setEditItemValue(_phonenumber);
  }

  const inputcHandler = (index, event)=>{
    const _phonenumber = [...editItemValue];
    const { name } = event.target;
    _phonenumber[index][name] = event.target.value;
    if(_phonenumber[index]['discount']){
      _phonenumber[index]['total'] = -(_phonenumber[index]['value']*_phonenumber[index]['price']);
    }else{
      _phonenumber[index]['total'] = _phonenumber[index]['value']*_phonenumber[index]['price']
    }
    let total = 0;
    for (const key in _phonenumber) {
      if (Object.hasOwnProperty.call(_phonenumber, key)) {
        const element = _phonenumber[key];
        total = element.total+total
      }
    }
    setGrandTotal(total);
    
    setEditItemValue(_phonenumber);
    
  }

  function priceViewer(index){
    
    if(priceValue){
      setEditLoader(false);
      setPriceIndex(index)
      setPriceModal(true);
    }else{
      get(child(dbRef, `${storeID}/pricing/${storeID}`)).then((snapshot) => {
        
        if(snapshot.exists){
          const arrayValue = [];
          snapshot.forEach(element => {
            const priceDescription = element.child("priceDescription").val();
            const priceHead = element.child('priceHead').val();
            const unitPrice = element.child('unitPrice').val();
            arrayValue.push({priceDescription:priceDescription, priceHead:priceHead, unitPrice:unitPrice})
          });
          setPriceValue(arrayValue);
          setEditLoader(false);
          setPriceIndex(index);
          setPriceModal(true);
        }
        else{
          setEditLoader(false);
          setPriceIndex(index);
          setPriceValue(false);
          setPriceModal(true);
        }
      });
    }
    
    
  }

  function cancelPrice(){
    setPriceModal(false);
    setPriceIndex('');
    setEditLoader(true);
  }

  function selPrice(price){
    const _phonenumber = [...editItemValue];
    
    _phonenumber[priceIndex]['price'] = price;
    if(_phonenumber[priceIndex]['discount']){
      _phonenumber[priceIndex]['total'] = -(_phonenumber[priceIndex]['value']*_phonenumber[priceIndex]['price']);
    }else{
      _phonenumber[priceIndex]['total'] = _phonenumber[priceIndex]['value']*_phonenumber[priceIndex]['price']
    }
    let total = 0;
    for (const key in _phonenumber) {
      if (Object.hasOwnProperty.call(_phonenumber, key)) {
        const element = _phonenumber[key];
        total = element.total+total
      }
    }
    setGrandTotal(total);
    setEditItemValue(_phonenumber);
    setPriceModal(false);
    setPriceIndex('');
    setEditLoader(true);
  }

  function cancelEditLoader(){
    setEditLoader(false);
    setListLoader(true);
  }

  function editCollection(item){
    
    setEditKey(item.key)
    setEditDate(moment(item.ptime).toDate());
    setEditInvoiceDate(item.invoiceDate);
    setEditNotes(item.pnotes);
    setEditItem(item);
    setEditReferenceNumber(item.referenceNumber);
    setEditSelectedServices(item.serviceSelected);
    if(item.itemPriceValue.length){
      setEditItemValue(item.itemPriceValue)
      let gtotal = 0;
      for (const key in item.itemPriceValue) {
        if (Object.hasOwnProperty.call(item.itemPriceValue, key)) {
          const element = item.itemPriceValue[key];
          const total = element.total;
          gtotal = total+gtotal;
        }
      }
      setGrandTotal(gtotal);

    }else{
      if(item.itemValue){
        setGrandTotal(0);
        const arrayValue = [];
        for (const key in item.itemValue) {
          if (Object.hasOwnProperty.call(item.itemValue, key)) {
            const element = item.itemValue[key];
            arrayValue.push({key:key, label:element.label, value:element.value, price:0, total:0, discount:false})
          }
        }
        setEditItemValue(arrayValue);


      }else{
        setGrandTotal(0);
        setEditItemValue({key: 0, label: '', value:0, price:0, total:0, discount:false});
      }
    }
    
    setEditCustomerData(item.cdata);
    setListLoader(false);
              
    if(item.printStore.length){
      setStores(item.printStore);
      setSelectedStore(item.selectedStore)
      setEditCustomerLoader(true);
      setEditLoader(true);
    }else{

      get(child(dbRef, `${storeID}/storeprofile/${storeID}`)).then((snapshot) => {
        if(snapshot.exists){
        const prof = [];
        snapshot.forEach(element => {
          const storeKey = element.key;
          const StoreName = element.child('StoreName').val() || 'Laundri';
          const Address = element.child('Address').val();
          const Billheader = element.child('Billheader').val();
          const Phonenumber = element.child('Phonenumber').val();
          prof.push({storeKey:storeKey, Billheader:Billheader, StoreName:StoreName, Address:Address, Phonenumber:Phonenumber})
        });
        setStores(prof);
        }else{
         setStores(''); 
        }
      }).then(()=>{
        setEditCustomerLoader(true);
        setEditLoader(true);
      });

    }
    

    
  }

  function saveBill(){
    const printStore = stores[selectedStore];
    const customerkey = editItem.cdata.customerID;
    update(dref(dbr, storeID+'/billing/'+storeID+'/'+customerkey+'/'+editKey), {
      status: "saved",
      itemPriceValue:editItemValue,
      stores:stores || '',
      printStore:printStore || '',
    }).then(()=>{
      setEditLoader(false);
      setShowModal(true);
    })
  }

  function finishBill(){
    
    const year = moment(editInvoiceDate).format('YYYY');
    const month = moment(editInvoiceDate).format('MMM');
    const dte = moment(editInvoiceDate).format('DD');
    const pdate = moment(editDate).format("DD/MMM/YYYY");
    const customerfname = editCustomerData.fullName;
    const billname = pdate+" "+customerfname   
    let storeKey, storeName;
    if(stores[selectedStore]){
      storeKey = stores[selectedStore].storeKey;
      storeName = stores[selectedStore].StoreName;
    }else{
      storeKey = 0;
      storeName = 'LaundrIQ';
    }
    
    get(child(dbRef, `${storeID}/accounts/${storeID}/${year}/${month}/${dte}`)).then((snapshot) => {
        
        const iTotal = snapshot.child("incomeTotal").val();
        const iDetails = snapshot.child("incomeDetails").val();
        let incTotal;   
        let iDetal;
        let incDetails = [];
        
        if(iTotal){
          incTotal = grandTotal+iTotal;
          const keyLength = iDetails.length+1;
          const _details = [...iDetails];
          _details.push([{key: keyLength, storeKey:storeKey, storeName:storeName, name:billname, description: billname, amount:grandTotal}])
          iDetal = _details;
        }else{
          incTotal = grandTotal;
          incDetails.push({key: 0, storeKey:storeKey, storeName:storeName, name:billname, description: billname, amount:grandTotal})
          iDetal = incDetails;
        }
        return [incTotal, iDetal];
    }).then((array)=>{
      let printStore;
      if(stores[selectedStore]){
        printStore = stores[selectedStore]
      }else{
        printStore = 'LaundrIQ'
      }
      const  one = update(dref(dbr, storeID+'/accounts/'+storeID+"/"+year+"/"+month+"/"+dte), {
        date:editInvoiceDate,
        incomeTotal: array[0],
        incomeDetails: array[1]
      })
      const two = update(dref(dbr, storeID+'/delivery/' + storeID+'/'+editKey), {
        status: "new",
      })
      const customerkey = editItem.cdata.customerID;
      const three = update(dref(dbr, storeID+'/billing/'+storeID+'/'+customerkey+'/'+editKey), {
        status: "finished",
        itemPriceValue:editItemValue,
        stores:stores || '',
        printStore:printStore || '',
        invoiceDate:editInvoiceDate,
        grandTotal:grandTotal
      })
      const finalupdate = [one, two, three];
      const fp = Promise.all(finalupdate);
      
      fp.then(()=>{
        setEditLoader(false);
        setEditItem({...editItem, status:"finished"})
        setShowModal(true);
      }).catch((error)=>{
        console.log(error);
      })
    });
  }
  
  function goToInvoiceList() {
    setShowModal(false);
    setInitialLoader(true);
  }

  function openBarcode(){
    setListLoader(false);
    setBarcodeloader(true);
  }
  
function closeBarcode(){
    setBarcodeloader(false);
    setListLoader(true);
  }

  function sucessBarcode(code){
    setSKU(code);
    setQuery(code);
    handleSearch(query);
    setBarcodeloader(false);
    setListLoader(true);
  }

  function handleSearch(e){
    setQuery(e);
    const elow = e.toLowerCase();
    const fData = listData.filter(item => {
      const min = moment(item.ptime).format("hh:mm a")
      const dt = moment(item.ptime).format("DD MMM")
      const yr = moment(item.ptime).format("YYYY")
      
      return (
        (item.status && item.status.toLowerCase().includes(elow)) ||
        (item.referenceNumber && item.referenceNumber.toLowerCase().includes(elow)) ||
        (item.paddress && item.paddress.address.toLowerCase().includes(elow)) ||
        (item.pstaff && item.pstaff.toLowerCase().includes(elow)) ||
        (item.cdata && item.cdata.fullName.toLowerCase().includes(elow)) ||
        (item.cdata && item.cdata.phoneNumber.toLowerCase().includes(elow)) ||
        (min && min.toLowerCase().includes(elow)) ||
        (dt && dt.toLowerCase().includes(elow)) ||
        (yr && yr.toLowerCase().includes(elow))

        );
    }
    );
    setFilterListData(fData);
  };

  

  

  
  async function print(){
    const data = await render(
      <Printer type="epson">
        <QRCode align="center" content={editReferenceNumber} />
        <Br />
        <Line character="-" />
        <Br />
        <Row left={editReferenceNumber} right={editCustomerData.fullName} />
        <Row left={moment(editDate).format('DD/MMM/YYYY')} right={editCustomerData.phoneNumber} />
        <Row left={moment(editInvoiceDate).format('DD/MMM/YYYY')} right='' />
        <Br />
        <Line character="-" />
        <Br />
        <Row left="Item - Qty X Price" right="Total" />
        <Line character="-" />
        <Br />
        {editItemValue.map((cinput, index)=>(
          <Row left={(cinput.label) (cinput.value) (cinput.price)} right={cinput.total}/>
        ))}
        <Line character="-" />
        <Br />
        <Row left="Grand Total" right={grandTotal} />
        <Br />
        <Cut />
      </Printer>
    );
    
    const port = await window.navigator.serial.requestPort();
    await port.open({ baudRate: 9600 });

    const writer = port.writable?.getWriter();

    if (writer != null) {
      await writer.write(data);
      writer.releaseLock();
    }
  }
  
  return (
    <>
    <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Invoice</span>
            </div>
          </li>
        </ol>
      </nav>
      {readAccess? 
      <>
      {listLoader && 
        <>
          {listDataExist? 
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
              <div class="flex items-center justify-between">
              <p class="mb-5 ml-5 text-xl text-gray-900 dark:text-white">Invoice List</p>
              </div>
              <div class="relative z-0 mb-6 w-1/2 ml-5 mr-5 group ">
                <input value={query} onChange={(e)=>handleSearch(e.target.value)} type="text" name="sku" id="sku" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                <button onClick={()=>openBarcode()} type="button" class="absolute top-0 right-0 p-2.5 text-sm font-medium text-black dark:text-white bg-grey-700 rounded-r-lg border border-grey-700 hover:bg-grey-800 focus:ring-4 focus:outline-none focus:ring-grey-300 dark:bg-grey-600 dark:hover:bg-grey-700 dark:focus:ring-grey-800"><svg aria-hidden="true" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M6 8h2v8H6zm3 0h1v8H9zm8 0h1v8h-1zm-4 0h3v8h-3zm-2 0h1v8h-1z"></path></svg></button>
                <label htmlFor="sku" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Search</label>
              </div>
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="py-3 px-6">
                          Date
                      </th>
                      <th scope="col" class="py-3 px-6">
                          Customer
                      </th>
                      <th scope="col" class="py-3 px-6">
                          Bill Amount
                      </th>
                      <th scope="col" class="py-3 px-6">
                          Status
                      </th>
                      <th scope="col" class="py-3 px-6">
                          Action
                      </th>
                  </tr>
                </thead>
                <tbody>
                  {filterListData.map((item, key)=>(
                    <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <p>{moment(item.ptime).format('DD MMM YYYY')}</p>
                      <p>{moment(item.ptime).format('hh:mm a')}</p>
                      </th>
                      <td class="py-4 px-6">
                      <p>{item.cdata.fullName}</p>
                      <p>{item.cdata.phoneNumber}</p>
                      </td>
                      <td class="py-4 px-6">
                      <p>{item.referenceNumber}</p>
                      {item.total? 
                      <p>{item.total}</p>:<p>Bill Not Generated</p>}
                      </td>
                      <td class="py-4 px-6">
                      <div>
                      <div class="flex items-center">
                      {(item.status === "new") &&  <span class="flex w-3 h-3 bg-green-300 rounded-full"></span>}
                      {(item.status === "saved") &&  <span class="flex w-3 h-3 bg-yellow-300 rounded-full"></span>}
                      {(item.status === "finished") &&  <span class="flex w-3 h-3 bg-purple-300 rounded-full"></span>}
                      <span class="ml-2">{item.status}</span> 
                      </div>
                      </div>
                      </td>
                      <td class="py-4 px-6">
                        <button onClick={()=>editCollection(item)} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center mr-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-blue-800">
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}   
                </tbody> 
                </table>     
              </div>
            :
            <div id="alert" class="p-4 mb-4 text-yellow-700 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800" role="alert">
              <div class="flex items-center">
                <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                <h3 class="text-lg font-medium">You have not added Collection</h3>
              </div>
              <div class="mt-2 mb-4 text-sm">
                Do you wish to create Collection?
              </div>
              <div class="flex">
                <button onClick={()=>navigate("/customers")} type="button" class="text-white bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800">
                  <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
                  Yes
                </button>
                <button onClick={()=>navigate("/")} type="button" class="text-yellow-700 bg-transparent border border-yellow-700 hover:bg-yellow-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-yellow-500 dark:border-yellow-500 dark:text-yellow-400 dark:hover:text-white dark:focus:ring-yellow-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                  Dismiss
                </button>
              </div>
            </div>
          } 
        </>
      }
      {barcodeloader && 
      <div>
      <BarcodeScannerComponent
        width={500}
        height={500}
        onUpdate={(err, result) => {
          if (result) sucessBarcode(result.text);
          else console.log(err)
        }}
        torch={false}
        
      />
      {SKU? <p class="text-sm text-gray-900 dark:text-white">Scanned value: {SKU}</p>:<p class="text-sm text-gray-900 dark:text-white">No value scanned...</p>}
      <button onClick={()=>closeBarcode()} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Close</button>
      </div>
    }
      {showModal && 
        <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Invoice updated successfully</h3>
          </div>
          
          <div class="flex mt-5 mb-5">
            <button onClick={()=>goToInvoiceList()} type="button" class="mr-2 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900">
              OK
            </button>
            {(editItem.status === "finished") &&
              <>
              <div style={{ display: "none" }}>
                
                {/* <ComponentToPrint ref={(el) => (componentRef = el)} /> */}
              </div>
              <button type="button" onClick={()=>print()}  class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900">Print Invoice</button>
              {/* <ReactToPrint
                pageStyle="@media print { body {width: 3.125 in; height: auto; }} @page {size: auto;}"
                trigger={() => <button type="button" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900">Print Invoice</button>}
                content={() => componentRef}
              /> */}
                
              </>
            }

          </div>
          
        </div>
      }
      {editLoader &&
        <div class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          {editCustomerLoader &&
          <>
            <div class="flex flex-col mt-5 ml-5 mr-5 pb-10">
              <div class="flex items-center justify-between">
              <h5 class="mt-5 mb-5 text-xl font-medium text-gray-900 dark:text-white">Edit Invoice</h5>  
              <div>
              <p class="text-sm text-gray-500 dark:text-gray-400">Ref Number: {editReferenceNumber}</p>
              <p class="text-sm text-gray-500 dark:text-gray-400">Bill Date: {moment(editInvoiceDate).format('DD/MMM/YYYY hh:mm a')}</p>
              <p class="text-sm text-gray-500 dark:text-gray-400">Collection Date: {moment(editDate).format('DD/MMM/YYYY hh:mm a')}</p>
              </div>
              </div>
              <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">{editCustomerData.fullName}</h5>
              {editItem.paddress &&
                <>
                  {editItem.paddress.address && <p class="text-sm text-gray-500 dark:text-gray-400">Address: {editItem.paddress.address}</p>}
                </>
                }
              <span class="text-sm text-gray-500 dark:text-gray-400">Phonenumber: {editCustomerData.phoneNumber}</span>
              {editCustomerData.alternateNumber.map((ph, index)=>(
                <p key={index} class="text-sm text-gray-500 dark:text-gray-400">Alternate Phone: {ph.alternateNumber}</p>
              ))}
              {editCustomerData.email && <span class="text-sm text-gray-500 dark:text-gray-400">{editCustomerData.email}</span>}
              <label htmlFor="message" class="block mt-5 mb-2 text-sm font-medium text-gray-900 dark:text-white">Notes</label>
              <p class="text-sm text-gray-500 dark:text-gray-400">{editNotes}</p>
              <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                  <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                                Item name
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Qty
                            </th>
                            <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                                Price
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Total
                            </th>
                            <th>
                            </th>
                        </tr>
                      </thead>
                      <tbody>
                      {editItemValue.map((cinput, index)=>(
                        <tr key={index}>
                          <td class="flex-row">
                            <input name="label" value={cinput.label}  onChange={(event)=>inputcHandler(index, event)} type="text" id="search-dropdown" class={"mt-5 mb-2 pl-2 pr-2 text-sm bg-transparent border-0 border-b-2 appearance-none dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" + (cinput.discount? "border-red-300 dark:border-red-600":"border-green-300 dark:border-green-600")}/>
                            <div>
                              {editSelectedServices.map((services, index)=>(
                              <kbd key={index} class="px-2 py-1.5 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500">{services}</kbd>
                              ))}
                            </div>
                          </td>
                          <td>
                            <input name="value" value={cinput.value}  onChange={(event)=>inputcHandler(index, event)} type="text" id="search-dropdown" class={"mt-5 mb-2 pl-2 pr-2 text-sm bg-transparent border-0 border-b-2 appearance-none dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" + (cinput.discount? "border-red-300 dark:border-red-600":"border-green-300 dark:border-green-600")}/>
                          </td>
                          <td class="relative">
                            <input name='price' value={cinput.price}  onChange={(event)=>inputcHandler(index, event)} type="text" id="search-dropdown" class={"mt-5 mb-2 pl-2 pr-2 text-sm bg-transparent border-0 border-b-2 appearance-none dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" + (cinput.discount? "border-red-300 dark:border-red-600":"border-green-300 dark:border-green-600")}/>
                            <button onClick={()=>priceViewer(index)} type="button" class="mt-4 text-white absolute bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            <svg aria-hidden="true" class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M2.047 14.668a.994.994 0 0 0 .465.607l1.91 1.104v2.199a1 1 0 0 0 1 1h2.199l1.104 1.91a1.01 1.01 0 0 0 .866.5c.174 0 .347-.046.501-.135L12 20.75l1.91 1.104a1.001 1.001 0 0 0 1.366-.365l1.103-1.91h2.199a1 1 0 0 0 1-1V16.38l1.91-1.104a1 1 0 0 0 .365-1.367L20.75 12l1.104-1.908a1 1 0 0 0-.365-1.366l-1.91-1.104v-2.2a1 1 0 0 0-1-1H16.38l-1.103-1.909a1.008 1.008 0 0 0-.607-.466.993.993 0 0 0-.759.1L12 3.25l-1.909-1.104a1 1 0 0 0-1.366.365l-1.104 1.91H5.422a1 1 0 0 0-1 1V7.62l-1.91 1.104a1.003 1.003 0 0 0-.365 1.368L3.251 12l-1.104 1.908a1.009 1.009 0 0 0-.1.76zM12 13c-3.48 0-4-1.879-4-3 0-1.287 1.029-2.583 3-2.915V6.012h2v1.109c1.734.41 2.4 1.853 2.4 2.879h-1l-1 .018C13.386 9.638 13.185 9 12 9c-1.299 0-2 .515-2 1 0 .374 0 1 2 1 3.48 0 4 1.879 4 3 0 1.287-1.029 2.583-3 2.915V18h-2v-1.08c-2.339-.367-3-2.003-3-2.92h2c.011.143.159 1 2 1 1.38 0 2-.585 2-1 0-.325 0-1-2-1z"></path></svg>
                            <span class="sr-only">Icon description</span>
                            </button>
                          </td>
                          <td>
                            <p class="mt-5">{cinput.total}</p>
                          </td>
                          <td>
                            <button onClick={()=> deletecHandler(index)} type="button" class="mt-5 text-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:focus:ring-red-800"><svg aria-hidden="true" class="w-5 h-5" fill="#ffffff" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path></svg></button>
                          </td>
                        </tr>
                      ))}    
                      <tr>
                        <td></td>
                        <td></td>
                        <td><p class="mt-5 text-right mr-3">Total</p></td>
                        <td><p  class="mt-5">{grandTotal}</p></td>
                      </tr>
                      </tbody>
                      
                  </table>
                  <button onClick={()=>addcHandler()} type="button" class="mt-5 mb-6 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Add Item</button>
                  <button onClick={()=>addDiscountHandler()} type="button" class="mt-5 mb-6 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Add Discount</button>
                  
                  
              </div>
              <h3 class="mt-5 mb-4 font-semibold text-gray-900 dark:text-white">Select Store</h3>
                    {stores.map((storeitem, index)=>( 
                    <ul key={index} class="w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                        <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                            <div class="flex items-center pl-3">
                                <input id="list-radio-license" type="radio" checked={selectedStore === index} onChange={()=>setSelectedStore(index)} name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                <label htmlFor="list-radio-license" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{storeitem.StoreName}</label>
                            </div>
                        </li>
                    </ul>
                    ))}

              <div class="flex mt-4 space-x-3 md:mt-6">
              <button  onClick={()=>cancelEditLoader()} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
              {invoiceupdate &&
                <>
                {!(editItem.status === "finished") && <button onClick={()=>saveBill()} type="button" class="focus:outline-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-900">Save</button>}
                {!(editItem.status === "finished") && <button onClick={()=>finishBill()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Finish</button>}
                </>
              }
              {(editItem.status === "finished") &&
                <>
                {/* <ReactToPrint
                  pageStyle="@media print {@page { size: 3.125in 3in; margin: 2mm !important}; height:auto; width:3.125in}"
                  trigger={() => <button type="button" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900">Print</button>}
                  content={() => componentRef}
                /> */}
                <button type="button" onClick={()=>print()}  class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900">Print Invoice</button>
                
                </>
                
              }
              </div>
            </div>
            
          </>
          }
          
        </div>
      }
      {priceModal && 
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        {priceValue.length? 
          <>
            <div class="flex justify-end px-4 pt-4">
            <button onClick={()=>cancelPrice()} type="button" class="text-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:focus:ring-red-800 dark:hover:bg-red-500">
                <svg aria-hidden="true" class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
                </svg>
                <span class="sr-only">Icon description</span>
              </button>
            </div>
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                        Price Head
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Description
                      </th>
                      <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                        Price
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Action
                      </th>
                  </tr>
              </thead>
              <tbody>
                {priceValue.map((item, key)=>(
                  <tr key={key} class="border-b border-gray-200 dark:border-gray-700">
                      <td class="px-6 py-4">
                        {item.priceHead}
                      </td>
                      <td class="px-6 py-4">
                        {item.priceDescription}
                      </td>
                      <td class="px-6 py-4 bg-gray-50 dark:bg-gray-800">
                        {item.unitPrice}
                      </td>
                      <td class="px-6 py-4">
                        <button onClick={()=>selPrice(item.unitPrice)} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Select</button>
                      </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        :
          <div id="alert" class="p-4 mb-4 text-red-700 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">You do not have Price</h3>
          </div>
          <div class="flex">
            <button onClick={()=>cancelPrice()} type="button" class="text-red-700 bg-transparent border border-red-700 hover:bg-red-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-500 dark:border-red-500 dark:text-red-400 dark:hover:text-white dark:focus:ring-red-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
              Dismiss
            </button>
          </div>
          </div>
        }
      </div>
      }
      </>:
      <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">You do not have permission to view Invoice. Contact your admin for permissions</h3>
          </div>
          <div class="flex">
            <button onClick={()=>navigate("/")} type="button" class="text-green-700 bg-transparent border border-green-700 hover:bg-green-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-green-500 dark:border-green-500 dark:text-green-400 dark:hover:text-white dark:focus:ring-green-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
              OK
            </button>
          </div>
      </div>
      }

    </>
  );
}
 