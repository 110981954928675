/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import { useUserStore } from "../../contexts/UserStoreContext";
import firebaseapp from '../../firebase';
import filter from 'lodash.filter';
import { getDatabase, ref as dref, child, get, update, push, set } from "firebase/database";
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom'
import ls from 'localstorage-slim';

export default function InventoryAudit() {
     
  
  const { storeID, setStoreID, countryCode, setCountryCode } = useUserStore();
  const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);
  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);
  const [audithistoryLoader, setAudithistoryLoader] = useState(false);
  const [audithistoryList, setAudithistoryList] = useState(false);
  const [filteredAudithistoryList, setFilteredAudithistoryList] = useState('')
  const [statusOfAudit, setStatusOfAudit] = useState(true);
  const [initialoader, setInitialoader] = useState(true);
  const [listLoader, setlistLoader] = useState(false);
  const [listavailable, setListavailable] = useState(false)
  const [expanded, setExpanded] = useState(false);
  const [newAuditLoader, setNewAuditLoader] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [noteditable, setNotEditable] = useState(true);
  const [listData, setlistData] = useState('');
  const [searchString, setSearchString] = useState('');
  const [filterlistData, setFilterlistData] = useState('');
  const [auditDate, setAuditDate] = useState(new Date());
  const [auditIsoDate, setAuditIsoDate] = useState(new Date());
  const [editItem, setEditItem] = useState('');
  const navigate = useNavigate();
  const [incompleteAuditLoader, setIncompleteAuditLoader] = useState(false)
  const [noItemsModal, setNoItemsModal] = useState(false)
  const [query, setQuery] = useState('')

  if(!storeID){
    setStoreID(userdata.storeID);
  }
    
  if(!countryCode){
    setCountryCode(userdata.country);
  }

  const handleSearch = (txt) => {
    
    const formattedQuery = txt.toLowerCase();
    const filteredData = filter(listData, user => {
      return contains(user, formattedQuery);
    });
    setFilterlistData(filteredData);
    setSearchString(txt)
  };

  const contains = ({brand, category, itemname, sku}, query) => {
    if ((brand||'').toLowerCase().includes(query) || (category||'').toLowerCase().includes(query) || 
    (itemname||'').toLowerCase().includes(query) ||  (sku||'').toLowerCase().includes(query) )
    {
      return true;
    }
    return false;
  };

  const onItemPress = (item) => {
    setExpanded(!expanded)
  }

  function auditRenarkHandler(text, index){

    const _auditListQty = [...listData];
    const _auditFilterQty = [...filterlistData];
    _auditListQty[index].remarks = text;
    _auditFilterQty[index].remarks = text;
    setlistData(_auditListQty);
    setFilterlistData(_auditFilterQty);

  }

  function newAuditCreator(){
    setInitialoader(false);
    setAudithistoryLoader(false);
    setNewAuditLoader(true);
  }

  function reindex(item, index){
    const pid = push(child(dref(dbr), 'inventory')).key;
    let value = item.physicalquantity || null;
    let historyObject = {
      value: item.physicalquantity || null,
      description: item.remarks || null,
      time: auditIsoDate,
      activity: 'Re-Index'
    }

    update(dref(dbr, storeID+'/inventory/' + storeID+"/stock/"+item.key+"/stock/"), {
      stock:value
    }).then(()=>{
      update(dref(dbr, storeID+'/inventory/' + storeID+"/stock/"+item.key+"/history/"), {
        [pid]: historyObject
      }).then(()=>{
        const _auditListQty = [...listData];
        const _auditFilterQty = [...filterlistData];
        _auditListQty[index].status = true;
        _auditFilterQty[index].status = true;
        _auditListQty[index].stock = item.physicalquantity;
        _auditFilterQty[index].stock = item.physicalquantity;
        setlistData(_auditListQty);
        setFilterlistData(_auditFilterQty);
      })
    })   
  }

  useEffect(() => { 
    if(initialoader){
      
    function listAuditLoader(){
      get(child(dbRef, `${storeID}/inventory/${storeID}/audit`)).then(async (snapshot) => {
         const listdata = [];
         if (snapshot.exists()) {
           snapshot.forEach(element => {
             const key = element.key;
             const auditdate = element.child('auditdate').val();
             const auditstatus = element.child('auditstatus').val();
             const items = element.child('items').val();
             if(!auditstatus){
              setStatusOfAudit(false)
             }
             listdata.push({
               key : key,
               auditdate : auditdate,
               auditstatus : auditstatus,
               items : items,
               day: moment(auditdate).format('DD'),
               month: moment(auditdate).format('MMM'),
               year: moment(auditdate).format('YYYY')
             })

             
           });
           const datasnaps = await Promise.all(listdata);
           const dataExist = true;
           return [dataExist, datasnaps];
           
         }
         else{
          const dataExist = false;
           return [dataExist, 0];
          
         }
       }).then((dataExist)=>{
        if(dataExist[0]){
          setInitialoader(false);
          setAudithistoryList(dataExist[1]);
          setFilteredAudithistoryList(dataExist[1]);
          setAudithistoryLoader(true);

        }else{
          setInitialoader(false);
          setAudithistoryList('');
          setAudithistoryLoader(true);
        }

       }).catch((error)=>{
        console.log(error);

       });
    }
    listAuditLoader();
    }
   }, [initialoader]);

   useEffect(() => { 
    if(newAuditLoader){
    setStatusOfAudit(true);
    setNotEditable(false);
    setIncompleteAuditLoader(false);
    function listloadData(){
      get(child(dbRef, `${storeID}/inventory/${storeID}/stock`)).then(async (snapshot) => {
          const listdata = [];
          if (snapshot.exists()) {
            get(child(dbRef, `${storeID}/inventory/${storeID}/audit`)).then((auditsnapshot) => {
              if (auditsnapshot.exists()) {
                const obj = auditsnapshot.val();
                
                for (const key in obj) {
                  if (Object.hasOwnProperty.call(obj, key)) {
                    const element = obj[key];
                    
                    if(!element.auditstatus){
                      setStatusOfAudit(false);
                      console.log(element.auditstatus, "element status");
                    }
                  }
                }

              } else {
                console.log("No data available");
              }
              
            }).catch((error) => {
              console.error(error);
            });

            snapshot.forEach(element => {
              const key = element.key;
              const brand = element.child('stock').child('brand').val();
              const itemname = element.child('stock').child('itemname').val();
              const sku = element.child('stock').child('sku').val();
              const stock = element.child('stock').child('stock').val();
              
              listdata.push({
                key : key,
                brand : brand,
                itemname : itemname,
                sku : sku,
                stock: stock,
                physicalquantity: 0,
                remarks: '',
                status: false,
                difference: stock
              })
              
            });
            const datasnaps = await Promise.all(listdata);
            const dataExist = true;
            return [dataExist, datasnaps];
          }
          else{
            const dataExist = false;
            return [dataExist, 0];
          }
        }).then((dataExist)=>{
          console.log("dataExist", dataExist);
          if(dataExist[0]){
            if(!audithistoryList){
              setAuditDate(moment(new Date()).format('DD/MMM/YYYY'))
              setAuditIsoDate(moment(new Date()).toISOString()) 
              setAudithistoryLoader(false);
              setListavailable(true);
              setlistData(dataExist[1]);
              setFilterlistData(dataExist[1]);
              setlistLoader(true);
            }
            else{
              if(statusOfAudit){
                setAuditDate(moment(new Date()).format('DD/MMM/YYYY'))
                setAuditIsoDate(moment(new Date()).toISOString()) 
                setAudithistoryLoader(false);
                setListavailable(true);
                setlistData(dataExist[1]);
                setFilterlistData(dataExist[1]);
                setlistLoader(true);
              }
              else{
                setAudithistoryLoader(false);
                setIncompleteAuditLoader(true);
              }
            }
          }else{
            setAudithistoryLoader(false);
            setNoItemsModal(true);
          }
        }).catch((error)=>{
          console.log(error);
        });
    }
    listloadData();
   }
  
  }, [newAuditLoader]);

  function submitAudit(status){
    const key = push(child(dref(dbr), 'inventory')).key;
    const items = {};
    
    for (const ky in listData) {
      if (Object.hasOwnProperty.call(listData, ky)) {
        const element = listData[ky];
        let elementkey = element.key;
        items[elementkey] = element;
      }
    }
    const updateObject = {
      key : key,
      auditdate: auditIsoDate,
      auditstatus: status,
      items
    }

    set(dref(dbr, storeID+'/inventory/' + storeID+'/audit/'), {
      [key]: updateObject
    }).then(()=>{
      setlistLoader(false);
      setInitialoader(true);
    });
  }

  function editAudit(status){
    setInitialoader(false);
    const mainkey = editItem.key;
    const items = {};
    for (const ky in listData) {
      if (Object.hasOwnProperty.call(listData, ky)) {
        const element = listData[ky];
        let elementkey = element.key;
        items[elementkey] = element;
        
      }
    }
    const updateObject = {
      key : mainkey,
      auditdate: auditIsoDate,
      auditstatus: status,
      items
    }
    update(dref(dbr, storeID+'/inventory/' + storeID+"/audit/"), {
      [mainkey]: updateObject
    }).then(()=>{
      setlistLoader(false);
      setInitialoader(true);

    });
  }

  function auditListLoader(){
    setIncompleteAuditLoader(false);
    setlistLoader(false);
    setAudithistoryLoader(true);
  }

  function historyItemPress(item){
    setEditItem(item);
    setNewAuditLoader(false);
    setAudithistoryLoader(false);
    setEditLoader(true);
  }

  const auditQtyHandler = (text, index)=>{
    const _auditListQty = [...listData];
    const _auditFilterQty = [...filterlistData];
    let sysQty;
    if(text){
      sysQty = (parseInt(_auditListQty[index].stock)) - (parseInt(text));
    }else{
      sysQty = (parseInt(_auditListQty[index].stock)) - (parseInt(text));
    }
    _auditListQty[index].physicalquantity = text;
    _auditFilterQty[index].physicalquantity = text;
    _auditListQty[index].difference = sysQty;
    _auditFilterQty[index].difference = sysQty;
    setlistData(_auditListQty);
    setFilterlistData(_auditFilterQty);
  }

  useEffect(() => { 
    
    if(editLoader){
    
    function editlistloadData(){
      
      setAuditIsoDate(editItem.auditdate);
      setAuditDate(moment(editItem.auditdate).format('DD/MMM/YYYY'));
      setNotEditable(editItem.auditstatus);
      const ldata = [];
      for (const key in editItem.items) {
        if (Object.hasOwnProperty.call(editItem.items, key)) {
          const element = editItem.items[key];
          ldata.push(element);
        }
      }
      setEditLoader(false);
      setlistData(ldata);
      setFilterlistData(ldata);
      setAudithistoryLoader(false);
      setListavailable(true);
      setlistLoader(true);
      
    }
    editlistloadData();
   }
  
  }, [editLoader]);

  function handlelistSearch(e){
    setQuery(e);
    const elow = e.toLowerCase();
    const fData = audithistoryList.filter(item => {
      return (
        (item.day && item.day.toLowerCase().includes(elow)) ||
        (item.month && item.month.toLowerCase().includes(elow)) ||
        (item.year && item.year.toLowerCase().includes(elow)) ||
        (item.status && item.status.toLowerCase().includes(elow))
        );
    }
    );
    setFilteredAudithistoryList(fData);
  };

  return (
    <div>
      <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <Link to='/inventory' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              Inventory
            </Link>
            </div>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Inventory Audit</span>
            </div>
          </li>
        </ol>
      </nav>
      {audithistoryLoader && 
      <>
        {audithistoryList ?
        <>
        <div class="relative z-0 mb-6 w-1/2 ml-5 mr-5 group ">
          <input value={query} onChange={(e)=>handlelistSearch(e.target.value)} type="text" name="sku" id="sku" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
          <label htmlFor="sku" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Search</label>
        </div>
        <div class="flex w-full items-center justify-between bg-white dark:text-white dark:bg-gray-800">
        
                <div class="p-5 text-lg font-semibold text-center text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                List of Audits
                </div>
                <div class="px-4 pt-4">
                <button onClick={()=>newAuditCreator()} type="button" class="text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">New Audit</button>
                </div>
              </div>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                          Audit Date
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Audit Status
                      </th>
                      <th scope="col" class="px-6 py-3">
                          <span class="sr-only">Edit</span>
                      </th>
                  </tr>
              </thead>
              <tbody>
              {filteredAudithistoryList.map((item, key)=>(
                  <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td class="px-6 py-4">
                      {item.day}/{item.month}/{item.year}
                      </td>
                      {item.auditstatus?
                        <td class="px-6 py-4">
                          Completed
                      </td>
                      :<td class="px-6 py-4">
                          Incomplete
                      </td>
                      }
                      <td class="px-6 py-4">
                        <button onClick={()=>historyItemPress(item)} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800">
                          Edit
                          <svg aria-hidden="true" class="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                      </td>
                      
                  </tr>
              ))}    
              </tbody>
          </table>
        </div>
        </>
        :
        <>
          <div id="alert" class="p-4 mb-4 text-yellow-700 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800" role="alert">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
              <span class="sr-only">Info</span>
              <h3 class="text-lg font-medium">You have not yet audited your stock.</h3>
            </div>
            <div class="mt-2 mb-4 text-sm">
              Do you wish to create new audit?
            </div>
            <div class="flex">
              <button onClick={()=>newAuditCreator()} type="button" class="text-white bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800">
                <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
                Yes
              </button>
              <button onClick={()=>navigate("/inventory")} type="button" class="text-yellow-700 bg-transparent border border-yellow-700 hover:bg-yellow-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-yellow-500 dark:border-yellow-500 dark:text-yellow-400 dark:hover:text-white dark:focus:ring-yellow-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                Dismiss
              </button>
            </div>
          </div>
        </>
        }
      </>
      }
      {incompleteAuditLoader &&
      <div id="alert" class="p-4 mb-4 text-yellow-700 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800" role="alert">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
              <span class="sr-only">Info</span>
              <h3 class="text-lg font-medium">Please complete your previous audit</h3>
            </div>
            <div class="mt-2 mb-4 text-sm">
              Do you wish to continue?
            </div>
            <div class="flex">
              <button onClick={()=>auditListLoader()} type="button" class="text-white bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800">
                <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
                Yes
              </button>
              <button onClick={()=>navigate("/inventory")} type="button" class="text-yellow-700 bg-transparent border border-yellow-700 hover:bg-yellow-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-yellow-500 dark:border-yellow-500 dark:text-yellow-400 dark:hover:text-white dark:focus:ring-yellow-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                Dismiss
              </button>
            </div>
      </div>
      }
      {listLoader && 
      <>
      {listavailable? 
      <>
      <div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    
            <div class="mt-5 flex flex-col items-center pb-10">
                <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">Audit Date: {auditDate}</h5>
                {newAuditLoader? 
                <div class="flex mt-4 space-x-3 md:mt-6">
                <button onClick={()=>submitAudit(false)} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Save</button>
                <button onClick={()=>submitAudit(true)} type="button" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Finish</button>
                </div>
                :
                <div class="flex mt-4 space-x-3 md:mt-6">
                <button onClick={()=>editAudit(false)} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Save</button>
                <button onClick={()=>editAudit(true)} type="button" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Finish</button>
                </div>
                }
            </div>
            <div class="ml-5 mr-5 mb-5">
            <input value={searchString} onChange={(e) => handleSearch(e.target.value)} type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" />
            </div>
        </div>
        {filterlistData.map((item, key)=>(
          <div key={key} onClick={()=>onItemPress(item)} class="mt-5 w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div class="flex justify-end px-4 pt-4">
            <span class="text-sm text-gray-500 dark:text-gray-400">{item.sku}</span>
            </div>
            <div class="flex flex-col items-center pb-10">
                <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">{item.itemname}</h5>
                {item.brand? <span class="text-sm text-gray-500 dark:text-gray-400">Brand: {item.brand}</span>
                :
                <span class="text-sm text-gray-500 dark:text-gray-400">Brand: Undefined</span>}
                
            </div>
            <div class="grid gap-6 mb-6 md:grid-cols-2">
            <div class="ml-5">
              <label htmlFor="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">System Quatity</label>
              {item.stock ? <span class="text-sm text-gray-500 dark:text-gray-400">{item.stock}</span>:<span class="text-sm text-gray-500 dark:text-gray-400">0</span>}
            </div>
            <div class="mr-5">
              <label htmlFor="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Physical Stock</label>
              <input onChange={(e)=>auditQtyHandler(e.target.value, key)} value={(item.physicalquantity).toString()} disabled={noteditable || item.status}  type="text" name="stock" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
            </div>
            </div>
            <div class="mr-5 ml-5 mb-5">
              <label htmlFor="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Remarks</label>
              <textarea onChange={(e)=>auditRenarkHandler(e.target.value, key)} value={item.remarks} disabled={noteditable || item.status} id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your thoughts here..."></textarea>
            </div>
            
            <div class="ml-5 mb-5">
            <button onClick={()=>reindex(item, key)} disabled={noteditable || item.status} type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              Re-Index
              <svg aria-hidden="true" class="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
            <span class="ml-5 text-sm text-gray-500 dark:text-gray-400">Difference: {item.difference}</span>
            
            </div>
            {item.status && <span class="ml-5 mb-5 mt-5 text-sm text-gray-500 dark:text-gray-400">Re-Index completed</span>}
          </div>
          ))}
          

      </>
      :
      <div id="alert" class="p-4 mb-4 text-yellow-700 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800" role="alert">
              <div class="flex items-center">
                <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                <span class="sr-only">Info</span>
                <h3 class="text-lg font-medium">You do not have any items.</h3>
              </div>
              <div class="mt-2 mb-4 text-sm">
                Create Items and return to auditing
              </div>
              <div class="flex">
                <button onClick={()=>navigate("/inventory")} type="button" class="text-white bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800">
                  <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
                  OK
                </button>
              </div>
      </div>
      }
      </>
      }
      {noItemsModal &&
      <>
          <div id="alert" class="p-4 mb-4 text-yellow-700 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800" role="alert">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
              <span class="sr-only">Info</span>
              <h3 class="text-lg font-medium">You do not have stock inventory to audit</h3>
            </div>
            <div class="mt-2 mb-4 text-sm">
              Create stock to improve asset manamegement and process auditing later.
            </div>
            <div class="flex">
              <button onClick={()=>navigate("/inventory")} type="button" class="text-yellow-700 bg-transparent border border-yellow-700 hover:bg-yellow-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-yellow-500 dark:border-yellow-500 dark:text-yellow-400 dark:hover:text-white dark:focus:ring-yellow-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                OK
              </button>
            </div>
          </div>
      </>
      }
    </div>
  )
}