/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import { useUserStore } from "../../contexts/UserStoreContext";
import firebaseapp from '../../firebase';
import { getDatabase, ref as dref, child, get, set } from "firebase/database";
import { Link} from 'react-router-dom'
import ls from 'localstorage-slim';
import BarcodeScannerComponent from "react-qr-barcode-scanner";

export default function InventoryList() {
     
  const { storeID, setStoreID, countryCode, setCountryCode, inventoryupdate, inventorydelete } = useUserStore();
  const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);
  if(!storeID){
      setStoreID(userdata.storeID);
    }
      
  if(!countryCode){
    setCountryCode(userdata.country);
  }

  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);   
  const [SKU, setSKU] = useState('');
  const [itemName, setItemName] = useState('');
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [inputUnit, setInputUnit] = useState('');
  const [unitList, setUnitList] = useState([]);
  const [inputSize, setInputSize] = useState('');
  const [selectedSize, setSelectedSize] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState('')
  const [categoryList, setCategoryList] = useState([]);
  const [editLoader, setEditLoader] = useState(false);
  const [listLoader, setlistLoader] = useState(false);
  const [listnotavailable, setListnotavailable] = useState(false)
  const [listavailable, setListavailable] = useState(false)
  const [listData, setlistData] = useState('');
  const [lister, setLister] = useState(true);
  const [itm, setItm] = useState('');
  const [selectedID, setSelectedID] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [editLoaderInit, setEditLoaderInit] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [barcodeloader, setBarcodeloader] = useState(false);
  const [filterListData, setFilterListData] = useState('');
  const [query, setQuery] = useState('')

  useEffect(() => { 
    if(lister){

    
    function listloadData(){
      setLister(false);
      get(child(dbRef, `${storeID}/inventory/${storeID}/items`)).then(async (snapshot) => {
        const listdata = [];
        if (snapshot.exists()) {
          snapshot.forEach(element => {
            const key = element.key;
            const brand = element.child('brand').val();
            const category = element.child('category').val();
            const itemname = element.child('itemname').val();
            const size = element.child('size').val();
            const sizeunit = element.child('sizeunit').val();
            const sku = element.child('sku').val();
            const location = element.child('location').val();
            const unit = element.child('units').val();
            const unitunit = element.child('unitunit').val();

            listdata.push({
              key : key,
              brand : brand,
              category : category,
              itemname : itemname,
              location : location,
              unit : unit,
              unitunit : unitunit,
              size : size,
              sizeunit : sizeunit,
              sku : sku
            })
            
          });
          const datasnaps = await Promise.all(listdata);
          const dataExist = true;
          return [dataExist, datasnaps];
        }
        else{
          const dataExist = false;
          return [dataExist, 0];
          
        }
      }).then((dataExist)=>{
          if(dataExist[0]){
          setListnotavailable(false);
          setListavailable(true);
          setlistData(dataExist[1]);
          setFilterListData(dataExist[1]);
          setlistLoader(true);

          }else{
          setListavailable(false);
          setListnotavailable(true);
          setlistLoader(true);
          }
          
      }).catch((error)=>{
          console.log(error);
      });
    }
    listloadData();
    }
  }, [lister]);

  function submitEditItem(){
      
      let {sku, brand, itemname, location, category,
          size, sizeunit, unit, unitunit} = ''

      if(SKU !== 'undefined' && SKU.length){
          sku = SKU;
      }

      if(selectedBrand !== 'undefined' && selectedBrand.length){
          brand = selectedBrand;
      }

      if(itemName !== 'undefined' && itemName.length){
          itemname  = itemName
      }
      
      if(selectedLocation.name !== 'undefined' && selectedLocation.length){
          location = selectedLocation
      }

      if(selectedCategory !== 'undefined' && selectedCategory.length){
          category = selectedCategory
      }
      
      if(inputSize !== 'undefined' && inputSize.length){
          size = inputSize;
      }

      if(selectedSize !== 'undefined' && selectedSize.length){
          sizeunit = selectedSize
      }

      if(inputUnit !== 'undefined' && inputUnit.length){
          unit =  inputUnit
      }

      if(selectedUnits !== 'undefined' && selectedUnits.length){
          unitunit = selectedUnits
      }

      if(itemName.length){
          set(dref(dbr, storeID+'/inventory/' + storeID+"/items/"+itm.key+"/"), {
                sku:sku || null, 
                itemname: itemname || null, 
                brand:brand || null, 
                location:location || null, 
                category:category || null, 
                size: size || null, 
                sizeunit: sizeunit || null,
                units: unit || null, 
                unitunit: unitunit || null, 
          }).then(()=>{
              
                setEditLoader(false);
                setShowEditModal(true);
                
          });

      }
      else{
        console.log('Item name should not be empty');
          
      }
      
  }

  

  function editItemfunction(item){
    setItm(item);
    setListavailable(false);
    setlistLoader(false);
    setEditLoaderInit(true);
  }


  function cancelItem(){
    setSelectedID('');
    setShowModal(false);
    setListavailable(true);
  }


  function deleteItem(id){
    setSelectedID(id);
    setListavailable(false);
    setShowModal(true);
  }

  useEffect(() => { 
        
      if(editLoaderInit){
        function editItem(){
          setEditLoaderInit(false);
          get(child(dbRef, `${storeID}/inventory/${storeID}/settings`)).then((snapshot) => {
                
                if (snapshot.exists()) {
                    const allBrands = snapshot.child('brands').val() || '';
                    const allCategory = snapshot.child('category').val() || '';
                    const allLocation = snapshot.child('location').val() || '';
                    const allunit = snapshot.child('unit').val() || '';
                    const allSize = snapshot.child('size').val() || '';
                    if(allBrands){
                          const brandsArr = allBrands.split(",");
                          const ArrayBrands = [];
                          for (const key in brandsArr) {
                              if (Object.hasOwnProperty.call(brandsArr, key)) {
                                    const name = brandsArr[key];
                                    if(itm.brand === name){
                                        setSelectedBrand(name);
                                    }
                                    const id = key;
                                    ArrayBrands.push({name:name,id:id})
                              }
                          }
                          setBrandList(ArrayBrands);
                    }
                    if(allLocation){
                          const locationArr = allLocation.split(",");
                          const ArrayLocation = [];
                          for (const key in locationArr) {
                              if (Object.hasOwnProperty.call(locationArr, key)) {
                                    const name = locationArr[key];

                                    if(itm.location === name){
                                        setSelectedLocation(name);
                                    }
                                    const id = key;
                                    ArrayLocation.push({name:name,id:id})
                              }
                          }
                          setLocationList(ArrayLocation);
                    }
                    else{
                      setLocationList([]);
                    }
                    if(allCategory){
                          const categoryArr = allCategory.split(",");
                          const ArrayCategory = [];
                          for (const key in categoryArr) {
                              if (Object.hasOwnProperty.call(categoryArr, key)) {
                                    const name = categoryArr[key];
                                    const id = key;
                                    if(itm.category === name){
                                        setSelectedCategory(name);
                                    }
                                    ArrayCategory.push({name:name,id:id})
                              }
                          }
                          setCategoryList(ArrayCategory);
                    }
                    if(allunit){
                          const unitArr = allunit.split(",");
                          const Arrayunit = [];
                          for (const key in unitArr) {
                              if (Object.hasOwnProperty.call(unitArr, key)) {
                                    const name = unitArr[key];
                                    const id = key;
                                    
                                    Arrayunit.push({name:name,id:id})
                              }
                          }
                          setUnitList(Arrayunit);
                    }
                    if(allSize){
                          const sizeArr = allSize.split(",");
                          const ArraySize = [];
                          for (const key in sizeArr) {
                              if (Object.hasOwnProperty.call(sizeArr, key)) {
                                    const name = sizeArr[key];
                                    const id = key;
                                    if(itm.sizeunit === name){
                                        setSelectedSize(name);
                                    }
                                    ArraySize.push({name:name,id:id})
                              }
                          }
                          setSizeList(ArraySize);
                    }
                }
          }).then(()=>{
                setSKU(itm.sku);
                setItemName(itm.itemname);
                setInputSize(itm.size);
                setInputUnit(itm.unit);
                setlistLoader(false);
                setEditLoader(true);
          });
        
        }
        editItem();
      }
    }, [editLoaderInit]);

  function itemDeleteConfirm(){
    setlistLoader(false);
    set(dref(dbr, storeID+'/inventory/' + storeID+"/items/"+selectedID), {
      sku: null
    }).then(()=>{
      setShowModal(false);
      setLister(true);
    })
  }

  function openBarcode(){
    console.log("not working");
  }

  function cancelEditItem(){
    setEditLoader(false);
    setListavailable(true);
    setlistLoader(true);
  }

  function cancelEditModal(){
    setShowEditModal(false);
    setLister(true);
  }

  function openBarcode(){
    setListavailable(false);
    setBarcodeloader(true);
  }
  
function closeBarcode(){
    setBarcodeloader(false);
    setListavailable(true);
  }

  function sucessBarcode(code){
    setSKU(code);
    setQuery(code);
    handleSearch(query);
    setBarcodeloader(false);
    setListavailable(true);
  }

  function handleSearch(e){
    setQuery(e);
    const elow = e.toLowerCase();
    const fData = listData.filter(item => {
      return (
        (item.itemname && item.itemname.toLowerCase().includes(elow)) ||
        (item.brand && item.brand.toLowerCase().includes(elow)) ||
        (item.sku && item.sku.address.toLowerCase().includes(elow))
        

        );
    }
    );
    setFilterListData(fData);
  };
         
  return (
    <div>
      <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <Link to='/inventory' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              Inventory
            </Link>
            </div>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Inventory List</span>
            </div>
          </li>
        </ol>
      </nav>
      {listLoader && 
        <>
          {listnotavailable && 
            <div class="w-full p-4 text-center bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                <h5 class="mb-2 text-3xl font-bold text-gray-900 dark:text-white">You do not have any items</h5>
                <p class="mb-5 text-base text-gray-500 sm:text-lg dark:text-gray-400">Asset management improves productivity</p>
            </div>
          }
          {barcodeloader && 
            <div>
            <BarcodeScannerComponent
              width={500}
              height={500}
              onUpdate={(err, result) => {
                if (result) sucessBarcode(result.text);
                else console.log(err)
              }}
              torch={false}
              
            />
            {SKU? <p class="text-sm text-gray-900 dark:text-white">Scanned value: {SKU}</p>:<p class="text-sm text-gray-900 dark:text-white">No value scanned...</p>}
            <button onClick={()=>closeBarcode()} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Close</button>
            </div>
          }
          {listavailable &&
          <>
            <div class="relative z-0 mb-6 w-1/2 ml-5 mr-5 group ">
              <input value={query} onChange={(e)=>handleSearch(e.target.value)} type="text" name="sku" id="sku" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
              <button onClick={()=>openBarcode()} type="button" class="absolute top-0 right-0 p-2.5 text-sm font-medium text-black dark:text-white bg-grey-700 rounded-r-lg border border-grey-700 hover:bg-grey-800 focus:ring-4 focus:outline-none focus:ring-grey-300 dark:bg-grey-600 dark:hover:bg-grey-700 dark:focus:ring-grey-800"><svg aria-hidden="true" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M6 8h2v8H6zm3 0h1v8H9zm8 0h1v8h-1zm-4 0h3v8h-3zm-2 0h1v8h-1z"></path></svg></button>
              <label htmlFor="sku" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Search</label>
            </div>
            {filterListData.map((item, key)=>(
              <div key={key} class="mt-5 w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div class="flex justify-end px-4 pt-4">
                  <span class="text-sm text-gray-500 dark:text-gray-400">{item.sku}</span>
                  </div>
                  <div class="flex flex-col items-center pb-10">
                      <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">{item.itemname}</h5>
                      {item.location? <span class="text-sm text-gray-500 dark:text-gray-400">{item.location}</span>
                      :
                      <span class="text-sm text-gray-500 dark:text-gray-400">Location undefined</span>}
                      {item.brand? <span class="text-sm text-gray-500 dark:text-gray-400">Brand: {item.brand}</span>
                      :
                      <span class="text-sm text-gray-500 dark:text-gray-400">Brand: Undefined</span>}
                      
                      <div class="flex mt-4 space-x-3 md:mt-6">
                      <p class="text-sm text-gray-500 dark:text-gray-400">Category:
                      {item.category ? <span class="text-sm text-gray-500 dark:text-gray-400"> {item.category}</span>
                      :
                      <span class="text-sm text-gray-500 dark:text-gray-400">Category undefined</span>}
                      </p>
                      {item.size? <span class="text-sm text-gray-500 dark:text-gray-400">Size: {item.size} {item.sizeunit}</span>
                      :
                      <span class="text-sm text-gray-500 dark:text-gray-400">Size: N/A</span>
                      }
                      {item.unit?
                <span class="text-sm text-gray-500 dark:text-gray-400">Unit:{item.unit} {item.unitunit}</span>
                :
                <span class="text-sm text-gray-500 dark:text-gray-400">Unit: N/A</span>}
                      </div>
              <div class="flex mt-4 space-x-3 md:mt-6">
                {inventoryupdate && <button onClick={()=>editItemfunction(item)} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Edit</button>}
                {inventorydelete && <button onClick={()=>deleteItem(item.key)} type="button" class="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Delete</button>}

                      </div>
                  </div>
              </div>
            ))}
          </>
          }
          {showModal && 
            <div id="alert" class="p-4 mb-4 text-red-700 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
              <div class="flex items-center">
                <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                <h3 class="text-lg font-medium">Delete Item?</h3>
              </div>
              <div class="mt-2 mb-4 text-sm">
                Do you wish to continue?
              </div>
              <div class="flex">
                <button onClick={()=>itemDeleteConfirm()} type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                  <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
                  Delete
                </button>
                <button onClick={()=>cancelItem()} type="button" class="text-red-700 bg-transparent border border-red-700 hover:bg-red-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-500 dark:border-red-500 dark:text-red-400 dark:hover:text-white dark:focus:ring-red-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                  Dismiss
                </button>
              </div>
            </div>
          }
          
        </>
      }
      {editLoader &&
            <>
            <p class="text-lg text-gray-900 dark:text-white mb-5">Edit Item</p>
              <div class="grid md:gap-6">
                <div class="relative z-0 mb-6 w-full group ">
                  <input value={SKU} onChange={(e)=>setSKU(e.target.value)} type="text" name="sku" id="sku" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                  <button onClick={()=>openBarcode()} type="button" class="absolute top-0 right-0 p-2.5 text-sm font-medium text-black dark:text-white bg-grey-700 rounded-r-lg border border-grey-700 hover:bg-grey-800 focus:ring-4 focus:outline-none focus:ring-grey-300 dark:bg-grey-600 dark:hover:bg-grey-700 dark:focus:ring-grey-800"><svg aria-hidden="true" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M6 8h2v8H6zm3 0h1v8H9zm8 0h1v8h-1zm-4 0h3v8h-3zm-2 0h1v8h-1z"></path></svg></button>
                  <label htmlFor="sku" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">SKU</label>
                </div>
                <div class="relative z-0 mb-6 w-full group">
                  <input value={itemName} onChange={(e)=>setItemName(e.target.value)} type="text" name="Item Name" id="Item Name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                  <label htmlFor="Item Name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Item Name</label>
                </div>
                <div class="relative z-0 mb-6 w-full group">
                
                <label htmlFor="Brand" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Brand</label>
                {Object.keys(brandList).length? 
                <select value={selectedBrand} onChange={e => setSelectedBrand(e.target.value)} id="Brand" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  {brandList.map((item, index)=>(
                  <option key={index} value={item.name}>{item.name}</option>
                  ))}
                </select>
                :
                <p class="text-sm text-gray-900 dark:text-white">No Brands added to select</p>}
                </div>
                <div class="relative z-0 mb-6 w-full group">
                <label htmlFor="Item Location" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Item Location</label>
                {Object.keys(locationList).length? 

                <select value={selectedLocation} onChange={e => setSelectedLocation(e.target.value)} id="Brand" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  {locationList.map((item, index)=>(
                  <option key={index} value={item.name || ''}>{item.name}</option>
                  ))}
                </select>
                :
                <p class="text-sm text-gray-900 dark:text-white">No Locations added to select</p>}
                </div>
                <div class="relative z-0 mb-6 w-full group">
                <label htmlFor="Category" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Category</label>
                {Object.keys(categoryList).length? 
                <select value={selectedCategory} onChange={e => setSelectedCategory(e.target.value)} id="Brand" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  {categoryList.map((item, index)=>(
                  <option key={index} value={item.name}>{item.name}</option>
                  ))}
                </select>
                :
                <p class="text-sm text-gray-900 dark:text-white">No Locations added to select</p>}
                </div>
                <div class="relative z-0 mb-6 w-full group">
                  <label htmlFor="size" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Size</label>
                  <div class="flex">
                  <input value={inputSize} onChange={(e)=>setInputSize(e.target.value)} type="text" id="small-input" class="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                  <select value={selectedSize} onChange={e => setSelectedSize(e.target.value)} id="Brand" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    {sizeList.map((item, index)=>(
                    <option key={index} value={item.name}>{item.name}</option>
                    ))}
                  </select>
                </div>
                  <label htmlFor="Unit" class="mt-5 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Unit</label>
                  <div class="flex">
                  <input value={inputUnit} onChange={(e)=>setInputUnit(e.target.value)} type="text" id="small-input" class="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                  <select value={selectedUnits} onChange={e => setSelectedUnits(e.target.value)} id="Unit" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    {unitList.map((item, index)=>(
                    <option key={index} value={item.name}>{item.name}</option>
                    ))}
                  </select>
                </div>
                </div>
                <div class="flex mt-4 space-x-3 md:mt-6">
                <button onClick={()=>cancelEditItem()} type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                    Cancel
                </button>
                <button onClick={()=>submitEditItem()} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800">
                    Update
                </button>
                </div>
              </div>
            </>
          }
        {showEditModal && 
            <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
              <div class="flex items-center">
                <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                <h3 class="text-lg font-medium">Item updated successfully</h3>
              </div>
              <div class="flex">
                <button onClick={()=>cancelEditModal()} type="button" class="text-green-700 bg-transparent border border-green-700 hover:bg-green-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-green-500 dark:border-green-500 dark:text-green-400 dark:hover:text-white dark:focus:ring-green-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                  Close
                </button>
              </div>
            </div>
          }
    </div>
  )
}
