import React from 'react'
import './topnav.scss'

const TopNav = () => {
    const openSidebar = () => {
        document.body.classList.add('sidebar-open')
    }

    return (
        <div class='topnav'>
            <div class="sidebar-toggle" onClick={openSidebar}>
                <i class='bx bx-menu-alt-right'></i>
            </div>
        </div>
    )
}

export default TopNav
