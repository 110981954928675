/* eslint-disable react-hooks/exhaustive-deps */
import { child, get, getDatabase, ref as dref, update } from "firebase/database";
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from "../../contexts/UserStoreContext";
import firebaseapp from '../../firebase';
import ls from 'localstorage-slim';
import { getFirestore, doc, updateDoc } from "firebase/firestore";

export default function Permission({navigation, route}) {
  const dbfirestore = getFirestore(firebaseapp);
  const navigate = useNavigate();
  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);
  const [listDataExist, setListDataExist] = useState(false)
  const [listLoader, setListLoader] = useState(false)
  const [listData, setListData] = useState('');
  const { storeID, setStoreID, countryCode, setCountryCode, staffread, staffupdate } = useUserStore();
  const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);
  const staffListInit = true;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [permissionLoader, setPermissionLoader] = useState(false);
  const [permissionInit, setPermissionInit] = useState(false);
  const [permissionData, setPermissionData] = useState('');
  const [showModal, setShowModal] = useState(false)
 
  if(!storeID){
    setStoreID(userdata.storeID);
  }
    
  if(!countryCode){
    setCountryCode(userdata.country);
  }
  
  useEffect(() => { 
    if(staffListInit){
      function listStaffData(){
        get(child(dbRef, `${storeID}/staff/${storeID}/`)).then(async (snapshot) => {
          const listdata = [];
          if (snapshot.exists()) {
            snapshot.forEach(element => {
              const key = element.key;
              const staffName = element.child('staffName').val(); 
              const email = element.child('email').val(); 
              listdata.push({
                key:key,
                staffName:staffName, 
                email:email
              })
            });
            const completedListData = await Promise.all(listdata);
            setListData(completedListData);
            setListDataExist(true);
            setListLoader(true);
          }
          else{
            setListDataExist(false);
            setListLoader(true);
          }
          
        }).catch((error)=>{
          console.log(error);
        });
     }
     listStaffData();
    }
  }, [staffListInit]);

  useEffect(() => { 
    if(permissionInit){
      function listStaffPermissionData(){
        setPermissionInit(false)
        const key = listData[selectedIndex].key;
        const pData = [];
        get(child(dbRef, `${storeID}/permission/${storeID}/${key}`)).then(async (snapshot) => {
          
          if (snapshot.exists()) {
              const key = snapshot.key;
              const accountscreate = snapshot.child('accountscreate').val();
              const customerscreate = snapshot.child('customerscreate').val();
              const deliverycreate = snapshot.child('deliverycreate').val();
              const settingscreate = snapshot.child('settingscreate').val();
              const collectioncreate = snapshot.child('collectioncreate').val();
              const inventorycreate = snapshot.child('inventorycreate').val();
              const invoicecreate = snapshot.child('invoicecreate').val();
              const laundrycreate = snapshot.child('laundrycreate').val();
              const offerscreate = snapshot.child('offerscreate').val();
              const pckagecreate = snapshot.child('pckagecreate').val();
              const pickupcreate = snapshot.child('pickupcreate').val();
              const pricingcreate = snapshot.child('pricingcreate').val();
              const rechargecreate = snapshot.child('rechargecreate').val();
              const reportscreate = snapshot.child('reportscreate').val();
              const staffcreate = snapshot.child('staffcreate').val();
              const storecreate = snapshot.child('storecreate').val();
              const accountsread = snapshot.child('accountsread').val();
              const customersread = snapshot.child('customersread').val();
              const deliveryread = snapshot.child('deliveryread').val();
              const settingsread = snapshot.child('settingsread').val();
              const collectionread = snapshot.child('collectionread').val();
              const inventoryread = snapshot.child('inventoryread').val();
              const invoiceread = snapshot.child('invoiceread').val();
              const laundryread = snapshot.child('laundryread').val();
              const offersread = snapshot.child('offersread').val();
              const pckageread = snapshot.child('pckageread').val();
              const pickupread = snapshot.child('pickupread').val();
              const pricingread = snapshot.child('pricingread').val();
              const rechargeread = snapshot.child('rechargeread').val();
              const reportsread = snapshot.child('reportsread').val();
              const staffread = snapshot.child('staffread').val();
              const storeread = snapshot.child('storeread').val();
              const accountsupdate = snapshot.child('accountsupdate').val();
              const customersupdate = snapshot.child('customersupdate').val();
              const deliveryupdate = snapshot.child('deliveryupdate').val();
              const settingsupdate = snapshot.child('settingsupdate').val();
              const collectionupdate = snapshot.child('collectionupdate').val();
              const inventoryupdate = snapshot.child('inventoryupdate').val();
              const invoiceupdate = snapshot.child('invoiceupdate').val();
              const laundryupdate = snapshot.child('laundryupdate').val();
              const offersupdate = snapshot.child('offersupdate').val();
              const pckageupdate = snapshot.child('pckageupdate').val();
              const pickupupdate = snapshot.child('pickupupdate').val();
              const pricingupdate = snapshot.child('pricingupdate').val();
              const rechargeupdate = snapshot.child('rechargeupdate').val();
              const reportsupdate = snapshot.child('reportsupdate').val();
              const staffupdate = snapshot.child('staffupdate').val();
              const storeupdate = snapshot.child('storeupdate').val();
              const accountsdelete = snapshot.child('accountsdelete').val();
              const customersdelete = snapshot.child('customersdelete').val();
              const deliverydelete = snapshot.child('deliverydelete').val();
              const settingsdelete = snapshot.child('settingsdelete').val();
              const collectiondelete = snapshot.child('collectiondelete').val();
              const inventorydelete = snapshot.child('inventorydelete').val();
              const invoicedelete = snapshot.child('invoicedelete').val();
              const laundrydelete = snapshot.child('laundrydelete').val();
              const offersdelete = snapshot.child('offersdelete').val();
              const pckagedelete = snapshot.child('pckagedelete').val();
              const pickupdelete = snapshot.child('pickupdelete').val();
              const pricingdelete = snapshot.child('pricingdelete').val();
              const rechargedelete = snapshot.child('rechargedelete').val();
              const reportsdelete = snapshot.child('reportsdelete').val();
              const staffdelete = snapshot.child('staffdelete').val();
              const storedelete = snapshot.child('storedelete').val();
              setPermissionData({
                key:key,
                accountscreate:accountscreate,
                customerscreate:customerscreate,		
                deliverycreate:deliverycreate,
                collectioncreate:collectioncreate,
                inventorycreate:inventorycreate,
                invoicecreate:invoicecreate,
                laundrycreate:laundrycreate,
                offerscreate:offerscreate,
                pckagecreate:pckagecreate,
                pickupcreate:pickupcreate,
                pricingcreate:pricingcreate,
                rechargecreate:rechargecreate,
                reportscreate:reportscreate,
                settingscreate:settingscreate,
                staffcreate:staffcreate,
                storecreate:storecreate,
                accountsread:accountsread,
                customersread:customersread,
                deliveryread:deliveryread,
                settingsread:settingsread,
                collectionread:collectionread,
                inventoryread:inventoryread,
                invoiceread:invoiceread,
                laundryread:laundryread,
                offersread:offersread,
                pckageread:pckageread,
                pickupread:pickupread,
                pricingread:pricingread,
                rechargeread:rechargeread,
                reportsread:reportsread,
                staffread:staffread,
                storeread:storeread,
                accountsupdate:accountsupdate,
                customersupdate:customersupdate,
                deliveryupdate:deliveryupdate,
                settingsupdate:settingsupdate,
                collectionupdate:collectionupdate,
                inventoryupdate:inventoryupdate,
                invoiceupdate:invoiceupdate,
                laundryupdate:laundryupdate,
                offersupdate:offersupdate,
                pckageupdate:pckageupdate,
                pickupupdate:pickupupdate,
                pricingupdate:pricingupdate,
                rechargeupdate:rechargeupdate,
                reportsupdate:reportsupdate,
                staffupdate:staffupdate,
                storeupdate:storeupdate,
                accountsdelete:accountsdelete,
                customersdelete:customersdelete,
                deliverydelete:deliverydelete,
                settingsdelete:settingsdelete,
                collectiondelete:collectiondelete,
                inventorydelete:inventorydelete,
                invoicedelete:invoicedelete,
                laundrydelete:laundrydelete,
                offersdelete:offersdelete,
                pckagedelete:pckagedelete,
                pickupdelete:pickupdelete,
                pricingdelete:pricingdelete,
                rechargedelete:rechargedelete,
                reportsdelete:reportsdelete,
                staffdelete:staffdelete,
                storedelete:storedelete,
              });
            setListLoader(false);
            setPermissionLoader(true);
          }
          else{
            const key = listData[selectedIndex].key;
            pData.push({
              key:key,
              accountscreate:false,
              customerscreate:false,
              deliverycreate:false,
              collectioncreate:false,
              inventorycreate:false,
              invoicecreate:false,
              laundrycreate:false,
              offerscreate:false,
              pckagecreate:false,
              pickupcreate:false,
              pricingcreate:false,
              rechargecreate:false,
              reportscreate:false,
              settingscreate:false,
              staffcreate:false,
              storecreate:false,
              accountsread:false,
              customersread:false,
              deliveryread:false,
              settingsread:false,
              collectionread:false,
              inventoryread:false,
              invoiceread:false,
              laundryread:false,
              offersread:false,
              pckageread:false,
              pickupread:false,
              pricingread:false,
              rechargeread:false,
              reportsread:false,
              staffread:false,
              storeread:false,
              accountsupdate:false,
              customersupdate:false,
              deliveryupdate:false,
              settingsupdate:false,
              collectionupdate:false,
              inventoryupdate:false,
              invoiceupdate:false,
              laundryupdate:false,
              offersupdate:false,
              pckageupdate:false,
              pickupupdate:false,
              pricingupdate:false,
              rechargeupdate:false,
              reportsupdate:false,
              staffupdate:false,
              storeupdate:false,
              accountsdelete:false,
              customersdelete:false,
              deliverydelete:false,
              settingsdelete:false,
              collectiondelete:false,
              inventorydelete:false,
              invoicedelete:false,
              laundrydelete:false,
              offersdelete:false,
              pckagedelete:false,
              pickupdelete:false,
              pricingdelete:false,
              rechargedelete:false,
              reportsdelete:false,
              staffdelete:false,
              storedelete:false,
            })
            setListLoader(false);
            setPermissionData(pData);
            setPermissionLoader(true);
          }
        }).catch((error)=>{
          console.log(error);
        });
     }
     listStaffPermissionData();
    }
  }, [permissionInit]);

  function cancelPermission(){
    setPermissionLoader(false);
    setListLoader(true);
  }

  function submitPermission(){
    const key = listData[selectedIndex].key;
    const email = listData[selectedIndex].email;
    const firestoreRef = doc(dbfirestore, "staff", email);
    const updatefirestore = updateDoc(firestoreRef, {
      accountscreate:permissionData.accountscreate || false,
      customerscreate:permissionData.customerscreate || false,		
      deliverycreate:permissionData.deliverycreate || false,
      collectioncreate:permissionData.collectioncreate || false,
      inventorycreate:permissionData.inventorycreate || false,
      invoicecreate:permissionData.invoicecreate || false,
      laundrycreate:permissionData.laundrycreate || false,
      offerscreate:permissionData.offerscreate || false,
      pckagecreate:permissionData.pckagecreate || false,
      pickupcreate:permissionData.pickupcreate || false,
      pricingcreate:permissionData.pricingcreate || false,
      rechargecreate:permissionData.rechargecreate || false,
      reportscreate:permissionData.reportscreate || false,
      staffcreate:permissionData.staffcreate || false,
      storecreate:permissionData.storecreate || false,
      settingscreate:permissionData.settingscreate || false,
      accountsread:permissionData.accountsread || false,
      customersread:permissionData.customersread || false,
      deliveryread:permissionData.deliveryread || false,
      settingsread:permissionData.settingsread || false,
      collectionread:permissionData.collectionread || false,
      inventoryread:permissionData.inventoryread || false,
      invoiceread:permissionData.invoiceread || false,
      laundryread:permissionData.laundryread || false,
      offersread:permissionData.offersread || false,
      pckageread:permissionData.pckageread || false,
      pickupread:permissionData.pickupread || false,
      pricingread:permissionData.pricingread || false,
      rechargeread:permissionData.rechargeread || false,
      reportsread:permissionData.reportsread || false,
      staffread:permissionData.staffread || false,
      storeread:permissionData.storeread || false,
      accountsupdate:permissionData.accountsupdate || false,
      customersupdate:permissionData.customersupdate || false,
      deliveryupdate:permissionData.deliveryupdate || false,
      settingsupdate:permissionData.settingsupdate || false,
      collectionupdate:permissionData.collectionupdate || false,
      inventoryupdate:permissionData.inventoryupdate || false,
      invoiceupdate:permissionData.invoiceupdate || false,
      laundryupdate:permissionData.laundryupdate || false,
      offersupdate:permissionData.offersupdate || false,
      pckageupdate:permissionData.pckageupdate || false,
      pickupupdate:permissionData.pickupupdate || false,
      pricingupdate:permissionData.pricingupdate || false,
      rechargeupdate:permissionData.rechargeupdate || false,
      reportsupdate:permissionData.reportsupdate || false,
      staffupdate:permissionData.staffupdate || false,
      storeupdate:permissionData.storeupdate || false,
      accountsdelete:permissionData.accountsdelete || false,
      customersdelete:permissionData.customersdelete || false,
      deliverydelete:permissionData.deliverydelete || false,
      settingsdelete:permissionData.settingsdelete || false,
      collectiondelete:permissionData.collectiondelete || false,
      inventorydelete:permissionData.inventorydelete || false,
      invoicedelete:permissionData.invoicedelete || false,
      laundrydelete:permissionData.laundrydelete || false,
      offersdelete:permissionData.offersdelete || false,
      pckagedelete:permissionData.pckagedelete || false,
      pickupdelete:permissionData.pickupdelete || false,
      pricingdelete:permissionData.pricingdelete || false,
      rechargedelete:permissionData.rechargedelete || false,
      reportsdelete:permissionData.reportsdelete || false,
      staffdelete:permissionData.staffdelete || false,
      storedelete:permissionData.storedelete || false,
    });
    const updatefirebase = update(dref(dbr, storeID+'/permission/' + storeID+'/'+key), {
      accountscreate:permissionData.accountscreate || false,
      customerscreate:permissionData.customerscreate || false,		
      deliverycreate:permissionData.deliverycreate || false,
      collectioncreate:permissionData.collectioncreate || false,
      inventorycreate:permissionData.inventorycreate || false,
      invoicecreate:permissionData.invoicecreate || false,
      laundrycreate:permissionData.laundrycreate || false,
      offerscreate:permissionData.offerscreate || false,
      pckagecreate:permissionData.pckagecreate || false,
      pickupcreate:permissionData.pickupcreate || false,
      pricingcreate:permissionData.pricingcreate || false,
      rechargecreate:permissionData.rechargecreate || false,
      reportscreate:permissionData.reportscreate || false,
      staffcreate:permissionData.staffcreate || false,
      storecreate:permissionData.storecreate || false,
      settingscreate:permissionData.settingscreate || false,
      accountsread:permissionData.accountsread || false,
      customersread:permissionData.customersread || false,
      deliveryread:permissionData.deliveryread || false,
      settingsread:permissionData.settingsread || false,
      collectionread:permissionData.collectionread || false,
      inventoryread:permissionData.inventoryread || false,
      invoiceread:permissionData.invoiceread || false,
      laundryread:permissionData.laundryread || false,
      offersread:permissionData.offersread || false,
      pckageread:permissionData.pckageread || false,
      pickupread:permissionData.pickupread || false,
      pricingread:permissionData.pricingread || false,
      rechargeread:permissionData.rechargeread || false,
      reportsread:permissionData.reportsread || false,
      staffread:permissionData.staffread || false,
      storeread:permissionData.storeread || false,
      accountsupdate:permissionData.accountsupdate || false,
      customersupdate:permissionData.customersupdate || false,
      deliveryupdate:permissionData.deliveryupdate || false,
      settingsupdate:permissionData.settingsupdate || false,
      collectionupdate:permissionData.collectionupdate || false,
      inventoryupdate:permissionData.inventoryupdate || false,
      invoiceupdate:permissionData.invoiceupdate || false,
      laundryupdate:permissionData.laundryupdate || false,
      offersupdate:permissionData.offersupdate || false,
      pckageupdate:permissionData.pckageupdate || false,
      pickupupdate:permissionData.pickupupdate || false,
      pricingupdate:permissionData.pricingupdate || false,
      rechargeupdate:permissionData.rechargeupdate || false,
      reportsupdate:permissionData.reportsupdate || false,
      staffupdate:permissionData.staffupdate || false,
      storeupdate:permissionData.storeupdate || false,
      accountsdelete:permissionData.accountsdelete || false,
      customersdelete:permissionData.customersdelete || false,
      deliverydelete:permissionData.deliverydelete || false,
      settingsdelete:permissionData.settingsdelete || false,
      collectiondelete:permissionData.collectiondelete || false,
      inventorydelete:permissionData.inventorydelete || false,
      invoicedelete:permissionData.invoicedelete || false,
      laundrydelete:permissionData.laundrydelete || false,
      offersdelete:permissionData.offersdelete || false,
      pckagedelete:permissionData.pckagedelete || false,
      pickupdelete:permissionData.pickupdelete || false,
      pricingdelete:permissionData.pricingdelete || false,
      rechargedelete:permissionData.rechargedelete || false,
      reportsdelete:permissionData.reportsdelete || false,
      staffdelete:permissionData.staffdelete || false,
      storedelete:permissionData.storedelete || false,
    });
    
    const finalUpdate = [updatefirebase, updatefirestore];
    const finalPromise = Promise.all(finalUpdate)
    finalPromise.then(()=>{
      setPermissionLoader(false);
      setShowModal(true);
    })
  }

  function afterPermissionUpdate(){
    setShowModal(false);
    setListLoader(true);
  }

  return (
    <div>
      <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <Link to='/staff' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              Staff
            </Link>
            </div>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Permission</span>
            </div>
          </li>
        </ol>
      </nav>
      {listLoader &&
        <>
          {listDataExist? 
          <div class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div class="mb-5 ml-5">
              <h5 class="mb-5 mt-5 text-xl font-medium text-gray-900 dark:text-white">Permission</h5>
              <label htmlFor="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Staff</label>
              {listData.map((staff, index)=>( 
                <div key={index} class="flex items-center mr-4">
                  <input checked={selectedIndex === index} onChange={()=>setSelectedIndex(index)} id="inline-radio" type="radio" name="inline-radio-group" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                  <label htmlFor="inline-radio" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{staff.staffName}</label>
                </div>
              ))}
              {staffread && <button onClick={()=>setPermissionInit(true)} type="button" class="mt-5 mb-5 text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">View Permission</button>}
              
            </div>
          </div>
          :
          <div id="alert" class="p-4 mb-4 text-yellow-700 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800" role="alert">
              <div class="flex items-center">
                <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                <h3 class="text-lg font-medium">You have not added staff</h3>
              </div>
              <div class="mt-2 mb-4 text-sm">
                Please continue permission settings after creating staff 
              </div>
              <div class="flex">
                <button onClick={()=>navigate("/staff")} type="button" class="text-yellow-700 bg-transparent border border-yellow-700 hover:bg-yellow-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-yellow-500 dark:border-yellow-500 dark:text-yellow-400 dark:hover:text-white dark:focus:ring-yellow-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                  ok
                </button>
              </div>
            </div>
          }
        </>
      }
      {permissionLoader &&
        <div class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
            <div class="flex items-center justify-between">
            <p class="mb-5 mt-5 ml-5 text-xl text-gray-900 dark:text-white">{listData[selectedIndex].staffName} - Permission Settings</p>
            </div>
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="py-3 px-6">
                          Module
                      </th>
                      <th scope="col" class="py-3 px-6">
                          Read
                      </th>
                      <th scope="col" class="py-3 px-6">
                          Create
                      </th>
                      <th scope="col" class="py-3 px-6">
                          Modify
                      </th>
                      <th scope="col" class="py-3 px-6">
                          Delete
                      </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <p>Accounts</p>
                    </th>
                    <td class="py-4 px-6">
                      <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.accountsread} class="sr-only peer" onChange={()=>setPermissionData({...permissionData, accountsread:!permissionData.accountsread})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                      </label>
                    </td>
                    <td class="py-4 px-6">
                      {permissionData.accountsread ?
                        <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" class="sr-only peer" checked={permissionData.accountscreate} onChange={()=>setPermissionData({...permissionData, accountscreate:!permissionData.accountscreate})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                        </label>
                        :
                        <></>
                      }
                    </td>
                    
                    <td class="py-4 px-6">
                    {permissionData.accountsread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.accountsupdate} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, accountsupdate:!permissionData.accountsupdate})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                    <td class="py-4 px-6">
                    {permissionData.accountsread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.accountsdelete} class="sr-only peer"   onChange={()=>setPermissionData({...permissionData, accountsdelete:!permissionData.accountsdelete})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <p>Customers</p>
                    </th>
                    <td class="py-4 px-6">
                      <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.customersread}  class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, customersread:!permissionData.customersread})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                      </label>
                    </td>
                    <td class="py-4 px-6">
                      {permissionData.customersread ?
                        <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.customerscreate} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, customerscreate:!permissionData.customerscreate})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                        </label>
                        :
                        <></>
                      }
                    </td>
                    
                    <td class="py-4 px-6">
                    {permissionData.customersread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.customersupdate} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, customersupdate:!permissionData.customersupdate})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                    <td class="py-4 px-6">
                    {permissionData.customersread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.customersdelete} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, customersdelete:!permissionData.customersdelete})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <p>Delivery</p>
                    </th>
                    <td class="py-4 px-6">
                      <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.deliveryread} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, deliveryread:!permissionData.deliveryread})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                      </label>
                    </td>
                    <td class="py-4 px-6">
                      {permissionData.deliveryread ?
                        <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.deliverycreate} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, deliverycreate:!permissionData.deliverycreate})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                        </label>
                        :
                        <></>
                      }
                    </td>
                    
                    <td class="py-4 px-6">
                    {permissionData.deliveryread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.deliveryupdate} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, deliveryupdate:!permissionData.deliveryupdate})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                    <td class="py-4 px-6">
                    {permissionData.deliveryread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.deliverydelete} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, deliverydelete:!permissionData.deliverydelete})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                  </tr>

                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <p>Collection</p>
                    </th>
                    <td class="py-4 px-6">
                      <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.collectionread}   class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, collectionread:!permissionData.collectionread})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                      </label>
                    </td>
                    <td class="py-4 px-6">
                      {permissionData.collectionread ?
                        <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.collectioncreate} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, collectioncreate:!permissionData.collectioncreate})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                        </label>
                        :
                        <></>
                      }
                    </td>
                    
                    <td class="py-4 px-6">
                    {permissionData.collectionread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.collectionupdate} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, collectionupdate:!permissionData.collectionupdate})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                    <td class="py-4 px-6">
                    {permissionData.collectionread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.collectiondelete} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, collectiondelete:!permissionData.collectiondelete})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                  </tr>


                  
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <p>Inventory</p>
                    </th>
                    <td class="py-4 px-6">
                      <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.inventoryread}   class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, inventoryread:!permissionData.inventoryread})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                      </label>
                    </td>
                    <td class="py-4 px-6">
                      {permissionData.inventoryread ?
                        <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.inventorycreate} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, inventorycreate:!permissionData.inventorycreate})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                        </label>
                        :
                        <></>
                      }
                    </td>
                    
                    <td class="py-4 px-6">
                    {permissionData.inventoryread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.inventoryupdate} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, inventoryupdate:!permissionData.inventoryupdate})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                    <td class="py-4 px-6">
                    {permissionData.inventoryread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.inventorydelete} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, inventorydelete:!permissionData.inventorydelete})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <p>Invoice</p>
                    </th>
                    <td class="py-4 px-6">
                      <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.invoiceread} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, invoiceread:!permissionData.invoiceread})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                      </label>
                    </td>
                    <td class="py-4 px-6">
                      {permissionData.invoiceread ?
                        <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.invoicecreate} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, invoicecreate:!permissionData.invoicecreate})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                        </label>
                        :
                        <></>
                      }
                    </td>
                    
                    <td class="py-4 px-6">
                    {permissionData.invoiceread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.invoiceupdate} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, invoiceupdate:!permissionData.invoiceupdate})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                    <td class="py-4 px-6">
                    {permissionData.invoiceread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.invoicedelete} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, invoicedelete:!permissionData.invoicedelete})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <p>Laundry</p>
                    </th>
                    <td class="py-4 px-6">
                      <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.laundryread} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, laundryread:!permissionData.laundryread})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                      </label>
                    </td>
                    <td class="py-4 px-6">
                      {permissionData.laundryread ?
                        <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.laundrycreate} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, laundrycreate:!permissionData.laundrycreate})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                        </label>
                        :
                        <></>
                      }
                    </td>
                    
                    <td class="py-4 px-6">
                    {permissionData.laundryread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.laundryupdate} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, laundryupdate:!permissionData.laundryupdate})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                    <td class="py-4 px-6">
                    {permissionData.laundryread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.laundrydelete} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, laundrydelete:!permissionData.laundrydelete})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <p>Offers</p>
                    </th>
                    <td class="py-4 px-6">
                      <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.offersread} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, offersread:!permissionData.offersread})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                      </label>
                    </td>
                    <td class="py-4 px-6">
                      {permissionData.offersread ?
                        <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.offerscreate} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, offerscreate:!permissionData.offerscreate})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                        </label>
                        :
                        <></>
                      }
                    </td>
                    
                    <td class="py-4 px-6">
                    {permissionData.offersread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.offersupdate} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, offersupdate:!permissionData.offersupdate})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                    <td class="py-4 px-6">
                    {permissionData.offersread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.offersdelete} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, offersdelete:!permissionData.offersdelete})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <p>Package</p>
                    </th>
                    <td class="py-4 px-6">
                      <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.pckageread} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, pckageread:!permissionData.pckageread})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                      </label>
                    </td>
                    <td class="py-4 px-6">
                      {permissionData.pckageread ?
                        <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.pckagecreate} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, pckagecreate:!permissionData.pckagecreate})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                        </label>
                        :
                        <></>
                      }
                    </td>
                    
                    <td class="py-4 px-6">
                    {permissionData.pckageread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.pckageupdate} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, pckageupdate:!permissionData.pckageupdate})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                    <td class="py-4 px-6">
                    {permissionData.pckageread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.pckagedelete} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, pckagedelete:!permissionData.pckagedelete})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    <p>Pickup</p>
                  </th>
                  <td class="py-4 px-6">
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.pickupread} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, pickupread:!permissionData.pickupread})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                  </td>
                  <td class="py-4 px-6">
                    {permissionData.pickupread ?
                      <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.pickupcreate} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, pickupcreate:!permissionData.pickupcreate})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                      </label>
                      :
                      <></>
                    }
                  </td>
                  
                  <td class="py-4 px-6">
                  {permissionData.pickupread ?
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" checked={permissionData.pickupupdate} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, pickupupdate:!permissionData.pickupupdate})}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                  </label>
                  :
                      <></>
                    }
                  </td>
                  <td class="py-4 px-6">
                  {permissionData.pickupread ?
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" checked={permissionData.pickupdelete} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, pickupdelete:!permissionData.pickupdelete})}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                  </label>
                  :
                      <></>
                    }
                  </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    <p>Pricing</p>
                  </th>
                  <td class="py-4 px-6">
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.pricingread} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, pricingread:!permissionData.pricingread})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                  </td>
                  <td class="py-4 px-6">
                    {permissionData.pricingread ?
                      <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.pricingcreate} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, pricingcreate:!permissionData.pricingcreate})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                      </label>
                      :
                      <></>
                    }
                  </td>
                  
                  <td class="py-4 px-6">
                  {permissionData.pricingread ?
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" checked={permissionData.pricingupdate} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, pricingupdate:!permissionData.pricingupdate})}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                  </label>
                  :
                      <></>
                    }
                  </td>
                  <td class="py-4 px-6">
                  {permissionData.pricingread ?
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" checked={permissionData.pricingdelete} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, pricingdelete:!permissionData.pricingdelete})}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                  </label>
                  :
                      <></>
                    }
                  </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <p>Recharge</p>
                    </th>
                    <td class="py-4 px-6">
                      <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.rechargeread} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, rechargeread:!permissionData.rechargeread})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                      </label>
                    </td>
                    <td class="py-4 px-6">
                      {permissionData.rechargeread ?
                        <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.rechargecreate} class="sr-only peer" onChange={()=>setPermissionData({...permissionData, rechargecreate:!permissionData.rechargecreate})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                        </label>
                        :
                        <></>
                      }
                    </td>
                    
                    <td class="py-4 px-6">
                    {permissionData.rechargeread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.rechargeupdate} class="sr-only peer" onChange={()=>setPermissionData({...permissionData, rechargeupdate:!permissionData.rechargeupdate})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                    <td class="py-4 px-6">
                    {permissionData.rechargeread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.rechargedelete} class="sr-only peer" onChange={()=>setPermissionData({...permissionData, rechargedelete:!permissionData.rechargedelete})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <p>Reports</p>
                    </th>
                    <td class="py-4 px-6">
                      <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.reportsread} class="sr-only peer" onChange={()=>setPermissionData({...permissionData, reportsread:!permissionData.reportsread})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                      </label>
                    </td>
                    <td class="py-4 px-6">
                      {permissionData.reportsread ?
                        <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.reportscreate} class="sr-only peer" onChange={()=>setPermissionData({...permissionData, reportscreate:!permissionData.reportscreate})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                        </label>
                        :
                        <></>
                      }
                    </td>
                    
                    <td class="py-4 px-6">
                    {permissionData.reportsread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.reportsupdate} class="sr-only peer" onChange={()=>setPermissionData({...permissionData, reportsupdate:!permissionData.reportsupdate})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                    <td class="py-4 px-6">
                    {permissionData.reportsread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.reportsdelete} class="sr-only peer" onChange={()=>setPermissionData({...permissionData, reportsdelete:!permissionData.reportsdelete})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                  </tr>

                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <p>Settings</p>
                    </th>
                    <td class="py-4 px-6">
                      <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.settingsread}   class="sr-only peer" onChange={()=>setPermissionData({...permissionData, settingsread:!permissionData.settingsread})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                      </label>
                    </td>
                    <td class="py-4 px-6">
                      {permissionData.settingsread ?
                        <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.settingscreate} class="sr-only peer" onChange={()=>setPermissionData({...permissionData, settingscreate:!permissionData.settingscreate})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                        </label>
                        :
                        <></>
                      }
                    </td>
                    
                    <td class="py-4 px-6">
                    {permissionData.settingsread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.settingsupdate} class="sr-only peer" onChange={()=>setPermissionData({...permissionData, settingsupdate:!permissionData.settingsupdate})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                    <td class="py-4 px-6">
                    {permissionData.settingsread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.settingsdelete} class="sr-only peer" onChange={()=>setPermissionData({...permissionData, settingsdelete:!permissionData.settingsdelete})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                  </tr>





                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <p>Staff</p>
                    </th>
                    <td class="py-4 px-6">
                      <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.staffread} class="sr-only peer" onChange={()=>setPermissionData({...permissionData, staffread:!permissionData.staffread})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                      </label>
                    </td>
                    <td class="py-4 px-6">
                      {permissionData.staffread ?
                        <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.staffcreate} class="sr-only peer" onChange={()=>setPermissionData({...permissionData, staffcreate:!permissionData.staffcreate})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                        </label>
                        :
                        <></>
                      }
                    </td>
                    
                    <td class="py-4 px-6">
                    {permissionData.staffread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.staffupdate} class="sr-only peer" onChange={()=>setPermissionData({...permissionData, staffupdate:!permissionData.staffupdate})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                    <td class="py-4 px-6">
                    {permissionData.staffread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.staffdelete} class="sr-only peer" onChange={()=>setPermissionData({...permissionData, staffdelete:!permissionData.staffdelete})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <p>Store</p>
                    </th>
                    <td class="py-4 px-6">
                      <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.storeread} class="sr-only peer" onChange={()=>setPermissionData({...permissionData, storeread:!permissionData.storeread})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                      </label>
                    </td>
                    <td class="py-4 px-6">
                      {permissionData.storeread ?
                        <label class="relative inline-flex items-center mr-5 cursor-pointer">
                        <input type="checkbox" checked={permissionData.storecreate} class="sr-only peer" onChange={()=>setPermissionData({...permissionData, storecreate:!permissionData.storecreate})}/>
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                        </label>
                        :
                        <></>
                      }
                    </td>
                    
                    <td class="py-4 px-6">
                    {permissionData.storeread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.storeupdate} class="sr-only peer" onChange={()=>setPermissionData({...permissionData, storeupdate:!permissionData.storeupdate})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                    <td class="py-4 px-6">
                    {permissionData.storeread ?
                    <label class="relative inline-flex items-center mr-5 cursor-pointer">
                      <input type="checkbox" checked={permissionData.storedelete} class="sr-only peer"  onChange={()=>setPermissionData({...permissionData, storedelete:!permissionData.storedelete})}/>
                      <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    </label>
                    :
                        <></>
                      }
                    </td>
                  </tr>
                </tbody>
                
            </table>
            <div class="flex mb-5 mt-5 ml-5 space-x-3 md:mt-6">
                  <button onClick={()=>cancelPermission()} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
                  {staffupdate && <button onClick={()=>submitPermission()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>}
                </div>
          </div>
        </div>
      }  
      {showModal && 
        <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Permission updated successfully</h3>
          </div>
          <div class="flex">
            <button onClick={()=>afterPermissionUpdate()} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              ok
            </button>
          </div>
        </div>
      }  
    </div>
    
  );
}

