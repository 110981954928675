/* eslint-disable react-hooks/exhaustive-deps */
 import { child, get, getDatabase, push, ref as dref, set, update } from "firebase/database";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUserStore } from "../contexts/UserStoreContext";
import firebaseapp from '../firebase';
import ls from 'localstorage-slim';

export default function Help() {
  
    
  const { storeID, setStoreID, countryCode, setCountryCode } = useUserStore();
  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);
  const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);
  const [query, setQuery] = useState('');
  const [filterListData, setFilterListData] = useState('');
  if(!storeID){
    setStoreID(userdata.storeID);
  }

  if(!countryCode){
    setCountryCode(userdata.country);
  }

  const [loader, setLoader] = useState(true);
  const [contactLoader, setContactLoader] = useState(false);
  const [touchLoader, setTouchLoader] = useState(false);
  const [videoLoader, setVideoLoader] = useState(false);
  const [supportTicketLoader, setSupportTicketLoader] = useState(false);
  const [supportTicketInit, setSupportTicketInit] = useState(false)
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('open');
  const [dataExist, setDataExist] = useState(false);
  const [listData, setListData] = useState([]);
  const [date, setDate] = useState(moment(new Date()).toISOString());
  const [openVisible, setOpenVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const [showModal, setShowModal] = useState(false)
  
  useEffect(() => {
    if(supportTicketInit){
      function loaditems(){
        setSupportTicketInit(false);
        get(child(dbRef, `${storeID}/help/${storeID}/`)).then((snapshot) => {
          if (snapshot.exists()) {
          const List = [];
          snapshot.forEach(element => {
            const key = element.key;
            const date = element.child('date').val();
            const message = element.child('message').val();
            const status = element.child('status').val();
            const title = element.child('title').val();
            List.push({key:key, date:date, message:message, status:status, title:title })

          });
          
          const dataExist = true;
          return [dataExist, List];
          }
          else{
            const dataExist = false;
            return [dataExist, 0]
            
          }
        }).then((dataExist)=>{
          if(dataExist[0]){
            setListData(dataExist[1]);
            setFilterListData(dataExist[1]);
            setDataExist(true);
            setSupportTicketLoader(true);
          }
          else{
            setDataExist(false);
            setSupportTicketLoader(true);
          }
        }); 
      }
      loaditems();

    }
    
  }, [supportTicketInit]);

  function contactusPressed(){
    setLoader(false);
    setTitle('');
    setMessage('');
    setContactLoader(true);
    
  }

  function getintouchPressed(){
    setLoader(false);
    setTouchLoader(true);
  }
  
  function videoPressed(){
    setLoader(false);
    setVideoLoader(true);
  }

  function stLoader(){
    setLoader(false);
    setSupportTicketInit(true);
  }

  

  function backtoLoader(){
    setContactLoader(false);
    setTouchLoader(false);
    setVideoLoader(false);
    setSupportTicketInit(false);
    setSupportTicketLoader(false);
    setLoader(true);
  }

  function submitTicket(){
    if(title.length && message.length){
      const pushId = push(child(dref(dbr), 'help')).key;
      set(dref(dbr, storeID+'/help/' + storeID+'/'+pushId), {
        title:title,
        message:message,
        status:status,
        date: date
      }).then(()=>{
        // Alert.alert(
        //   'Thank You. The form was submitted successfully'
        // )
        setContactLoader(false);
        setLoader(true);
      })

    }
    else{
      // Alert.alert(
      //   '"Title or message cannot be empty"'
      // )
    }
    
  }

  function newSupport(){
    setDataExist(false);
    setSupportTicketLoader(false);
    setContactLoader(true);
  }

  function itemPressed(itm){

    setSelectedItem(itm);

    if(itm.status === 'open'){
      setOpenVisible(true);
    }
    setSupportTicketLoader(false);
    setShowModal(true);
    // if(itm.status === 'open'){
    //   Alert.alert("Your ticket is in open state.", "Our support team will contact you", [
    //     {
    //       text: "Cancel",
    //       onClick: () => null,
    //       style: "cancel"
    //     },
    //     { text: "Delete", onClick: () => deleteTicket(itm)},
    //     { text: "Close", onClick: () => closeTicket(itm)},
    //     { text: "Edit", onClick: () => editOpenTicket(itm)},
    //   ]);
      
    //   // Alert.alert("Your ticket is already in open state. Our support team will contact you", [
    //   //   {
    //   //     text: "Cancel",
    //   //     onClick: () => null,
    //   //     style: "cancel"
    //   //   },
    //   //   { text: "Delete", onClick: () => deleteTicket(itm)},
    //   //   { text: "Close", onClick: () => closeTicket(itm)},
    //   //   { text: "Edit", onClick: () => editOpenTicket(itm)},

    //   // ]);

      



    // }else{
    //   // Alert.alert("Your ticket is in closed state.", [
    //   //   {
    //   //     text: "Cancel",
    //   //     onClick: () => null,
    //   //     style: "cancel"
    //   //   },
    //   //   { text: "Delete", onClick: () => deleteTicket(itm) },
    //   //   { text: "Re-open", onClick: () => editOpenTicket(itm) },

    //   // ]);
    // }
  }

  function deleteTicket(){
    setShowModal(false);
    setOpenVisible(false);
    const key = selectedItem.key;
    set(dref(dbr, storeID+'/help/' + storeID+'/'+key), {
      null:null
    }).then(()=>{
      setSupportTicketInit(false);
      setDataExist(false);
      setSupportTicketLoader(false);
      setLoader(true);
    })
  }

  function editOpenTicket(){
    setShowModal(false);
    setOpenVisible(false);
    const key = selectedItem.key;
    update(dref(dbr, storeID+'/help/' + storeID+'/'+key), {
      title:selectedItem.title,
      message:selectedItem.message,
      status:'open',
      date: selectedItem.date
    }).then(()=>{
      // Alert.alert(
      //   'Thank You. Ticket closed successfully'
      // )
      setSupportTicketInit(false);
      setDataExist(false);
      setSupportTicketLoader(false);
      setLoader(true);
    })

  }

  function closeTicket(){
    setShowModal(false);
    setOpenVisible(false);
    const key = selectedItem.key;
    set(dref(dbr, storeID+'/help/' + storeID+'/'+key), {
      title:selectedItem.title,
      message:selectedItem.message,
      status:'closed',
      date: selectedItem.date
    }).then(()=>{
      // Alert.alert(
      //   'Thank You. Ticket closed successfully'
      // )
      setSupportTicketInit(false);
      setDataExist(false);
      setSupportTicketLoader(false);
      setLoader(true);
    })
  }

  function editTicket(){
    setShowModal(false)
    setSupportTicketInit(false);
    setOpenVisible(false);
    setTitle(selectedItem.title);
    setMessage(selectedItem.message);
    setStatus('open');
    setDate(selectedItem.date);
    setDataExist(false);
    setSupportTicketLoader(false);
    setContactLoader(true);

  }

  function cancelTicket(){
    setShowModal(false);
    setSupportTicketLoader(true);
  }

  const ButtonMailto = ({ mailto, label }) => {
    return (
        <Link
            to='mailto:contact@tchzo.com'
            onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
            }}
        >
            {label}
        </Link>
    );
  };

  function handleSearch(e){
    setQuery(e);
    const elow = e.toLowerCase();
    const fData = listData.filter(item => {
      const dt = moment(item.date).format("DD/MMM/YYYY")
      
      
      return (
        (item.status && item.status.toLowerCase().includes(elow)) ||
        (item.title && item.title.toLowerCase().includes(elow)) ||
        (dt && dt.toLowerCase().includes(elow))

        );
    }
    );
    setFilterListData(fData);
  };

  return (
    <>
    <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Help</span>
            </div>
          </li>
        </ol>
      </nav>
      {loader && 
        <div class="mt-10 grid grid-cols-2 gap-10">
            <button onClick={()=>contactusPressed()} class="block max-w-sm p-10 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              Feedback
            </button>
            <button onClick={()=>stLoader()} class="block max-w-sm p-10 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            Support Tickets
            </button>
            <button onClick={()=>getintouchPressed()} class="block max-w-sm p-10 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            Get in touch 
            </button>
            <button onClick={()=>videoPressed()} class="block max-w-sm p-10 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            Videos & knowledgebase
            </button>
        </div>
      }
      {contactLoader &&
        <div class="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
            <h5 class="text-xl font-medium text-gray-900 dark:text-white">Feedback/Suggestion/Complaints</h5>
                <div>
                    <label htmlFor="email" class="block mt-5 mb-2 text-sm font-medium text-gray-900 dark:text-white">Title</label>
                    <input value={title} onChange={(e)=>setTitle(e.target.value)} type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="title" required />
                </div>
                <div>
                    <label htmlFor="message" class="block mt-5  mb-2 text-sm font-medium text-gray-900 dark:text-white">Your message</label>
                    <textarea value={message} onChange={(e)=>setMessage(e.target.value)} id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your thoughts here..."></textarea>
                </div>
                <div class="flex mt-4 space-x-3 md:mt-6">
                <button onClick={()=>backtoLoader()} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
                <button onClick={()=>submitTicket()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>
                
                </div>
        </div>
      }
      {videoLoader &&
        <section class="bg-white dark:bg-gray-900">
            <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16">
                <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">Coming Soon...</h1>
                <button onClick={()=>backtoLoader()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Back</button>
            </div>
        </section>
      }
      {touchLoader &&
        <div class="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
          <h5 class="mt-5 text-xl font-medium text-gray-900 dark:text-white">GET IN TOUCH</h5>
          <div class="block mt-5">
            <label htmlFor="email" class="block text-sm font-medium text-gray-900 dark:text-white">Email:</label>
            <ButtonMailto label="contact@tchzo.com" mailto="mailto:contact@tchzo.com" />
          </div>
          <div>
            <label htmlFor="password" class="mt-5 block text-sm font-medium text-gray-900 dark:text-white">Phone:</label>
            <label htmlFor="password">+91 80 49563512</label>
          </div>
          <div>
            <label htmlFor="password" class="block mt-5 text-sm font-medium text-gray-900 dark:text-white">Address:</label>
            <p htmlFor="password" >1569, 19th Main, AECS A Block</p>
            <p htmlFor="password" >Singasandra,  Bangalore</p>
            <p htmlFor="password" >Karnataka India</p>
          </div>
          <div>
          <button onClick={()=>backtoLoader()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Back</button>
          </div>
        </div>
      }
      {supportTicketLoader &&
        <>
          {dataExist? 
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
              <div class="flex items-center justify-between">
              <div class="flex items-center justify-between"></div>
              <p class="mb-5 ml-5 text-xl text-gray-900 dark:text-white">Support tickets</p>
              <button onClick={() => newSupport()} type="button" class="text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">New Ticket</button>
              </div>
              <div id="accordion-collapse" data-accordion="collapse"></div>  
              <div class="relative z-0 mb-6 w-1/2 ml-5 mr-5 group ">
                <input value={query} onChange={(e)=>handleSearch(e.target.value)} type="text" name="sku" id="sku" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                <label htmlFor="sku" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Search</label>
              </div>
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="py-3 px-6">
                          Date
                      </th>
                      <th scope="col" class="py-3 px-6">
                          Title
                      </th>
                      <th scope="col" class="py-3 px-6">
                          Status
                      </th>
                      <th scope="col" class="py-3 px-6">
                          Action
                      </th>
                  </tr>
                </thead>
                <tbody>
                  {filterListData.map((item, key)=>(
                    <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <p class="text-sm text-gray-900 dark:text-white">{moment(item.date).format('DD/MMM/YYYY')}</p>
                      </th>
                      <td class="py-4 px-6">
                        {item.title}
                      </td>
                      <td class="py-4 px-6">
                      {item.status}
                      </td>
                      <td class="py-4 px-6">
                        <button onClick={()=>itemPressed(item)} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center mr-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-blue-800">
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}   
                </tbody> 
                </table>     
              </div>
            :
            <div id="alert" class="p-4 mb-4 text-yellow-700 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800" role="alert">
              <div class="flex items-center">
                <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                <h3 class="text-lg font-medium">You have no support tickets.</h3>
              </div>
              <div class="mt-2 mb-4 text-sm">
                Create Ticket?
              </div>
              <div class="flex">
                <button onClick={() => newSupport()} type="button" class="text-white bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800">
                  <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
                  Yes
                </button>
                <button onClick={()=>backtoLoader()} type="button" class="text-yellow-700 bg-transparent border border-yellow-700 hover:bg-yellow-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-yellow-500 dark:border-yellow-500 dark:text-yellow-400 dark:hover:text-white dark:focus:ring-yellow-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                  Dismiss
                </button>
              </div>
            </div>
          } 
        </>
      }
      {showModal &&
        <>
        {openVisible? 
          <div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div class="p-5 flex items-center justify-between">
              <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Ticket Details</label>
              <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{moment(date).format("DD/MMM/YYYY")}</label>
            </div>
            <div class="p-5">
              <p class="text-sm font-medium text-gray-900 truncate dark:text-white">Title:</p>
                <p class="text-sm text-gray-500 dark:text-gray-400">{selectedItem.title}</p>
                <p class="mt-5 text-sm font-medium text-gray-900 truncate dark:text-white">Message:</p>
                <p class="text-sm text-gray-500 dark:text-gray-400">{selectedItem.message}</p>
            </div>    
            
            <div class="flex flex-col items-center pb-10">
                <h5 class="mt-5 mb-1 text-xl font-medium text-gray-900 dark:text-white">Support Ticket is in open state</h5>
                <span class="text-sm text-gray-500 dark:text-gray-400">Do you want your ticket to be?</span>
                <div class="mt-5 inline-flex rounded-md shadow-sm" role="group">
                  <button onClick={() => cancelTicket(false)} type="button" class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                    Canceled
                  </button>
                  <button onClick={() => deleteTicket()} type="button" class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                    Deleted
                  </button>
                  <button onClick={() => closeTicket()} type="button" class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                    Closed
                  </button>
                  <button onClick={() => editTicket()} type="button" class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                    Edited
                  </button>
                </div>

            </div>
        </div>
        :
        <div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div class="p-5 flex items-center justify-between">
              <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Ticket Details</label>
              <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{moment(date).format("DD/MMM/YYYY")}</label>
            </div>
            <div class="p-5">
              <p class="text-sm font-medium text-gray-900 truncate dark:text-white">Title:</p>
                <p class="text-sm text-gray-500 dark:text-gray-400">{selectedItem.title}</p>
                <p class="mt-5 text-sm font-medium text-gray-900 truncate dark:text-white">Message:</p>
                <p class="text-sm text-gray-500 dark:text-gray-400">{selectedItem.message}</p>
            </div>    
            
            <div class="flex flex-col items-center pb-10">
                <h5 class="mt-5 mb-1 text-xl font-medium text-gray-900 dark:text-white">Support Ticket is in closed state</h5>
                <span class="text-sm text-gray-500 dark:text-gray-400">Do you want your ticket to be?</span>
                <div class="mt-5 inline-flex rounded-md shadow-sm" role="group">
                  <button onClick={() => cancelTicket(false)} type="button" class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                    Canceled
                  </button>
                  <button onClick={() => deleteTicket()} type="button" class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                    Deleted
                  </button>
                  <button onClick={() => editOpenTicket()} type="button" class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                    Re-opened
                  </button>
                  <button onClick={() => editTicket()} type="button" class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                    Edited
                  </button>
                </div>

            </div>
        </div>
        }
        
        </>
      }
    </>
  );
}
 