/* eslint-disable react-hooks/exhaustive-deps */
import { child, get, getDatabase, ref as dref, set, update } from "firebase/database";
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUserStore } from "../../contexts/UserStoreContext";
import firebaseapp from '../../firebase';
import ls from 'localstorage-slim';

export default function Laundryservices() {
  
  const { storeID, setStoreID, countryCode, setCountryCode, settingsupdate, settingscreate, settingsdelete } = useUserStore();
  const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);
  if(!storeID){
      setStoreID(userdata.storeID);
    }
  
  if(!countryCode){
    setCountryCode(userdata.country);
  }

  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);
  const [selectLoader, setSelectLoader] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedFilterValue, setSelectedFilterValue] = useState([]);
  const [query, setQuery] = useState('');
  const [newLoader, setNewLoader] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [editParameterValue, setEditParameterValue] = useState('');
  const [deleteParameterValue, setDeleteParameterValue] = useState('');
  const [initialLoader, setInitialLoader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [newModal, setNewModal] = useState(false);
  const [existModal, setExistModal] = useState(false);
  const [newItem, setNewItem] = useState('');

  useEffect(() => { 
    if(initialLoader){
      function loadData(){
      setInitialLoader(false);
      get(child(dbRef, `${storeID}/settings/${storeID}/laundryservices`)).then((snapshot) => {
      const str = snapshot.child("laundryservices").val();
      let selectedArr;
      if(str){
        selectedArr = str.split(",");
      }else{
        selectedArr = [];
      }
      setSelectedValue(selectedArr);
      setSelectedFilterValue(selectedArr);
      setSelectLoader(true);
      
      }).catch((error)=>{
        console.log(error);
      })
      }
      loadData();
    }
  }, [initialLoader])

  function handleSearch(e){
      setNewItem(e)
      const filteredData = selectedValue.filter(
        user => {
          return (
            user.toLowerCase().includes(e.toLowerCase()) 
          );
        }
      );
    
    setSelectedFilterValue(filteredData);
    setQuery(e);
  };

  function addnewservice(){
    let svalue = selectedValue;
    let queryLowercase = query.toLowerCase();
    const selectedValuelowercase = selectedValue.map(name => name.toLowerCase());
    let servicePresent = selectedValuelowercase.includes(queryLowercase);
    if(query.length){
      if(servicePresent){
        setNewLoader(false);
        setExistModal(true);
      }
      else{
        setNewLoader(false);
        svalue.push(query);
        setSelectedFilterValue(svalue);
        setSelectedValue(svalue);
        let newvalue = svalue.toString();
        console.log(newvalue);

        if(newvalue.length){
          set(dref(dbr, storeID+'/settings/' + storeID + '/laundryservices/'), {
            laundryservices : newvalue
          }).then(()=>{
            setQuery('');
            setNewLoader(true);
          })
        }
        else{
          setNewLoader(false);
          setNewModal(true);
        }
      }
    }
    else{
      setNewLoader(false);
      setNewModal(true);
    }
  }

  function cancelnewservice(){
    setNewLoader(false);
    setSelectLoader(true);
  }

  function cancelEdit(){
    setEditLoader(false);
    setSelectLoader(true);
  }

  function deleteservice(){
    const stringselectedValue = selectedValue.toString();
    const arr = stringselectedValue.split(",");
    const index = arr.indexOf(deleteParameterValue);
    if (index !== -1) {
        arr.splice(index, 1);
    }
    const updateValue = arr.join(",");
    if(updateValue.length){
      update(dref(dbr, storeID+'/settings/' + storeID+'/laundryservices'), {
        laundryservices: updateValue
      }).then(()=>{
        setEditLoader(false);
        setNewLoader(false);
        setSelectLoader(false);
        setInitialLoader(true);
      })

    }
    else{
      setEditLoader(false);
      setShowModal(true);
    }
  }

  function editservice(){
      const stringselectedValue = selectedValue.toString();
      if(editParameterValue.length){
      var updateValue = stringselectedValue.replace(deleteParameterValue, editParameterValue);
      if(updateValue.length){
        set(dref(dbr, storeID+'/settings/' + storeID+'/laundryservices/'), {
          laundryservices: updateValue
        }).then(()=>{
          setEditLoader(false);
          setNewLoader(false);
          setSelectLoader(false);
          setInitialLoader(true);
        })
      }
      else{
        setEditLoader(false);
        setShowModal(true);
      }
      }else{
        setEditLoader(false);
        setShowModal(true);
      }
  }

  function onFloatinActionClick(){
    setSelectLoader(false);
    setNewLoader(true);
  }

  function editParameter(service){
    setEditParameterValue(service)
    setDeleteParameterValue(service)
    setSelectLoader(false);
    setEditLoader(true);
  }

  function backFromEditLoader(){
    setShowModal(false);
    setEditLoader(true);
  }

  function backFromNewLoader(){
    setNewModal(false);
    setNewLoader(true);
  }

  function backtoNewLoader(){
    setExistModal(false);
    setNewLoader(true);
  }

  return (
    <>
    <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <Link to='/Settings' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              Settings
            </Link>
            </div>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Laundry Services</span>
            </div>
          </li>
        </ol>
      </nav>
    {selectLoader &&
      <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
          <div class="flex items-center justify-between">
            <p class="mb-5 ml-5 text-xl text-gray-900 dark:text-white">Laundry Services</p>
            {settingscreate && <button onClick={()=>onFloatinActionClick()} type="button" class="text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">New Service</button>}
          </div>
          <div class="relative z-0 mb-6 w-1/2 ml-5 mr-5 group ">
            <input value={query} onChange={(e)=>handleSearch(e.target.value)} type="text" name="sku" id="sku" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
            <label htmlFor="sku" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Search</label>
          </div> 
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="py-3 px-6">
                    Laundry Service
                </th>
                <th scope="col" class="py-3 px-6">
                    Action
                </th>
            </tr>
          </thead>
          <tbody>
            {selectedFilterValue.map((service, index)=>(
              <tr key={index} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td class="py-4 px-6">{service}</td>
                <td class="py-4 px-6">
                  <button onClick={()=>editParameter(service)} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center mr-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-blue-800">
                    Edit
                  </button>
                </td>
              </tr>
            ))}   
          </tbody> 
          </table>     
        </div>
    } 
    {newLoader && 
      <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
          <div class="flex items-center justify-between">
            <p class="mb-5 ml-5 text-xl text-gray-900 dark:text-white">Add New Laundry Service</p>
          </div>
          <div class="relative z-0 mb-6 w-1/2 ml-5 mr-5 group ">
            <input value={newItem} onChange={e => handleSearch(e.target.value)} type="text" name="sku" id="sku" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
            <label htmlFor="sku" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">New Service</label>
          </div> 
          <div class="ml-5 mb-5 flex mt-4 md:mt-6">
          <button onClick={()=>cancelnewservice()} type="button" class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Cancel</button>
          <button onClick={()=>addnewservice()} type="button" class="ml-5 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Add Service</button>
          </div>
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="py-3 px-6">
                    Laundry Service
                </th>
            </tr>
          </thead>
          <tbody>
            {selectedFilterValue.map((service, index)=>(
              <tr key={index} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td class="py-4 px-6">{service}</td>
              </tr>
            ))}   
          </tbody> 
          </table>     
        </div>
    }
    {newModal && 
        <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Value should not be empty</h3>
          </div>
          
          <div class="flex">
            <button onClick={()=>backFromNewLoader()} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              OK
            </button>
            
            
          </div>
        </div>
      }
    {showModal && 
        <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Value should not be empty</h3>
          </div>
          
          <div class="flex">
            <button onClick={()=>backFromEditLoader()} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              OK
            </button>
            
            
          </div>
        </div>
      }
      {existModal && 
        <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Value exist already</h3>
          </div>
          
          <div class="flex">
            <button onClick={()=>backtoNewLoader()} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              OK
            </button>
            
            
          </div>
        </div>
      }
    {editLoader && 
      <div class="w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
        <h5 class="mb-5 text-xl font-medium text-gray-900 dark:text-white">Edit/Delete Laundry Item</h5>
        <div class="relative">
        
        <input value={editParameterValue} onChange={e => setEditParameterValue(e.target.value)} type="search" id="search" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" />
        </div>
        <div class="flex mt-4 space-x-3 md:mt-6">
            <button onClick={()=>cancelEdit()} type="button" class="text-white bg-red-900 hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-red-800 dark:hover:bg-red-900 dark:focus:ring-red-900">
              <svg aria-hidden="true" class="w-5 h-5 mr-2 -ml-1" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
              </svg>
                Cancel
            </button>
            {settingsupdate &&
            <button onClick={()=>editservice()} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800">
              Edit
              <svg aria-hidden="true" class="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z"></path></svg>
            </button>
            }
            {settingsdelete && 
            <button type="button" onClick={()=>deleteservice()}  class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
              Delete
              <svg aria-hidden="true" class="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6 7H5v13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7H6zm4 12H8v-9h2v9zm6 0h-2v-9h2v9zm.618-15L15 2H9L7.382 4H3v2h18V4z"></path></svg>
            </button>
            }
        </div>
      </div>
    }
    </>
  )  

}