/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import { child, get, getDatabase, push, ref as dref, set } from "firebase/database";
import React, { useEffect, useState } from 'react';
import PhoneInput, {parsePhoneNumber} from 'react-phone-number-input';
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from "../../contexts/UserStoreContext";
import firebaseapp from '../../firebase';
import ls from 'localstorage-slim';

export default function InventoryVendor({navigation, route}) {
  
  const navigate = useNavigate();
  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);
  const { storeID, setStoreID, countryCode, setCountryCode, inventorycreate } = useUserStore();
  const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);
  const [listDataExist, setListDataExist] = useState(false)
  const [vendorListInit, setVendorListInit] = useState(true)
  const [newVendorLoader, setNewVendorLoader] = useState(false)
  const [listLoader, setListLoader] = useState(false)
  const [vendorName, setVendorName] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [phonenumber, setphonenumber] = useState([{key: '', value: ''}]);
  const [listData, setListData] = useState('');
  const [editItem, setEditItem] = useState('');
  const [editLoader, setEditLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [filterListData, setFilterListData] = useState('');
  const [query, setQuery] = useState('')
  
  const addcHandler = ()=>{
    
    if(Object.keys(phonenumber).length){
      const _phonenumber = [...phonenumber];
      const kl = Object.keys(_phonenumber).length+1;
      _phonenumber.push({key: kl, value: ''});
      setphonenumber(_phonenumber);
    }else{
      const _phonenumber = [];
      _phonenumber.push({key: 1, value: ''});
      setphonenumber(_phonenumber);
    }

  }
  const deletecHandler = (ckey)=>{
    const _phonenumber = phonenumber.filter((cinput,index) => index !== ckey);
    setphonenumber(_phonenumber);
  }
  

  const inputcHandler = (text, ckey)=>{
    const _phonenumber = [...phonenumber];
    _phonenumber[ckey].value = text;
    _phonenumber[ckey].key   = ckey;
    setphonenumber(_phonenumber);
    
  }
  if(!storeID){
    setStoreID(userdata.storeID);
  }
    
  if(!countryCode){
    setCountryCode(userdata.country);
  }

  
  
  
  
  useEffect(() => { 

    if(vendorListInit){
      function listVendorData(){
        get(child(dbRef, `${storeID}/vendor/${storeID}/`)).then(async (snapshot) => {
          const listdata = [];
          if (snapshot.exists()) {
            snapshot.forEach(element => {
              const key = element.key;
              const address = element.child('address').val(); 
              const email = element.child('email').val(); 
              const phonenumber = element.child('phonenumber').val();
              const vendorName = element.child('vendorName').val(); 
              const whatsappNumber = element.child('whatsappNumber').val();
              listdata.push({
                key:key,
                address:address, 
                email:email, 
                phonenumber:phonenumber,
                vendorName:vendorName,
                whatsappNumber:whatsappNumber
                })

            });
            const completedListData = await Promise.all(listdata);
            const dataExist =  true;
            return [dataExist, completedListData];;
          }
          else{
            const dataExist =  false;
            return [dataExist, 0];;
          }
          
        }).then((dataExist)=>{
          if(dataExist[0]){
            setListData(dataExist[1]);
            setFilterListData(dataExist[1]);
            setListDataExist(true)
            setListLoader(true);
          }else{
            setListDataExist(false)
            setListLoader(true);
          }
        }).catch((error)=>{
          console.log(error);
        });
     }
     listVendorData();
    }
   
   
  
  }, [vendorListInit]);
  
  function newVendorCreator() {
  setVendorName('');
  setAddress('');
  setEmail('');
  setphonenumber([{key: '', value: ''}]);
  setListLoader(false);
  setVendorListInit(false);
  setNewVendorLoader(true);
  } 

  function cancelNewVendor(){
    setNewVendorLoader(false);
    setVendorListInit(false);
    setListLoader(true);
  }

  function afterNewVendorCreation(){
    setShowModal(false);
    setNewVendorLoader(false);
    setVendorListInit(false);
    setListLoader(false);
    setVendorListInit(true);

  }

  function onSubmitNewVendor(){
    
    const key = push(child(dref(dbr), 'vendor')).key;
    let WNumber
    let anumber = [];
    let keynum = 1;
    if(phonenumber.length){
      for (const key in phonenumber) {
        if (Object.hasOwnProperty.call(phonenumber, key)) {
          const element = phonenumber[key];
          anumber.push({key: keynum, value: parsePhoneNumber(element.value).nationalNumber, code:parsePhoneNumber(element.value).countryCallingCode, country:parsePhoneNumber(element.value).country})
          keynum = keynum+1;
        }
      }
    }
    else{
      anumber.push({key: '1', value: ''})
    }
    if(whatsappNumber.length){
      WNumber = {value:parsePhoneNumber(whatsappNumber).nationalNumber, code:parsePhoneNumber(whatsappNumber).countryCallingCode, country:parsePhoneNumber(whatsappNumber).country};
      }else{
        WNumber = '';
      }

    set(dref(dbr, storeID+'/vendor/' + storeID+'/'+key), {
      vendorName,
      address,
      email,
      phonenumber:anumber,
      whatsappNumber:WNumber
    }).then(()=>{
      setNewVendorLoader(false);
      setShowModal(true);
    })

    

  }

  function editVendor(item){
    let wNumber = "+"+item.whatsappNumber.code+item.whatsappNumber.value;
    
    let anumber = [];
    let keynum = 1;
    if(item.phonenumber.length){
      for (const key in item.phonenumber) {
        if (Object.hasOwnProperty.call(item.phonenumber, key)) {
          const element = item.phonenumber[key];
          const numbr = "+"+element.code+element.value;
          anumber.push({key: keynum, value:numbr})
          keynum = keynum+1;
        }
      }
    }
    else{
      anumber.push({key: '1', value: ''})
    }
    
    const address = item.address;
    const email = item.email;
    const key = item.key;
    const vendorName = item.vendorName;
    setEditItem({
      address:address,
      email:email,
      key:key,
      phonenumber:anumber,
      whatsappNumber:wNumber,
      vendorName:vendorName,
      });
    setListLoader(false);
    setEditLoader(true);
  }

  function cancelEditVendor(){
    setEditItem('');
    setEditLoader(false);
    setListLoader(true);
  }

  function onSubmitEditVendor(){
    setVendorListInit(false);
    const key = editItem.key;
    const vendorName= editItem.vendorName;
    const address= editItem.address;
    const email= editItem.email;
    const phonenumber = editItem.phonenumber;
    const whatsappNumber = editItem.whatsappNumber;
    let WNumber;

    let anumber = [];
    let keynum = 1;
    if(phonenumber.length){
      for (const key in phonenumber) {
        if (Object.hasOwnProperty.call(phonenumber, key)) {
          const element = phonenumber[key];
          anumber.push({key: keynum, value: parsePhoneNumber(element.value).nationalNumber, code:parsePhoneNumber(element.value).countryCallingCode, country:parsePhoneNumber(element.value).country})
          keynum = keynum+1;
        }
      }
    }
    else{
      anumber.push({key: '1', value: ''})
    }

    if(whatsappNumber.length){
      WNumber = {value:parsePhoneNumber(whatsappNumber).nationalNumber, code:parsePhoneNumber(whatsappNumber).countryCallingCode, country:parsePhoneNumber(whatsappNumber).country};
      }else{
        WNumber = '';
      }

    set(dref(dbr, storeID+'/vendor/' + storeID +"/"+key), {
      vendorName,
      address,
      email,
      phonenumber:anumber,
      whatsappNumber:WNumber,
    }).then(()=>{
      setEditLoader(false);
      setEditItem('');
      setVendorListInit(true);
    })
  }

  function deleteVendor(){
    setEditLoader(false);
    setDeleteModal(true);
  }

  function cancelDelete(){
    setDeleteModal(false);
    setEditLoader(true);
  }

  function continueDeleteVendor(){
    setDeleteModal(false);
    setVendorListInit(false);
    const key = editItem.key;
    set(dref(dbr, storeID+'/vendor/' + storeID+'/'+key), {
      null:null
    }).then(()=>{
      setEditLoader(false);
      setEditItem('');
      setVendorListInit(true);
    })
  }

  const inputeditHandler = (text, ckey)=>{
    const _phonenumber = [...editItem.phonenumber];
    _phonenumber[ckey].value = text;
    _phonenumber[ckey].key   = ckey;
    setEditItem({...editItem, phonenumber:_phonenumber});
    
  }

  const deleteeditHandler = (ckey)=>{
    const _phonenumber = editItem.phonenumber.filter((cinput,index) => index !== ckey);
    setEditItem({...editItem, phonenumber:_phonenumber});
  }

  

  const addeditHandler = ()=>{
    if(Object.keys(editItem.phonenumber).length){
      const _phonenumber = [...editItem.phonenumber];
      const kl = Object.keys(_phonenumber).length+1;
      _phonenumber.push({key: kl, value: ''});
      setEditItem({...editItem, phonenumber:_phonenumber});
    }else{
      
      const _phonenumber = [];
      _phonenumber.push({key: 1, value: ''});
      setEditItem({...editItem, phonenumber:_phonenumber});
    }
    
  }

  function handleSearch(e){
    setQuery(e);
    const elow = e.toLowerCase();
    console.log(listData);
    const fData = listData.filter(item => {
    
      return (
        (item.vendorName && item.vendorName.toLowerCase().includes(elow)));
    }
    );
    setFilterListData(fData);
  };

  return (
    <div>
      <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <Link to='/inventory' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              Inventory
            </Link>
            </div>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Vendor</span>
            </div>
          </li>
        </ol>
      </nav>
      {listLoader && 
        <>
          {listDataExist? 
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
                  <div class="flex items-center justify-between">
                  <p class="mb-5 text-xl text-gray-900 dark:text-white">Vendor List</p>
                  <button onClick={()=>newVendorCreator()} type="button" class="text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">New Vendor</button>
                  </div>
                  <div class="relative z-0 mb-6 w-1/2 ml-5 mr-5 group ">
                <input value={query} onChange={(e)=>handleSearch(e.target.value)} type="text" name="sku" id="sku" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                <label htmlFor="sku" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Search</label>
                </div>
                  <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                              <th scope="col" class="py-3 px-6">
                                  Name
                              </th>
                              <th scope="col" class="py-3 px-6">
                                  Phonenumber
                              </th>
                              <th scope="col" class="py-3 px-6">
                                  Action
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                      {filterListData.map((item, key)=>(
                          <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                              <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                <p>{item.vendorName}</p>
                              </th>
                              <td class="py-4 px-6">
                              {item.phonenumber.map((ph, ckey)=>(
                                <p key={ckey}>+{ph.code}{ph.value}</p>
                                ))}
                              </td>
                              <td class="py-4 px-6">
                                <button onClick={()=>editVendor(item)} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center mr-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-blue-800">
                                  Edit
                                </button>
                              </td>
                          </tr>
                      ))}    
                      </tbody>
                  </table>
              </div>
            :
            <div id="alert" class="p-4 mb-4 text-yellow-700 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800" role="alert">
              <div class="flex items-center">
                <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                <h3 class="text-lg font-medium">You have not added Vendor</h3>
              </div>
              <div class="mt-2 mb-4 text-sm">
                Do you wish to add Vendor?
              </div>
              <div class="flex">
              {inventorycreate &&
                <button onClick={()=>newVendorCreator()} type="button" class="text-white bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800">
                  <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
                  Yes
                </button>
              }
                <button onClick={()=>navigate("/vendor")} type="button" class="text-yellow-700 bg-transparent border border-yellow-700 hover:bg-yellow-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-yellow-500 dark:border-yellow-500 dark:text-yellow-400 dark:hover:text-white dark:focus:ring-yellow-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                  Dismiss
                </button>
              </div>
            </div>
          } 
        </>
      }
      {newVendorLoader &&
        <>
          <div class="mt-2 mb-2 text-lg">New Vendor</div>
          
          <div class="relative z-0 w-full mb-6 group">
            <input value={vendorName} onChange={(e)=>setVendorName(e.target.value)} type="text" name="Vendor Name" id="Vendor Name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
            <label htmlFor="Vendor Name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Vendor Name</label>
          </div>
          <div class="relative z-0 mb-6 w-full group">
              <input value={address} onChange={(e)=>setAddress(e.target.value)} type="text" name="address" id="address" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "/>
              <label htmlFor="address" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Address</label>
          </div>
          <div class="relative z-0 mb-6 w-full group">
              <input value={email} onChange={(e)=>setEmail(e.target.value)} type="text" name="email" id="email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "/>
              <label htmlFor="email" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email</label>
          </div>
          
          <div class="relative z-0 mb-6 w-full group">
          {phonenumber.map((cinput, ckey)=>(
          <div key={ckey} class="relative z-0 mb-6 w-full group">
          <label htmlFor="phonenumber" class="mb-5 text-sm text-gray-500 dark:text-gray-400">Phone Number</label>
          <PhoneInput
            id="businessUserPhoneNumber"
            class="block py-2.5 ps-6 pe-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            international
            withDarkTheme
            placeholder="Phonenumber"
            defaultCountry={countryCode}
            value={cinput.value}
            onChange={(e)=>inputcHandler(e, ckey)}
            />
            <button onClick = {()=> deletecHandler(ckey)} type="button" class="absolute top-5 right-0 p-2.5 text-red-700 hover:text-purple font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:text-red-500 dark:hover:text-purple"><svg aria-hidden="true" class="w-5 h-5" fill="#ffffff" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path></svg></button>
          </div>
          ))}
          <button onClick={()=>addcHandler()} type="button" class="mb-6 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Add Another Number</button>
          
          <div class="relative z-0 mb-6 w-full group">
          <label htmlFor="phonenumber" class="mb-5 text-sm text-gray-500 dark:text-gray-400">Whatsapp Number</label>
          <PhoneInput
            id="businessUserPhoneNumber"
            class="block py-2.5 ps-6 pe-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            international
            withDarkTheme
            placeholder="Phonenumber"
            defaultCountry={countryCode}
            value={whatsappNumber}
            onChange={(e)=>setWhatsappNumber(e)}
            />
          </div>
          
          <div class="relative z-0 mb-6 mt-6 w-full group">
          {inventorycreate && <button onClick={()=>onSubmitNewVendor()} type="button" class="mt-2 mr-2 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>}
          <button onClick={()=>cancelNewVendor()} type="button" class="mt-2 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
          </div>
          </div>
        </>
      }
      {showModal && 
        <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Vendor created successfully</h3>
          </div>
          <div class="flex">
            <button onClick={()=>afterNewVendorCreation()} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              ok
            </button>
          </div>
        </div>
      }
      {editLoader &&
        <>
          <div class="mt-2 mb-5 text-lg">Edit Vendor</div>
          
          <div class="relative z-0 w-full mb-6 group">
            <input value={editItem.vendorName} onChange={(e)=>setEditItem({...editItem, vendorName:e.target.value})} type="text" name="Vendor Name" id="Vendor Name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
            <label htmlFor="Vendor Name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Vendor Name</label>
          </div>
          <div class="relative z-0 mb-6 w-full group">
              <input value={editItem.address} onChange={(e)=>setEditItem({...editItem, address:e.target.value})} type="text" name="address" id="address" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "/>
              <label htmlFor="address" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Address</label>
          </div>
          <div class="relative z-0 mb-6 w-full group">
              <input value={editItem.email} onChange={(e)=>setEditItem({...editItem, email:e.target.value})} type="text" name="email" id="email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "/>
              <label htmlFor="email" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email</label>
          </div>
          
          <div class="relative z-0 mb-6 w-full group">
          {editItem.phonenumber.map((cinput, ckey)=>(
          <div key={ckey} class="relative z-0 mb-6 w-full group">
          <label htmlFor="phonenumber" class="mb-5 text-sm text-gray-500 dark:text-gray-400">Phone Number</label>
          <PhoneInput
            id="businessUserPhoneNumber"
            class="block py-2.5 ps-6 pe-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            international
            withDarkTheme
            placeholder="Phonenumber"
            defaultCountry={cinput.country}
            value={cinput.value}
            onChange={(e)=>inputeditHandler(e, ckey)}
            />
          <button onClick = {()=> deleteeditHandler(ckey)} type="button" class="absolute top-5 right-0 p-2.5 text-red-700 hover:text-purple font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:text-red-500 dark:hover:text-purple"><svg aria-hidden="true" class="w-5 h-5" fill="#ffffff" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path></svg></button>
          </div>
          ))}
          <button onClick={()=>addeditHandler()} type="button" class="mb-6 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Add Another Number</button>
          
          <div class="relative z-0 mb-6 w-full group">
          <label htmlFor="phonenumber" class="mb-5 text-sm text-gray-500 dark:text-gray-400">Whatsapp Number</label>
          <PhoneInput
            id="businessUserPhoneNumber"
            class="block py-2.5 ps-6 pe-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            international
            withDarkTheme
            placeholder="Phonenumber"
            defaultCountry={countryCode}
            value={editItem.whatsappNumber}
            onChange={(e)=>setEditItem({...editItem, whatsappNumber:e})}
            />
          </div>
          <div class="relative z-0 mb-6 mt-6 w-full group">
          <button onClick={()=>onSubmitEditVendor()} type="button" class="mt-2 mr-2 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Save</button>
          <button onClick={()=>cancelEditVendor()} type="button" class="mt-2 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
          <button onClick={()=>deleteVendor()} type="button" class="mt-2 text-red-900 bg-white border border-red-300 focus:outline-none hover:bg-red-100 focus:ring-4 focus:ring-red-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-800 dark:text-white dark:border-red-600 dark:hover:bg-red-700 dark:hover:border-red-600 dark:focus:ring-red-700">Delete</button>
          </div>
          </div>
        </>
      }
      {deleteModal && 
        <div id="alert" class="p-4 mb-4 text-red-700 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Delete Vendor?</h3>
          </div>
          <div class="mt-2 mb-4 text-sm">
            Do you wish to continue?
          </div>
          <div class="flex">
            <button onClick={()=>continueDeleteVendor()} type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Yes
            </button>
            <button onClick={()=>cancelDelete()} type="button" class="text-red-700 bg-transparent border border-red-700 hover:bg-red-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-500 dark:border-red-500 dark:text-red-400 dark:hover:text-white dark:focus:ring-red-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
              Dismiss
            </button>
          </div>
        </div>
      }

    </div>

  );
}

