import React, { createContext, useState } from "react";

export const ThemeContext = createContext();

export const ThemeProvider = (props) => {
  const [theme, setTheme] = useState("light");

  const toggleTheme = () => {
    setTheme((theme) => (theme === "light" ? "dark" : "light"));
    let className = document.body.className;
    let th;
    if(className === 'light'){
      th = 'light'
    }else{
      th = 'dark'
    }
    document.documentElement.classList.toggle(th);
    console.log(theme);
  };

  return (  
    <ThemeContext.Provider value={{theme, setTheme, toggleTheme}}>
      {props.children}
    </ThemeContext.Provider>
  );
};