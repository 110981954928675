/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import './sidebar.scss'
import { Link, useNavigate } from 'react-router-dom'
import { useUserAuth } from "../../contexts/UserAuthContext";
import { ThemeContext } from '../../contexts/ThemeContext';
import { useUserStore } from "../../contexts/UserStoreContext";
import moment from 'moment';
import ls from 'localstorage-slim';
import logo from "../../assets/images/logo.png";

const Sidebar = () => {
    const navigate = useNavigate();
    const { logOut, user } = useUserAuth();
    const { toggleTheme, theme } = useContext(ThemeContext);
    const [activeMenu, setActiveMenu] = useState('home')
    const [loader, setLoader] = useState(false)
    const [stValidity, setStValidity] = useState(false)
    const {accountsread, customersread, deliveryread, inventoryread, invoiceread, laundryread, collectionread, offersread, pckageread, pickupread, pricingread, rechargeread, reportsread, staffread, settingsread, setUserLanguage, setCountryCode, setUserPhoneNumber, setUserEmail, userEmail, setLanguageCode, setAccountsread, setCustomersread, setDeliveryread, setInventoryread, setInvoiceread, setLaundryread, setCollectionread, setOffersread, setPckageread, setPickupread, setPricingread, setRechargeread, setReportsread, setStaffread, setSettingsread} = useUserStore();
    
        const dt = ls.get("val", { decrypt: true });
        
        const validityDate = moment(dt);
        useEffect(() => { 
            async function checkData(){
                var validity = moment(new Date()).isSameOrBefore(validityDate);
                        if (!validity) {
                            setStValidity(false);
                        }else{
                            setStValidity(true);
                        }
                        setLoader(true);  
            }
            checkData();
       }, [user])
    

    const closeSidebar = () => {
        document.querySelector('.main__content').style.transform = 'scale(1) translateX(0)'
        setTimeout(() => {
            document.body.classList.remove('sidebar-open')
            document.querySelector('.main__content').style = ''
        }, 500);
    }

    const handleLogout = async () => {
        try {

          await logOut();
          ls.clear();
          console.log("logout function called");
          navigate("/login");
        } catch (error) {
          console.log(error.message);
        }
      };

      function menuClicked(menuItem){
        console.log(menuItem);
        setActiveMenu(menuItem);
        closeSidebar();
      }
    

    return (
        <div class='sidebar bg-white dark:bg-slate-800'>
            {loader && 
            <>
            <div class="sidebar__logo" >
                <img src={logo} alt="" />
                <div class="sidebar-close">
                    <i class='bx bx-x'></i>
                </div>
            </div>
            <div class="sidebar__menu" className="overflow-y-auto">
               
                    
                    <Link onClick={() => { menuClicked('home')}} id='home' to='/' key={0} class={`text-slate-900 dark:text-white sidebar__menu__item ${activeMenu === 'home' && 'active'}`} >
                        <div class="sidebar__menu__item__icon">
                            <i class='bx bx-home-alt' />
                        </div>
                        <div class="sidebar__menu__item__txt">
                            Home
                        </div>
                    </Link>
                    {customersread && stValidity &&
                    <Link onClick={() => { menuClicked('customers')}} id='customers' to='/customers' key={1} class={`text-slate-900 dark:text-white sidebar__menu__item ${activeMenu === 'customers' && 'active'}`} >
                        <div class="sidebar__menu__item__icon">
                            <i class='bx bx-user' />
                        </div>
                        <div class="sidebar__menu__item__txt">
                            Customers
                        </div>
                    </Link>
                    }
                    {pickupread && stValidity &&
                    <Link onClick={() => { menuClicked('pickup')}} id='pickup' to='/pickup' state={["Home", false]} key={2} class={`text-slate-900 dark:text-white sidebar__menu__item ${activeMenu === 'pickup' && 'active'}`} >
                        <div class="sidebar__menu__item__icon">
                            <i class='bx bx-cart' />
                        </div>
                        <div class="sidebar__menu__item__txt">
                            Pickup
                        </div>
                    </Link>
                    }
                    {collectionread && stValidity &&
                    <Link onClick={() => { menuClicked('collection')}} id='collection' to='/collection' state={["Home", false]} key={3} class={`text-slate-900 dark:text-white sidebar__menu__item ${activeMenu === 'collection' && 'active'}`} >
                        <div class="sidebar__menu__item__icon">
                            <i class='bx bx-chevrons-up'></i>
                        </div>
                        <div class="sidebar__menu__item__txt">
                            Collection
                        </div>
                    </Link>
                    }
                    {laundryread && stValidity &&
                    <Link onClick={() => { menuClicked('laundry')}} id='laundry' to='/laundry' key={4} class={`text-slate-900 dark:text-white sidebar__menu__item ${activeMenu === 'laundry' && 'active'}`} >
                        <div class="sidebar__menu__item__icon">
                            <i class='bx bx-window-alt' />
                        </div>
                        <div class="sidebar__menu__item__txt">
                            Laundry
                        </div>
                    </Link>
                    }
                    {deliveryread && stValidity &&
                    <Link onClick={() => { menuClicked('delivery')}} id='delivery' to='/delivery' key={5} class={`text-slate-900 dark:text-white sidebar__menu__item ${activeMenu === 'delivery' && 'active'}`} >
                        <div class="sidebar__menu__item__icon">
                            <i class='bx bx-timer' />
                        </div>
                        <div class="sidebar__menu__item__txt">
                            Delivery
                        </div>
                    </Link>
                    }
                    {invoiceread && stValidity &&
                    <Link onClick={() => { menuClicked('invoice')}} id='invoice' to='/invoice' key={6} class={`text-slate-900 dark:text-white sidebar__menu__item ${activeMenu === 'invoice' && 'active'}`} >
                        <div class="sidebar__menu__item__icon">
                            <i class='bx bx-money' />
                        </div>
                        <div class="sidebar__menu__item__txt">
                            Invoice
                        </div>
                    </Link>
                    }
                    {accountsread && stValidity &&
                    <Link onClick={() => { menuClicked('accounts')}} id='accounts' to='/accounts' key={7} class={`text-slate-900 dark:text-white sidebar__menu__item ${activeMenu === 'accounts' && 'active'}`} >
                        <div class="sidebar__menu__item__icon">
                            <i class='bx bx-calculator' />
                        </div>
                        <div class="sidebar__menu__item__txt">
                            Accounts
                        </div>
                    </Link>
                    }
                    {pricingread && stValidity &&
                    <Link onClick={() => { menuClicked('pricing')}} id='pricing' to='/pricing' key={8} class={`text-slate-900 dark:text-white sidebar__menu__item ${activeMenu === 'pricing' && 'active'}`} >
                        <div class="sidebar__menu__item__icon">
                            <i class='bx bx-purchase-tag-alt' />
                        </div>
                        <div class="sidebar__menu__item__txt">
                            Pricing
                        </div>
                    </Link>
                    }
                    {offersread && stValidity &&
                    <Link onClick={() => { menuClicked('offers')}} id='offers' to='/offers' key={9} class={`text-slate-900 dark:text-white sidebar__menu__item ${activeMenu === 'offers' && 'active'}`} >
                        <div class="sidebar__menu__item__icon">
                            <i class='bx bx-gift' />
                        </div>
                        <div class="sidebar__menu__item__txt">
                            Offers
                        </div>
                    </Link>
                    }
                    {pckageread && stValidity &&
                    <Link onClick={() => { menuClicked('package')}} id='package' to='/package' key={10} class={`text-slate-900 dark:text-white sidebar__menu__item ${activeMenu === 'package' && 'active'}`} >
                        <div class="sidebar__menu__item__icon">
                            <i class='bx bx-package' />
                        </div>
                        <div class="sidebar__menu__item__txt">
                            Package
                        </div>
                    </Link>
                    }
                    {inventoryread && stValidity &&
                    <Link onClick={() => { menuClicked('inventory')}} id='inventory' to='/inventory' key={11} class={`text-slate-900 dark:text-white sidebar__menu__item ${activeMenu === 'inventory' && 'active'}`} >
                        <div class="sidebar__menu__item__icon">
                            <i class='bx bx-notepad' />
                        </div>
                        <div class="sidebar__menu__item__txt">
                            Inventory
                        </div>
                    </Link>
                    }
                    {staffread && stValidity &&
                    <Link onClick={() => { menuClicked('staff')}} id='staff' to='/staff' key={12} class={`text-slate-900 dark:text-white sidebar__menu__item ${activeMenu === 'staff' && 'active'}`} >
                        <div class="sidebar__menu__item__icon">
                            <i class='bx bxs-user-account' />
                        </div>
                        <div class="sidebar__menu__item__txt">
                            Staff
                        </div>
                    </Link>
                    }
                    {settingsread && stValidity &&
                    <Link onClick={() => { menuClicked('Settings')}} id='store' to='/Settings'  key={13} class={`text-slate-900 dark:text-white sidebar__menu__item ${activeMenu === 'store' && 'active'}`} >
                        <div class="sidebar__menu__item__icon">
                            <i class='bx bx-cog' />
                        </div>
                        <div class="sidebar__menu__item__txt">
                            Settings
                        </div>
                    </Link>
                    }
                    {reportsread && stValidity &&
                    <Link onClick={() => { menuClicked('reports')}} id='reports' to='/reports' key={14} class={`text-slate-900 dark:text-white sidebar__menu__item ${activeMenu === 'report' && 'active'}`} >
                        <div class="sidebar__menu__item__icon">
                            <i class='bx bx-line-chart' />
                        </div>
                        <div class="sidebar__menu__item__txt">
                            Report
                        </div>
                    </Link>
                    }
                    {rechargeread && 
                    <Link onClick={() => { menuClicked('recharge')}} id='recharge' to='/recharge' key={15} class={`text-slate-900 dark:text-white sidebar__menu__item ${activeMenu === 'recharge' && 'active'}`} >
                        <div class="sidebar__menu__item__icon">
                            <i class='bx bx-wallet-alt' />
                        </div>
                        <div class="sidebar__menu__item__txt">
                            Recharge
                        </div>
                    </Link>
                    }

                    <Link onClick={() => { menuClicked('help')}} id='help' to='/help' key={16} class={`text-slate-900 dark:text-white sidebar__menu__item ${activeMenu === 'help' && 'active'}`} >
                        <div class="sidebar__menu__item__icon">
                            <i class='bx bx-help-circle' />
                        </div>
                        <div class="sidebar__menu__item__txt">
                            Help
                        </div>
                    </Link>
                    
                    
                
                <div class="sidebar__menu__item">
                <label class="inline-flex relative items-center mr-5 cursor-pointer">
                <input type="checkbox" onChange={()=>toggleTheme()}
                checked={theme === "dark"} class="sr-only peer" />
                <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Dark Mode</span>
                </label>
                </div>
                <button onClick={handleLogout} class="text-slate-900 dark:text-white sidebar__menu__item">
                    
                        <div class="text-slate-900 dark:text-white sidebar__menu__item__icon">
                            <i class='bx bx-log-out'></i>
                        </div>
                        <div class="text-slate-900 dark:text-white sidebar__menu__item__txt">
                            Logout
                        </div>
                    
                </button>
                
            </div>
            </>
            }
        </div>
    )
}

export default Sidebar
