/* eslint-disable react-hooks/exhaustive-deps */
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, signInWithEmailAndPassword, sendPasswordResetEmail, createUserWithEmailAndPassword } from "firebase/auth";
import { collection, doc, getDocs, getFirestore, query, setDoc, where } from "firebase/firestore";
import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../contexts/UserAuthContext";
import { useUserStore } from "../contexts/UserStoreContext";
import firebaseapp from '../firebase';
import '../index.css';
import ls from 'localstorage-slim';

const Login = () => { 
    
    const [error, setError] = useState("");
    const [businessUserPhoneNumber, setBusinessUserPhoneNumber] = useState('');
    const [codeView, setCodeView] = useState(false);
    const [phoneView, setPhoneView] = useState(false);
    const [emailView, setEmailView] = useState(false);
    const [forgetView, setForgetView] = useState(false);
    const [languageView, setLanguageView] = useState(false);
    const [userTypeView, setUserTypeView] = useState(true);
    const [staff, setStaff] = useState(true);
    const [bname, setBname] = useState('Laundri');
    const [passwordSuccess, setPasswordSuccess] = useState(false);
    const [staffModal, setStaffModal] = useState(false);
    const [adminModal, setAdminModal] = useState(false);
    const [country, setCountry] = useState('US');
    const [udata, setUdata] = useState('');
    const [signupView, setSignupView] = useState(false);
    const options = [
        {value: 'en', text: 'English'},
        {value: 'ar', text: 'العربية'},
        {value: 'hi', text: 'தமிழ்'},
        {value: 'ml', text: 'മലയാളം'},
        {value: 'kn', text: 'ಕನ್ನಡ'},
        {value: 'te', text: 'తెలుగు'}
      ];
    const [selectedLanguage, setSelectedLanguage] = useState(options[0].value);
    const auth = getAuth(firebaseapp);
    const [otp, setOtp] = useState('');
    const { signUp } = useUserAuth();
    const { setBusinessName, setStoreID, setUserLanguage, setUserCategory, setCountryCode, setUserPhoneNumber, setUserEmail, setLanguageCode, setAccountscreate, setCustomerscreate, setDeliverycreate, setInventorycreate, setSettingscreate, setCollectioncreate, setInvoicecreate, setLaundrycreate, setOfferscreate, setPckagecreate, setPickupcreate, setPricingcreate, setRechargecreate, setReportscreate, setStaffcreate, setStorecreate, setAccountsread, setCustomersread, setSettingsread, setCollectionread, setDeliveryread, setInventoryread, setInvoiceread, setLaundryread, setOffersread, setPckageread, setPickupread, setPricingread, setRechargeread, setReportsread, setStaffread, setStoreread, setAccountsupdate, setCustomersupdate, setSettingsupdate, setCollectionupdate, setDeliveryupdate, setInventoryupdate, setInvoiceupdate, setLaundryupdate, setOffersupdate, setPckageupdate, setPickupupdate, setPricingupdate, setRechargeupdate, setReportsupdate, setStaffupdate, setStoreupdate, setAccountsdelete, setCustomersdelete, setSettingsdelete, setCollectiondelete, setDeliverydelete, setInventorydelete, setInvoicedelete, setLaundrydelete, setOffersdelete, setPckagedelete, setPickupdelete, setPricingdelete, setRechargedelete, setReportsdelete, setStaffdelete, setStoredelete} = useUserStore();
    const dbfirestore = getFirestore(firebaseapp);
    let navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    function generateRecaptcha(){
        try {
            window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible',
                'callback': (response) => {
                  // reCAPTCHA solved, allow signInWithPhoneNumber.
                  
                }
              }, auth);
            
        } catch (err) {
            const errmssage = err.message.replace("Firebase:", "")
            setError(errmssage);
        }
        
    }

    async function requestOTP(){
        
        if (businessUserPhoneNumber === "" || businessUserPhoneNumber === undefined)
        return setError("Please enter a valid phone number!");
        
        setPhoneView(false);
        setCodeView(true);
        generateRecaptcha();
        let appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth,businessUserPhoneNumber,appVerifier).then((confirmationResult)=>{
            window.confirmationResult = confirmationResult;
        }).catch((error)=>{
            const errmssage = error.message.replace("Firebase:", "")
            setError(errmssage);
        })
    }

    function cancelOTP(){
        setBusinessUserPhoneNumber('');
        setCodeView(false);
        setPhoneView(true);
    }

    function verifyOTP(){
        if(otp.length === 6){
            try {
            let confirmationResult = window.confirmationResult;
            confirmationResult.confirm(otp).then(async (result) => {
                const userdata = result.user;
                await signUp();
                checkUserInfo(userdata);
                 
              }).catch((error) => {
                const errmssage = error.message.replace("Firebase:", "")
                setError(errmssage);
              });
        } catch (error) {
            const errmssage = error.message.replace("Firebase:", "")
            setError(errmssage);
        }
        }
        else{
            setError('Please enter the correct OTP')
        }
        
    }

    async function checkUserInfo(userdata){
        setPhoneView(false);
        setCodeView(false);
        setUdata(userdata);
        const phoneNumber = userdata.phoneNumber;
        const userMail = userdata.email;
        const uid = userdata.uid;
        if(staff){
            
            const q = query(collection(dbfirestore, "staff"), where("email", "==", userMail));
        
            const querySnapshot = await getDocs(q);
            if(querySnapshot.empty){
               
            setStaffModal(true);
                 
            }
            else{

                const pushID = []; 
                querySnapshot.forEach(element => {
                    const userinfo = JSON.stringify({
                        uid: uid,
                        languageCode:selectedLanguage,
                        phoneNumber:phoneNumber,
                        email:email,
                        storeID:element.data().storeID, 
                        businessName: element.data().businessName,
                        countryCode:element.data().countryCode,
                        userCategory:'staff',  
                        accountscreate:element.data().accountscreate,
                        customerscreate:element.data().customerscreate,
                        deliverycreate:element.data().deliverycreate,
                        settingscreate:element.data().settingscreate,
                        collectioncreate:element.data().collectioncreate,
                        inventorycreate:element.data().inventorycreate,
                        invoicecreate:element.data().invoicecreate,
                        laundrycreate:element.data().laundrycreate,
                        offerscreate:element.data().offerscreate,
                        pckagecreate:element.data().pckagecreate,
                        pickupcreate:element.data().pickupcreate,
                        pricingcreate:element.data().pricingcreate,
                        rechargecreate:element.data().rechargecreate,
                        reportscreate:element.data().reportscreate,
                        staffcreate:element.data().staffcreate,
                        storecreate:element.data().storecreate,
                        accountsread:element.data().accountsread,
                        customersread:element.data().customersread,
                        deliveryread:element.data().deliveryread,
                        settingsread:element.data().settingsread,
                        collectionread:element.data().collectionread,
                        inventoryread:element.data().inventoryread,
                        invoiceread:element.data().invoiceread,
                        laundryread:element.data().laundryread,
                        offersread:element.data().offersread,
                        pckageread:element.data().pckageread,
                        pickupread:element.data().pickupread,
                        pricingread:element.data().pricingread,
                        rechargeread:element.data().rechargeread,
                        reportsread:element.data().reportsread,
                        staffread:element.data().staffread,
                        storeread:element.data().storeread,
                        accountsupdate:element.data().accountsupdate,
                        customersupdate:element.data().customersupdate,
                        deliveryupdate:element.data().deliveryupdate,
                        collectionupdate:element.data().collectionupdate,
                        settingsupdate:element.data().settingsupdate,
                        inventoryupdate:element.data().inventoryupdate,
                        invoiceupdate:element.data().invoiceupdate,
                        laundryupdate:element.data().laundryupdate,
                        offersupdate:element.data().offersupdate,
                        pckageupdate:element.data().pckageupdate,
                        pickupupdate:element.data().pickupupdate,
                        pricingupdate:element.data().pricingupdate,
                        rechargeupdate:element.data().rechargeupdate,
                        reportsupdate:element.data().reportsupdate,
                        staffupdate:element.data().staffupdate,
                        storeupdate:element.data().storeupdate,
                        accountsdelete:element.data().accountsdelete,
                        customersdelete:element.data().customersdelete,
                        deliverydelete:element.data().deliverydelete,
                        settingsdelete:element.data().settingsdelete,
                        collectiondelete:element.data().collectiondelete,
                        inventorydelete:element.data().inventorydelete,
                        invoicedelete:element.data().invoicedelete,
                        laundrydelete:element.data().laundrydelete,
                        offersdelete:element.data().offersdelete,
                        pckagedelete:element.data().pckagedelete,
                        pickupdelete:element.data().pickupdelete,
                        pricingdelete:element.data().pricingdelete,
                        rechargedelete:element.data().rechargedelete,
                        reportsdelete:element.data().reportsdelete,
                        staffdelete:element.data().staffdelete,
                        storedelete:element.data().storedelete
                      })
                    ls.set("userinfo", userinfo, { encrypt: true })
                    setStoreID(element.data().storeID);
                    setBusinessName(element.data().businessName);
                    setUserLanguage(element.data().userLanguage); 
                    setUserCategory(element.data().userCategory);
                    setCountryCode(element.data().countryCode); 
                    setUserPhoneNumber(element.data().userPhoneNumber); 
                    setUserEmail(element.data().userEmail);
                    setLanguageCode(element.data().languageCode); 
                    setAccountscreate(element.data().accountscreate); 
                    setCustomerscreate(element.data().customerscreate); 
                    setDeliverycreate(element.data().deliverycreate); 
                    setSettingscreate(element.data().settingscreate);
                    setCollectioncreate(element.data().collectioncreate); 
                    setInventorycreate(element.data().inventorycreate); 
                    setInvoicecreate(element.data().invoicecreate); 
                    setLaundrycreate(element.data().laundrycreate); 
                    setOfferscreate(element.data().offerscreate); 
                    setPckagecreate(element.data().pckagecreate); 
                    setPickupcreate(element.data().pickupcreate); 
                    setPricingcreate(element.data().pricingcreate); 
                    setRechargecreate(element.data().rechargecreate); 
                    setReportscreate(element.data().reportscreate); 
                    setStaffcreate(element.data().staffcreate); 
                    setStorecreate(element.data().storecreate); 
                    setAccountsread(element.data().accountsread); 
                    setCustomersread(element.data().customersread); 
                    setDeliveryread(element.data().deliveryread);
                    setSettingsread(element.data().settingsread);  
                    setCollectionread(element.data().collectionread);  
                    setInventoryread(element.data().inventoryread); 
                    setInvoiceread(element.data().invoiceread); 
                    setLaundryread(element.data().laundryread); 
                    setOffersread(element.data().offersread); 
                    setPckageread(element.data().pckageread); 
                    setPickupread(element.data().pickupread);
                    setPricingread(element.data().pricingread); 
                    setRechargeread(element.data().rechargeread); 
                    setReportsread(element.data().reportsread); 
                    setStaffread(element.data().staffread); 
                    setStoreread(element.data().storeread); 
                    setAccountsupdate(element.data().accountsupdate); 
                    setCustomersupdate(element.data().customersupdate); 
                    setDeliveryupdate(element.data().deliveryupdate);
                    setSettingsupdate(element.data().settingsupdate);
                    setCollectionupdate(element.data().collectionupdate);
                    setInventoryupdate(element.data().inventoryupdate); 
                    setInvoiceupdate(element.data().invoiceupdate);
                    setLaundryupdate(element.data().laundryupdate); 
                    setOffersupdate(element.data().offersupdate); 
                    setPckageupdate(element.data().pckageupdate); 
                    setPickupupdate(element.data().pickupupdate); 
                    setPricingupdate(element.data().pricingupdate);  
                    setRechargeupdate(element.data().rechargeupdate); 
                    setReportsupdate(element.data().reportsupdate);
                    setStaffupdate(element.data().staffupdate); 
                    setStoreupdate(element.data().storeupdate); 
                    setAccountsdelete(element.data().accountsdelete); 
                    setCustomersdelete(element.data().customersdelete); 
                    setDeliverydelete(element.data().deliverydelete);
                    setSettingsdelete(element.data().settingsdelete); 
                    setCollectiondelete(element.data().collectiondelete);  
                    setInventorydelete(element.data().inventorydelete); 
                    setInvoicedelete(element.data().invoicedelete);
                    setLaundrydelete(element.data().laundrydelete); 
                    setOffersdelete(element.data().offersdelete); 
                    setPckagedelete(element.data().pckagedelete);
                    setPickupdelete(element.data().pickupdelete);
                    setPricingdelete(element.data().pricingdelete);
                    setRechargedelete(element.data().rechargedelete); 
                    setReportsdelete(element.data().reportsdelete); 
                    setStaffdelete(element.data().staffdelete); 
                    setStoredelete(element.data().storedelete);
                    pushID.push(element.id);
                });

                navigate('/landing');
            }

        }else{
            const q = query(collection(dbfirestore, "store"), where("phoneNumber", "==", phoneNumber));
        
            const querySnapshot = await getDocs(q);
            if(querySnapshot.empty){
               setAdminModal(true);
            }
            else{

                const pushID = []; 
                querySnapshot.forEach(element => {
                    const userinfo = JSON.stringify({
                        uid: uid,
                        languageCode:selectedLanguage,
                        phoneNumber:phoneNumber,
                        storeID:element.data().storeID, 
                        businessName:element.data().businessName,
                        userCategory:'admin',
                        countryCode:element.data().countryCode,  
                        accountscreate:element.data().accountscreate,
                        customerscreate:element.data().customerscreate,
                        deliverycreate:element.data().deliverycreate,
                        settingscreate:element.data().settingscreate,
                        collectioncreate:element.data().collectioncreate,
                        inventorycreate:element.data().inventorycreate,
                        invoicecreate:element.data().invoicecreate,
                        laundrycreate:element.data().laundrycreate,
                        offerscreate:element.data().offerscreate,
                        pckagecreate:element.data().pckagecreate,
                        pickupcreate:element.data().pickupcreate,
                        pricingcreate:element.data().pricingcreate,
                        rechargecreate:element.data().rechargecreate,
                        reportscreate:element.data().reportscreate,
                        staffcreate:element.data().staffcreate,
                        storecreate:element.data().storecreate,
                        accountsread:element.data().accountsread,
                        customersread:element.data().customersread,
                        deliveryread:element.data().deliveryread,
                        settingsread:element.data().settingsread,
                        collectionread:element.data().collectionread,
                        inventoryread:element.data().inventoryread,
                        invoiceread:element.data().invoiceread,
                        laundryread:element.data().laundryread,
                        offersread:element.data().offersread,
                        pckageread:element.data().pckageread,
                        pickupread:element.data().pickupread,
                        pricingread:element.data().pricingread,
                        rechargeread:element.data().rechargeread,
                        reportsread:element.data().reportsread,
                        staffread:element.data().staffread,
                        storeread:element.data().storeread,
                        accountsupdate:element.data().accountsupdate,
                        customersupdate:element.data().customersupdate,
                        deliveryupdate:element.data().deliveryupdate,
                        settingsupdate:element.data().settingsupdate,
                        collectionupdate:element.data().collectionupdate,
                        inventoryupdate:element.data().inventoryupdate,
                        invoiceupdate:element.data().invoiceupdate,
                        laundryupdate:element.data().laundryupdate,
                        offersupdate:element.data().offersupdate,
                        pckageupdate:element.data().pckageupdate,
                        pickupupdate:element.data().pickupupdate,
                        pricingupdate:element.data().pricingupdate,
                        rechargeupdate:element.data().rechargeupdate,
                        reportsupdate:element.data().reportsupdate,
                        staffupdate:element.data().staffupdate,
                        storeupdate:element.data().storeupdate,
                        accountsdelete:element.data().accountsdelete,
                        customersdelete:element.data().customersdelete,
                        deliverydelete:element.data().deliverydelete,
                        settingsdelete:element.data().settingsdelete,
                        collectiondelete:element.data().collectiondelete,
                        inventorydelete:element.data().inventorydelete,
                        invoicedelete:element.data().invoicedelete,
                        laundrydelete:element.data().laundrydelete,
                        offersdelete:element.data().offersdelete,
                        pckagedelete:element.data().pckagedelete,
                        pickupdelete:element.data().pickupdelete,
                        pricingdelete:element.data().pricingdelete,
                        rechargedelete:element.data().rechargedelete,
                        reportsdelete:element.data().reportsdelete,
                        staffdelete:element.data().staffdelete,
                        storedelete:element.data().storedelete
                      })
                    ls.set("userinfo", userinfo, { encrypt: true })
                    setStoreID(element.data().storeID);
                    setBusinessName(element.data().businessName);
                    setUserLanguage(element.data().userLanguage); 
                    setUserCategory(element.data().userCategory);
                    setCountryCode(element.data().countryCode); 
                    setUserPhoneNumber(element.data().userPhoneNumber);
                    setUserEmail(element.data().userEmail); 
                    setLanguageCode(element.data().languageCode); 
                    setAccountscreate(element.data().accountscreate); 
                    setCustomerscreate(element.data().customerscreate); 
                    setDeliverycreate(element.data().deliverycreate); 
                    setSettingscreate(element.data().settingscreate);
                    setCollectioncreate(element.data().collectioncreate);
                    setInventorycreate(element.data().inventorycreate); 
                    setInvoicecreate(element.data().invoicecreate); 
                    setLaundrycreate(element.data().laundrycreate); 
                    setOfferscreate(element.data().offerscreate); 
                    setPckagecreate(element.data().pckagecreate); 
                    setPickupcreate(element.data().pickupcreate); 
                    setPricingcreate(element.data().pricingcreate); 
                    setRechargecreate(element.data().rechargecreate); 
                    setReportscreate(element.data().reportscreate); 
                    setStaffcreate(element.data().staffcreate); 
                    setStorecreate(element.data().storecreate); 
                    setAccountsread(element.data().accountsread); 
                    setCustomersread(element.data().customersread); 
                    setDeliveryread(element.data().deliveryread);
                    setSettingsread(element.data().settingsread); 
                    setCollectionread(element.data().collectionread);  
                    setInventoryread(element.data().inventoryread); 
                    setInvoiceread(element.data().invoiceread); 
                    setLaundryread(element.data().laundryread); 
                    setOffersread(element.data().offersread); 
                    setPckageread(element.data().pckageread); 
                    setPickupread(element.data().pickupread);
                    setPricingread(element.data().pricingread); 
                    setRechargeread(element.data().rechargeread); 
                    setReportsread(element.data().reportsread); 
                    setStaffread(element.data().staffread); 
                    setStoreread(element.data().storeread); 
                    setAccountsupdate(element.data().accountsupdate); 
                    setCustomersupdate(element.data().customersupdate); 
                    setDeliveryupdate(element.data().deliveryupdate);
                    setSettingsupdate(element.data().settingsupdate);
                    setCollectionupdate(element.data().collectionupdate);
                    setInventoryupdate(element.data().inventoryupdate); 
                    setInvoiceupdate(element.data().invoiceupdate);
                    setLaundryupdate(element.data().laundryupdate); 
                    setOffersupdate(element.data().offersupdate); 
                    setPckageupdate(element.data().pckageupdate); 
                    setPickupupdate(element.data().pickupupdate); 
                    setPricingupdate(element.data().pricingupdate);  
                    setRechargeupdate(element.data().rechargeupdate); 
                    setReportsupdate(element.data().reportsupdate);
                    setStaffupdate(element.data().staffupdate); 
                    setStoreupdate(element.data().storeupdate); 
                    setAccountsdelete(element.data().accountsdelete); 
                    setCustomersdelete(element.data().customersdelete); 
                    setDeliverydelete(element.data().deliverydelete);
                    setSettingsdelete(element.data().settingsdelete);
                    setCollectiondelete(element.data().collectiondelete); 
                    setInventorydelete(element.data().inventorydelete); 
                    setInvoicedelete(element.data().invoicedelete);
                    setLaundrydelete(element.data().laundrydelete); 
                    setOffersdelete(element.data().offersdelete); 
                    setPckagedelete(element.data().pckagedelete);
                    setPickupdelete(element.data().pickupdelete);
                    setPricingdelete(element.data().pricingdelete);
                    setRechargedelete(element.data().rechargedelete); 
                    setReportsdelete(element.data().reportsdelete); 
                    setStaffdelete(element.data().staffdelete); 
                    setStoredelete(element.data().storedelete);
                    pushID.push(element.id);
                });

                
                navigate('/landing');
            }

        }
        
    }

    function continueStoreCreation(){

        const phoneNumber = udata.phoneNumber;
        const uid = udata.uid;
        setStoreID(uid);
        setBusinessName(bname);
        setUserLanguage(selectedLanguage); 
        setUserCategory("admin");
        setCountryCode(country); 
        setUserPhoneNumber(phoneNumber); 
        setUserEmail("");
        setLanguageCode(selectedLanguage); 
        setAccountscreate(true); 
        setCustomerscreate(true); 
        setDeliverycreate(true);
        setSettingscreate(true); 
        setCollectioncreate(true); 
            setInventorycreate(true); 
            setInvoicecreate(true);
            setLaundrycreate(true); 
            setOfferscreate(true); 
            setPckagecreate(true); 
            setPickupcreate(true); 
            setPricingcreate(true); 
            setRechargecreate(true); 
            setReportscreate(true); 
            setStaffcreate(true); 
            setStorecreate(true); 
            setAccountsread(true); 
            setCustomersread(true); 
            setDeliveryread(true);
            setSettingsread(true);  
            setCollectionread(true);  
            setInventoryread(true); 
            setInvoiceread(true); 
            setLaundryread(true); 
            setOffersread(true); 
            setPckageread(true); 
            setPickupread(true);
            setPricingread(true); 
            setRechargeread(true); 
            setReportsread(true); 
            setStaffread(true); 
            setStoreread(true); 
            setAccountsupdate(true); 
            setCustomersupdate(true); 
            setDeliveryupdate(true);
            setSettingsupdate(true);
            setCollectionupdate(true);
            setInventoryupdate(true); 
            setInvoiceupdate(true);
            setLaundryupdate(true); 
            setOffersupdate(true); 
            setPckageupdate(true); 
            setPickupupdate(true); 
            setPricingupdate(true);
            setRechargeupdate(true); 
            setReportsupdate(true);
            setStaffupdate(true); 
            setStoreupdate(true); 
            setAccountsdelete(true); 
            setCustomersdelete(true); 
            setDeliverydelete(true); 
            setSettingsdelete(true); 
            setCollectiondelete(true); 
            setInventorydelete(true); 
            setInvoicedelete(true);
            setLaundrydelete(true); 
            setOffersdelete(true); 
            setPckagedelete(true);
            setPickupdelete(true);
            setPricingdelete(true);
            setRechargedelete(true); 
            setReportsdelete(true); 
            setStaffdelete(true); 
            setStoredelete(true);
            const userinfo = JSON.stringify({
                uid: uid,
                languageCode:selectedLanguage,
                phoneNumber:phoneNumber,
                storeID:uid,
                businessName:bname, 
                userCategory: "admin",
                countryCode: country,  
                accountscreate:true,
                customerscreate:true,
                deliverycreate:true,
                settingscreate:true,
                collectioncreate:true,
                inventorycreate:true,
                invoicecreate:true,
                laundrycreate:true,
                offerscreate:true,
                pckagecreate:true,
                pickupcreate:true,
                pricingcreate:true,
                rechargecreate:true,
                reportscreate:true,
                staffcreate:true,
                storecreate:true,
                accountsread:true,
                customersread:true,
                deliveryread:true,
                settingsread:true,
                collectionread:true,
                inventoryread:true,
                invoiceread:true,
                laundryread:true,
                offersread:true,
                pckageread:true,
                pickupread:true,
                pricingread:true,
                rechargeread:true,
                reportsread:true,
                staffread:true,
                storeread:true,
                accountsupdate:true,
                customersupdate:true,
                deliveryupdate:true,
                settingsupdate:true,
                collectionupdate:true,
                inventoryupdate:true,
                invoiceupdate:true,
                laundryupdate:true,
                offersupdate:true,
                pckageupdate:true,
                pickupupdate:true,
                pricingupdate:true,
                rechargeupdate:true,
                reportsupdate:true,
                staffupdate:true,
                storeupdate:true,
                accountsdelete:true,
                customersdelete:true,
                deliverydelete:true,
                settingsdelete:true,
                collectiondelete:true,
                inventorydelete:true,
                invoicedelete:true,
                laundrydelete:true,
                offersdelete:true,
                pckagedelete:true,
                pickupdelete:true,
                pricingdelete:true,
                rechargedelete:true,
                reportsdelete:true,
                staffdelete:true,
                storedelete:true,
            })
            ls.set("userinfo", userinfo, { encrypt: true })
            setDoc(doc(dbfirestore, 'store/' + phoneNumber), {
                uid: uid,
                languageCode:selectedLanguage,
                phoneNumber:phoneNumber,
                storeID:uid, 
                businessName:bname,
                userCategory: "admin",
                countryCode: country,  
                accountscreate:true,
                customerscreate:true,
                deliverycreate:true,
                settingscreate:true,
                collectioncreate:true,
                inventorycreate:true,
                invoicecreate:true,
                laundrycreate:true,
                offerscreate:true,
                pckagecreate:true,
                pickupcreate:true,
                pricingcreate:true,
                rechargecreate:true,
                reportscreate:true,
                staffcreate:true,
                storecreate:true,
                accountsread:true,
                customersread:true,
                deliveryread:true,
                settingsread:true,
                collectionread:true,
                inventoryread:true,
                invoiceread:true,
                laundryread:true,
                offersread:true,
                pckageread:true,
                pickupread:true,
                pricingread:true,
                rechargeread:true,
                reportsread:true,
                staffread:true,
                storeread:true,
                accountsupdate:true,
                customersupdate:true,
                deliveryupdate:true,
                settingsupdate:true,
                collectionupdate:true,
                inventoryupdate:true,
                invoiceupdate:true,
                laundryupdate:true,
                offersupdate:true,
                pckageupdate:true,
                pickupupdate:true,
                pricingupdate:true,
                rechargeupdate:true,
                reportsupdate:true,
                staffupdate:true,
                storeupdate:true,
                accountsdelete:true,
                customersdelete:true,
                deliverydelete:true,
                settingsdelete:true,
                collectiondelete:true,
                inventorydelete:true,
                invoicedelete:true,
                laundrydelete:true,
                offersdelete:true,
                pckagedelete:true,
                pickupdelete:true,
                pricingdelete:true,
                rechargedelete:true,
                reportsdelete:true,
                staffdelete:true,
                storedelete:true,
            }).then(()=>{
                navigate('/landing');
            })
    }

    const selectLanguage = event => {
        setSelectedLanguage(event.target.value);
    };

    function languageSelected(){
        setLanguageView(false);
        if(staff){
            setEmailView(true);
        }else{
            setPhoneView(true);
        }
        
    }

    function userTypeSelection(statusType){
        setBusinessUserPhoneNumber('');
        setOtp('');
        setSignupView(false);
        setForgetView(false)
        if(statusType){
            setUserTypeView(false);
            setStaff(true);
            setLanguageView(true);
        }else{
            setUserTypeView(false);
            setStaff(false);
            setLanguageView(true);
        }
    }

    function closeStaffModal(){
        setStaffModal(false);
        setAdminModal(false);
        setUserTypeView(true);
    }  
    const onLogin = () => {
        setEmailView(false);
        signInWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => { 
            const userdata = userCredential.user;
            await signUp();
            checkUserInfo(userdata);
        })
        .catch((error) => {

            const errorCode = error.code;
            const errmssage = error.message.replace("Firebase:", "")
            setError(errmssage);
            console.log(errorCode, errmssage)
        });
        
    }

    function forgetViewer() {
        setEmailView(false);
        setForgetView(true);
    }

    const triggerResetEmail = async () => {

        await sendPasswordResetEmail(auth, email);
        setPasswordSuccess(true);
      }

    function closeReset(){
        setPasswordSuccess(false);
        setForgetView(false);
        setEmailView(true);
    }

    const onSubmit = async () => {
        setSignupView(false);
        await createUserWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            const userdata = userCredential.user;
            await signUp();
            checkUserInfo(userdata);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errmssage = error.message.replace("Firebase:", "")
            setError(errmssage);
            console.log(errorCode, errmssage);
          });
      }

      function signupViewer(){
        setEmailView(false);
        setSignupView(true);
      }

    return (
        <>
            {userTypeView ?
                <section>
                    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16">
                        <div class="rounded-lg p-8 md:p-12 mb-8">
                            <h1 class="text-center text-gray-900 dark:text-gray-900 text-3xl md:text-5xl font-extrabold mb-2">Please select your account type</h1>
                            
                        </div>
                        <div class="grid md:grid-cols-2 gap-8">
                            <button onClick={()=>userTypeSelection(true)}>
                                <div class="flex flex-col items-center pb-10 bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-8 md:p-12">
                                <svg class="w-10 h-10 mr-1 mb-10" fill="purple" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path d="M12 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"></path>
                                    </svg>
                                    <h2 class="text-gray-900 dark:text-white text-3xl font-extrabold mb-2">Staff</h2>
                                    
                                </div>
                            </button>
                            <button onClick={()=>userTypeSelection(false)}>
                                <div class="flex flex-col items-center pb-10 bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-8 md:p-12">
                                    <svg class="w-10 h-10 mb-10" fill="purple" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path d="M7 17a5.007 5.007 0 0 0 4.898-4H14v2h2v-2h2v3h2v-3h1v-2h-9.102A5.007 5.007 0 0 0 7 7c-2.757 0-5 2.243-5 5s2.243 5 5 5zm0-8c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3z"></path>
                                    </svg>
                                    <h2 class="text-gray-900 dark:text-white text-3xl font-extrabold mb-2">Admin</h2>
                                    
                                </div>
                            </button>    
                        </div>
                    </div>
                </section>
            :
                <div class="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                    <div class="w-full max-w-md space-y-8">
                        {error &&
                            <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                            <strong class="font-bold">Error!</strong>
                            <span class="block sm:inline"> {error}</span>
                            <span onClick={()=>setError('')} class="absolute top-0 bottom-0 right-0 px-4 py-3">
                                <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                            </span>
                            </div>
                        }
                        <div class="flex items-center justify-center">
                        <img class="w-20 h-20 rounded-full" src={require("../assets/images/logo.png")} alt="Rounded avatar"></img>
                        </div>
                        <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Account Authentication</h2>
                        <div class="mt-8 space-y-6">
                        {languageView && 
                            <>
                            <div class="-space-y-px rounded-md shadow-sm">
                            <label htmlFor="languages_multiple" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select language</label>
                                <select value={selectedLanguage} onChange={selectLanguage} class="block w-full px-4 py-3 text-base text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500 text-sm p-2.5">
                                {options.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                                ))}
                                </select>
                            </div>
                            <div>
                                <button onClick={languageSelected} type="submit" class="group relative flex w-full justify-center rounded-md border border-transparent bg-purple-600 py-2 px-4 text-sm font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                                Next
                                </button>
                            </div>
                            </>
                        }
                        {phoneView &&    
                            <>
                            <div class="-space-y-px rounded-md shadow-sm">
                                <label htmlFor="price" class="block text-sm font-medium text-gray-700">Enter your mobile number</label>
                                <PhoneInput
                                    id="businessUserPhoneNumber"
                                    class="block py-2.5 ps-6 pe-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    international
                                    withDarkTheme
                                    countryCallingCodeEditable={false}
                                    defaultCountry={country}
                                    value={businessUserPhoneNumber}
                                    onCountryChange={(value)=>setCountry(value)}
                                    onChange={(value)=>setBusinessUserPhoneNumber(value)}/> 
                            </div>
                            <div>
                                <button onClick={requestOTP} type="submit" class="group relative flex w-full justify-center rounded-md border border-transparent bg-purple-600 py-2 px-4 text-sm font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                                Next
                                </button>
                            </div>
                            </>
                        }
                        {emailView &&
                            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                                <div>
                                    <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                    <input onChange={(e)=>setEmail(e.target.value)} type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="email@gmail.com" required="" />
                                </div>
                                <div>
                                    <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                    <input onChange={(e)=>setPassword(e.target.value)} type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                                </div>
                                <div class="flex items-center justify-between">
                                <button onClick={()=>forgetViewer()} type="button" class="text-purple-700 focus:outline-none font-medium rounded-lg text-sm py-2.5 text-center mr-2 mb-2 dark:text-purple-400 ">Forgot password?</button>
                                    
                                    
                                </div>
                                
                                <button onClick={onLogin} type="button" class="w-full focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Sign in</button>
                                <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                                    Don’t have an account yet? &nbsp; 
                                    <button onClick={()=>signupViewer()} type="button" class="text-purple-700 focus:outline-none font-medium rounded-lg text-sm py-2.5 text-center mr-2 mb-2 dark:text-purple-400 ">Sign up</button>
                                </p>
                            </div>
                        }
                        {forgetView &&
                            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                                    <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                        Reset your password
                                    </h1>
                                    <div>
                                        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                        <input onChange={(e)=>setEmail(e.target.value)} type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="" />
                                    </div>
                                    <button disabled={passwordSuccess} onClick={()=>triggerResetEmail()} type="button" class="w-full focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Reset Password</button>
                                    {passwordSuccess &&
                                        <div id="alert-additional-content-3" class="p-4 mb-4 text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
                                            <div class="flex items-center">
                                                <svg class="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                                                </svg>
                                                <span class="sr-only">Info</span>
                                                <h3 class="text-lg font-medium">Password reset email sent</h3>
                                            </div>
                                            <div class="mt-2 mb-4 text-sm">
                                                Check your inbox. Incase you have not recceived check your spam folder.
                                            </div>
                                            <div class="flex">
                                                <button onClick={()=>closeReset()} type="button" class="text-white bg-green-800 hover:bg-green-900 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                                                <svg class="-ml-0.5 mr-2 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
                                                    <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"/>
                                                </svg>
                                                Continue
                                                </button>
                                                
                                            </div>
                                        </div>
                                    }
                            </div>
                        }
                        {signupView &&
                            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                                <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                    New User Registeration
                                </h1>
                                <div className=" space-y-6 rounded-md shadow-sm">
                                <div>
                                    <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                    <input onChange={(e)=>setEmail(e.target.value)} type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="email@gmail.com" required="" />
                                </div>
                                <div>
                                    <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                    <input onChange={(e)=>setPassword(e.target.value)} type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                                </div>
                                <button onClick={()=>forgetViewer()} type="button" class="text-purple-700 focus:outline-none font-medium rounded-lg text-sm py-2.5 text-center mr-2 mb-2 dark:text-purple-400 ">Forgot password?</button>
                                    
                                    
                                </div>   
                                <div>
                                <button onClick={()=>onSubmit()} type="button" class="w-full focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Sign up</button>
                                </div>
                            </div>
                        }
                        
                        <div id='recaptcha-container'></div>
                        {codeView &&
                            <>
                            <div class="-space-y-px rounded-md shadow-sm">
                            <label htmlFor="price" class="block text-sm font-medium text-gray-700">Phone Number: {businessUserPhoneNumber}</label>
                                <div>
                                <label htmlFor="text" class="sr-only">Verify OTP</label>
                                <input value={otp} onChange={(e)=>setOtp(e.target.value)} id="otp" name="otp" type="text" required class="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm" placeholder="OTP" />
                                </div>
                            </div>
                            <div>
                            <button onClick={cancelOTP} type="button" class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Cancel</button>
                            <button onClick={verifyOTP} type="button" class="justify-center rounded-md border border-transparent bg-purple-600 py-2 px-4 text-sm font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Verify</button>
                                
                            </div>
                            </>
                        }
                        {staffModal &&
                            <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
                            <div class="flex items-center">
                            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                            <span class="sr-only">Info</span>
                            <h3 class="text-lg font-medium">Contact your admin to activate your account.</h3>
                            </div>
                            
                            <div class="flex">
                            <button onClick={()=>closeStaffModal()} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                                <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
                                OK
                            </button>
                            
                            </div>
                            </div>
                        }
                        {adminModal &&
                            <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
                            <div class="flex items-center">
                            <span class="sr-only">Info</span>
                            <h4 class="text-lg font-medium mb-3">Are you sure you want to create new Store Account?</h4>
                            </div>
                            <div class="flex items-center">
                            <span class="sr-only">Info</span>
                            <h4 class="text-lg font-medium">If you already have an account then login using your admin phonenumber. else continue creating new store account</h4>
                            </div>
                            <div class="flex mt-5">
                            <button onClick={()=>closeStaffModal()} type="button" class="mb-5 text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                                <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
                                Login with admin Number
                            </button>
                            
                            </div>
                            <div class="flex items-center">
                            <span class="sr-only">Info</span>
                            <h4 class="text-lg font-medium">If you want to continue creating new store account with the above number</h4>
                            </div>
                            <div class="mb-6 mt-5">
                                <label for="businessName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Store Name</label>
                                <input value={bname} onChange={(e)=>setBname(e.target.value)}  type="text" id="businessName" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                            </div>
                            
                            <div class="flex mt-5">
                            <button onClick={()=>continueStoreCreation()} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                                <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
                                Continue
                            </button>
                            
                            </div>
                            </div>
                        }
                        </div>
                    </div>
                </div>
            }   
        
        </>
    )
}

export default Login
