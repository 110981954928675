/* eslint-disable react-hooks/exhaustive-deps */
 import React, { useEffect, useState } from 'react';
import firebaseapp from '../firebase';
import { child, get, getDatabase, push, ref as dref, set } from "firebase/database";
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from "../contexts/UserStoreContext";


export default function Packages({ route, navigation }) {
  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);
  const navigate = useNavigate();
  const [readAccess,setReadAccess] = useState(true);
  const [packloader, setPackloader] = useState(false);
  const [newpackloader, setNewpackloader] = useState(false)
  const [packList, setPackList] = useState('');
  const [packagename, setPackagename] = useState('');
  const [packageprice, setPackageprice] = useState('');
  const [packagedetails, setPackagedetails] = useState('');
  const [initialloader, setInitialloader] = useState(true);
  const [editpackloader, setEditpackloader] = useState(false);
  const [editparametername, setEditparametername] = useState('');
  const [editparameterkey, setEditparameterkey] = useState('');
  const [editparameterdetails, setEditparameterdetails] = useState('');
  const [editparameterprice, setEditparameterprice] = useState('');
  const { storeID, pckagecreate, pckageread, pckageupdate, pckagedelete } = useUserStore();
  const [deleteLoader, setDeleteLoader] = useState(false);
  


 

  function editPackage(ky, nme, det, pr){
    setEditparameterkey(ky);
    setEditparametername(nme);
    setEditparameterdetails(det);
    setEditparameterprice(pr);
    setPackloader(false);
    setEditpackloader(true);
  }
  
  function cancelEditpackage(){
    setEditpackloader(false);
    setPackloader(true);
  }

  function submitEditpackage(){
   if(editparametername.length && editparameterdetails.length && editparameterprice.length){
    const key = editparameterkey;
    const name = editparametername;
    const details = editparameterdetails;
    const price = editparameterprice;
    set(dref(dbr, storeID+'/packages/' + storeID+'/'+key), {
      name:name,
      details:details,
      price:price
    }).then(()=>{
      setEditpackloader(false);
      setInitialloader(true);
    }).catch((error)=>{
      console.log(error);
    })
   }
   else{
    alert(
      'Package title, details and price should not be empty'
    )
   }
    
  }

  async function deletePackage(){
    setEditpackloader(false);
    setDeleteLoader(true);
  }

  function continueDeletePackage(){
    setPackList('');
    setDeleteLoader(false);
    setEditpackloader(false);
    setPackloader(false);
    const key = editparameterkey;
    set(dref(dbr, storeID+'/packages/' + storeID+'/'+key), {
      null:null
    }).then(()=>{
      setPackloader(false);
      setInitialloader(true);
    }).catch((error)=>{
      console.log(error);
    })
  }

  async function cancelDeletePackage(){
    
    setDeleteLoader(false);
    setEditpackloader(true);
  }
  
  useEffect(() => { 

    function loadpackages(){
      if(pckageread){
        if(initialloader){
          setInitialloader(false);
          get(child(dbRef, `${storeID}/packages/${storeID}/`)).then(async (snapshot) => {
            const packPromises = [];
            if(snapshot.exists()){
              snapshot.forEach(element => {
                packPromises.push({
                    key: element.key,  
                    name: element.child('name').val(), 
                    details: element.child('details').val(), 
                    price: element.child('price').val(), 
                })
              });
              const datapacksnap = await Promise.all(packPromises);
              const dataExist = true;
              return [dataExist, datapacksnap]
              ;
            }
            else{
              const dataExist = false;
              return [dataExist, 0]
            }
          }).then((dataExist)=>{
            if(dataExist[0]){
              setPackList(dataExist[1])
            }else{
              setPackList('');
            }
            setInitialloader(false);
            setNewpackloader(false);
            setPackloader(true);
          }).catch((error)=>{
              console.log(error);
          });
          }
      }else{
        setReadAccess(false);
      }
    }
    loadpackages();
  }, [initialloader])

  function onFloatinActionClick(){
    setPackloader(false);
    setPackagename('');
    setPackageprice('');
    setPackagedetails('');
    setNewpackloader(true);
  }

  function cancelNewpackage(){
    setNewpackloader(false);
    setPackloader(true);
  }

  function submitNewpackage(){

    if(packagename.length && packagedetails.length && packageprice.length){
      const billkey = push(child(dref(dbr), 'packages')).key;

      set(dref(dbr, storeID+'/packages/' + storeID+'/'+billkey), {
        name: packagename, 
        details: packagedetails, 
        price: packageprice,
    
      }).then(()=>{
        setInitialloader(true);
      });
    }
    else{
      alert(
        'Package title, details and price should not be empty'
      )
    }



    
  }

  return (
    <div>
      <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Package</span>
            </div>
          </li>
        </ol>
      </nav>
      {readAccess? 
      <>
      {packloader && 
        <>
        {packList? 
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 md:gap-4 lg:gap-6 p-5">
          {packList.map((item, index)=>(
            <div key={index} class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <div class="flex justify-end px-4 pt-4">
                <p class="mb-3 text-base font-normal text-gray-700 dark:text-gray-400">Price: {item.price}</p>
                </div>
                <div class="flex flex-col items-center pb-10">
                    <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">{item.name}</h5>
                    <span class="text-sm text-gray-500 dark:text-gray-400">{item.details}</span>
                    <div class="flex mt-4 space-x-3 md:mt-6">
                    <button class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" onClick={() => editPackage(item.key, item.name, item.details, item.price)}>Edit</button>
                        
                    </div>
                </div>
            </div>
          ))}
          </div>
        :
        <div class="max-w-lg p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">You have not created any packages!</h5>
          <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Bundling service increases your profit</p>
        </div>
        }
        
        {pckagecreate && <button onClick={()=>onFloatinActionClick()} type="button" class="mt-5 ml-5 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Create New Package</button> }
        
        </>
      }
      {newpackloader &&
        <div>
          <p class="mb-5 text-xl text-gray-900 dark:text-white">New Package</p>
          <div class="relative z-0 w-full mb-6 group">
          <input maxLength={256} onChange={(e) => setPackagename(e.target.value)} value={packagename} type="text" name="Package Name" id="floating_email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
          <label htmlFor="Package Name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Package Name</label>
          </div>
          <div class="relative z-0 w-full mb-6 group">
          <input maxLength={256} onChange={(e) => setPackagedetails(e.target.value)}  value={packagedetails} multiline={4} type="text" name="Package details" id="Package details" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
          <label htmlFor="Package details" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Package details</label>
          </div>
          <div class="relative z-0 w-full mb-6 group">
          <input onChange={(e) => setPackageprice(e.target.value)} value={packageprice} maxLength={256} type="text" name="Package Price" id="Package Price" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
          <label htmlFor="Package Price" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Package Price</label>
            </div>
            {pckagecreate &&  <button onClick={()=>submitNewpackage()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>}
          <button onClick={()=>cancelNewpackage()} type="button" class="ml-5 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
        </div>
      }

      {editpackloader &&
        <div>
          <p class="mb-5 text-xl text-gray-900 dark:text-white">Edit Package</p>
          <div class="relative z-0 w-full mb-6 group">
          <input maxLength={256} onChange={(e) => setEditparametername(e.target.value)} value={editparametername} type="text" name="Package Name" id="floating_email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
          <label htmlFor="Package Name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Package Name</label>
          </div>
          <div class="relative z-0 w-full mb-6 group">
          <input maxLength={256} onChange={(e) => setEditparameterdetails(e.target.value)}  value={editparameterdetails} multiline={4} type="text" name="Package details" id="Package details" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
          <label htmlFor="Package details" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Package details</label>
          </div>
          <div class="relative z-0 w-full mb-6 group">
          <input onChange={(e) => setEditparameterprice(e.target.value)} value={editparameterprice} maxLength={256} type="text" name="Package Price" id="Package Price" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
          <label htmlFor="Package Price" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Package Price</label>
            </div>
          
          <button onClick={()=>cancelEditpackage()} type="button" class="ml-5 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
          {pckagedelete && <button onClick={()=>deletePackage()} type="button" class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Delete</button>}
	        {pckageupdate && <button onClick={()=>submitEditpackage()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>}
        </div>
      }

      {deleteLoader &&
        <div id="alert-additional-content-2" class="p-4 mb-4 text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Delete Package?</h3>
          </div>
          <div class="mt-2 mb-4 text-sm">
            Are you sure want to continue?
          </div>
          <div class="flex">
            <button onClick={()=>continueDeletePackage()} type="button" class="text-white bg-red-800 hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Yes
            </button>
            <button onClick={()=>cancelDeletePackage()} type="button" class="text-red-800 bg-transparent border border-red-800 hover:bg-red-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-600 dark:border-red-600 dark:text-red-500 dark:hover:text-white dark:focus:ring-red-800" data-dismiss-target="#alert-additional-content-2" aria-label="Close">
              Dismiss
            </button>
          </div>
        </div>
      }
      </>
      :
      <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">You do not have permission to view Package. Contact your admin for permissions</h3>
          </div>
          <div class="flex">
            <button onClick={()=>navigate("/")} type="button" class="text-green-700 bg-transparent border border-green-700 hover:bg-green-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-green-500 dark:border-green-500 dark:text-green-400 dark:hover:text-white dark:focus:ring-green-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
              OK
            </button>
          </div>
      </div>
      }
      
    </div>
  );
}

// const themedStyle = StyleService.create({
//     container: {
//       flex: 1,
//       flexDirection: 'column',
//       backgroundColor: 'background-basic-color-1',
//       alignItems: 'center',
//       justifyContent: 'center',
//       padding: 10
//     },
//     nopackageview:{
//       alignItems: 'center'
//     },
//     card: {
//       marginTop: 10,
//       borderColor: 'border-alternative-color-1',
//       minWidth: '100%'
//     },
//     cardview:{
//       flexDirection: 'row',
//       justifyContent: 'space-between'
//     },
//     innerview: {
//       flexDirection: 'row',
//       justifyContent: 'flex-start',
//       alignItems: 'center',
//     },
//     dateview: {
//       flexDirection: 'column',
//       alignItems: 'flex-start',
//       justifyContent: 'flex-start',
      
//     },
//     nameview: {
//       flexDirection: 'column',
//       alignItems: 'flex-start',
//       justifyContent: 'center',
//       maxWidth: '65%'
//     },
//     priceview:{
//       flexDirection: 'row',
//       alignItems: 'center'
//     },
//     actionview: {
//       flexDirection: 'column',
//       alignItems: 'flex-end',
//       justifyContent: 'center'
//     },
//     FloatingActionButtonStyle: {
//       position: 'absolute',
//       width: 60,
//       height: 60,
//       alignItems: 'center',
//       justifyContent: 'center',
//       right: 30,
//       bottom: 30,
//       backgroundColor:'color-primary-default',
//       borderColor:'border-alternative-color-1',
//       borderRadius: 200/2
//     },
//     floatIcon:{
//       width: 30,
//       height: 30,
//       resizeMode: 'contain',
//       tintColor:'background-basic-color-1'
//     },
//     cardheader:{
//       flexDirection: 'row',
//       justifyContent: 'space-between',
//       padding: 10,
//       borderBottomWidth: 1,
//       borderBottomColor: 'border-alternative-color-1'
//     },
//     inputprice:{
//       width: '50%'
//     },
//     inputdetails:{
//       marginTop: 10,
//       marginBottom: 10,
//     },
//     footerbutton: {
//       flexDirection: 'row',
//       justifyContent: 'flex-end',
//       marginBottom: 10,
//       marginTop: 10,
      
//     },
//     footerControl:{
//       marginRight: 10
//     }
//   });