/* eslint-disable react-hooks/exhaustive-deps */
import { child, get, getDatabase, push, ref as dref, set } from "firebase/database";
import filter from 'lodash.filter';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from "../contexts/UserStoreContext";
import firebaseapp from '../firebase';

export default function Pricing() {
  const navigate = useNavigate();
  const [readAccess,setReadAccess] = useState(true);
  const { storeID, pricingcreate, pricingread, pricingupdate, pricingdelete } = useUserStore();
  const [initialLoader, setInitialLoader] = useState(true);
  const [priceLoader, setPriceLoader] = useState(false);
  const [pricingData, setPricingData] = useState(false);
  const [newPriceLoader, setNewPriceLoader] = useState(false);
  const [filteredPricingData, setFilteredPricingData] = useState(false);
  const [pricingPresent, setPricingPresent] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [priceHead, setPriceHead] = useState('');
  const [priceDescription, setPriceDescription] = useState('');
  const [unitPrice, setUnitPrice] = useState('');
  const [editPricekey, setEditPricekey] = useState('');
  const [editPriceHeader, setEditPriceHeader] = useState('');
  const [editUnitPrice, setEditUnitPrice] = useState('');
  const [editPriceDescription, setEditPriceDescription] = useState('');
  const [editPriceloader, setEditPriceloader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);

  useEffect(() => {
    if(pricingread){
    function loadItems() {
      setInitialLoader(false);
      get(child(dbRef, `${storeID}/pricing/${storeID}/`)).then(async (snapshot) => {
        //step 1 data check
        if (snapshot.exists()) {
          try {
            const prmises = [];
            snapshot.forEach(pricesnapshot => {
              const priceKey = pricesnapshot.key;
              const priceHead = pricesnapshot.child('priceHead').val() || false;
              const priceDescription = pricesnapshot.child('priceDescription').val();
              const unitPrice = pricesnapshot.child('unitPrice').val();
              prmises.push({priceKey:priceKey, priceHead:priceHead, priceDescription:priceDescription, unitPrice:unitPrice});
            });
            const dtasnaps = await Promise.all(prmises);
            const dataExist = true;
            return [dataExist, dtasnaps]
          } catch (error) {
            console.log(error);          
          }
        } else{
          const dataExist = false;
          return [dataExist, 0]
          
        }
      }).then((dataExist)=>{
        if(dataExist[0]){
          setPricingData(dataExist[1]);
          setFilteredPricingData(dataExist[1]);
          setPricingPresent(true);
          setPriceLoader(true);
        }else{
          setPricingPresent(false);
          setPriceLoader(true);
        }
      }).catch((error) => {

        console.error("inside catch", error);
      });

    }
    loadItems();
    }else{
      setReadAccess(false);
    }
  }, [initialLoader])

  function onFloatinActionClick(){
    setPriceLoader(false);
    setPriceHead('');
    setPriceDescription('');
    setUnitPrice('');
    setNewPriceLoader(true);
  }

  function inputChange(nextValue){
    setSearchText(nextValue);
    const query = nextValue.toLowerCase();
    if(pricingData){
      const obj = pricingData.map(function(a) { 
        a.priceHead = a.priceHead.toString();
        a.priceDescription = a.priceDescription.toString();
        a.unitPrice = a.unitPrice.toString();
        return a;
      });
  
      const fdata = filter(obj, ({priceHead, priceDescription, unitPrice}) => {
        if((priceHead && priceHead.toLowerCase().includes(query)) || (priceDescription && priceDescription.toLowerCase().includes(query)) || (unitPrice && unitPrice.toLowerCase().includes(query)))
        {
          return true;
        }
        return false;
      });
      setFilteredPricingData(fdata);
    }
    
  }

  function submitNewprice(){
    setNewPriceLoader(false);
    if(priceHead.length && priceDescription.length && unitPrice.length){
      const pricekey = push(child(dref(dbr), 'pricing')).key;

      set(dref(dbr, storeID+'/pricing/' + storeID+'/'+pricekey), {
        priceHead: priceHead, 
        priceDescription: priceDescription, 
        unitPrice: unitPrice,
    
      }).then(()=>{
        setInitialLoader(true);
      });
    }
    else{
      alert(
        'Price head, description and price should not be empty'
      )
    }
  }

  function cancelNewprice(){
    setNewPriceLoader(false);
    setPriceLoader(true);
  }

  function editPrice(ky, nme, det, pr){
    setEditPricekey(ky);
    setEditPriceHeader(nme);
    setEditPriceDescription(det);
    setEditUnitPrice(pr);
    setPriceLoader(false);
    setEditPriceloader(true);
  }

  function cancelEditprice(){
    setEditPriceloader(false);
    setPriceLoader(true);
  }

  function submitEditPrice(){
   if(editPriceHeader.length && editPriceDescription.length && editUnitPrice.length){
    const priceKey = editPricekey;
    const priceHeader = editPriceHeader;
    const priceDescription = editPriceDescription;
    const unitPrice = editUnitPrice;
    set(dref(dbr, storeID+'/pricing/' + storeID+'/'+priceKey), {
      priceHeader:priceHeader,
      priceDescription:priceDescription,
      unitPrice:unitPrice
    }).then(()=>{
      setEditPriceloader(false);
      setInitialLoader(true);
    }).catch((error)=>{
      console.log(error);
    })
   }
   else{
    alert(
      'Price head, description and price should not be empty'
    )
   }
    
  }

  function deletePrice(){
    setEditPriceloader(false);
    setDeleteLoader(true);
  }

  function continueDeletePrice(){
    setPricingData('');
    setDeleteLoader(false);
    setEditPriceloader(false);
    setPriceLoader(false);
    set(dref(dbr, storeID+'/pricing/' + storeID+'/'+editPricekey), {
      null:null
    }).then(()=>{
      setPriceLoader(false);
      setInitialLoader(true);
    }).catch((error)=>{
      console.log(error);
    })
  }

  function cancelDeletePrice(){
    setDeleteLoader(false);
    setEditPriceloader(true);
  }

  

  return (
    <div>
      <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Pricing</span>
            </div>
          </li>
        </ol>
      </nav>
      {readAccess? 
      <>
    {priceLoader &&
      <>
        {pricingPresent? 
          <>
          {Object.keys(filteredPricingData).length ? 
          
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
                <div class="pb-4">
                    <label htmlFor="table-search" class="sr-only">Search</label>
                    <div class="relative mt-1">
                        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                        </div>
                        <label htmlFor="search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                          <div class="relative">
                              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                  <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                              </div>
                              <input value={searchText} onChange={(e) => inputChange(e.target.value)} type="search" id="search" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search"/>
                          </div>
                    </div>
                </div>
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="py-3 px-6">
                                Details
                            </th>
                            <th scope="col" class="py-3 px-6">
                                Price
                            </th>
                            <th scope="col" class="py-3 px-6">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {filteredPricingData.map((cdata, key)=>(
                        <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {cdata.priceHead}
                              <p>{cdata.priceDescription}</p>
                            </th>
                            <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {cdata.unitPrice}
                            </th>
                            <td class="py-4 px-6">
                            <button onClick={() => editPrice(cdata.priceKey, cdata.priceHead, cdata.priceDescription, cdata.unitPrice)} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center mr-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-blue-800">
                              <svg aria-hidden="true" class="w-3 h-3 mr-2 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path></svg>
                              Edit
                            </button> 
                            </td>
                        </tr>
                    ))}    
                    </tbody>
                </table>
                {pricingcreate && <button onClick={()=>onFloatinActionClick()} type="button" class="mt-5 ml-5 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Create New Pricing</button>}
            </div>
          :
            <main class="h-screen w-full flex flex-col justify-center items-center bg-purple-200">
              <h1 class="text-lg font-extrabold tracking-widest">You do not have Pricing List</h1>
              <h1 class="text-lg font-extrabold tracking-widest">Do you wish to create a new Pricing?</h1>
              <div class="flex mt-4 justify-center space-x-3 md:mt-6">
              <button onClick={()=>inputChange('')} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
              {pricingcreate && <button onClick={()=>onFloatinActionClick()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Create New Pricing</button>}
              </div>
            </main>
          }
          
          </>
          :
          <main class="h-screen w-full flex flex-col justify-center items-center">
            <h1 class="text-lg text-gray-900 dark:text-white">You have not added Prices</h1>
              <div class="-space-y-px rounded-md shadow-sm mt-6">
                  <button onClick={()=>onFloatinActionClick()} type="button" class="mt-5 ml-5 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Create New Pricing</button>
              </div>
          </main>
          
        }
      </>
    }
    {newPriceLoader &&
        <div>
          <p class="mb-5 text-xl text-gray-900 dark:text-white">New Price</p>
          <div class="relative z-0 w-full mb-6 group">
          <input maxLength={256} onChange={(e) => setPriceHead(e.target.value)} value={priceHead} type="text" name="Price Head" id="floating_email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
          <label htmlFor="Price Head" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Price Head</label>
          </div>
          <div class="relative z-0 w-full mb-6 group">
          <input maxLength={256} onChange={(e) => setPriceDescription(e.target.value)}  value={priceDescription} multiline={4} type="text" name="Price Description" id="Price Description" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
          <label htmlFor="Price description" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Price Description</label>
          </div>
          <div class="relative z-0 w-full mb-6 group">
          <input onChange={(e) => setUnitPrice(e.target.value)} value={unitPrice} maxLength={256} type="text" name="Unit Price" id="Unit Price" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
          <label htmlFor="Unit Price" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Unit Price</label>
            </div>
          <button onClick={()=>submitNewprice()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>
          <button onClick={()=>cancelNewprice()} type="button" class="ml-5 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
        </div>
      }
      {editPriceloader &&
        <div>
          <p class="mb-5 text-xl text-gray-900 dark:text-white">Edit Price</p>
          <div class="relative z-0 w-full mb-6 group">
          <input maxLength={256} onChange={(e) => setEditPriceHeader(e.target.value)} value={editPriceHeader} type="text" name="Package Name" id="floating_email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
          <label htmlFor="Package Name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Package Name</label>
          </div>
          <div class="relative z-0 w-full mb-6 group">
          <input maxLength={256} onChange={(e) => setEditPriceDescription(e.target.value)}  value={editPriceDescription} multiline={4} type="text" name="Package details" id="Package details" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
          <label htmlFor="Package details" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Package details</label>
          </div>
          <div class="relative z-0 w-full mb-6 group">
          <input onChange={(e) => setEditUnitPrice(e.target.value)} value={editUnitPrice} maxLength={256} type="text" name="Package Price" id="Package Price" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
          <label htmlFor="Package Price" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Package Price</label>
            </div>
          
          <button onClick={()=>cancelEditprice()} type="button" class="ml-5 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
          {pricingdelete && <button onClick={()=>deletePrice()} type="button" class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Delete</button>}
	        {pricingupdate && <button onClick={()=>submitEditPrice()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>}
        </div>
      }

      {deleteLoader &&
        <div id="alert-additional-content-2" class="p-4 mb-4 text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Delete Price?</h3>
          </div>
          <div class="mt-2 mb-4 text-sm">
            Are you sure want to continue?
          </div>
          <div class="flex">
            <button onClick={()=>continueDeletePrice()} type="button" class="text-white bg-red-800 hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Yes
            </button>
            <button onClick={()=>cancelDeletePrice()} type="button" class="text-red-800 bg-transparent border border-red-800 hover:bg-red-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-600 dark:border-red-600 dark:text-red-500 dark:hover:text-white dark:focus:ring-red-800" data-dismiss-target="#alert-additional-content-2" aria-label="Close">
              Dismiss
            </button>
          </div>
        </div>
      }
      </>:
      <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">You do not have permission to view Pricing. Contact your admin for permissions</h3>
          </div>
          <div class="flex">
            <button onClick={()=>navigate("/")} type="button" class="text-green-700 bg-transparent border border-green-700 hover:bg-green-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-green-500 dark:border-green-500 dark:text-green-400 dark:hover:text-white dark:focus:ring-green-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
              OK
            </button>
          </div>
      </div>
      }
    </div>
  )
}
