import React from 'react';
import ReactDOM from 'react-dom';
import PWAPrompt from 'react-ios-pwa-prompt';
import App from './App';
import * as serviceWorker from './serviceworker';

ReactDOM.render(
    <React.StrictMode>
        
        <App />
        <PWAPrompt
        promptOnVisit={1}
        timesToShow={3}
        copyClosePrompt="Close"
        permanentlyHideOnDismiss={false}
        />
        
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.register();