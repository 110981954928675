/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import { child, get, getDatabase, push, ref as dref, set } from "firebase/database";
import { collection, doc, getDocs, getFirestore, query, setDoc, where } from "firebase/firestore";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import PhoneInput, {parsePhoneNumber} from 'react-phone-number-input';
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from "../../contexts/UserStoreContext";
import firebaseapp from '../../firebase';
import ls from 'localstorage-slim';

export default function Employees({navigation, route}) {
  
  const navigate = useNavigate();
  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);
  const dbfirestore = getFirestore(firebaseapp);
  const { setBusinessName, businessName, storeID, setStoreID, countryCode, setCountryCode, staffcreate, staffupdate, staffdelete } = useUserStore();
  const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);
  const [listDataExist, setListDataExist] = useState(false)
  const [staffListInit, setStaffListInit] = useState(true)
  const [newStaffLoader, setNewStaffLoader] = useState(false)
  const [listLoader, setListLoader] = useState(false)
  const [staffName, setStaffName] = useState('');
  const [address, setAddress] = useState('');
  const [sunChecked, setSunChecked] = useState(false);
  const [monChecked, setMonChecked] = useState(false);
  const [tueChecked, setTueChecked] = useState(false);
  const [wedChecked, setWedChecked] = useState(false);
  const [thuChecked, setThuChecked] = useState(false);
  const [friChecked, setFriChecked] = useState(false);
  const [satChecked, setSatChecked] = useState(false);
  const [sunStartTime, setSunStartTime] = useState(new Date());
  const [sunEndTime, setSunEndTime] = useState(new Date());
  const [monStartTime, setMonStartTime] = useState(new Date());
  const [monEndTime, setMonEndTime] = useState(new Date());
  const [tueStartTime, setTueStartTime] = useState(new Date());
  const [tueEndTime, setTueEndTime] = useState(new Date());
  const [wedStartTime, setWedStartTime] = useState(new Date());
  const [wedEndTime, setWedEndTime] = useState(new Date());
  const [thuStartTime, setThuStartTime] = useState(new Date());
  const [thuEndTime, setThuEndTime] = useState(new Date());
  const [friStartTime, setFriStartTime] = useState(new Date());
  const [friEndTime, setFriEndTime] = useState(new Date());
  const [satStartTime, setSatStartTime] = useState(new Date());
  const [satEndTime, setSatEndTime] = useState(new Date());
  const [email, setEmail] = useState('');
  const [phonenumber, setphonenumber] = useState([{key: '', value: ''}]);
  const [listData, setListData] = useState('');
  const [editItem, setEditItem] = useState('');
  const [editLoader, setEditLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [filterListData, setFilterListData] = useState('');
  const [searchQuery, setSearchQuery] = useState('')
  const [emailempty, setEmailempty] = useState(false)
  const [customerexist, setCustomerexist] = useState(false)
  
  const addcHandler = ()=>{
    const _phonenumber = [...phonenumber];
    const kl = Object.keys(_phonenumber).length+1;
    _phonenumber.push({key: kl, value: ''});
    setphonenumber(_phonenumber);
  }
  const deletecHandler = (ckey)=>{
    const _phonenumber = phonenumber.filter((cinput,index) => index !== ckey);
    setphonenumber(_phonenumber);
  }
  

  const inputcHandler = (text, ckey)=>{
    const _phonenumber = [...phonenumber];
    _phonenumber[ckey].value = text;
    _phonenumber[ckey].key   = ckey;
    setphonenumber(_phonenumber);
    
  }
  if(!storeID){
    setStoreID(userdata.storeID);
  }
  if(!businessName){
    setBusinessName(userdata.businessName);
  }
    
  if(!countryCode){
    setCountryCode(userdata.country);
  }

  
  
  
  
  useEffect(() => { 

    if(staffListInit){
      function listStaffData(){
        get(child(dbRef, `${storeID}/staff/${storeID}/`)).then(async (snapshot) => {
          const listdata = [];
          if (snapshot.exists()) {
            snapshot.forEach(element => {
              const key = element.key;
              const FriEndTime = element.child('FriEndTime').val(); 
              const FriStartTime = element.child('FriStartTime').val(); 
              const MonEndTime = element.child('MonEndTime').val(); 
              const MonStartTime = element.child('MonStartTime').val(); 
              const SatEndTime = element.child('SatEndTime').val(); 
              const SatStartTime = element.child('SatStartTime').val(); 
              const SunEndTime = element.child('SunEndTime').val(); 
              const SunStartTime = element.child('SunStartTime').val(); 
              const ThuEndTime = element.child('ThuEndTime').val(); 
              const ThuStartTime = element.child('ThuStartTime').val(); 
              const TueEndTime = element.child('TueEndTime').val(); 
              const TueStartTime = element.child('TueStartTime').val(); 
              const WedEndTime = element.child('WedEndTime').val(); 
              const WedStartTime = element.child('WedStartTime').val(); 
              const address = element.child('address').val(); 
              const email = element.child('email').val(); 
              const friChecked = element.child('friChecked').val(); 
              const monChecked = element.child('monChecked').val(); 
              const phonenumber = element.child('phonenumber').val();
              const satChecked = element.child('satChecked').val(); 
              const staffName = element.child('staffName').val(); 
              const sunChecked = element.child('sunChecked').val(); 
              const thuChecked = element.child('thuChecked').val(); 
              const tueChecked = element.child('tueChecked').val(); 
              const wedChecked = element.child('wedChecked').val(); 
              listdata.push({
                key:key,
                FriEndTime: FriEndTime, 
                FriStartTime:FriStartTime, 
                MonEndTime:MonEndTime, 
                MonStartTime:MonStartTime, 
                SatEndTime:SatEndTime, 
                SatStartTime:SatStartTime, 
                SunEndTime:SunEndTime, 
                SunStartTime:SunStartTime, 
                ThuEndTime:ThuEndTime, 
                ThuStartTime:ThuStartTime, 
                TueEndTime:TueEndTime, 
                TueStartTime:TueStartTime, 
                WedEndTime:WedEndTime, 
                WedStartTime:WedStartTime, 
                address:address, 
                email:email, 
                friChecked:friChecked, 
                monChecked:monChecked, 
                phonenumber:phonenumber,
                satChecked:satChecked, 
                staffName:staffName, 
                sunChecked:sunChecked, 
                thuChecked:thuChecked, 
                tueChecked:tueChecked, 
                wedChecked:wedChecked, 
                })

            });
            const completedListData = await Promise.all(listdata);
            const dataExist =  true;
            return [dataExist, completedListData];;
          }
          else{
            const dataExist =  false;
            return [dataExist, 0];;
          }
          
        }).then((dataExist)=>{
          if(dataExist[0]){
            setListData(dataExist[1]);
            setFilterListData(dataExist[1])
            setListDataExist(true)
            setListLoader(true);
          }else{
            setListDataExist(false)
            setListLoader(true);
          }
        }).catch((error)=>{
          console.log(error);
        });
     }
     listStaffData();
    }
   
   
  
  }, [staffListInit]);
  
  function newStaffCreator() {
  setStaffName('');
  setAddress('');
  setSunChecked(false);
  setMonChecked(false);
  setTueChecked(false);
  setWedChecked(false);
  setThuChecked(false);
  setFriChecked(false);
  setSatChecked(false);
  setSunStartTime(new Date());
  setSunEndTime(new Date());
  setMonStartTime(new Date());
  setMonEndTime(new Date());
  setTueStartTime(new Date());
  setTueEndTime(new Date());
  setWedStartTime(new Date());
  setWedEndTime(new Date());
  setThuStartTime(new Date());
  setThuEndTime(new Date());
  setFriStartTime(new Date());
  setFriEndTime(new Date());
  setSatStartTime(new Date());
  setSatEndTime(new Date());
  setEmail('');
  setphonenumber([{key: '', value: ''}]);


    setListLoader(false);
    setStaffListInit(false);
    setNewStaffLoader(true);
  } 

  function cancelNewStaff(){
    setNewStaffLoader(false);
    setStaffListInit(false);
    setListLoader(true);
  }

  function afterNewStaffCreation(){
    setShowModal(false);
    setNewStaffLoader(false);
    setStaffListInit(false);
    setListLoader(false);
    setStaffListInit(true);

  }

  async function onSubmitNewStaff(){
    if(email){
      setEmailempty(false);
      const colRef = collection(dbfirestore, "staff");
      const qry = query(colRef, where('email', '==', email));
      const querySnapshot = await getDocs(qry);
      if (querySnapshot.empty) {
      setCustomerexist(false);
      const SunStartTime = moment(sunStartTime).toISOString();
      const MonStartTime = moment(monStartTime).toISOString();
      const TueStartTime = moment(tueStartTime).toISOString();
      const WedStartTime = moment(wedStartTime).toISOString();
      const ThuStartTime = moment(thuStartTime).toISOString();
      const FriStartTime = moment(friStartTime).toISOString();
      const SatStartTime = moment(satStartTime).toISOString();
      const SunEndTime = moment(sunEndTime).toISOString();
      const MonEndTime = moment(monEndTime).toISOString();
      const TueEndTime = moment(tueEndTime).toISOString();
      const WedEndTime = moment(wedEndTime).toISOString();
      const ThuEndTime = moment(thuEndTime).toISOString();
      const FriEndTime = moment(friEndTime).toISOString();
      const SatEndTime = moment(satEndTime).toISOString();
      const key = push(child(dref(dbr), 'staff')).key;
      const one = setDoc(doc(dbfirestore, 'staff/' + email), {
        email:email,
        storeID:storeID, 
        businessName:businessName,
        countryCode:countryCode,
      })
      let anumber = [];
      let keynum = 1;
      if(phonenumber.length){
        for (const key in phonenumber) {
          if (Object.hasOwnProperty.call(phonenumber, key)) {
            const element = phonenumber[key];
            if(element.key){
              console.log("phonenumber exist");
              anumber.push({key: keynum, value: parsePhoneNumber(element.value).nationalNumber, code:parsePhoneNumber(element.value).countryCallingCode, country:parsePhoneNumber(element.value).country})
              keynum = keynum+1;
            }else{
              console.log("phonenumber not exist");
              anumber.push({key: '1', value: ''})
            }
            
          }
        }
      }
      else{
        anumber.push({key: '1', value: ''})
      }
      const two = set(dref(dbr, storeID+'/staff/' + storeID+'/'+key), {
        staffName,
        address,
        email,
        sunChecked,
        monChecked,
        tueChecked,
        wedChecked,
        thuChecked,
        friChecked,
        satChecked,
        SunStartTime, 
        MonStartTime,
        TueStartTime,
        WedStartTime,
        ThuStartTime,
        FriStartTime,
        SatStartTime,
        SunEndTime,
        MonEndTime,
        TueEndTime,
        WedEndTime,
        ThuEndTime,
        FriEndTime,
        SatEndTime,
        countryCode:countryCode,
        phonenumber:anumber
        
      });
      const final = [one, two];
      const finalPromise = Promise.all(final);
      finalPromise.then(()=>{
        setNewStaffLoader(false);
        setShowModal(true);
      })
      }else{
        setCustomerexist(true);
      } 
    }else{
      setEmailempty(true);
    }
  }

  function editStaff(item){
    let anumber = [];
    let keynum = 1;
    if(item.phonenumber.length){
      for (const key in item.phonenumber) {
        if (Object.hasOwnProperty.call(item.phonenumber, key)) {
          const element = item.phonenumber[key];
          const numbr = "+"+element.code+element.value;
          anumber.push({key: keynum, value:numbr})
          keynum = keynum+1;
        }
      }
    }
    else{
      anumber.push({key: '1', value: ''})
    }

    const FriEndTime = moment(item.FriEndTime).toDate();
    const FriStartTime = moment(item.FriStartTime).toDate();
    const MonEndTime = moment(item.MonEndTime).toDate();
    const MonStartTime = moment(item.MonStartTime).toDate();
    const SatEndTime = moment(item.SatEndTime).toDate();
    const SatStartTime = moment(item.SatStartTime).toDate();
    const SunEndTime = moment(item.SunEndTime).toDate();
    const SunStartTime = moment(item.SunStartTime).toDate();
    const ThuEndTime = moment(item.ThuEndTime).toDate();
    const ThuStartTime = moment(item.ThuStartTime).toDate();
    const TueEndTime = moment(item.TueEndTime).toDate();
    const TueStartTime = moment(item.TueStartTime).toDate();
    const WedEndTime = moment(item.WedEndTime).toDate();
    const WedStartTime = moment(item.WedStartTime).toDate();
    const address = item.address;
    const email = item.email;
    const friChecked = item.friChecked;
    const key = item.key;
    const monChecked = item.monChecked;
    const satChecked = item.satChecked;
    const staffName = item.staffName;
    const sunChecked = item.sunChecked;
    const thuChecked = item.thuChecked;
    const tueChecked = item.tueChecked;
    const wedChecked = item.wedChecked;
    setEditItem({FriEndTime:FriEndTime,
      FriStartTime:FriStartTime,
      MonEndTime:MonEndTime,
      MonStartTime:MonStartTime,
      SatEndTime:SatEndTime,
      SatStartTime:SatStartTime,
      SunEndTime:SunEndTime,
      SunStartTime:SunStartTime,
      ThuEndTime:ThuEndTime,
      ThuStartTime:ThuStartTime,
      TueEndTime:TueEndTime,
      TueStartTime:TueStartTime,
      WedEndTime:WedEndTime,
      WedStartTime:WedStartTime,
      address:address,
      email:email,
      friChecked:friChecked,
      key:key,
      monChecked:monChecked,
      phonenumber:anumber,
      satChecked:satChecked,
      staffName:staffName,
      sunChecked:sunChecked,
      thuChecked:thuChecked,
      tueChecked:tueChecked,
      wedChecked:wedChecked});
    setListLoader(false);
    setEditLoader(true);
  }

  function cancelEditStaff(){
    setEditItem('');
    setEditLoader(false);
    setListLoader(true);
  }

  function onSubmitEditStaff(){
    setStaffListInit(false);
    const key = editItem.key;
    const staffName= editItem.staffName;
    const address= editItem.address;
    const email= editItem.email;
    const sunChecked= editItem.sunChecked;
    const monChecked= editItem.monChecked;
    const tueChecked= editItem.tueChecked;
    const wedChecked= editItem.wedChecked;
    const thuChecked= editItem.thuChecked;
    const friChecked= editItem.friChecked;
    const satChecked= editItem.satChecked;
    const SunStartTime = moment(editItem.SunStartTime).toISOString();
    const MonStartTime = moment(editItem.MonStartTime).toISOString();
    const TueStartTime = moment(editItem.TueStartTime).toISOString();
    const WedStartTime = moment(editItem.WedStartTime).toISOString();
    const ThuStartTime = moment(editItem.ThuStartTime).toISOString();
    const FriStartTime = moment(editItem.FriStartTime).toISOString();
    const SatStartTime = moment(editItem.SatStartTime).toISOString();
    const SunEndTime = moment(editItem.SunEndTime).toISOString();
    const MonEndTime = moment(editItem.MonEndTime).toISOString();
    const TueEndTime = moment(editItem.TueEndTime).toISOString();
    const WedEndTime = moment(editItem.WedEndTime).toISOString();
    const ThuEndTime = moment(editItem.ThuEndTime).toISOString();
    const FriEndTime = moment(editItem.FriEndTime).toISOString();
    const SatEndTime = moment(editItem.SatEndTime).toISOString();
    let anumber = [];
    let keynum = 1;
    if(editItem.phonenumber.length){
      for (const key in editItem.phonenumber) {
        if (Object.hasOwnProperty.call(editItem.phonenumber, key)) {
          const element = editItem.phonenumber[key];
          anumber.push({key: keynum, value: parsePhoneNumber(element.value).nationalNumber, code:parsePhoneNumber(element.value).countryCallingCode, country:parsePhoneNumber(element.value).country})
          keynum = keynum+1;
        }
      }
    }
    else{
      anumber.push({key: '1', value: ''})
    }

    set(dref(dbr, storeID+'/staff/' + storeID +"/"+key), {
      staffName,
      businessName,
      address,
      email,
      sunChecked,
      monChecked,
      tueChecked,
      wedChecked,
      thuChecked,
      friChecked,
      satChecked,
      SunStartTime, 
      MonStartTime,
      TueStartTime,
      WedStartTime,
      ThuStartTime,
      FriStartTime,
      SatStartTime,
      SunEndTime,
      MonEndTime,
      TueEndTime,
      WedEndTime,
      ThuEndTime,
      FriEndTime,
      SatEndTime,
      countryCode:countryCode,
      phonenumber:anumber,
    }).then(()=>{
      setEditLoader(false);
      setEditItem('');
      setStaffListInit(true);
    })
  }

  function deleteStaff(){
    setEditLoader(false);
    setDeleteModal(true);
  }

  function cancelDelete(){
    setDeleteModal(false);
    setEditLoader(true);
  }

  function continueDeleteStaff(){
    setDeleteModal(false);
    setStaffListInit(false);
    const key = editItem.key;
    set(dref(dbr, storeID+'/staff/' + storeID+'/'+key), {
      null:null
    }).then(()=>{
      setEditLoader(false);
      setEditItem('');
      setStaffListInit(true);
    })
  }

  const inputeditHandler = (text, ckey)=>{
    const _phonenumber = [...editItem.phonenumber];
    _phonenumber[ckey].value = text;
    _phonenumber[ckey].key   = ckey;
    setEditItem({...editItem, phonenumber:_phonenumber});
  }

  const deleteeditHandler = (ckey)=>{
    const _phonenumber = editItem.phonenumber.filter((cinput,index) => index !== ckey);
    setEditItem({...editItem, phonenumber:_phonenumber});
  }

  const addeditHandler = ()=>{
    if(Object.keys(editItem.phonenumber).length){
      const _phonenumber = [...editItem.phonenumber];
      const kl = Object.keys(_phonenumber).length+1;
      _phonenumber.push({key: kl, value: ''});
      setEditItem({...editItem, phonenumber:_phonenumber});
    }else{
      
      const _phonenumber = [];
      _phonenumber.push({key: 1, value: ''});
      setEditItem({...editItem, phonenumber:_phonenumber});
    }
  }

  function handleSearch(e){
    setSearchQuery(e);
    const elow = e.toLowerCase();
    const fData = listData.filter(item => {
      return (
        (item.staffName && item.staffName.toLowerCase().includes(elow)));
    }
    );
    setFilterListData(fData);
  };



  return (
    <div>
      <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <Link to='/staff' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              Staff
            </Link>
            </div>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Employees</span>
            </div>
          </li>
        </ol>
      </nav>
      {listLoader && 
        <>
          {listDataExist? 
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
                  <div class="flex items-center justify-between">
                  <p class="mb-5 text-xl text-gray-900 dark:text-white">Employee List</p>
                  {staffcreate && <button onClick={()=>newStaffCreator()} type="button" class="text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-5 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">New Staff</button>}
                  </div>
                  <div class="relative z-0 mb-6 w-1/2 ml-5 mr-5 group ">
                    <input value={searchQuery} onChange={(e)=>handleSearch(e.target.value)} type="text" name="sku" id="sku" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                    <label htmlFor="sku" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Search</label>
                  </div>
                  <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                              <th scope="col" class="py-3 px-6">
                                  Name
                              </th>
                              <th scope="col" class="py-3 px-6">
                                  Phonenumber
                              </th>
                              <th scope="col" class="py-3 px-6">
                                  Action
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                      {filterListData.map((item, key)=>(
                          <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                              <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                <p>{item.staffName}</p>
                              </th>
                              <td class="py-4 px-6">
                              {item.phonenumber.map((ph, ckey)=>(
                                (ph.value) && <p key={ckey}>+{ph.code}{ph.value}</p> 
                                ))}
                              </td>
                              <td class="py-4 px-6">
                                <button onClick={()=>editStaff(item)} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center mr-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-blue-800">
                                  Edit
                                </button>
                              </td>
                          </tr>
                      ))}    
                      </tbody>
                  </table>
              </div>
            :
            <div id="alert" class="p-4 mb-4 text-yellow-700 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800" role="alert">
              <div class="flex items-center">
                <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                <h3 class="text-lg font-medium">You have not added staff</h3>
              </div>
              <div class="mt-2 mb-4 text-sm">
                Do you wish to add staff?
              </div>
              <div class="flex">
                {staffcreate &&
                <button onClick={()=>newStaffCreator()} type="button" class="text-white bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800">
                  <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
                  Yes
                </button>
                }
                <button onClick={()=>navigate("/staff")} type="button" class="text-yellow-700 bg-transparent border border-yellow-700 hover:bg-yellow-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-yellow-500 dark:border-yellow-500 dark:text-yellow-400 dark:hover:text-white dark:focus:ring-yellow-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                  Dismiss
                </button>
              </div>
            </div>
          } 
        </>
      }
      {newStaffLoader &&
        <>
          <div class="mt-2 mb-2 text-lg">New Staff</div>
          <div class="relative z-0 w-full mb-6 group">
            <input value={staffName} onChange={(e)=>setStaffName(e.target.value)} type="text" name="Staff Name" id="Staff Name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
            <label htmlFor="Staff Name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Staff Name</label>
          </div>
          <div class="relative z-0 mb-6 w-full group">
              <input value={address} onChange={(e)=>setAddress(e.target.value)} type="text" name="address" id="address" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "/>
              <label htmlFor="address" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Address</label>
          </div>
          <div class="relative z-0 mb-6 w-full group">
              <input value={email} onChange={(e)=>setEmail(e.target.value)} type="text" name="email" id="email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "/>
              <label htmlFor="email" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email - Login ID</label>
          </div>
          
          <div class="relative z-0 mb-6 w-full group">
          {phonenumber.map((cinput, ckey)=>(
          <div key={ckey} class="relative z-0 mb-6 w-full group">
          <label htmlFor="phonenumber" class="mb-5 text-sm text-gray-500 dark:text-gray-400">Phone Number</label>
          <PhoneInput
            id="businessUserPhoneNumber"
            class="block py-2.5 ps-6 pe-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            international
            withDarkTheme
            placeholder="Search Customer Phonenumber"
            defaultCountry={countryCode}
            value={cinput.value}
            onChange={(e)=>inputcHandler(e, ckey)}
            />
            <button onClick={()=> deletecHandler(ckey)} type="button" class="absolute top-5 right-0 p-2.5 text-red-700 hover:text-purple font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:text-red-500 dark:hover:text-purple"><svg aria-hidden="true" class="w-5 h-5" fill="#ffffff" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path></svg></button>
            
          </div>
          ))}
          <button onClick={()=>addcHandler()} type="button" class="mb-6 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Add Another Number</button>
          <p class="text-xl text-gray-900 dark:text-white">Shift Working Hours</p>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          
            <table class="mt-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                        Day
                    </th>
                    <th scope="col" class="px-6 py-3">
                        From
                    </th>
                    <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                        To
                    </th>
                </tr>
              </thead>
              <tbody>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox"  class="sr-only peer" value={sunChecked} onChange={()=>setSunChecked(sunChecked => !sunChecked)}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Sunday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                    {sunChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(date)=>setSunStartTime(date)} selected={sunStartTime} />:<></>}
                  </td>
                  <td class="px-6 py-4">
                    {sunChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(date)=>setSunEndTime(date)} selected={sunEndTime} />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" class="sr-only peer" value={monChecked} onChange={()=>setMonChecked(monChecked => !monChecked)}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Monday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {monChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(date)=>setMonStartTime(date)} selected={monStartTime} />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {monChecked?   <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(date)=>setMonEndTime(date)} selected={monEndTime} />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox"  class="sr-only peer" value={tueChecked} onChange={()=>setTueChecked(tueChecked => !tueChecked)}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Tuesday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {tueChecked?   <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(date)=>setTueStartTime(date)} selected={tueStartTime} />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {tueChecked?   <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(date)=>setTueEndTime(date)} selected={tueEndTime} />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox"  class="sr-only peer" value={wedChecked} onChange={()=>setWedChecked(wedChecked => !wedChecked)}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Wednesday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {wedChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(date)=>setWedStartTime(date)} selected={wedStartTime} />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {wedChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(date)=>setWedEndTime(date)} selected={wedEndTime} />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox"  class="sr-only peer" value={thuChecked} onChange={()=>setThuChecked(thuChecked => !thuChecked)}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Thursday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {thuChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(date)=>setThuStartTime(date)} selected={thuStartTime} />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {thuChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(date)=>setThuEndTime(date)} selected={thuEndTime} />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox"  class="sr-only peer" value={friChecked} onChange={()=>setFriChecked(friChecked => !friChecked)}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Friday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {friChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(date)=>setFriStartTime(date)} selected={friStartTime} />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {friChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(date)=>setFriEndTime(date)} selected={friEndTime} />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox"  class="sr-only peer" value={satChecked} onChange={()=>setSatChecked(satChecked => !satChecked)}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Saturday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {satChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(date)=>setSatStartTime(date)} selected={satStartTime} />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {satChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(date)=>setSatEndTime(date)} selected={satEndTime} />:<></>}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {emailempty && 
          <div id="alert-2" class="flex items-center p-4 mb-4 text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
            <svg class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <span class="sr-only">Info</span>
            <div class="ms-3 text-sm font-medium">
              Email should not be empty
            </div>
            
          </div>
          }
          {customerexist && 
            <div id="alert-2" class="flex items-center p-4 mb-4 text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
              <svg class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span class="sr-only">Info</span>
              <div class="ms-3 text-sm font-medium">
                Staff  with this email address already exists.
              </div>  
            </div>
          }
          <div class="relative z-0 mb-6 mt-6 w-full group">
          <button onClick={()=>onSubmitNewStaff()} type="button" class="mt-2 mr-2 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>
          <button onClick={()=>cancelNewStaff()} type="button" class="mt-2 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
          </div>
          </div>
          
        </>
      }
      {showModal && 
        <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Staff created successfully</h3>
          </div>
          <div class="flex">
            <button onClick={()=>afterNewStaffCreation()} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              ok
            </button>
          </div>
        </div>
      }
      {editLoader &&
        <>
          <div class="mt-2 mb-5 text-lg">Edit Staff</div>
          
          <div class="relative z-0 w-full mb-6 group">
            <input value={editItem.staffName} onChange={(e)=>setEditItem({...editItem, staffName:e.target.value})} type="text" name="Staff Name" id="Staff Name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
            <label htmlFor="Staff Name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Staff Name</label>
          </div>
          <div class="relative z-0 mb-6 w-full group">
              <input value={editItem.address} onChange={(e)=>setEditItem({...editItem, address:e.target.value})} type="text" name="address" id="address" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "/>
              <label htmlFor="address" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Address</label>
          </div>
          <div class="relative z-0 mb-6 w-full group">
              <input value={editItem.email} disabled={true} onChange={(e)=>setEditItem({...editItem, email:e.target.value})} type="text" name="email" id="email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "/>
              <label htmlFor="email" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email</label>
          </div>
          
          <div class="relative z-0 mb-6 w-full group">
          {editItem.phonenumber.map((cinput, ckey)=>(
          <div key={ckey} class="relative z-0 mb-6 w-full group">
          <label htmlFor="phonenumber" class="mb-5 text-sm text-gray-500 dark:text-gray-400">Phone Number</label>
          <PhoneInput
            id="businessUserPhoneNumber"
            class="block py-2.5 ps-6 pe-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            international
            withDarkTheme
            placeholder="Search Customer Phonenumber"
            defaultCountry={countryCode}
            value={cinput.value}
            onChange={(e)=>inputeditHandler(e, ckey)}
            />
          <button onClick = {()=> deleteeditHandler(ckey)} type="button" class="absolute top-5 right-0 p-2.5 text-red-700 hover:text-purple font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:text-red-500 dark:hover:text-purple"><svg aria-hidden="true" class="w-5 h-5" fill="#ffffff" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path></svg></button>
          </div>
          ))}
          <button onClick={()=>addeditHandler()} type="button" class="mb-6 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Add Another Number</button>
          <p class="text-xl text-gray-900 dark:text-white">Shift Working Hours</p>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          
            <table class="mt-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                        Day
                    </th>
                    <th scope="col" class="px-6 py-3">
                        From
                    </th>
                    <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                        To
                    </th>
                </tr>
              </thead>
              <tbody>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" class="sr-only peer" checked={editItem.sunChecked} onChange={()=>setEditItem({...editItem, sunChecked:!editItem.sunChecked})}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Sunday {editItem.sunChecked}</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                    {editItem.sunChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(e)=>setEditItem({...editItem, SunStartTime:e})} selected={editItem.SunStartTime} />:<></>}
                  </td>
                  <td class="px-6 py-4">
                    {editItem.sunChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(e)=>setEditItem({...editItem, SunEndTime:e})} selected={editItem.SunEndTime} />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" class="sr-only peer" checked={editItem.monChecked} onChange={()=>setEditItem({...editItem, monChecked:!editItem.monChecked})}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Monday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {editItem.monChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(e)=>setEditItem({...editItem, MonStartTime:e})} selected={editItem.MonStartTime} />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {editItem.monChecked?   <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(e)=>setEditItem({...editItem, MonEndTime:e})} selected={editItem.MonEndTime} />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox"  class="sr-only peer" checked={editItem.tueChecked} onChange={()=>setEditItem({...editItem, tueChecked:!editItem.tueChecked})}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Tuesday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {editItem.tueChecked?   <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(e)=>setEditItem({...editItem, TueStartTime:e})} selected={editItem.TueStartTime} />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {editItem.tueChecked?   <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(e)=>setEditItem({...editItem, TueEndTime:e})} selected={editItem.TueEndTime} />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox"  class="sr-only peer" checked={editItem.wedChecked} onChange={()=>setEditItem({...editItem, wedChecked:!editItem.wedChecked})}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Wednesday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {editItem.wedChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(e)=>setEditItem({...editItem, WedEndTime:e})} selected={editItem.WedStartTime} />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {editItem.wedChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(e)=>setEditItem({...editItem, WedEndTime:e})} selected={editItem.WedEndTime} />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox"  class="sr-only peer" checked={editItem.thuChecked} onChange={()=>setEditItem({...editItem, thuChecked:!editItem.thuChecked})}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Thursday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {editItem.thuChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(e)=>setEditItem({...editItem, ThuEndTime:e})} selected={editItem.ThuStartTime} />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {editItem.thuChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(e)=>setEditItem({...editItem, ThuEndTime:e})} selected={editItem.ThuEndTime} />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox"  class="sr-only peer" checked={editItem.friChecked} onChange={()=>setEditItem({...editItem, friChecked:!editItem.friChecked})}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Friday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {editItem.friChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(e)=>setEditItem({...editItem, FriEndTime:e})} selected={editItem.FriStartTime} />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {editItem.friChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(e)=>setEditItem({...editItem, FriEndTime:e})} selected={editItem.FriEndTime} />:<></>}
                  </td>
                </tr>
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                  <label class="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox"  class="sr-only peer" checked={editItem.satChecked} onChange={()=>setEditItem({...editItem, satChecked:!editItem.satChecked})}/>
                    <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-purple-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Saturday</span>
                    </label>
                  </th>
                  <td class="px-6 py-4">
                  {editItem.satChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(e)=>setEditItem({...editItem, SatEndTime:e})} selected={editItem.SatStartTime} />:<></>}
                  </td>
                  <td class="px-6 py-4">
                  {editItem.satChecked? <DatePicker className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" showTimeSelect showTimeSelectOnly timeIntervals={1} timeCaption="Time"  dateFormat="h:mm aa" onChange={(e)=>setEditItem({...editItem, SatEndTime:e})} selected={editItem.SatEndTime} />:<></>}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="relative z-0 mb-6 mt-6 w-full group">
          {staffupdate && <button onClick={()=>onSubmitEditStaff()} type="button" class="mt-2 mr-2 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Save</button>}
          <button onClick={()=>cancelEditStaff()} type="button" class="mt-2 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
          {staffdelete && <button onClick={()=>deleteStaff()} type="button" class="mt-2 text-red-900 bg-white border border-red-300 focus:outline-none hover:bg-red-100 focus:ring-4 focus:ring-red-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-800 dark:text-white dark:border-red-600 dark:hover:bg-red-700 dark:hover:border-red-600 dark:focus:ring-red-700">Delete</button>}
          </div>
          </div>
        </>
      }
      {deleteModal && 
        <div id="alert" class="p-4 mb-4 text-red-700 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Delete Staff?</h3>
          </div>
          <div class="mt-2 mb-4 text-sm">
            Do you wish to continue?
          </div>
          <div class="flex">
            <button onClick={()=>continueDeleteStaff()} type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Yes
            </button>
            <button onClick={()=>cancelDelete()} type="button" class="text-red-700 bg-transparent border border-red-700 hover:bg-red-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-500 dark:border-red-500 dark:text-red-400 dark:hover:text-white dark:focus:ring-red-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
              Dismiss
            </button>
          </div>
        </div>
      }

    </div>

  );
}

