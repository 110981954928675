/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from "../contexts/UserStoreContext";

export default function Inventory() {
     const navigate = useNavigate();
     const {inventoryread, inventorycreate} = useUserStore();

     
         
  return (
     <>
     <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Inventory</span>
            </div>
          </li>
        </ol>
      </nav>
     {inventoryread? 
     <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 lg:gap-4 flex flex-col items-center justify-center ">
               
                    <Link to='/inventoryList'>
                    <div class="p-2 bg-[#F3B0C3] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <path d="M4 6h2v2H4zm0 5h2v2H4zm0 5h2v2H4zm16-8V6H8.023v2H18.8zM8 11h12v2H8zm0 5h12v2H8z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Item List</h5>
                         </div>
                    </div>
                    </Link>
                    <Link to='/inventoryUpdate'>
                    <div class="p-2 bg-[#a2e1db] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <path d="M4 7h11v2H4zm0 4h11v2H4zm0 4h7v2H4zm15.299-2.708-4.3 4.291-1.292-1.291-1.414 1.415 2.706 2.704 5.712-5.703z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Stock Update</h5>
                         </div>
                    </div>
                    </Link>
                    {inventorycreate && 
                    <Link to='/inventoryNewitem'>
                    <div class="p-2 bg-[#ffffb5] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <path d="M20 5h-9.586L8.707 3.293A.997.997 0 0 0 8 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V7c0-1.103-.897-2-2-2zm-4 9h-3v3h-2v-3H8v-2h3V9h2v3h3v2z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">New Item</h5>
                         </div>
                    </div>
                    </Link>
                    }
                    <Link to='/inventoryAudit'>
                    <div class="p-2 bg-[#cbaacb] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <path d="M11.488 21.754c.294.157.663.156.957-.001 8.012-4.304 8.581-12.713 8.574-15.104a.988.988 0 0 0-.596-.903l-8.05-3.566a1.005 1.005 0 0 0-.813.001L3.566 5.747a.99.99 0 0 0-.592.892c-.034 2.379.445 10.806 8.514 15.115zM8.674 10.293l2.293 2.293 4.293-4.293 1.414 1.414-5.707 5.707-3.707-3.707 1.414-1.414z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Audit</h5>
                         </div>
                    </div>
                    </Link>
                    <Link to='/inventorySettings'>
                    <div class="p-2 bg-[#cce2cb] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <path d="M12 16c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.084 0 2 .916 2 2s-.916 2-2 2-2-.916-2-2 .916-2 2-2z"></path><path d="m2.845 16.136 1 1.73c.531.917 1.809 1.261 2.73.73l.529-.306A8.1 8.1 0 0 0 9 19.402V20c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-.598a8.132 8.132 0 0 0 1.896-1.111l.529.306c.923.53 2.198.188 2.731-.731l.999-1.729a2.001 2.001 0 0 0-.731-2.732l-.505-.292a7.718 7.718 0 0 0 0-2.224l.505-.292a2.002 2.002 0 0 0 .731-2.732l-.999-1.729c-.531-.92-1.808-1.265-2.731-.732l-.529.306A8.1 8.1 0 0 0 15 4.598V4c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v.598a8.132 8.132 0 0 0-1.896 1.111l-.529-.306c-.924-.531-2.2-.187-2.731.732l-.999 1.729a2.001 2.001 0 0 0 .731 2.732l.505.292a7.683 7.683 0 0 0 0 2.223l-.505.292a2.003 2.003 0 0 0-.731 2.733zm3.326-2.758A5.703 5.703 0 0 1 6 12c0-.462.058-.926.17-1.378a.999.999 0 0 0-.47-1.108l-1.123-.65.998-1.729 1.145.662a.997.997 0 0 0 1.188-.142 6.071 6.071 0 0 1 2.384-1.399A1 1 0 0 0 11 5.3V4h2v1.3a1 1 0 0 0 .708.956 6.083 6.083 0 0 1 2.384 1.399.999.999 0 0 0 1.188.142l1.144-.661 1 1.729-1.124.649a1 1 0 0 0-.47 1.108c.112.452.17.916.17 1.378 0 .461-.058.925-.171 1.378a1 1 0 0 0 .471 1.108l1.123.649-.998 1.729-1.145-.661a.996.996 0 0 0-1.188.142 6.071 6.071 0 0 1-2.384 1.399A1 1 0 0 0 13 18.7l.002 1.3H11v-1.3a1 1 0 0 0-.708-.956 6.083 6.083 0 0 1-2.384-1.399.992.992 0 0 0-1.188-.141l-1.144.662-1-1.729 1.124-.651a1 1 0 0 0 .471-1.108z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Item Settings</h5>
                         </div>
                    </div>
                    </Link>
                    <Link to='/inventoryVendor'>
                    <div  class="p-2 bg-[#ffaea5] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <path d="M20 2H8a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm-6 2.5a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zM19 15H9v-.25C9 12.901 11.254 11 14 11s5 1.901 5 3.75V15z"></path><path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Vendor</h5>
                         </div>
                    </div>
                    </Link>
                    <Link to='/inventoryOrder'>
                    <div  class="p-2 bg-[#abdee6] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <circle cx="10.5" cy="19.5" r="1.5"></circle><circle cx="17.5" cy="19.5" r="1.5"></circle><path d="M21 7H7.334L6.18 4.23A1.995 1.995 0 0 0 4.333 3H2v2h2.334l4.743 11.385c.155.372.52.615.923.615h8c.417 0 .79-.259.937-.648l3-8A1.003 1.003 0 0 0 21 7zm-4 6h-2v2h-2v-2h-2v-2h2V9h2v2h2v2z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Order</h5>
                         </div>

                         




                    </div>
                    </Link>
                    
     </div>:
      <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">You do not have permission to view Customers. Contact your admin for permissions</h3>
          </div>
          <div class="flex">
            <button onClick={()=>navigate("/")} type="button" class="text-green-700 bg-transparent border border-green-700 hover:bg-green-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-green-500 dark:border-green-500 dark:text-green-400 dark:hover:text-white dark:focus:ring-green-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
              OK
            </button>
          </div>
      </div>
     }
     
     </>
     
  )
}
