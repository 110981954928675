/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from "../contexts/UserStoreContext";

export default function Settings() {

     const navigate = useNavigate();
     const {settingsread} = useUserStore();
     
         
  return (
     <>
     <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Settings</span>
            </div>
          </li>
        </ol>
      </nav>
      {settingsread?
          <>
          
               <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 lg:gap-4 flex flex-col items-center justify-center ">
                    <Link to='/store'>
                    <div class="p-2 bg-[#F3B0C3] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <path d="M19.148 2.971A2.008 2.008 0 0 0 17.434 2H6.566c-.698 0-1.355.372-1.714.971L2.143 7.485A.995.995 0 0 0 2 8a3.97 3.97 0 0 0 1 2.618V19c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-8.382A3.97 3.97 0 0 0 22 8a.995.995 0 0 0-.143-.515l-2.709-4.514zm.836 5.28A2.003 2.003 0 0 1 18 10c-1.103 0-2-.897-2-2 0-.068-.025-.128-.039-.192l.02-.004L15.22 4h2.214l2.55 4.251zM10.819 4h2.361l.813 4.065C13.958 9.137 13.08 10 12 10s-1.958-.863-1.993-1.935L10.819 4zM6.566 4H8.78l-.76 3.804.02.004C8.025 7.872 8 7.932 8 8c0 1.103-.897 2-2 2a2.003 2.003 0 0 1-1.984-1.749L6.566 4zM10 19v-3h4v3h-4zm6 0v-3c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v3H5v-7.142c.321.083.652.142 1 .142a3.99 3.99 0 0 0 3-1.357c.733.832 1.807 1.357 3 1.357s2.267-.525 3-1.357A3.99 3.99 0 0 0 18 12c.348 0 .679-.059 1-.142V19h-3z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Store</h5>
                         </div>
                    </div>
                    </Link>
                    <Link to='/pickupstaff'>
                    <div class="p-2 bg-[#a2e1db] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <circle cx="17" cy="4" r="2"></circle><path d="M15.777 10.969a2.007 2.007 0 0 0 2.148.83l3.316-.829-.483-1.94-3.316.829-1.379-2.067a2.01 2.01 0 0 0-1.272-.854l-3.846-.77a1.998 1.998 0 0 0-2.181 1.067l-1.658 3.316 1.789.895 1.658-3.317 1.967.394L7.434 17H3v2h4.434c.698 0 1.355-.372 1.715-.971l1.918-3.196 5.169 1.034 1.816 5.449 1.896-.633-1.815-5.448a2.007 2.007 0 0 0-1.506-1.33l-3.039-.607 1.772-2.954.417.625z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Pickup Staff</h5>
                         </div>
                    </div>
                    </Link>
                    <Link to='/laundryitems'>
                    <div class="p-2 bg-[#ffffb5] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <path d="M19 4h-3V2h-2v2h-4V2H8v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zM5 20V7h14V6l.002 14H5z"></path><path d="M7 9h10v2H7zm0 4h5v2H7z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Laundry Items</h5>
                         </div>
                    </div>
                    </Link>
                    <Link to='/laundryservices'>
                    <div class="p-2 bg-[#cbaacb] rounded-md shadow-xl w-70 items-center justify-center ">
                         <div class="flex flex-col items-center pb-10">
                              <svg xmlns="http://www.w3.org/2000/svg" class="mt-4 w-8 h-8 stroke-black" viewBox="0 0 24 24">
                              <path d="M10 3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM9 9H5V5h4v4zm5 2h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1zm1-6h4v4h-4V5zM3 20a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v6zm2-5h4v4H5v-4zm8 5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v6zm2-5h4v4h-4v-4z"></path>
                              </svg>
                              <h5 class="text-black mt-6 mb-1 text-xl font-medium text-gray-900">Laundry Services</h5>
                         </div>
                    </div>
                    </Link>   
               </div>
          
          </>
          :
          <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
     <div class="flex items-center">
          <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
          <span class="sr-only">Info</span>
          <h3 class="text-lg font-medium">You do not have permission to view Settings. Contact your admin for permissions</h3>
     </div>
     <div class="flex">
          <button onClick={()=>navigate("/")} type="button" class="text-green-700 bg-transparent border border-green-700 hover:bg-green-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-green-500 dark:border-green-500 dark:text-green-400 dark:hover:text-white dark:focus:ring-green-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
          OK
          </button>
     </div>
          </div>
     }
     </>
     
  )
}
