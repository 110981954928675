/* eslint-disable react-hooks/exhaustive-deps */
 import { child, get, getDatabase, ref as dref, update } from "firebase/database";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from "../contexts/UserStoreContext";
import firebaseapp from '../firebase';

export default function Accounts({ route, navigation }) {
  const navigate = useNavigate();
  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);
  const [readAccess,setReadAccess] = useState(true);
  const { storeID, accountscreate, accountsupdate, accountsdelete, accountsread } = useUserStore();
  const [initialLoader, setInitialLoader] = useState(true);
  const [listLoader, setlistLoader] = useState(false);
  const [listLoaderData, setListLoaderData] = useState(false);
  const [accountLoader, setAccountLoader] = useState(false);
  const [accountInitLoader, setAccountInitLoader] = useState(false);
  const [incomeServices, setIncomeServices] = useState([{key: 0, storeKey:'', storeName:'', name:'', description: '', amount:0}]);
  const [accountIncomeTotal, setAccountIncomeTotal] = useState('');
  const [expenseServices, setExpenseServices] = useState([{key: 0,  storeKey:'', storeName:'', name:'', description: '', amount:0}]);
  const [accountExpenseTotal, setAccountExpenseTotal] = useState('');
  const selectedDate = new Date();
  const [accountDate, setAccountDate] = useState(new Date());
  const [accountYear, setAccountYear] = useState(moment(selectedDate).format('YYYY'))
  const [accountMonth, setAccountMonth] = useState(moment(selectedDate).format('MMM'))
  const [accountDay, setAccountDay] = useState(moment(selectedDate).format('DD'))
  const [accountDateIso, setAccountDateIso] = useState(moment(selectedDate).toISOString());
  const [listData, setListData] = useState('');
  const [dateLoader, setDateLoader] = useState(false);
  const [income, setIncome] = useState(true)
  const [stores, setStores] = useState('');
  const [filterListData, setFilterListData] = useState('');
  const [query, setQuery] = useState('')

  useEffect(() => { 
    if(accountsread){
      if(initialLoader){
        setInitialLoader(false);
        function loadData(){
          get(child(dbRef, `${storeID}/accounts/${storeID}/`)).then(async (snapshot) => {
            //step 1 data check
            if (snapshot.exists()) {
              const listdata = [];
              snapshot.forEach(yr => {
                const year = yr.key
                yr.forEach(mnth => {
                  const month = mnth.key
                  
                  mnth.forEach(dy => {
                    const dt = dy.key;
                    const inctotal = dy.child('incomeTotal').val();
                    const incdetails = dy.child('incomeDetails').val();
                    const exptotal = dy.child('expenseTotal').val();
                    const expdetails = dy.child('expenseDetails').val();
                    const profitandloss = Number(inctotal)-Number(exptotal);
                    let plcolor = true;
                    if(profitandloss < 0){
                      plcolor =false
                    }
                    const isodate = dy.child('date').val();
                    listdata.push({
                      year:year,
                      month:month,
                      date:dt,
                      inctotal:inctotal,
                      incdetails:incdetails,
                      exptotal:exptotal,
                      expdetails:expdetails,
                      isodate: isodate,
                      profitandloss: profitandloss,
                      plcolor: plcolor
                    })
                  });
                });
              });
              const datasnaps = await Promise.all(listdata);
              const dataExist = true;
              return [dataExist, datasnaps];
            }
            else{
              const dataExist = false;
              return [dataExist, 0];
            }
          }).then((dataExist)=>{
            if(dataExist[0]){
              setListData(dataExist[1]);
              setFilterListData(dataExist[1]);
              setAccountLoader(false);
              setListLoaderData(true);
              setlistLoader(true);
            }
            else{
              setAccountLoader(false);
              setListLoaderData(false);
              setlistLoader(true);
            }

          }).catch((error)=>{
              console.log(error);
          });
        }
      loadData();
      }
    }else{
      setReadAccess(false);
    }
  }, [initialLoader])

  useEffect(() => { 
     if(accountInitLoader){
       function loadDateData(){
        get(child(dbRef, `${storeID}/accounts/${storeID}/`)).then(async (snapshot) => {
             //step 1 data check
             if (snapshot.exists()) {
                  console.log('account loader');
             }
             else{
               setAccountLoader(true);
             }
             
         }).catch((error)=>{
             console.log(error);
         });
         
       }
     loadDateData();
 
     }
  }, [accountInitLoader])

  function accLoader(){
    
     setInitialLoader(false);
     setlistLoader(false);
     setAccountInitLoader(false);
     setDateLoader(true);
  }

  const addIncomeHandler = ()=>{
     const _services = [...incomeServices];
     _services.push({key: '',  storeKey:'', storeName:'', name:'', description: '', amount:0});
     setIncomeServices(_services);
   }

   function onCancelIncomeAccount(){
    setInitialLoader(false);
    setAccountInitLoader(false);
    setAccountLoader(false);
    setlistLoader(true);
   }

   function onDeleteIncomeAccount(){

    alert("Delete Income Data?", "Are you sure want continue?", [
      {
        text: "Cancel",
        onClick: () => null,
        style: "cancel"
      },
      { text: "Yes", onClick: () => continueOnDeleteIncomeAccount()},
    ]);
 
   }

   function continueOnDeleteIncomeAccount(){
    update(dref(dbr, storeID+'/accounts/'+storeID+"/"+accountYear+"/"+accountMonth+"/"+accountDay), {
      incomeTotal: null,
      incomeDetails: null
    }).then(()=>{
      setIncomeServices([{key: 0,  storeKey:'', storeName:'', name:'', description: '', amount:0}]);
      setAccountInitLoader(false);
      setAccountLoader(false);
      setlistLoader(false);
      setInitialLoader(true);

    })
   }

   function onSubmitIncomeAccount(){

    if(incomeServices.length === 0){
      alert("You have not added any accounts data");
    }else{
      let emptyvalue = false;
      for (const key in incomeServices) {
        if (Object.hasOwnProperty.call(incomeServices, key)) {
          const element = incomeServices[key];
          if(element.amount.length === 0 || element.description.length === 0){
              emptyvalue = true
          }
          
        }
        if(emptyvalue){
          alert("Decription or amount value cannot be empty");
        }else{
            update(dref(dbr, storeID+'/accounts/'+storeID+"/"+accountYear+"/"+accountMonth+"/"+accountDay), {
                date: accountDateIso,
                incomeTotal: accountIncomeTotal,
                incomeDetails: incomeServices
              }).then(()=>{
                setAccountInitLoader(false);
                setAccountLoader(false);
                setlistLoader(false);
                setInitialLoader(true);
              });
        }
      }
    }
   }

   const deleteIncomeHandler = (amount, key)=>{
    const _services = incomeServices.filter((input,index) => index !== key);
    var deductionamount = parseFloat(amount);
    if(!deductionamount){
      deductionamount = 0;
    }
    var totalamount = parseFloat(accountIncomeTotal);
    var total = totalamount - deductionamount;
    setAccountIncomeTotal(total);
    setIncomeServices(_services);
  }

  const inputIncomeDescriptionHandler = (text, key)=>{
    const _services = [...incomeServices];
    _services[key].description = text;
    _services[key].key   = key;
    setIncomeServices(_services);
  }

  const selectIncomeStoreHandler = (value, key)=>{
    const _services = [...incomeServices];
    const stokey = stores[value].storeKey;
    const stoName = stores[value].StoreName;
    _services[key].storeKey = stokey;
    _services[key].storeName = stoName
    _services[key].key   = key;
    setIncomeServices(_services);
  }

  const selectExpenseStoreHandler = (value, key)=>{
    const _services = [...expenseServices];
    const stokey = stores[value].storeKey;
    const stoName = stores[value].StoreName;
    _services[key].storeKey = stokey;
    _services[key].storeName = stoName
    _services[key].key   = key;
    setExpenseServices(_services);
  }

  const inputIncomeAmountHandler = (text, key)=>{
    
    const _services = [...incomeServices];
    _services[key].amount = text;
    _services[key].key   = key;
    setIncomeServices(_services);
    var sum = 0;
    for (const key in incomeServices) {
      if (incomeServices.hasOwnProperty.call(incomeServices, key)) {
        var amt = parseFloat(incomeServices[key].amount);
        if(!amt){
          amt = 0;
        }
        sum += amt;
      }
    }
    setAccountIncomeTotal(sum);
  }

  const inputExpenseDescriptionHandler = (text, key)=>{
    const _services = [...expenseServices];
    _services[key].description = text;
    _services[key].key   = key;
    setExpenseServices(_services);
  }

  const inputExpenseAmountHandler = (text, key)=>{
    
    const _services = [...expenseServices];
    _services[key].amount = text;
    _services[key].key   = key;
    setExpenseServices(_services);
    var sum = 0;
    for (const key in expenseServices) {
      if (expenseServices.hasOwnProperty.call(expenseServices, key)) {
        var amt = parseFloat(expenseServices[key].amount);
        if(!amt){
          amt = 0;
        }
        sum += amt;
      }
    }
    setAccountExpenseTotal(sum);
  }

  const deleteExpenseHandler = (amount, key)=>{
    const _services = expenseServices.filter((input,index) => index !== key);
    var deductionamount = parseFloat(amount);
    if(!deductionamount){
      deductionamount = 0;
    }
    var totalamount = parseFloat(accountExpenseTotal);
    var total = totalamount - deductionamount;
    setAccountExpenseTotal(total);
    setExpenseServices(_services);
  }

  const addExpenseHandler = ()=>{
     const _services = [...expenseServices];
     _services.push({key: '',  storeKey:'', storeName:'', name:'', description: '', amount:0});
     setExpenseServices(_services);
   }

   function onDeleteExpenseAccount(){

    alert("Delete Expense Data?", "Are you sure want continue?", [
      {
        text: "Cancel",
        onClick: () => null,
        style: "cancel"
      },
      { text: "Yes", onClick: () => contineOnDeleteExpenseAccount()},
    ]);

    
 
   }

   function contineOnDeleteExpenseAccount(){
    update(dref(dbr, storeID+'/accounts/'+storeID+"/"+accountYear+"/"+accountMonth+"/"+accountDay), {
      expenseTotal: null,
      expenseDetails: null
    }).then(()=>{
      setExpenseServices([{key: 0,  storeKey:'', storeName:'', name:'', description: '', amount:0}]);
      setAccountInitLoader(false);
      setAccountLoader(false);
      setlistLoader(false);
      setInitialLoader(true);

    })
   }

   function onSubmitExpenseAccount(){
    if(expenseServices.length === 0){
      alert("You have not added any accounts data");
    }else{
      let emptyvalue = false;
      for (const key in expenseServices) {
       if (Object.hasOwnProperty.call(expenseServices, key)) {
         const element = expenseServices[key];
         if(element.amount.length === 0 || element.description.length === 0){
            emptyvalue = true
         }
         
       }
       if(emptyvalue){
        alert("Decription or amount value cannot be empty");
       }else{
          update(dref(dbr, storeID+'/accounts/'+storeID+"/"+accountYear+"/"+accountMonth+"/"+accountDay), {
            date: accountDateIso,
            expenseTotal: accountExpenseTotal,
            expenseDetails: expenseServices
          }).then(()=>{
            setAccountInitLoader(false);
            setAccountLoader(false);
            setlistLoader(false);
            setInitialLoader(true);
          });

       }
       
     }

    }
   }

   

   function OnDayPressed(itm){
    setAccountDateIso(itm.isodate);
    const dt = itm.date+'/'+itm.month+'/'+itm.year;
    if(itm.incdetails){
    setIncomeServices(itm.incdetails);
    }
    setAccountIncomeTotal(itm.inctotal);
    if(itm.expdetails){
      setExpenseServices(itm.expdetails);
    }
    setDateLoader(false);
    
    setAccountExpenseTotal(itm.exptotal);
    setAccountDay(itm.date);
    setAccountMonth(itm.month);
    setAccountYear(itm.year);
    setAccountDate(dt);
    setlistLoader(false);
    if(!stores){
      get(child(dbRef, `${storeID}/storeprofile/${storeID}`)).then((snapshot) => {
        if(snapshot.exists){
        const prof = [];
        snapshot.forEach(element => {
          const storeKey = element.key;
          const StoreName = element.child('StoreName').val();
          prof.push({storeKey:storeKey, StoreName:StoreName})
        });
        setStores(prof);
        
        }else{
          
         setStores(''); 
        }
      }).then(()=>{
        setAccountLoader(true); 
      })
    }else{
      setAccountLoader(true); 
    }
  }

   function OnNewPressed(){
    for (const key in listData) {
      if (Object.hasOwnProperty.call(listData, key)) {
        const element = listData[key];
        if((element.month === accountMonth)&&(element.year === accountYear)&&(element.date === accountDay)){
            if(element.incdetails){
            setIncomeServices(element.incdetails);
            }
            setAccountIncomeTotal(element.inctotal);
            if(element.expdetails){
              setExpenseServices(element.expdetails);
            }
            setAccountExpenseTotal(element.exptotal);
            
        }
      }
    }

    if(!stores){
      get(child(dbRef, `${storeID}/storeprofile/${storeID}`)).then((snapshot) => {
        if(snapshot.exists){
        const prof = [];
        snapshot.forEach(element => {
          const storeKey = element.key;
          const StoreName = element.child('StoreName').val();
          prof.push({storeKey:storeKey, StoreName:StoreName})
        });
        setStores(prof);
        
        }else{
          
         setStores(''); 
        }
      }).then(()=>{
        setDateLoader(false);
        setlistLoader(false);
        setAccountLoader(true);  
      })
    }else{
      setDateLoader(false);
      setlistLoader(false);
      setAccountLoader(true); 
    }
    
    

   }

   function cancelNewAccount(){
    setDateLoader(false);
    setlistLoader(true);
   }

   function handleSearch(e){
    setQuery(e);
    const elow = e.toLowerCase();
    const fData = listData.filter(item => {
      return (
        (item.date && item.date.toLowerCase().includes(elow)) ||
        (item.month && item.month.toLowerCase().includes(elow)) ||
        (item.year && item.year.toLowerCase().includes(elow))
        );
    }
    );
    setFilterListData(fData);
  };

  return (
    <div>
      <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Accounts</span>
            </div>
          </li>
        </ol>
      </nav>
      {readAccess? 
      <>
      {listLoader && 
        <>
        {listLoaderData?
          
          <div class="relative overflow-x-auto">
          <div class="relative z-0 mb-6 mt-6 w-1/2 ml-5 mr-5 group ">
                <input value={query} onChange={(e)=>handleSearch(e.target.value)} type="text" name="sku" id="sku" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                <label htmlFor="sku" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Search</label>
              </div>
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        Date
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Income
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Expense
                    </th>
                    <th scope="col" class="px-6 py-3">
                        P&L
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Action
                    </th>
                    
                </tr>
              </thead>
              <tbody>
                {filterListData.map((item, key)=>(
                <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {item.date}/{item.month}/{item.year}
                    </th>
                    <td class="px-6 py-4">
                      {item.inctotal?<span>{item.inctotal}</span>:<span>0</span>}
                    </td>
                    <td class="px-6 py-4">
                      {item.exptotal?<span>{item.exptotal}</span>:<span>0</span>}
                    </td>
                    <td class="px-6 py-4">
                      {item.plcolor?<span class="text-green-500">{item.profitandloss}</span>:<span class="text-red-600">{item.profitandloss}</span>}
                    </td>
                    <td class="px-6 py-4">
                      <button onClick={()=>OnDayPressed(item)} type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        <svg aria-hidden="true" class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        <span class="sr-only">Icon description</span>
                      </button>
                    </td>
                </tr>
                ))}
              </tbody>
            </table>
				    {accountscreate && <button onClick={()=>accLoader()} type="button" class="mt-5 ml-5 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">New Accounts</button>}
			    </div>
        :
        <div>
          <div class="max-w-lg p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">You do not have accounts data</h5>
          <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Use accounts to manage income and expenses</p>
          {accountscreate && <button class="mt-5 ml-5 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900" onClick={()=>accLoader()} type="button">Next</button>}
          </div>
        </div>
        }
        </>
      }
      {accountLoader &&
        <div>
          <div class="inline-flex rounded-md shadow-sm" role="group">
            <button onClick={()=>setIncome(true)} type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
              Income
            </button>
            <button onClick={()=>setIncome(false)} type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
              Expense
            </button>
          </div>
          {income?
            <div class="p-4 rounded-lg bg-gray-50 dark:bg-gray-800" id="income" role="tabpanel" aria-labelledby="income-tab">
              <div class="relative overflow-x-auto">
              <p class="ml-2 mb-2 text-2xl text-gray-900 dark:text-white">Income</p>
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <td class="p-2">
                                Store
                            </td>
                            <td class="p-2">
                                Description
                            </td>
                            <td class="p-2">
                                Amount
                            </td>
                            <td class="p-2">
                                
                            </td>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {incomeServices.map((input, key)=>(
                        <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <td class="pl-2 pr-2  font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              <select value={stores.findIndex(x => x.storeKey === input.storeKey)} onChange={e => selectIncomeStoreHandler(e.target.value, key)} id="countries" class="p-2 mt-5 block w-full mb-6 text-xs text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                {stores.map((str, skey)=>(
                                <option key={skey} value={skey}>{str.StoreName}</option>
                                ))}  
                              </select>
                              
                            </td>
                            <td class="pl-2 pr-2  font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            <input
                                class="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={input.description}  
                                disabled={input.name? true:false}
                                maxLength={256}
                                onChange={(e)=>inputIncomeDescriptionHandler(e.target.value,key)}
                                />
                            </td>
                            <td class="pl-2 pr-2  font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              <input
                                class="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={input.amount} 
                                disabled={input.name? true:false}
                                maxLength={10}
                                onChange={(e)=>inputIncomeAmountHandler(e.target.value,key)}
                                />
                            </td>
                            <td class="pl-2 pr-2  font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              <button disabled={input.name? true:false} onClick = {()=> deleteIncomeHandler(input.amount, key)} type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                                <svg aria-hidden="true" class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z" clipRule="evenodd"></path></svg>
                                <span class="sr-only">Icon description</span>
                              </button>
                              
                            </td>
                        </tr>
                        ))}
                        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <th scope="row" class="p-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            <button class="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={()=>addIncomeHandler()}>Add Row</button>
                            </th>
                            <td class="p-2">
                              
                            </td>
                            <td class="p-2">
                            Total:{accountIncomeTotal}
                            </td>
                            <td class="p-2">

                            </td>
                        </tr>
                        

                    </tbody>
                </table>
                <div class="mt-10">
                <button class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" onClick={() => onCancelIncomeAccount()}>Cancel</button>
                {accountsdelete && <button class="ml-2 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={() => onDeleteIncomeAccount()}>Delete</button>  }
                {accountsupdate && <button class="ml-2 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900" onClick={() => onSubmitIncomeAccount()}>Submit</button>}
                </div>  
              </div>
            </div>
            :
            <div class="p-4 rounded-lg bg-gray-50 dark:bg-gray-800" id="income" role="tabpanel" aria-labelledby="income-tab">
              <div class="relative overflow-x-auto">
              <p class="ml-2 mb-2 text-2xl text-gray-900 dark:text-white">Exprense</p>
                
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <td class="p-2">
                                Store
                            </td>
                            <td class="p-2">
                                Description
                            </td>
                            <td class="p-2">
                                Amount
                            </td>
                            <td class="p-2">
                                
                            </td>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {expenseServices.map((input, key)=>(
                        <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="pl-2 pr-2  font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          <select value={stores.findIndex(x => x.storeKey === input.storeKey)} onChange={e => selectExpenseStoreHandler(e.target.value, key)} id="countries" class="p-2 mt-5 block w-full mb-6 text-xs text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            {stores.map((str, skey)=>(
                            <option key={skey} value={skey}>{str.StoreName}</option>
                            ))}  
                          </select>
                        </td>
                        <td class="pl-2 pr-2  font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            <input
                                class="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={input.description}
                                disabled={input.name? true:false}  
                                maxLength={256}
                                onChange={(e)=>inputExpenseDescriptionHandler(e.target.value,key,key)}
                                />
                        </td>
                        <td class="pl-2 pr-2  font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          <input
                            class="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            value={input.amount} 
                            disabled={input.name? true:false}
                            maxLength={10}
                            onChange={(e)=>inputExpenseAmountHandler(e.target.value,key,key)}
                            />
                        </td>
                        <td class="pl-2 pr-2  font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          <button disabled={input.name? true:false} onClick = {()=> deleteExpenseHandler(input.amount, key)} type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                            <svg aria-hidden="true" class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z" clipRule="evenodd"></path></svg>
                            <span class="sr-only">Icon description</span>
                          </button>
                        </td>
                        </tr>
                        ))}
                        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            <button onClick={()=>addExpenseHandler()} class="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Add Row</button>
                            </th>
                            <td class="p-2">
                              
                            </td>
                            <td class="px-6 py-4">
                              Total:{accountExpenseTotal}
                            </td>
                            <td class="px-6 py-4">
                              
                            </td>
                        </tr>
                        

                    </tbody>
                </table>
                <div class="mt-10">
                <button class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" onClick={() => onCancelIncomeAccount()}>Cancel</button>
                {accountsdelete && <button class="ml-2 focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={() => onDeleteExpenseAccount()}>Delete</button>}  
                {accountsupdate && <button class="ml-2 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900" onClick={() => onSubmitExpenseAccount()}>Submit</button>}
                </div>  
              </div>
            </div>
          }
        </div>
      }
      {dateLoader &&
        <div>
          <div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            
            <div class="flex flex-col items-center pb-10">
                <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">Select Account Date</h5>
                <div class="pl-8">
                <DatePicker
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                  onChange={(date) => setAccountDate(date)}
                  selected={accountDate}
                  />
                </div>
                <div class="flex mt-4 space-x-3 md:mt-6">
                <button onClick={() => OnNewPressed()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Next</button>
                <button onClick={() => cancelNewAccount()} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
                    
                </div>
              </div>
          </div>

        </div>
      }
      </>
      :
      <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">You do not have permission to view Accounts. Contact your admin for permissions</h3>
          </div>
          <div class="flex">
            <button onClick={()=>navigate("/")} type="button" class="text-green-700 bg-transparent border border-green-700 hover:bg-green-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-green-500 dark:border-green-500 dark:text-green-400 dark:hover:text-white dark:focus:ring-green-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
              OK
            </button>
          </div>
      </div>
      }
    </div>
  );
}
 

