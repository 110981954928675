/* eslint-disable react-hooks/exhaustive-deps */
import { child, get, getDatabase, push, ref as dref, set, update } from "firebase/database";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import filter from 'lodash.filter';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput, {parsePhoneNumber} from 'react-phone-number-input';
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useUserStore } from "../contexts/UserStoreContext";
import firebaseapp from '../firebase';
import ls from 'localstorage-slim';

export default function Customers() {
    
  const navigate = useNavigate();
  const { businessName, setBusinessName, storeID, setStoreID, countryCode, setCountryCode, customerscreate, customersread, customersupdate, pickupread, collectionread, collectioncreate, pickupcreate } = useUserStore();
  const [initialLoader, setInitialLoader] = useState(true);
  const [customerLoader, setCustomerLoader] = useState(false);
  const [customerPresent, setCustomerPresent] = useState(false);
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState('');
  const [fstore, setFstore] = useState(false);
  const [custKey, setCustKey] = useState('');
  const [customerData, setCustomerData] = useState('');
  const [customerFilteredData, setFilteredCustomerData] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedCustomerLoader, setSelectedCustomerLoader] = useState(false);
  const [customerDetailLoader, setCustomerDetailLoader] = useState(false);
  const [fullName, setFullName] = useState('');
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [address, setAddress] = useState([{key: '1', address:'', latitude: '', longitude: ''}]);
  const [email, setEmail] = useState('');
  const [alternateNumber, setAlternateNumber] = useState([{key: '1', value: ''}]);
  const [fullNameEdit, setFullNameEdit] = useState('');
  const [whatsappNumberEdit, setWhatsappNumberEdit] = useState('');
  const [addressEdit, setAddressEdit] = useState([{key: '1', address:'', latitude: '', longitude: ''}]);
  const [emailEdit, setEmailEdit] = useState('');
  const [alternateNumberEdit, setAlternateNumberEdit] = useState([{key: '1', value: ''}]);
  const [customerID, setCustomerID] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModelNew, setShowModelNew] = useState(true);
  const [readAccess,setReadAccess] = useState(true);
  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);
  const dbstore = getFirestore(firebaseapp);
  let userID = storeID;
  const [countryID, setCountryID] = useState(countryCode);
  const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);
  const geolocationAPI = navigator.geolocation;
  const [customerExist, setCustomerExist] = useState(false);
  if(!storeID){
    setStoreID(userdata.storeID);
    userID = userdata.storeID;
  }
  if(!businessName){
    setBusinessName(userdata.businessName)
  }
  if(!countryCode){
    setCountryCode(userdata.country);
  }
  

  function inputChange(nextValue){
    setCustomerPhoneNumber(nextValue);
    if(customerData){
      const obj = customerData.map(function(a) { 
        a.phoneNumber = a.phoneNumber.toString();
        return a;
      });
  
      const fdata = filter(obj, ({phoneNumber}) => {
        if((phoneNumber.includes(nextValue)))
        {
          return true;
        }
        return false;
      });
      setFilteredCustomerData(fdata);
    }
    
  }

  useEffect(() => {
    function loadItems() {
      if(customersread){
        setInitialLoader(false);
        get(child(dbRef, `${userID}/mycustomer/${userID}/`)).then(async (snapshot) => {
          //step 1 data check
          if (snapshot.exists()) {
            try {
              const prmises = [];
              snapshot.forEach(customersnapshot => {
                const phoneNumber = customersnapshot.key;
                const address = customersnapshot.child('address').val() || false;
                const alternateNumber = customersnapshot.child('alternateNumber').val() || false;
                const customerID = customersnapshot.child('customerID').val();
                const email = customersnapshot.child('email').val() || false;
                const fullName = customersnapshot.child('fullName').val();
                const whatsappNumber = customersnapshot.child('whatsappNumber').val() || false;
                prmises.push({phoneNumber:phoneNumber, address:address, alternateNumber:alternateNumber, customerID:customerID,
                email:email, fullName:fullName, whatsappNumber:whatsappNumber});
              });
              const dtasnaps = await Promise.all(prmises);
              const dataExist = true;
              return [dataExist, dtasnaps]
            } catch (error) {
              console.log(error);          
            }
          } else{
            const dataExist = false;
            return [dataExist, 0]
            
          }
        }).then((dataExist)=>{
          if(dataExist[0]){
            setCustomerData(dataExist[1]);
            setFilteredCustomerData(dataExist[1]);
            setCustomerPresent(true);
            setCustomerLoader(true);
          }else{
            setCustomerPresent(false);
            setCustomerLoader(true);
          }
        }).catch((error) => {

          console.error("inside catch", error);
        });
      }else{
        setReadAccess(false);
      }
      

    }
  loadItems();
  }, [initialLoader])

  

  async function cprofileCheck(){
    let rt =false;
    if(customerData){
      const obj = customerData.map(function(a) { 
        a.phoneNumber = a.phoneNumber.toString();
        return a;
      });
      filter(obj, ({phoneNumber}) => {
        if((phoneNumber.includes(customerPhoneNumber)))
        {
          rt = true;
        }
        
      });
      console.log(rt, "return");
    }
    if(rt){
      setCustomerExist(true);
    }else{
      const docRef = doc(dbstore, "customer", customerPhoneNumber);
    const querySnapshot = await getDoc(docRef);
    if(querySnapshot.exists()){
      
      setFstore(false);
      const qdata = querySnapshot.data()
      setCustKey(qdata.customerID);
      
      if(qdata.hasOwnProperty('fullName')){
        setFullName(qdata.fullName);
      }else{
        setFullName('');
      }
      if(qdata.hasOwnProperty('whatsappNumber')){
        setWhatsappNumber(qdata.whatsappNumber);
      }else{
        setWhatsappNumber('');
      }
      
      if(qdata.hasOwnProperty('address')){
        setAddress(qdata.address);
      }else{
        setAddress([{key: '1', address:'', latitude: '', longitude: ''}]);
      }
      if(qdata.hasOwnProperty('alternateNumber')){
        setAlternateNumber(qdata.alternateNumber);
      }else{
        setAlternateNumber([{key: '1', value: ''}]);
      }
      if(qdata.hasOwnProperty('email')){
        setEmail(qdata.email);
      }else{
        setEmail('');
      }
      customerDetailScreen();
      
      

    }
    else{
      
      setFstore(true);
      const customerKey = push(child(dref(dbr), 'customer')).key;
      setCustKey(customerKey);
      customerDetailScreen();
    }

    }
    
    
  }

 

  function customerDetailScreen(){
    setCustomerLoader(false);
    setCustomerPresent(false);
    if(fstore){
      setFullName('');
      setAddress([{key: '1', address:'', latitude: '', longitude: ''}]);
      setEmail('');
      setAlternateNumber([{key: '1', value: ''}]);
      setWhatsappNumber('');
    }
    setCustomerDetailLoader(true);
  }

  function cancelCustomerDetailScreen(){
    setCustomerPhoneNumber('');
    setCustomerDetailLoader(false);
    setInitialLoader(true);
  }

  const addcHandler = ()=>{
    const _phonenumber = [...alternateNumber];
    const kl = Object.keys(_phonenumber).length+1;
    _phonenumber.push({key: kl, value: '', code:'', country:countryID});
    setAlternateNumber(_phonenumber);   
  }
  
  const deletecHandler = (ckey)=>{
    const _phonenumber = alternateNumber.filter((cinput,index) => index !== ckey);
    setAlternateNumber(_phonenumber);
  }

  const addaHandler = ()=>{
    const _phonenumber = [...address];
    _phonenumber.push({ckey: '', address: '', longitude: '', latitude: ''});
    setAddress(_phonenumber);
  }

  const deleteaHandler = (ckey)=>{
    const _phonenumber = address.filter((cinput,index) => index !== ckey);
    setAddress(_phonenumber);
  }

  const inputaHandler = (index, event)=>{
    const _address = [...address];
    _address[index][event.target.name] = event.target.value;
    setAddress(_address);
  }

  const inputcHandler = (text, index)=>{
    const _phonenumber = [...alternateNumber];
    _phonenumber[index].value = text;
    _phonenumber[index].key   = index;
    setAlternateNumber(_phonenumber);
  }

  const inputaLocationHandler = (ckey)=>{
    if (!geolocationAPI) {
      console.log('Geolocation API is not available in your browser!')
    } else {
      geolocationAPI.getCurrentPosition((position) => {
        const { coords } = position;
        const _phonenumber = [...address];
        _phonenumber[ckey].latitude = coords.latitude;
        _phonenumber[ckey].longitude = coords.longitude;
        _phonenumber[ckey].key   = ckey;
        setAddress(_phonenumber);
      }, (error) => {
        console.log(error, 'Something went wrong getting with your position!')
      })
    }

    
  }

  function SubmitCustomer(){
    let WNumber
    if(whatsappNumber.length){
    WNumber = {value:parsePhoneNumber(whatsappNumber).nationalNumber, code:parsePhoneNumber(whatsappNumber).countryCallingCode, country:parsePhoneNumber(whatsappNumber).country};
    }else{
      WNumber = '';
    }
    let anumber = [];
    let keynum = 1;
    if(alternateNumber.length){
      for (const key in alternateNumber) {
        if (Object.hasOwnProperty.call(alternateNumber, key)) {
          const element = alternateNumber[key];
          anumber.push({key: keynum, value: parsePhoneNumber(element.value).nationalNumber, code:parsePhoneNumber(element.value).countryCallingCode, country:parsePhoneNumber(element.value).country})
          keynum = keynum+1;
        }
      }
    }
    else{
      anumber.push({key: '1', value: ''})
    }
    const dt = moment(new Date()).toISOString(); 
    const fbaseobject = storeID+'/mycustomer/'+userID+'/'+customerPhoneNumber;
      const fbaseobjectdata = {
        customerID: custKey,
        phoneNumber: customerPhoneNumber,
        fullName: fullName,
        address: address,
        alternateNumber: anumber,
        email: email,
        date:dt,
        whatsappNumber:WNumber
      }
      const fbasecustomerobject = 'mystore/'+custKey+'/'+userID;
      const fbasecustomerobjectdata = {
        storeID: userID,
        storeName: businessName
      }
      
      const updateobject = {
        [fbasecustomerobject]: fbasecustomerobjectdata,
        [fbaseobject]:fbaseobjectdata,
      }

      if(fstore){
        setDoc(doc(dbstore, 'customer/' + customerPhoneNumber), {
          customerID: custKey
        }).then(()=>{
          update(dref(dbr), updateobject).then(()=>{
            setCustomerPhoneNumber('');
            setCustomerDetailLoader(false);
            setShowModelNew(true);
            setShowModal(true);
            
          });
        })

      }
      else{
        update(dref(dbr), updateobject).then(()=>{
          setCustomerPhoneNumber('');
          setCustomerDetailLoader(false);
          setShowModelNew(true);
          setShowModal(true);
          
        });

      }
  }

  const addcHandlerEdit = ()=>{
    const _phonenumber = [...alternateNumberEdit];
    _phonenumber.push([{key: '', value: ''}]);
    setAlternateNumberEdit(_phonenumber);
  }
  
  const deletecHandlerEdit = (ckey)=>{
    const _phonenumber = alternateNumberEdit.filter((cinput,index) => index !== ckey);
    setAlternateNumberEdit(_phonenumber);
  }

  const addaHandlerEdit = ()=>{
    const _phonenumber = [...addressEdit];
    _phonenumber.push({ckey: '', address: '', longitude: '', latitude: ''});
    setAddressEdit(_phonenumber);
  }

  const deleteaHandlerEdit = (ckey)=>{
    const _phonenumber = addressEdit.filter((cinput,index) => index !== ckey);
    setAddressEdit(_phonenumber);
  }

  const inputaHandlerEdit = (index, event)=>{
    const _address = [...addressEdit];
    _address[index][event.target.name] = event.target.value;
    setAddressEdit(_address);
  }

  const inputcHandlerEdit = (event, index)=>{
    
    const _phonenumber = [...alternateNumberEdit];
    _phonenumber[index].value = event;
    _phonenumber[index].key   = index;
    setAlternateNumberEdit(_phonenumber);
  }

  const inputaLocationHandlerEdit = (ckey)=>{
    if (!geolocationAPI) {
      console.log('Geolocation API is not available in your browser!')
    } else {
      geolocationAPI.getCurrentPosition((position) => {
        const { coords } = position;
        const _phonenumber = [...addressEdit];
        _phonenumber[ckey].latitude = coords.latitude;
        _phonenumber[ckey].longitude = coords.longitude;
        _phonenumber[ckey].key   = ckey;
        setAddressEdit(_phonenumber);
      }, (error) => {
        console.log(error, 'Something went wrong getting with your position!')
      })
    }
  }

  function goList(){
    setShowModal(false);
    setInitialLoader(true);
  }

  function gopickup(){
    setShowModal(false);
    return <Navigate to="/pickup" />;
  }

  function selectCustomer(item){
    setSelectedCustomer(item.phoneNumber);
    setFullNameEdit(item.fullName);
    let wNumber = "+"+item.whatsappNumber.code+item.whatsappNumber.value;
    
    setWhatsappNumberEdit(wNumber);
    setAddressEdit(item.address);
    setEmailEdit(item.email || '');
    let anumber = [];
    let keynum = 1;
    if(item.alternateNumber.length){
      for (const key in item.alternateNumber) {
        if (Object.hasOwnProperty.call(item.alternateNumber, key)) {
          const element = item.alternateNumber[key];
          const numbr = "+"+element.code+element.value;
          anumber.push({key: keynum, value:numbr})
          keynum = keynum+1;
        }
      }
    }
    else{
      anumber.push({key: '1', value: ''})
    }
    setAlternateNumberEdit(anumber);
    setCustomerID(item.customerID); 
    setCustomerLoader(false);
    setSelectedCustomerLoader(true);
  }

  function updateCustomer(){
    let WNumber
    if(whatsappNumberEdit.length){
    WNumber = {value:parsePhoneNumber(whatsappNumberEdit).nationalNumber, code:parsePhoneNumber(whatsappNumberEdit).countryCallingCode, country:parsePhoneNumber(whatsappNumberEdit).country};
    }else{
      WNumber = '';
    }
    let anumber = [];
    let keynum = 1;
    if(alternateNumberEdit.length){
      for (const key in alternateNumberEdit) {
        if (Object.hasOwnProperty.call(alternateNumberEdit, key)) {
          const element = alternateNumberEdit[key];
          anumber.push({key: keynum, value: parsePhoneNumber(element.value).nationalNumber, code:parsePhoneNumber(element.value).countryCallingCode, country:parsePhoneNumber(element.value).country})
          keynum = keynum+1;
        }
      }
    }
    else{
      anumber.push({key: '1', value: ''})
    }

    set(dref(dbr, userID+'/mycustomer/'+userID+"/"+selectedCustomer), {
      customerID: customerID,
      phoneNumber: selectedCustomer,
      fullName: fullNameEdit,
      address: addressEdit,
      alternateNumber: anumber,
      email: emailEdit,
      whatsappNumber:WNumber
    }).then(()=>{
        setCustomerPhoneNumber('');
        setSelectedCustomerLoader(false);
        setShowModelNew(false);
        setShowModal(true);
      });

  }

  function cancelUpdateDetailScreen(){
    setSelectedCustomer('');
    setFullNameEdit('');
    setWhatsappNumberEdit('');
    setAddress([{key: '1', address:'', latitude: '', longitude: ''}]);
    setAlternateNumberEdit([{key: '1', value: ''}]);
    setEmailEdit('');
    setCustomerID(''); 
    setSelectedCustomerLoader(false);
    setCustomerLoader(true);
  }

  const openmap = (lat,long) => {
    const url = "http://www.google.com/maps/place/"+lat+","+long
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  

  

  

  return (
    
    <div>
      
      <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Customers</span>
            </div>
          </li>
        </ol>
      </nav>
      {readAccess? 
      <>
      {customerLoader &&
        <>
          {customerPresent? 
            <>
              {Object.keys(customerFilteredData).length ? 
                <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
                    <div class="pb-4">
                        <label htmlFor="table-search" class="sr-only">Search</label>
                        <div class="relative mt-1">
                            <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                            </div>
                            <PhoneInput
                                id="businessUserPhoneNumber"
                                class="block py-2.5 ps-6 pe-0 w-full text-sm text-gray-900 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                international
                                placeholder="Search Customer Phonenumber"
                                defaultCountry={countryID}
                                value={customerPhoneNumber}
                                onCountryChange={(value)=>setCountryID(value)}
                                onChange={(e)=>inputChange(e)}/>
                              
                            
                        </div>
                    </div>
                    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="py-3 px-6">
                                    Customer
                                </th>
                                <th scope="col" class="py-3 px-6">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {customerFilteredData.map((cdata, key)=>(
                            <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                <button onClick={()=>selectCustomer(cdata)} class="font-medium text-blue-600 dark:text-blue-500 hover:underline">{cdata.phoneNumber}</button>
                                  <p class="text-sm text-gray-900 dark:text-white">{cdata.fullName}</p>
                                </th>
                                <td class="py-4 px-6">
                                {(pickupread || pickupcreate) &&
                                <button onClick={()=>navigate("/pickup", {state:["Customers", cdata]})} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center mr-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800">
                                  <svg aria-hidden="true" class="w-3 h-3 mr-2 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path></svg>
                                  Pickup
                                </button>
                                }
                                { (collectionread || collectioncreate) &&
                                <button onClick={()=>navigate("/collection", {state:["Collection", cdata]})} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center mr-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                                  <svg aria-hidden="true" class="w-3 h-3 mr-2 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path></svg>
                                  Collection
                                </button>
                                }  
                                </td>
                            </tr>
                        ))}    
                        </tbody>
                    </table>
                </div>
              :
                <main class="h-screen w-full flex flex-col justify-center items-center bg-white dark:bg-slate-800">
                  {customerExist? 
                    <>
                      <h1 class="text-lg font-extrabold tracking-widest">Number you have entered already exist in your customer List</h1>
                      <h1 class="text-lg font-extrabold tracking-widest">Modify the number in order to include a new customer?</h1>
                    </>
                    :
                    <>
                      <h1 class="text-lg font-extrabold tracking-widest">Number you have entered is not in your customer List</h1>
                      <h1 class="text-lg font-extrabold tracking-widest">Do you wish to create a new customer?</h1>
                    </>
                  }
                  <div class="-space-y-px rounded-md shadow-sm mt-6">
                    <label htmlFor="price" class="block text-sm font-medium text-gray-700"> Customer Phone number</label>
                    <PhoneInput
                      id="businessUserPhoneNumber"
                      class="block py-2.5 ps-6 pe-0 w-full text-sm text-gray-900 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry={countryID}
                      value={customerPhoneNumber}
                      onCountryChange={(value)=>setCountryID(value)}
                      onChange={(value)=>setCustomerPhoneNumber(value)}/>
                  </div>
                  <div class="flex justify-center">
                      <button onClick={()=>inputChange(customerPhoneNumber)} type="button" class="mt-6 group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      Cancel
                      </button>
                      {customerscreate &&
                      <button onClick={()=>cprofileCheck()} type="button" class="ml-6 mt-6 group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      Next
                      </button>
                      }
                  </div>
                  {!customerscreate &&
                  <div class="flex justify-center">
                    <h1 class="mt-5 text-lg font-extrabold tracking-widest">You do not have permission to create Customers. Contact your admin</h1>
                  </div>
                  }  
                </main>
              }
            </>
            :
            <>
              <main class="h-screen w-full flex flex-col justify-center items-center bg-white dark:bg-slate-800">
                <h1 class="text-slate-900 dark:text-white text-lg font-extrabold tracking-widest">You have not added customers</h1>
                
                
                      <div class="-space-y-px rounded-md shadow-sm mt-6">
                          <label htmlFor="price" class="block text-sm font-medium text-slate-900 dark:text-white">Customer Phone number</label>
                          <PhoneInput
                              id="businessUserPhoneNumber"
                              class="block py-2.5 ps-6 pe-0 w-full text-sm text-gray-900 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              international
                              countryCallingCodeEditable={false}
                              defaultCountry={countryID}
                              value={customerPhoneNumber}
                              onCountryChange={(value)=>setCountryID(value)}
                              onChange={(value)=>setCustomerPhoneNumber(value)}/>
                            
                      </div>
                      <div>
                          <button onClick={()=>cprofileCheck()} type="submit" class="mt-6 group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          Next
                          </button>
                      </div>
                      
                
              </main>
            </>
          }
        </>
      }
      {customerDetailLoader && 
        <>
          <div class="text-slate-900 dark:text-white mt-2 mb-5 text-lg">New Customer</div>
          
          <div class="grid md:grid-cols-2 md:gap-6">
            <div class="relative z-0 mb-6 w-full group">
            <label htmlFor="fullName" class="text-sm text-gray-500 dark:text-gray-400">Full Name</label>
                <input value={fullName} onChange={(e)=>setFullName(e.target.value)} type="text" name="fullName" id="fullName" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                
            </div>
            <div class="relative z-0 mb-6 w-full group">
            <label htmlFor="email" class="text-sm text-gray-500 dark:text-gray-400">Email</label>
          <input value={email} onChange={(e)=>setEmail(e.target.value)}  type="email" name="email" id="email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                
          </div>
          </div>
          <div class="grid md:grid-cols-2 md:gap-6">
            <div class="relative z-0 mb-6 w-full group">
            <label htmlFor="customerPhoneNumber" class="text-sm text-gray-500 dark:text-gray-400">Phone number</label>
                <input value={customerPhoneNumber} disabled={true} type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name="customerPhoneNumber" id="customerPhoneNumber" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                
            </div>
            <div class="relative z-0 mb-6 w-full group">
            <label htmlFor="whatsappNumber" class="text-sm text-gray-500 dark:text-gray-400">Whatsapp Number</label>
                <PhoneInput
                id="businessUserPhoneNumber"
                class="block py-2.5 ps-6 pe-0 w-full text-sm text-gray-900 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                international
                withCountryCallingCode
                placeholder="alternatenumber"
                defaultCountry={countryID}
                value={whatsappNumber}
                onChange={(e)=>setWhatsappNumber(e)}
                />
                
            </div>
          </div>
          
          
          
          {alternateNumber.map((cinput, index)=>(
          <div key={index} class="relative z-0 mb-6 w-full group">
          
          <label htmlFor="alternateNumber" class="text-sm text-gray-500 dark:text-gray-400">Alternate Phone number</label>  
          <PhoneInput
            id="businessUserPhoneNumber"
            class="block py-2.5 ps-6 pe-0 w-full text-sm text-gray-900 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            international
            placeholder="alternatenumber"
            defaultCountry={countryID}
            value={cinput.value}
            onChange={(e)=>inputcHandler(e, index)}
            />
          <button onClick={()=> deletecHandler(index)} type="button" class="absolute top-5 right-0 p-2.5 text-red-700 hover:text-purple font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:text-red-500 dark:hover:text-purple"><svg aria-hidden="true" class="w-5 h-5" fill="#ffffff" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path></svg></button>
          </div>
          ))}
          <button onClick={()=>addcHandler()} type="button" class="mb-6 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Add Another Number</button>
          
          {address.map((cinput, index)=>(
          <div key={index}>
          <div class="relative z-0 mb-6 mt-6 w-full group">
          <label htmlFor="address" class="text-sm text-gray-500 dark:text-gray-400">Address</label>  
          <input name='address' value={cinput.address}  onChange={(event)=>inputaHandler(index, event)} type="text" id="search-dropdown" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"/>
          
          </div>

          <div class="relative z-0 mb-6 w-full group">
          <button onClick={()=> deleteaHandler(index)} type="button" class="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Delete Address</button>
          <button onClick={()=>inputaLocationHandler(index)} type="button" class="ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-900">Location</button>
          {cinput.latitude && <button onClick={()=> openmap(cinput.latitude, cinput.longitude)} type="button" class="ml-2 text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Map</button> }
          
          </div>
          </div>
          ))}

          <button onClick={()=>addaHandler()} type="button" class="mt-2 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Add Another Address</button>
          
          <div class="relative z-0 mb-6 mt-6 w-full group">
          <button onClick={()=>SubmitCustomer()} type="button" class="mt-2 mr-2 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>
          <button onClick={()=>cancelCustomerDetailScreen()} type="button" class="mt-2 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
          </div>
          
        </>
      }
      {showModal && 
        <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            {showModelNew? <h3 class="text-lg font-medium">Customer created successfully</h3>:
            <h3 class="text-lg font-medium">Customer updated successfully</h3>}
          </div>
          <div class="flex">
            <button onClick={()=>goList()} type="button" class="text-green-700 bg-transparent border border-green-700 hover:bg-green-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-green-500 dark:border-green-500 dark:text-green-400 dark:hover:text-white dark:focus:ring-green-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
              OK
            </button>
          </div>
        </div>
      }
      {selectedCustomerLoader &&
        <>
            <h6 class="text-lg font-bold dark:text-white mb-5">Customer Details</h6>
            <div class="grid md:grid-cols-2 md:gap-6">
            
              <div class="relative z-0 mb-6 w-full group">
                  <input value={fullNameEdit} onChange={(e)=>setFullNameEdit(e.target.value)} type="text" name="fullName" id="fullName" class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                  <label htmlFor="fullName" class="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Full Name</label>
              </div>
              <div class="relative z-0 mb-6 w-full group">
                  <input value={emailEdit} onChange={(e)=>setEmailEdit(e.target.value)}  type="email" name="email" id="email" class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                  <label htmlFor="email" class="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email</label>
              </div>
            </div>
            <div class="grid md:grid-cols-2 md:gap-6">
              <div class="relative z-0 mb-6 w-full group">
                  <input value={selectedCustomer} disabled={true} type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name="customerPhoneNumber" id="customerPhoneNumber" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                  <label htmlFor="customerPhoneNumber" class="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone Number</label>
              </div>
              <div class="z-0 mb-6 w-full">
              <label htmlFor="whatsappNumber" class="text-xs text-gray-500 dark:text-gray-400">Whatsapp Number</label>
              <PhoneInput
                  id="businessUserPhoneNumber"
                  class="block py-2.5 px-0 w-full text-xs text-gray-900 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  international
                  placeholder="alternatenumber"
                  defaultCountry={countryID}
                  value={whatsappNumberEdit}
                  onChange={(e)=>setWhatsappNumberEdit(e)}
                  />
                  
              </div>
            </div>
            {alternateNumberEdit.map((cinput, index)=>(
            <div key={index} class="relative z-0 mb-6 w-full group">
            <label htmlFor="alternateNumber" class="text-xs text-gray-500 dark:text-gray-400">Alternate Phone Number</label>  
            <PhoneInput
              id="businessUserPhoneNumber"
              class="block py-2.5 ps-6 pe-0 w-full text-xs text-gray-900 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              international
              placeholder="alternatenumber"
              defaultCountry={countryID}
              value={cinput.value}
              onChange={(e)=>inputcHandlerEdit(e, index)}
              />  
            <button onClick={()=> deletecHandlerEdit(index)} type="button" class="absolute top-5 right-0 p-2.5 text-red-700 hover:text-purple font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:text-red-500 dark:hover:text-purple"><svg aria-hidden="true" class="w-5 h-5" fill="#ffffff" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path></svg></button>
            </div>
            ))}
            <button onClick={()=>addcHandlerEdit()} type="button" class="mb-6 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Add Another Number</button>
            
            {addressEdit.map((cinput, index)=>(
            <div key={index}>
            <div class="relative z-0 mb-6 mt-6 w-full group">
            <label htmlFor="address" class="peer-focus:font-medium absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Address</label>  
            <input name='address' value={cinput.address}  onChange={(event)=>inputaHandlerEdit(index, event)} type="text" id="search-dropdown" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"/>
            
            </div>

            <div class="relative z-0 mb-6 w-full group">
            <button onClick={()=> deleteaHandlerEdit(index)} type="button" class="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Delete Address</button>
            <button onClick={()=>inputaLocationHandlerEdit(index)} type="button" class="ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-900">Location</button> 
            {cinput.latitude && <button onClick={()=> openmap(cinput.latitude, cinput.longitude)} type="button" class="ml-2 text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Map</button> }
            
            
            
            
            </div>
            </div>
            ))}

            <button onClick={()=>addaHandlerEdit()} type="button" class="mt-2 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Add Another Address</button>
            
            <div class="relative z-0 mb-6 mt-6 w-full group">
            {customersupdate && <button onClick={()=>updateCustomer()} type="button" class="mt-2 mr-2 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Update</button>}
            <button onClick={()=>cancelUpdateDetailScreen()} type="button" class="mt-2 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
            </div>
            
        </>
      }
      </>
      :
      <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">You do not have permission to view Customers. Contact your admin for permissions</h3>
          </div>
          <div class="flex">
            <button onClick={()=>navigate("/")} type="button" class="text-green-700 bg-transparent border border-green-700 hover:bg-green-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-green-500 dark:border-green-500 dark:text-green-400 dark:hover:text-white dark:focus:ring-green-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
              OK
            </button>
          </div>
      </div>
      }
    </div>
  );
}