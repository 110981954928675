//@ts-ignore
/* eslint-disable react-hooks/exhaustive-deps */
import { child, get, getDatabase, push, ref as dref, set, update } from "firebase/database";
import "font-awesome/css/font-awesome.min.css";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useUserStore } from "../contexts/UserStoreContext";
import firebaseapp from '../firebase';
import ls from 'localstorage-slim';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import {QRCodeSVG} from 'qrcode.react';
import { render, Printer, QRCode, Br, Line, Row, Cut } from 'react-thermal-printer';

export default function Collection() {
  
  const { state } = useLocation();
  const fromData = state[0];
  const [customerData, setCustomerData] = useState('');
  const navigate = useNavigate();
  const { storeID, setStoreID, countryCode, setCountryCode, collectioncreate, collectionread, collectionupdate } = useUserStore();
  const [readAccess,setReadAccess] = useState(true);
  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);
  const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);
  const [editReferenceNumber, setEditReferenceNumber] = useState(false);
  if(!storeID){
    setStoreID(userdata.storeID);
  }

  if(!countryCode){
    setCountryCode(userdata.country);
  }
  const initialLoader = true;
  const [listDataExist, setListDataExist] = useState(false)
  const [collectionListInit, setCollectionListInit] = useState(false)
  const [newCollectionLoader, setNewCollectionLoader] = useState(false)
  const [listLoader, setListLoader] = useState(false)
  const [pickupTime, setPickupTime] = useState(new Date());
  const [listData, setListData] = useState('');
  const [editItem, setEditItem] = useState('');
  const [editLoader, setEditLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [pickupNotes, setPickupNotes] = useState('');
  const [editIndex, setEditIndex] = useState(0);
  const [editNotes, setEditNotes] = useState('')
  const [stateIndex, setStateIndex] = useState(0);
  const editModal  =  false
  const [showCollectionModal, setShowCollectionModal] = useState(false);
  const [newServiceLoader, setNewServiceLoader] = useState(false)
  const [newItemValueLoader, setNewItemValueLoader] = useState(false)
  const [newItemsLoader, setNewItemsLoader] = useState(false)          
  const [newCustomerLoader, setNewCustomerLoader] = useState(false)          
  const [itemValue, setItemValue] = useState()
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState([]) 
  const [serviceOptions, setServiceOptions] = useState([])
  const [serviceSelected, setServiceSelected] = useState([])
  const [laundryModal, setLaundryModal] = useState(false)
  const [editServiceLoader, setEditServiceLoader] = useState(false)
  const [editItemValueLoader, setEditItemValueLoader] = useState(false)
  const [editItemsLoader, setEditItemsLoader] = useState(false)          
  const [editCustomerLoader, setEditCustomerLoader] = useState(false)          
  const [editItemValue, setEditItemValue] = useState({key: '', label: '', value:''})
  const [editOptions, setEditOptions] = useState([])
  const [editSelected, setEditSelected] = useState([]) 
  const [editServiceOptions, setEditServiceOptions] = useState([])
  const [editServiceSelected, setEditServiceSelected] = useState([])
  const [editCustomerData, setEditCustomerData] = useState([])
  const [newStateIndex, setNewStateIndex] = useState(0)
  const [editKey, setEditKey] = useState('');
  const [editStaff, setEditStaff] = useState('');
  const [editStaffID, setEditStaffID] = useState('');
  const [itemServiceModal, setItemServiceModal] = useState(false)
  const [SKU, setSKU] = useState('');
  const [barcodeloader, setBarcodeloader] = useState(false);
  const [filterListData, setFilterListData] = useState('');
  const [query, setQuery] = useState('')
  const [modalview, setModalview] = useState(false)
  const [barcodenumbers, setBarcodenumbers] = useState(1)

  useEffect(() => {
    if(collectionread){
      if(initialLoader){
        
        if(fromData === "Collection"){
          setCustomerData(state[1]);
          setNewCustomerLoader(true);
          setNewCollectionLoader(true);
        }else{
          setCollectionListInit(true);
        }
      }
    }else{
      setReadAccess(false);
    }
  }, [initialLoader])

  useEffect(() => { 
    if(collectionListInit){
      function listCollectionData(){
        setCollectionListInit(false);
        get(child(dbRef, `${storeID}/collection/${storeID}/`)).then(async (datasnapshot) => {
          const listdata = [];
          if (datasnapshot.exists()) {
            datasnapshot.forEach(snapshot => {
              snapshot.forEach(element => {
                const key = element.key;
                const cdata = element.child('customerData').val(); 
                const paddress = element.child('pickupAddress').val(); 
                const pnotes = element.child('pickupNotes').val(); 
                const pstaff = element.child('pickupStaff').val(); 
                const pickupstaffloginID = element.child('pickupstaffloginID').val(); 
                const ptime = element.child('pickupTime').val(); 
                const status = element.child('status').val(); 
                const referenceNumber = element.child('referenceNumber').val(); 
                const addressIndex = element.child('addressIndex').val();
                const itemValue = element.child('itemValue').val() || [];
                const options = element.child('options').val() || [];
                const pstaffID = element.child('pstaffID').val();
                const selected = element.child('selected').val() || [];
                const serviceOptions = element.child('serviceOptions').val() || [];
                const serviceSelected = element.child('serviceSelected').val() || [];
                listdata.push({
                  key:key,
                  cdata: cdata, 
                  paddress:paddress, 
                  pnotes:pnotes, 
                  pstaff:pstaff, 
                  ptime:ptime, 
                  status:status,
                  addressIndex:addressIndex,
                  itemValue:itemValue,
                  options: options,
                  pstaffID:pstaffID,
                  pickupstaffloginID:pickupstaffloginID,
                  selected:selected,
                  serviceOptions:serviceOptions,
                  serviceSelected:serviceSelected,
                  referenceNumber:referenceNumber
                  })

              });
            });
            const completedListData = await Promise.all(listdata);
            const dataExist =  true;
            return [dataExist, completedListData];;
          }
          else{
            const dataExist =  false;
            return [dataExist, 0];;
          }
          
        }).then((dataExist)=>{
          if(dataExist[0]){
            setListData(dataExist[1]);
            setFilterListData(dataExist[1]);
            setListDataExist(true)
            setListLoader(true);
          }else{
            setListDataExist(false)
            setListLoader(true);
          }
        }).catch((error)=>{
          console.log(error);
        });
     }
     listCollectionData();
    }
  }, [collectionListInit]);

  function cancelCollection(){
    if(fromData){
      navigate("/customers");
    }else{
      setCollectionListInit(true);
    }
  }

  function showmap(lat, long){
    console.log(lat, long);
  }

  function showCollectionList(){
    setShowCollectionModal(false);
    setShowModal(false);
    setLaundryModal(false);
    setCollectionListInit(true);
  }

  function continueDeleteCollection(){
    setDeleteModal(false);
    setCollectionListInit(false);
    const key = editItem.key;
    set(dref(dbr, storeID+'/collection/' + storeID+'/'+key), {
      null:null
    }).then(()=>{
      setEditLoader(false);
      setEditItem('');
      setCollectionListInit(true);
    })
  }

  function cancelDelete(){
    setDeleteModal(false);
    setEditLoader(true);
  }

  const addaHandlerEdit = ()=>{
    const _phonenumber = [...itemValue];
    _phonenumber.push({key: '', label: '', value:''});
    setItemValue(_phonenumber);
  }

  const deleteaHandlerEdit = (ckey)=>{
    const _phonenumber = itemValue.filter((cinput,index) => index !== ckey);
    setItemValue(_phonenumber);
  }


  const inputaHandlerEdit = (index, event)=>{
    
    const _address = [...itemValue];
    _address[index][event.target.name] = event.target.value;
    setItemValue(_address);
  }

  const addeHandlerEdit = ()=>{
    const _phonenumber = [...editItemValue];
    _phonenumber.push({key: '', label: '', value:''});
    setEditItemValue(_phonenumber);
  }

  const deleteeHandlerEdit = (ckey)=>{
    const _phonenumber = editItemValue.filter((cinput,index) => index !== ckey);
    setEditItemValue(_phonenumber);
  }

  const inputeHandlerEdit = (index, event)=>{
    
    const _address = [...editItemValue];
    _address[index][event.target.name] = event.target.value;
    setEditItemValue(_address);
  }


  function gotoNewItemsLoader(){
    get(child(dbRef, `${storeID}/settings/${storeID}/laundryitems`)).then((snapshot) => {
      if(snapshot.exists){
      const str = snapshot.child("laundryitems").val();
      if(str){
        let selectedArr = str.split(",");
      const arraydata = [];
      for (const key in selectedArr) {
        if (Object.hasOwnProperty.call(selectedArr, key)) {
          const element = selectedArr[key];
          arraydata.push({key:key, value:element, label:element})
        }
      }
      setOptions(arraydata);
      setNewCustomerLoader(false);
      setNewItemsLoader(true);
      }else{
        setNewCustomerLoader(false);
        setItemServiceModal(true);
      }

      }else{
      setNewCustomerLoader(false);
      setNewItemsLoader(true);
      }
    })
  }

  function backtonewcustomerLoader(){
    setNewItemsLoader(false)
    setNewCustomerLoader(true)
  }

  function gotoNewItemValueLoader(){
    const itemval = [];
    for (const key in selected) {
      if (Object.hasOwnProperty.call(selected, key)) {

        const element = selected[key];
        itemval.push({label:element, value:0})
      }
    }
    setItemValue(itemval);
    setNewItemsLoader(false);
    setNewItemValueLoader(true);
  }

  function backtoNewitemsLoader(){
    setNewItemValueLoader(false)
    setNewItemsLoader(true);
  }

  function gotoNewServiceLoader(){
    get(child(dbRef, `${storeID}/settings/${storeID}/laundryservices`)).then((snapshot) => {
      if(snapshot.exists){
      const str = snapshot.child("laundryservices").val();
      let selectedArr = str.split(",");
      const arraydata = [];
      for (const key in selectedArr) {
        if (Object.hasOwnProperty.call(selectedArr, key)) {
          const element = selectedArr[key];
          arraydata.push({key:key, value:element, label:element})
        }
      }
      setServiceOptions(arraydata);
        setNewItemValueLoader(false);
        setNewServiceLoader(true);
      }else{
        setNewItemValueLoader(false);
        setNewServiceLoader(true);
      }
    })
  }

  function backtonewItemvalueLoader(){
    setNewServiceLoader(false);
    setNewItemValueLoader(true);
  }

  function submitCollection(){
    const selectedAddress = customerData.address[selectedIndex];
    const customerkey = customerData.customerID;
    const key = push(child(dref(dbr), 'staff')).key;
    let nstatus;
    if(newStateIndex === 0){
      nstatus = "new"
    }else if(newStateIndex === 1){
      nstatus = "collected"
    }else{
      nstatus = "completed"
    }
    let incrementValue, refValue;
    const udate = moment(new Date()).format('DDMMYYYY')
    get(child(dbRef, `${storeID}/refNumber/${storeID}/${udate}`)).then(async (snapshot) => {
    
      if (snapshot.exists()) {
        const a =  Object.keys(snapshot.val()).length;
        incrementValue = a+1;
        refValue = `${udate}-${incrementValue}`
        const refNum = update(dref(dbr, storeID+'/refNumber/'+storeID+'/'+udate+'/'+key), {
        
        referenceNumber: refValue
        })
        if(newStateIndex === 2){
          const coll = set(dref(dbr, storeID+'/collection/' + storeID+'/'+customerkey+'/'+key), {
            key:key,
            pickupAddress: selectedAddress,
            pickupTime: moment(pickupTime).toISOString(),
            pickupStaff: "In-Store",
            pstaffID: "In-Store",
            pickupstaffloginID: false,
            pickupNotes: pickupNotes,
            referenceNumber:refValue,
            customerData:customerData,
            addressIndex:selectedIndex,
            status: nstatus,
            options:options || [],
            selected:selected || [],
            itemValue:itemValue || [],
            serviceOptions:serviceOptions || [],
            serviceSelected:serviceSelected || []
          })
          
          const laun = set(dref(dbr, storeID+'/laundry/' + storeID+'/'+customerkey+'/'+key), {
            key:key,
            pickupAddress: selectedAddress,
            pickupTime: moment(pickupTime).toISOString(),
            pickupStaff: "In-Store",
            pstaffID: "In-Store",
            pickupstaffloginID: false,
            pickupNotes: pickupNotes,
            referenceNumber:refValue,
            customerData:customerData,
            addressIndex:selectedIndex,
            status: "new",
            options:options || [],
            selected:selected || [],
            itemValue:itemValue || [],
            serviceOptions:serviceOptions || [],
            serviceSelected:serviceSelected || []
          })
          const finalUpdate = [coll, laun, refNum];
          const finalPromise = Promise.all(finalUpdate)
          finalPromise.then(()=>{
            setNewServiceLoader(false);
            setLaundryModal(true);
          })
        }else{
          const coll = update(dref(dbr, storeID+'/collection/' + storeID+'/'+customerkey+'/'+key), {
            key:key,
            pickupAddress: selectedAddress,
            pickupTime: moment(pickupTime).toISOString(),
            pickupStaff: "In-Store",
            pstaffID: "In-Store",
            pickupstaffloginID:false,
            pickupNotes: pickupNotes,
            referenceNumber:refValue,
            customerData:customerData,
            addressIndex:selectedIndex,
            status: "collected",
            options:options || [],
            selected:selected || [],
            itemValue:itemValue || [],
            serviceOptions:serviceOptions || [],
            serviceSelected:serviceSelected || []
          })
          
          const finalUpdate = [coll, refNum];
          const finalPromise = Promise.all(finalUpdate)
          finalPromise.then(()=>{
            setNewServiceLoader(false);
            setShowModal(true);
          })
        }

      }else{
        incrementValue = 1;
        refValue = `${udate}-${incrementValue}`
        const refNum = update(dref(dbr, storeID+'/refNumber/'+storeID+'/'+udate+'/'+key), {
        referenceNumber: refValue
        })
        if(newStateIndex === 2){
          const coll = set(dref(dbr, storeID+'/collection/' + storeID+'/'+customerkey+'/'+key), {
            key:key,
            pickupAddress: selectedAddress,
            pickupTime: moment(pickupTime).toISOString(),
            pickupStaff: "In-Store",
            pstaffID: "In-Store",
            pickupstaffloginID: false,
            pickupNotes: pickupNotes,
            referenceNumber: refValue,
            customerData:customerData,
            addressIndex:selectedIndex,
            status: nstatus,
            options:options || [],
            selected:selected || [],
            itemValue:itemValue || [],
            serviceOptions:serviceOptions || [],
            serviceSelected:serviceSelected || []
          })
          const laun = set(dref(dbr, storeID+'/laundry/' + storeID+'/'+customerkey+'/'+key), {
            key:key,
            pickupAddress: selectedAddress,
            pickupTime: moment(pickupTime).toISOString(),
            pickupStaff: "In-Store",
            pstaffID: "In-Store",
            pickupstaffloginID:false,
            pickupNotes: pickupNotes,
            referenceNumber:refValue,
            customerData:customerData,
            addressIndex:selectedIndex,
            status: "new",
            options:options || [],
            selected:selected || [],
            itemValue:itemValue || [],
            serviceOptions:serviceOptions || [],
            serviceSelected:serviceSelected || []
          })
          const finalUpdate = [coll, laun, refNum];
          const finalPromise = Promise.all(finalUpdate)
          finalPromise.then(()=>{
            setNewServiceLoader(false);
            setLaundryModal(true);
          })
        }else{
          const coll = update(dref(dbr, storeID+'/collection/' + storeID+'/'+customerkey+'/'+key), {
            key:key,
            pickupAddress: selectedAddress,
            pickupTime: moment(pickupTime).toISOString(),
            pickupStaff: "In-Store",
            pstaffID: "In-Store",
            pickupstaffloginID:false,
            pickupNotes: pickupNotes,
            referenceNumber:refValue,
            customerData:customerData,
            addressIndex:selectedIndex,
            status: "collected",
            options:options || [],
            selected:selected || [],
            itemValue:itemValue || [],
            serviceOptions:serviceOptions || [],
            serviceSelected:serviceSelected || []
          });
          const finalUpdate = [coll, refNum];
          const finalPromise = Promise.all(finalUpdate)
          finalPromise.then(()=>{
            setNewServiceLoader(false);
            setShowModal(true);
          })
        }
          
          
      }

    }) 
  }

  function gotoEditItemsLoader(){
    get(child(dbRef, `${storeID}/settings/${storeID}/laundryitems`)).then((snapshot) => {
      if(snapshot.exists){
      const str = snapshot.child("laundryitems").val();
      let selectedArr = str.split(",");
      const arraydata = [];
      for (const key in selectedArr) {
        if (Object.hasOwnProperty.call(selectedArr, key)) {
          const element = selectedArr[key];
          arraydata.push({key:key, value:element, label:element})
        }
      }
      setOptions(arraydata);
      setEditCustomerLoader(false);
      setEditItemsLoader(true);
      }else{
      setEditCustomerLoader(false);
      setEditItemsLoader(true);
      }
    })
  }

  function backtoeditcustomerLoader(){
    setEditItemsLoader(false)
    setEditCustomerLoader(true)
  }

  function editCollection(item){
    console.log(item, '537');
    setEditReferenceNumber(item.referenceNumber);
    setEditKey(item.key);
    setEditIndex(item.addressIndex);
    setEditNotes(item.pnotes);
    setEditItem(item);
    setEditStaff(item.pstaff)
    setEditStaffID(item.pstaffID)
    if(item.itemValue){
      setEditItemValue(item.itemValue);
    }else{
      setEditItemValue({key: '', label: '', value:''});
    }
    setEditCustomerData(item.cdata);
    setListLoader(false);
    if(item.status === "new"){
      setStateIndex(0);
    }else if(item.status === "collected"){
      setStateIndex(1)
    }else{
      setStateIndex(2)
    }

    if(!item.options.length || !item.serviceOptions.length){
      const laundryitems = get(child(dbRef, `${storeID}/settings/${storeID}/laundryitems`));
      const laundryservices = get(child(dbRef, `${storeID}/settings/${storeID}/laundryservices`));
      const pmany = [laundryitems, laundryservices]
      const finalpromise = Promise.all(pmany)
      
      setEditSelected([]);
      setEditServiceSelected([]);
      finalpromise.then((results)=>{
        const str = results[0].child("laundryitems").val()
        const strls = results[1].child("laundryservices").val();

        if(str && strls){
          let selectedArr = str.split(",");
          const arraydata = [];
          for (const key in selectedArr) {
            if (Object.hasOwnProperty.call(selectedArr, key)) {
              const element = selectedArr[key];
              arraydata.push({key:key, value:element, label:element})
            }
          }
          
          let selectedArrls = strls.split(",");
          const arraydatals = [];
          for (const key in selectedArrls) {
            if (Object.hasOwnProperty.call(selectedArrls, key)) {
              const element = selectedArrls[key];
              arraydatals.push({key:key, value:element, label:element})
            }
          }
          setEditOptions(arraydata);
          setEditServiceOptions(arraydatals);
          setEditCustomerLoader(true);
          setEditLoader(true);

        }else{
          setItemServiceModal(true);
        }

        
      })
    }else{
      setEditSelected(item.selected);
      setEditServiceSelected(item.serviceSelected);
      setEditOptions(item.options)
      setEditServiceOptions(item.serviceOptions)
      setEditCustomerLoader(true);
      setEditLoader(true);
    }
  }

  function gotoEditItemValueLoader(){
    
    if(Object.keys(editItemValue).length){
      const selectedArray = [];
      for (const key in editSelected) {
        if (Object.hasOwnProperty.call(editSelected, key)) {
          const editSelectedelement = editSelected[key];
          let obj = editItemValue.find(o => o.label === editSelectedelement);
          
          let objValue;
          if(obj){
            if(obj.value){
              objValue = obj.value;
            }else{
              objValue = 0;
            }
            selectedArray.push({key:key, label: editSelectedelement, value:objValue})
          }else{
            selectedArray.push({key:key, label: editSelectedelement, value:0})
          }
          
        }
      }

      setEditItemValue(selectedArray);

    }else{
      const itemval = [];
      for (const key in editSelected) {
        if (Object.hasOwnProperty.call(editSelected, key)) {
          const element = editSelected[key];
          itemval.push({key:key, label:element, value:0})
        }
      }
      setEditItemValue(itemval);
    }
    setEditItemsLoader(false);
    setEditItemValueLoader(true);
  }

  function backtoEdititemsLoader(){
    setEditItemValueLoader(false)
    setEditItemsLoader(true);
  }

  function gotoEditServiceLoader(){
    get(child(dbRef, `${storeID}/settings/${storeID}/laundryservices`)).then((snapshot) => {
      if(snapshot.exists){
      const str = snapshot.child("laundryservices").val();
      let selectedArr = str.split(",");
      const arraydata = [];
      for (const key in selectedArr) {
        if (Object.hasOwnProperty.call(selectedArr, key)) {
          const element = selectedArr[key];
          arraydata.push({key:key, value:element, label:element})
        }
      }
      setServiceOptions(arraydata);
        setEditItemValueLoader(false);
        setEditServiceLoader(true);
      }else{
        setEditItemValueLoader(false);
        setEditServiceLoader(true);
      }
    })

    
  }

  function backtoeditItemvalueLoader(){
    setEditServiceLoader(false);
    setEditItemValueLoader(true);
    
  }
  function editLaundrySubmit(){
    const selectedAddress = editCustomerData.address[editIndex];
    let estatus;
    if(stateIndex === 0){
      estatus = "new"
    }else if(stateIndex === 1){
      estatus = "collected"
    }else{
      estatus = "completed"
    }

    const postData = {
      key:editKey,
      pickupAddress: selectedAddress,
      pickupTime: moment(pickupTime).toISOString(),
      pickupStaff: editStaff,
      pstaffID: editStaffID,
      pickupstaffloginID:editItem.pickupstaffloginID,
      pickupNotes: editNotes,
      referenceNumber:editReferenceNumber,
      customerData:editCustomerData,
      addressIndex:editIndex,
      status: estatus,
      options:editOptions,
      selected:editSelected,
      itemValue:editItemValue,
      serviceOptions:editServiceOptions,
      serviceSelected:editServiceSelected
    }

    const postDatalaundry = {
      key:editKey,
      pickupAddress: selectedAddress,
      pickupTime: moment(pickupTime).toISOString(),
      pickupStaff: editStaff,
      pstaffID: editStaffID,
      pickupstaffloginID:editItem.pickupstaffloginID,
      pickupNotes: editNotes,
      referenceNumber:editReferenceNumber,
      customerData:editCustomerData,
      addressIndex:editIndex,
      status: "new",
      options:editOptions,
      selected:editSelected,
      itemValue:editItemValue,
      serviceOptions:editServiceOptions,
      serviceSelected:editServiceSelected
    }
    const customerkey = editCustomerData.customerID;
    const updates = {};
    updates[storeID+'/collection/'+storeID+'/'+customerkey+'/'+editKey] = postData;
    updates[storeID+'/laundry/'+storeID+'/'+customerkey+'/'+editKey] = postDatalaundry;

    if(stateIndex === 2){
      update(dref(dbr), updates).then(()=>{
        setEditServiceLoader(false);
        setLaundryModal(true);
      })
    }else{
      
      set(dref(dbr, storeID+'/collection/' + storeID+'/'+customerkey+'/'+editKey), {
        key:editKey,
        pickupAddress: selectedAddress,
        pickupTime: moment(pickupTime).toISOString(),
        pickupStaff: editStaff,
        pstaffID: editStaffID,
        pickupstaffloginID:editItem.pickupstaffloginID,
        pickupNotes: editNotes,
        referenceNumber:editReferenceNumber,
        customerData:editCustomerData,
        addressIndex:editIndex,
        status: estatus,
        options:editOptions,
        selected:editSelected,
        itemValue:editItemValue,
        serviceOptions:editServiceOptions,
        serviceSelected:editServiceSelected
      }).then(()=>{
        setEditServiceLoader(false);
        setShowModal(true);
      })
    }
  }

  function openBarcode(){
    setListLoader(false);
    setBarcodeloader(true);
  }
  
function closeBarcode(){
    setBarcodeloader(false);
    setListLoader(true);
  }

  function sucessBarcode(code){
    setSKU(code);
    setQuery(code);
    handleSearch(query);
    setBarcodeloader(false);
    setListLoader(true);
  }

  function handleSearch(e){
    setQuery(e);
    const elow = e.toLowerCase();
    const fData = listData.filter(item => {
      const min = moment(item.ptime).format("hh:mm a")
      const dt = moment(item.ptime).format("DD MMM")
      const yr = moment(item.ptime).format("YYYY")
      
      return (
        (item.status && item.status.toLowerCase().includes(elow)) ||
        (item.referenceNumber && item.referenceNumber.toLowerCase().includes(elow)) ||
        (item.paddress && item.paddress.address.toLowerCase().includes(elow)) ||
        (item.pstaff && item.pstaff.toLowerCase().includes(elow)) ||
        (item.cdata && item.cdata.fullName.toLowerCase().includes(elow)) ||
        (item.cdata && item.cdata.phoneNumber.toLowerCase().includes(elow)) ||
        (min && min.toLowerCase().includes(elow)) ||
        (dt && dt.toLowerCase().includes(elow)) ||
        (yr && yr.toLowerCase().includes(elow))

        );
    }
    );
    setFilterListData(fData);
  };

  async function print(){
    setModalview(false);
    setEditLoader(true);
    let qrArray = [];
    const data = await render(
      <Printer type="epson">
        {qrArray}
        <Cut />
      </Printer>
    );
    for (let index = 0; index < barcodenumbers; index++) {
      qrArray.push(<><QRCode align="center" content={editItem.referenceNumber} /><Br /></>);
    }
    const port = await window.navigator.serial.requestPort();
    await port.open({ baudRate: 9600 });

    const writer = port.writable?.getWriter();

    if (writer != null) {
      await writer.write(data);
      writer.releaseLock();
    }
  }

  function openmodal(){
    setEditLoader(false)
    setModalview(true)
  }

  function closemodal(){
    setModalview(false)
    setEditLoader(true)
  }
  
  return (
    <>
      <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Collection</span>
            </div>
          </li>
        </ol>
      </nav>
      {readAccess?
      <>
      {listLoader && 
        <>
          {listDataExist? 
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
              <div class="flex items-center justify-between">
              <p class="mb-5 ml-5 text-xl text-gray-900 dark:text-white">Collection List</p>
              <button onClick={()=>navigate("/customers")} type="button" class="text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">New Collection</button>
              </div>
              <div class="relative z-0 mb-6 w-1/2 ml-5 mr-5 group ">
                <input value={query} onChange={(e)=>handleSearch(e.target.value)} type="text" name="sku" id="sku" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                <button onClick={()=>openBarcode()} type="button" class="absolute top-0 right-0 p-2.5 text-sm font-medium text-black dark:text-white bg-grey-700 rounded-r-lg border border-grey-700 hover:bg-grey-800 focus:ring-4 focus:outline-none focus:ring-grey-300 dark:bg-grey-600 dark:hover:bg-grey-700 dark:focus:ring-grey-800"><svg aria-hidden="true" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M6 8h2v8H6zm3 0h1v8H9zm8 0h1v8h-1zm-4 0h3v8h-3zm-2 0h1v8h-1z"></path></svg></button>
                <label htmlFor="sku" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Search</label>
              </div>
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="py-3 px-6">
                          Date
                      </th>
                      <th scope="col" class="py-3 px-6">
                          Customer
                      </th>
                      <th scope="col" class="py-3 px-6">
                          Reference Number
                      </th>
                      <th scope="col" class="py-3 px-6">
                          staff & Status
                      </th>
                      <th scope="col" class="py-3 px-6">
                          Action
                      </th>
                  </tr>
                </thead>
                <tbody>
                  {filterListData.map((item, key)=>(
                    <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <p>{moment(item.ptime).format('DD MMM YYYY')}</p>
                      <p>{moment(item.ptime).format('hh:mm a')}</p>
                      </th>
                      <td class="py-4 px-6">
                      <p>{item.cdata.fullName}</p>
                      <p>{item.cdata.phoneNumber}</p>
                      </td>
                      <td class="py-4 px-6">
                      {item.referenceNumber}
                      </td>
                      <td class="py-4 px-6">
                      <p>{item.pstaff}</p>
                      <div>
                      <div class="flex items-center">
                      {(item.status === "new") &&  <span class="flex w-3 h-3 bg-green-300 rounded-full"></span>}
                      {(item.status === "collected") &&  <span class="flex w-3 h-3 bg-yellow-300 rounded-full"></span>}
                      {(item.status === "completed") &&  <span class="flex w-3 h-3 bg-purple-300 rounded-full"></span>}
                      <span class="ml-2">{item.status}</span> 
                      </div>
                      </div>
                      </td>
                      <td class="py-4 px-6">
                        {collectionupdate &&
                        <button onClick={()=>editCollection(item)} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center mr-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-blue-800">
                          Edit
                        </button>
                        }
                      </td>
                    </tr>
                  ))}   
                </tbody> 
                </table>     
              </div>
            :
            <div id="alert" class="p-4 mb-4 text-yellow-700 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800" role="alert">
              <div class="flex items-center">
                <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                <h3 class="text-lg font-medium">You have not added Collection</h3>
              </div>
              <div class="mt-2 mb-4 text-sm">
                Do you wish to create Collection?
              </div>
              <div class="flex">
                <button onClick={()=>navigate("/customers")} type="button" class="text-white bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800">
                  <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
                  Yes
                </button>
                <button onClick={()=>navigate("/")} type="button" class="text-yellow-700 bg-transparent border border-yellow-700 hover:bg-yellow-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-yellow-500 dark:border-yellow-500 dark:text-yellow-400 dark:hover:text-white dark:focus:ring-yellow-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                  Dismiss
                </button>
              </div>
            </div>
          } 
        </>
      }
      {barcodeloader && 
      <div>
      <BarcodeScannerComponent
        width={500}
        height={500}
        onUpdate={(err, result) => {
          if (result) sucessBarcode(result.text);
          else console.log(err)
        }}
        torch={false}
        
      />
      {SKU? <p>Scanned value: {SKU}</p>:<p class="text-sm text-gray-900 dark:text-white">No value scanned...</p>}
      <button onClick={()=>closeBarcode()} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Close</button>
      </div>
    }
      {newCollectionLoader &&
        <div class="w-full max-w-lg bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            
            {newCustomerLoader &&
            <div class="flex flex-col ml-5 mr-5 pb-10">
              <h5 class="mt-5 mb-5 text-xl font-medium text-gray-900 dark:text-white">New Collection</h5>  
              <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">{customerData.fullName}</h5>
              <span class="text-sm text-gray-500 dark:text-gray-400">Phonenumber: {customerData.phoneNumber}</span>
              {customerData.alternateNumber.map((ph, index)=>(
                <p key={index} class="text-sm text-gray-500 dark:text-gray-400">Alternate Phone: {ph.alternateNumber}</p>
              ))}
              {customerData.email && <span class="text-sm text-gray-500 dark:text-gray-400">{customerData.email}</span>}

              <label htmlFor="full_name" class="mt-5 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Address</label>
              <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <tbody>
                  {customerData.address.map((adr, index)=>( 
                    <tr key={index} class="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <td class="w-4 p-4">
                      <div class="flex items-center">
                        <input checked={selectedIndex === index} onChange={()=>setSelectedIndex(index)} id="default-radio-1" type="radio" value="" name="default-radio" class="w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                      </div>
                    </td>
                    <td class="px-6 py-4">
                    {adr.address && <p class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{adr.address}</p>}
                    {adr.latitude && <button onClick={()=>showmap(adr.latitude, adr.longitude)} type="button" class="ml-3 mt-3 text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-xs px-3 py-2 text-center mr-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">map</button>}
                    </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
              <label htmlFor="full_name" class="mt-5 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Date & Time</label>
              <DatePicker class="bg-purple-50 border border-purple-500 text-purple-900 dark:text-purple-400 placeholder-purple-700 dark:placeholder-purple-500 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-purple-500" showTimeSelect timeIntervals={15} timeCaption="Time"  dateFormat="MMM/d/yyyy h:mm aa" onChange={(date)=>setPickupTime(date)} selected={pickupTime} /> 
              <label htmlFor="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Notes</label>
              <textarea value={pickupNotes} onChange={(e)=>setPickupNotes(e.target.value)} id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-purple-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-purple-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-purple-500"></textarea>
              <div class="flex mt-4 space-x-3 md:mt-6">
              <button onClick={()=>cancelCollection()} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
              {collectioncreate && 
              <button onClick={()=>gotoNewItemsLoader(customerData)} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Next</button>
              }
              </div>
            </div>
            }
            {newItemsLoader &&
              <div class="flex flex-col ml-5 mr-5 pb-10">
              <h5 class="mt-5 mb-5 text-xl font-medium text-gray-900 dark:text-white">Collection Items</h5>  
              <DualListBox
                canFilter 
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                showHeaderLabels
                selectedHeader
                lang = {{
                  availableFilterHeader: 'Filter available',
                  availableHeader: 'Dress Materials',
                  filterPlaceholder: 'Search...',
                  moveAllLeft: 'Move all to available',
                  moveAllRight: 'Move all to selected',
                  moveLeft: 'Move to available',
                  moveRight: 'Move to selected',
                  moveBottom: 'Rearrange to bottom',
                  moveDown: 'Rearrange down',
                  moveUp: 'Rearrange up',
                  moveTop: 'Rearrange to top',
                  noAvailableOptions: 'No available options',
                  noSelectedOptions: 'No selected options',
                  requiredError: 'Please select at least one option.',
                  selectedFilterHeader: 'Filter selected',
                  selectedHeader: 'Customer Dresses',
                }}
                options={options}
                selected={selected}
                onChange={(value) => setSelected(value)}
              />
              <div class="flex mt-4 space-x-3 md:mt-6">
              <button onClick={()=>cancelCollection()} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
              <button onClick={()=>backtonewcustomerLoader()} type="button" class="text-yellow-900 bg-white border border-yellow-300 focus:outline-none hover:bg-yellow-100 focus:ring-4 focus:ring-yellow-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-yellow-800 dark:text-white dark:border-yellow-600 dark:hover:bg-yellow-700 dark:hover:border-yellow-600 dark:focus:ring-yellow-700">Back</button>
              <button onClick={()=>gotoNewItemValueLoader()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Next</button>
              </div>
            </div>
            }
            {newItemValueLoader &&
            <div class="relative overflow-x-auto">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <caption class="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                Item - Numbers
              </caption>
              <thead>
              <tr>
                <th scope="col" class="px-6 py-3">
                    Dress
                </th>
                <th scope="col" class="px-6 py-3">
                    Quantity
                </th>
                <th scope="col" class="px-6 py-3">
                </th>
              </tr>
              </thead>
              <tbody>
              {itemValue.map((item, index)=>(
              <tr key={index}>
              <td class="px-6 py-4">
              <input name='label' value={item.label}  onChange={(event)=>inputaHandlerEdit(index, event)} type="text" id="search-dropdown" class="bg-purple-50 border border-purple-500 text-purple-900 dark:text-purple-400 placeholder-purple-700 dark:placeholder-purple-500 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-purple-500"/>
              </td>  
              <td class="px-6 py-4">
              <input name='value' value={item.value}  onChange={(event)=>inputaHandlerEdit(index, event)} type="text" id="search-dropdown" class="bg-purple-50 border border-purple-500 text-purple-900 dark:text-purple-400 placeholder-purple-700 dark:placeholder-purple-500 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-purple-500"/>  
              </td>
              <td class="px-6 py-4">
              <button onClick={()=> deleteaHandlerEdit(index)} type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                <svg aria-hidden="true" class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path></svg>
                <span class="sr-only">Icon description</span>
              </button>
              </td>
              </tr>
              ))} 
                
              </tbody>
              </table>
              <button onClick={()=>addaHandlerEdit()} type="button" class="ml-5 mt-2 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Add another Dress</button>
              <div class="ml-5 flex mt-4 space-x-3">
              <button onClick={()=>cancelCollection()} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
              <button onClick={()=>backtoNewitemsLoader()} type="button" class="text-yellow-900 bg-white border border-yellow-300 focus:outline-none hover:bg-yellow-100 focus:ring-4 focus:ring-yellow-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-yellow-800 dark:text-white dark:border-yellow-600 dark:hover:bg-yellow-700 dark:hover:border-yellow-600 dark:focus:ring-yellow-700">Back</button>
              <button onClick={()=>gotoNewServiceLoader()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Next</button>
              </div>
            </div>
            }
            {newServiceLoader &&
              <div class="flex flex-col ml-5 mr-5 pb-10">
              <h5 class="mt-5 mb-5 text-xl font-medium text-gray-900 dark:text-white">Services</h5>  
              <DualListBox
                canFilter 
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                showHeaderLabels
                selectedHeader
                lang = {{
                  availableFilterHeader: 'Filter available',
                  availableHeader: 'Our Services',
                  filterPlaceholder: 'Search...',
                  moveAllLeft: 'Move all to available',
                  moveAllRight: 'Move all to selected',
                  moveLeft: 'Move to available',
                  moveRight: 'Move to selected',
                  moveBottom: 'Rearrange to bottom',
                  moveDown: 'Rearrange down',
                  moveUp: 'Rearrange up',
                  moveTop: 'Rearrange to top',
                  noAvailableOptions: 'No available options',
                  noSelectedOptions: 'No selected options',
                  requiredError: 'Please select at least one option.',
                  selectedFilterHeader: 'Filter selected',
                  selectedHeader: 'Service to Customer',
                }}
                options={serviceOptions}
                selected={serviceSelected}
                onChange={(value) => setServiceSelected(value)}
              />
              <label htmlFor="full_name" class="mt-5 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select State</label>
                <div class="flex items-center">
                    <input checked={newStateIndex === 0} onChange={()=>setNewStateIndex(0)} id="disabled-radio-2" type="radio" value="" name="disabled-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label htmlFor="disabled-radio-2" class="ml-2 text-sm font-medium text-gray-400 dark:text-gray-500">New</label>
                </div>
                <div class="flex items-center">
                    <input checked={newStateIndex === 1} onChange={()=>setNewStateIndex(1)} id="disabled-radio-2" type="radio" value="" name="disabled-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label htmlFor="disabled-radio-2" class="ml-2 text-sm font-medium text-gray-400 dark:text-gray-500">Collected</label>
                </div>
                <div class="flex items-center">
                    <input checked={newStateIndex === 2} onChange={()=>setNewStateIndex(2)} id="disabled-radio-2" type="radio" value="" name="disabled-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label htmlFor="disabled-radio-2" class="ml-2 text-sm font-medium text-gray-400 dark:text-gray-500">Completed</label>
                </div>
              <div class="flex mt-4 space-x-3 md:mt-6">
              <button onClick={()=>cancelCollection()} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
              <button onClick={()=>backtonewItemvalueLoader()} type="button" class="text-yellow-900 bg-white border border-yellow-300 focus:outline-none hover:bg-yellow-100 focus:ring-4 focus:ring-yellow-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-yellow-800 dark:text-white dark:border-yellow-600 dark:hover:bg-yellow-700 dark:hover:border-yellow-600 dark:focus:ring-yellow-700">Back</button>
              <button onClick={()=>submitCollection()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>
              </div>
            </div>
            }
        </div>
      }
      {showModal && 
        <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            {editModal? <h3 class="text-lg font-medium">Collection updated successfully</h3>:<h3 class="text-lg font-medium">Collection created successfully</h3>}
          </div>
          <div class="mt-2 mb-4 text-sm">
            Do you wish to create new Collection?
          </div>
          <div class="flex">
            <button onClick={()=>navigate("/customers")} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              New Collection
            </button>
            <button onClick={()=>showCollectionList()} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Collection List
            </button>
            
          </div>
        </div>
      }
      {modalview &&
        <div class="relative p-4 w-full max-w-md max-h-full">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                    Number of barcodes
                </h3>
                <button onClick={()=>closemodal()} type="button" class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
            </div>
            <div class="p-4 md:p-5"> 
              <input value={barcodenumbers} onChange={(e)=>setBarcodenumbers(e.target.value)} type="number" name="number" id="number" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="name@company.com" required />
              <button type="button" onClick={()=>print()} class="w-full mt-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Continue Print</button>
            </div>
          </div>
        </div> 
      }
      {editLoader &&
        <div class="w-full max-w-lg bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            
            {editCustomerLoader &&
            <div class="flex flex-col ml-5 mr-5 pb-10">
              <div class="flex items-center justify-between">
              <h5 class="mt-5 mb-5 text-xl font-medium text-gray-900 dark:text-white">Edit Collection</h5>  
              <span class="text-sm text-gray-500 dark:text-gray-400">{editReferenceNumber}</span>
              </div>
              <div class="grid grid-cols-2 gap-4">
            <div>
            <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">{editCustomerData.fullName}</h5>
              <span class="text-sm text-gray-500 dark:text-gray-400">Phonenumber: {editCustomerData.phoneNumber}</span>
              {editCustomerData.alternateNumber.map((ph, index)=>(
                <p key={index} class="text-sm text-gray-500 dark:text-gray-400">Alternate Phone: {ph.alternateNumber}</p>
              ))}
              {editCustomerData.email && <span class="text-sm text-gray-500 dark:text-gray-400">{customerData.email}</span>}

            </div>
            {editItem.referenceNumber &&
            <div class="items-center align-center">
            <QRCodeSVG 
              size={70}
              style={{ marginBottom:10 }}
              value={editItem.referenceNumber}
              />
            <button onClick={()=>openmodal()} type="button" class="text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">Print</button>
            </div>
            }
          </div>
              
              <label htmlFor="full_name" class="mt-5 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Address</label>
              <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <tbody>
                  {editCustomerData.address.map((adr, index)=>( 
                    <tr key={index} class="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <td class="w-4 p-4">
                      <div class="flex items-center">
                        <input checked={editIndex === index} onChange={()=>setEditIndex(index)} id="default-radio-1" type="radio" value="" name="default-radio" class="w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                      </div>
                    </td>
                    <td class="px-6 py-4">
                    {adr.address && <p class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{adr.address}</p>}
                    {adr.latitude && <button onClick={()=>showmap(adr.latitude, adr.longitude)} type="button" class="ml-3 mt-3 text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-xs px-3 py-2 text-center mr-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">map</button>}
                    </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
              <label htmlFor="full_name" class="mt-5 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Date & Time</label>
              <DatePicker class="bg-purple-50 border border-purple-500 text-purple-900 dark:text-purple-400 placeholder-purple-700 dark:placeholder-purple-500 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-purple-500" showTimeSelect timeIntervals={15} timeCaption="Time"  dateFormat="MMM/d/yyyy h:mm aa" onChange={(date)=>setPickupTime(date)} selected={pickupTime} /> 
              <label htmlFor="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Notes</label>
              <textarea value={editNotes} onChange={(e)=>setEditNotes(e.target.value)} id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-purple-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-purple-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-purple-500"></textarea>
              <div class="flex mt-4 space-x-3 md:mt-6">
              <button onClick={()=>cancelCollection()} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
              <button onClick={()=>gotoEditItemsLoader()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Next</button>
              </div>
            </div>
            }
            {editItemsLoader &&
              <div class="flex flex-col ml-5 mr-5 pb-10">
              <div class="flex items-center justify-between">
              <h5 class="mt-5 mb-5 text-xl font-medium text-gray-900 dark:text-white">Collection Items</h5>  
              <span class="text-sm text-gray-500 dark:text-gray-400">{editReferenceNumber}</span>
              </div>
              <DualListBox
                canFilter 
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                showHeaderLabels
                selectedHeader
                lang = {{
                  availableFilterHeader: 'Filter available',
                  availableHeader: 'Dress Materials',
                  filterPlaceholder: 'Search...',
                  moveAllLeft: 'Move all to available',
                  moveAllRight: 'Move all to selected',
                  moveLeft: 'Move to available',
                  moveRight: 'Move to selected',
                  moveBottom: 'Rearrange to bottom',
                  moveDown: 'Rearrange down',
                  moveUp: 'Rearrange up',
                  moveTop: 'Rearrange to top',
                  noAvailableOptions: 'No available options',
                  noSelectedOptions: 'No selected options',
                  requiredError: 'Please select at least one option.',
                  selectedFilterHeader: 'Filter selected',
                  selectedHeader: 'Customer Dresses',
                }}
                options={editOptions}
                selected={editSelected}
                onChange={(value) => setEditSelected(value)}
              />
              <div class="flex mt-4 space-x-3 md:mt-6">
              <button onClick={()=>cancelCollection()} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
              <button onClick={()=>backtoeditcustomerLoader()} type="button" class="text-yellow-900 bg-white border border-yellow-300 focus:outline-none hover:bg-yellow-100 focus:ring-4 focus:ring-yellow-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-yellow-800 dark:text-white dark:border-yellow-600 dark:hover:bg-yellow-700 dark:hover:border-yellow-600 dark:focus:ring-yellow-700">Back</button>
              <button onClick={()=>gotoEditItemValueLoader()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Next</button>
              </div>
            </div>
            }
            {editItemValueLoader &&
            <div class="relative overflow-x-auto">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <caption class="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
              <div class="flex items-center justify-between">
              <h5 class="mt-5 mb-5 text-xl font-medium text-gray-900 dark:text-white">Item -Numbers</h5>  
              <span class="text-sm text-gray-500 dark:text-gray-400">{editReferenceNumber}</span>
              </div>
              </caption>
              <tbody>
              <tr>
                <th scope="col" class="px-6 py-3">
                    Dress
                </th>
                <th scope="col" class="px-6 py-3">
                    Quantity
                </th>
                <th scope="col" class="px-6 py-3">
                </th>
              </tr>
              
              {editItemValue.map((eitem, index)=>(
              <tr key={index}>
              <td class="px-6 py-4">
              <input name='label' value={eitem.label}  onChange={(event)=>inputeHandlerEdit(index, event)} type="text" id="search-dropdown" class="bg-purple-50 border border-purple-500 text-purple-900 dark:text-purple-400 placeholder-purple-700 dark:placeholder-purple-500 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-purple-500"/>
              </td>  
              <td class="px-6 py-4">
              <input name='value' value={eitem.value}  onChange={(event)=>inputeHandlerEdit(index, event)} type="text" id="search-dropdown" class="bg-purple-50 border border-purple-500 text-purple-900 dark:text-purple-400 placeholder-purple-700 dark:placeholder-purple-500 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-purple-500"/>  
              </td>
              <td class="px-6 py-4">
              <button onClick={()=> deleteeHandlerEdit(index)} type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                <svg aria-hidden="true" class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path></svg>
                <span class="sr-only">Icon description</span>
              </button>
              </td>
              </tr>
              ))} 
                
              </tbody>
              </table>
              <button onClick={()=>addeHandlerEdit()} type="button" class="ml-5 mt-2 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Add another Dress</button>
              <div class="ml-5 flex mt-4 space-x-3">
              <button onClick={()=>cancelCollection()} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
              <button onClick={()=>backtoEdititemsLoader()} type="button" class="text-yellow-900 bg-white border border-yellow-300 focus:outline-none hover:bg-yellow-100 focus:ring-4 focus:ring-yellow-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-yellow-800 dark:text-white dark:border-yellow-600 dark:hover:bg-yellow-700 dark:hover:border-yellow-600 dark:focus:ring-yellow-700">Back</button>
              <button onClick={()=>gotoEditServiceLoader()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Next</button>
              </div>
            </div>
            }
            {editServiceLoader &&
              <div class="flex flex-col ml-5 mr-5 pb-10">
              <div class="flex items-center justify-between">
              <h5 class="mt-5 mb-5 text-xl font-medium text-gray-900 dark:text-white">Services</h5>  
              <span class="text-sm text-gray-500 dark:text-gray-400">{editReferenceNumber}</span>
              </div>
              <DualListBox
                canFilter 
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                showHeaderLabels
                selectedHeader
                lang = {{
                  availableFilterHeader: 'Filter available',
                  availableHeader: 'Our Services',
                  filterPlaceholder: 'Search...',
                  moveAllLeft: 'Move all to available',
                  moveAllRight: 'Move all to selected',
                  moveLeft: 'Move to available',
                  moveRight: 'Move to selected',
                  moveBottom: 'Rearrange to bottom',
                  moveDown: 'Rearrange down',
                  moveUp: 'Rearrange up',
                  moveTop: 'Rearrange to top',
                  noAvailableOptions: 'No available options',
                  noSelectedOptions: 'No selected options',
                  requiredError: 'Please select at least one option.',
                  selectedFilterHeader: 'Filter selected',
                  selectedHeader: 'Service to Customer',
                }}
                options={editServiceOptions}
                selected={editServiceSelected}
                onChange={(value) => setEditServiceSelected(value)}
              />
              <label htmlFor="full_name" class="mt-5 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select State</label>
                <div class="flex items-center">
                    <input checked={stateIndex === 0} onChange={()=>setStateIndex(0)} id="disabled-radio-2" type="radio" value="" name="disabled-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label htmlFor="disabled-radio-2" class="ml-2 text-sm font-medium text-gray-400 dark:text-gray-500">New</label>
                </div>
                <div class="flex items-center">
                    <input checked={stateIndex === 1} onChange={()=>setStateIndex(1)} id="disabled-radio-2" type="radio" value="" name="disabled-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label htmlFor="disabled-radio-2" class="ml-2 text-sm font-medium text-gray-400 dark:text-gray-500">Collected</label>
                </div>
                <div class="flex items-center">
                    <input checked={stateIndex === 2} onChange={()=>setStateIndex(2)} id="disabled-radio-2" type="radio" value="" name="disabled-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label htmlFor="disabled-radio-2" class="ml-2 text-sm font-medium text-gray-400 dark:text-gray-500">Completed</label>
                </div>
                
              <div class="flex mt-4 space-x-3 md:mt-6">
              <button onClick={()=>cancelCollection()} type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
              <button onClick={()=>backtoeditItemvalueLoader()} type="button" class="text-yellow-900 bg-white border border-yellow-300 focus:outline-none hover:bg-yellow-100 focus:ring-4 focus:ring-yellow-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-yellow-800 dark:text-white dark:border-yellow-600 dark:hover:bg-yellow-700 dark:hover:border-yellow-600 dark:focus:ring-yellow-700">Back</button>
              <button onClick={()=>editLaundrySubmit()} type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>
              </div>
            </div>
            }
        </div>
      }
      {deleteModal && 
        <div id="alert" class="p-4 mb-4 text-red-700 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Delete Collection?</h3>
          </div>
          <div class="mt-2 mb-4 text-sm">
            Do you wish to continue?
          </div>
          <div class="flex">
            <button onClick={()=>continueDeleteCollection()} type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Yes
            </button>
            <button onClick={()=>cancelDelete()} type="button" class="text-red-700 bg-transparent border border-red-700 hover:bg-red-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-500 dark:border-red-500 dark:text-red-400 dark:hover:text-white dark:focus:ring-red-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
              Dismiss
            </button>
          </div>
        </div>
      }
      {showCollectionModal && 
        <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            {editModal? <h3 class="text-lg font-medium">Collection updated successfully</h3>:<h3 class="text-lg font-medium">Collection created successfully</h3>}
          </div>
          <div class="mt-2 mb-4 text-sm">
            Do you wish to create new Collection?
          </div>
          <div class="flex">
            <button onClick={()=>navigate("/customers")} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              New Collection
            </button>
            <button onClick={()=>showCollectionList()} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Collection List
            </button>
          </div>
        </div>
      }
      {laundryModal && 
        <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Collection finished successfully</h3>
          </div>
          <div class="mt-2 mb-4 text-sm">
            Do you wish to create new Collection?
          </div>
          <div class="flex">
            <button onClick={()=>navigate("/customers")} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              New Collection
            </button>
            <button onClick={()=>showCollectionList()} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Collection List
            </button>
            <button onClick={()=>navigate("/laundry")} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Laundry Service
            </button>
            
          </div>
        </div>
      }
      {itemServiceModal && 
        <div id="alert" class="p-4 mb-4 text-red-700 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">You have not added service or items</h3>
          </div>
          <div class="mt-2 mb-4 text-sm">
            Do you wish to create new items or service?
          </div>
          <div class="flex">
            <button onClick={()=>navigate("/laundryitems")} type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Items
            </button>
            <button onClick={()=>navigate("/laundryservices")} type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Services
            </button>
            <button onClick={()=>navigate("/")} type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              Cancel Collection
            </button>
            
          </div>
        </div>
      }
      </>
      :
      <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">You do not have permission to view Collection. Contact your admin for permissions</h3>
          </div>
          <div class="flex">
            <button onClick={()=>navigate("/")} type="button" class="text-green-700 bg-transparent border border-green-700 hover:bg-green-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-green-500 dark:border-green-500 dark:text-green-400 dark:hover:text-white dark:focus:ring-green-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
              OK
            </button>
          </div>
      </div>
      }
    </>
  );
}
 