import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../components/sidebar/Sidebar'
import TopNav from '../components/topnav/TopNav'
import { ThemeContext } from '../contexts/ThemeContext'
import './main-layout.scss'

const MainLayout = () => {
    const { theme } = useContext(ThemeContext);
     
    return (
        <div id={theme}>
            <Sidebar />
            <div class="main bg-white dark:bg-slate-800">
                <div class="main__content">
                    <TopNav />
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default MainLayout
