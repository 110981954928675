/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import { child, get, getDatabase, push, ref as dref, set, update } from "firebase/database";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from "../../contexts/UserStoreContext";
import firebaseapp from '../../firebase';
import ls from 'localstorage-slim';

export default function InventoryOrder({navigation, route}) {
  
  const navigate = useNavigate();
  const dbr = getDatabase(firebaseapp);
  const dbRef = dref(dbr);
  const { storeID, setStoreID, countryCode, setCountryCode, inventorycreate } = useUserStore();
  const usinfo = ls.get('userinfo', { decrypt: true });
  const userdata = JSON.parse(usinfo);
  const [listDataExist, setListDataExist] = useState(false)
  const [listOrderDataExist, setListOrderDataExist] = useState(false)
  const [orderListInit, setOrderListInit] = useState(true)
  const [newOrderListInit, setNewOrderListInit] = useState(false)
  const [newOrderLoader, setNewOrderLoader] = useState(false)
  const [listLoader, setListLoader] = useState(false)
  const [listData, setListData] = useState('');
  const [listOrderData, setListOrderData] = useState('');
  const [editItem, setEditItem] = useState('');
  const [editLoader, setEditLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [vendorDataExist, setVendorDataExist] = useState(false);
  const [listVendorData, setListVendorData] = useState('');
  const [selectedVendor, setSelectedVendor] = useState('');
  const [editOrderItem, setEditOrderItem] = useState('');
  const [editStatus, setEditStatus] = useState('');
  const [editStatusModal, setEditStatusModal] = useState(false);
  const [multipathUpdate, setMultipathUpdate] = useState(false);
  const [orderCancel, setOrderCancel] = useState(false)
  const [filterListData, setFilterListData] = useState('');
  const [query, setQuery] = useState('')
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  
  if(!storeID){
    setStoreID(userdata.storeID);
  }
    
  if(!countryCode){
    setCountryCode(userdata.country);
  }

  useEffect(() => { 

    if(orderListInit){
      function listVendorData(){
        setOrderListInit(false);
        get(child(dbRef, `${storeID}/order/${storeID}/`)).then(async (snapshot) => {
          const listdata = [];
          if (snapshot.exists()) {
            snapshot.forEach(element => {
              const key = element.key;
              const poNumber = element.child('poNumber').val(); 
              const Date = element.child('Date').val(); 
              const status = element.child('status').val();
              const amount = element.child('amount').val() || 0;
              const orderData = element.child('orderData').val();
              const vendorData = element.child('vendorData').val();
              
              listdata.push({
                key:key,
                poNumber:poNumber, 
                Date:Date, 
                status:status,
                amount:amount,
                orderData:orderData,
                vendorData:vendorData
                })
            });
            const completedListData = await Promise.all(listdata);
            setListOrderDataExist(true);
            setListOrderData(completedListData);
            setFilterListData(completedListData);
            setListLoader(true);
          }
          else{
            setListOrderDataExist(false);
            setListOrderData(0);
            setListLoader(true);
          }
          
        }).catch((error)=>{
          console.log(error);
        });
     }
     listVendorData();
    }
  }, [orderListInit]);

  useEffect(() => { 
    if(newOrderListInit){
      
      function listNewOrderData(){
        setNewOrderListInit(false);
        const stockData = get(child(dbRef, `${storeID}/inventory/${storeID}/stock/`)).then(async (snapshot) => {
          const listdata = [];
          if (snapshot.exists()) {
            snapshot.forEach(element => {
              const key = element.key;
              const brand = element.child('stock/stock').child('brand').val(); 
              const itemname = element.child('stock/itemname').val(); 
              const sku = element.child('stock/sku').val();
              const stock = element.child('stock/stock').val(); 
              const transit = element.child('stock/transit').val();
              const total = parseInt(stock)+parseInt(transit);
              const order = 0;
              listdata.push({
                key:key,
                brand:brand, 
                itemname:itemname, 
                sku:sku,
                stock:stock,
                transit:transit,
                total:total,
                order:order
                })

            });
            const completedListData = await Promise.all(listdata);
            setListData(completedListData);
            setListDataExist(true);
          }
          else{
            setListData(0);
            setListDataExist(false);
          }
          
        })

        const vendordata = get(child(dbRef, `${storeID}/vendor/${storeID}/`)).then(async (snapshot) => {
          const vdata = [];
          if (snapshot.exists()) {
            snapshot.forEach(element => {
              const key = element.key;
              const address = element.child('address').val(); 
              const email = element.child('email').val(); 
              const phonenumber = element.child('phonenumber').val();
              const vendorName = element.child('vendorName').val(); 
              const whatsappNumber = element.child('whatsappNumber').val();
              vdata.push({
                key:key,
                address:address, 
                email:email, 
                phonenumber:phonenumber,
                vendorName:vendorName,
                whatsappNumber:whatsappNumber
                })

            });
            const completedListData = await Promise.all(vdata);
            
            setListVendorData(completedListData);
            setVendorDataExist(true)
          }
          else{
            setListVendorData(0);
            setVendorDataExist(false)
          }
        })
        
        const final = [stockData, vendordata]; 
        const finalPromise = Promise.all(final)

        finalPromise.then(()=>{
          setNewOrderLoader(true);
        }).catch((error)=>{
          console.log(error);
        });
      }
      listNewOrderData();
    }
  }, [newOrderListInit]);
  
  function newOrderCreator() {
  setListLoader(false);
  setNewOrderListInit(true);
  } 

  function orderValue(event, key){

    const _phonenumber = [...listData];
    _phonenumber[key]['order'] = event.target.value;
    setListData(_phonenumber);
    
  }
  
  function onSubmitNewOrder(){
    const key = push(child(dref(dbr), 'vendor')).key;
    const todaysDate = moment(new Date()).toISOString();
    const poNumber = moment(new Date()).format("DDMMYYYYHHmm")
    const vendorData = listVendorData[selectedVendor];
    const whatsappnumber = "+"+vendorData.whatsappNumber.code+vendorData.whatsappNumber.value;
    const line1 = "PO Number "+poNumber+"\r\n Date "+moment(new Date()).format("DD MMM YYYY")+"\r\n"
    let line2 = '';
    let itemnumber = 1;
    let updateObject = {};
    for (const key in listData) {
      if (Object.hasOwnProperty.call(listData, key)) {
        const element = listData[key];
        const path = storeID+"/inventory/"+storeID+"/stock/"+element.key+"/stock/transit"
        updateObject[path] = element.order;
        line2 = line2+itemnumber+":"+element.itemname+"-"+element.order+"\r\n"
        itemnumber = itemnumber+1;
      }
    }
    const message = line1+line2;
    const encodemessage = encodeURIComponent(message);
    const eurl = "https://wa.me/"+whatsappnumber+"/?text="+encodemessage
    
    set(dref(dbr, storeID+'/order/' + storeID+'/'+key), {
      key:key,
      poNumber:poNumber, 
      Date:todaysDate, 
      status:"new",
      amount:0,
      orderData:listData,
      vendorData:vendorData
    }).then(()=>{
      openInNewTab(eurl);
      setNewOrderLoader(false);
      setShowModal(true);
    })
  }

  function cancelNewOrder(){
    setNewOrderLoader(false);
    setListLoader(true);
  }

  function afterNewOrderCreation(){
    setShowModal(false);
    setNewOrderLoader(false);
    setListLoader(false);
    setOrderListInit(true);
  }

  function editOrder(item){
    
    setEditItem(item);
    setEditStatus(item.status);
    if(item.status === "new"){
      setMultipathUpdate(true);
    }
    if(item.status === "cancel"){
      console.log("you are here 261");
      setOrderCancel(true);
    }
    setEditOrderItem(item.orderData);
    setListLoader(false);
    setEditLoader(true);
  }

  function orderEditValue(event, key){
    const _phonenumber = [...editOrderItem];
    _phonenumber[key]['order'] = event.target.value;
    setEditOrderItem(_phonenumber);
  }

  const editamounthandler = (e)=>{
    setEditItem(prevState => ({
      ...prevState,
      amount: e
   }));

  }
  
  function cancelEditOrder(){
    setEditItem('');
    setEditLoader(false);
    setListLoader(true);
  }

  function onSubmitEditOrder(){
    let objLength, expenseTotal, expenseDetails;
    const tdate = new Date();
    const year = moment(tdate).format("YYYY");
    const month = moment(tdate).format("MMM");
    const day = moment(tdate).format("DD");
    const isodate = moment(tdate).toISOString();
    const key = editItem.key;
    const poNumber = editItem.poNumber;
    const editDate = editItem.Date;
    const amount = editItem.amount;
    const vendorData = editItem.vendorData;
    const orderData = editOrderItem;
    const name = "order - "+poNumber
    let updateObject = {};

    if(editStatus !== "new"){
      setEditStatusModal(false);
    }

    if(editStatus === "paid"){
      get(child(dbRef, `${storeID}/accounts/${storeID}/${year}/${month}/${day}`)).then(async (snapshot) => {
        const eTotal = snapshot.child("expenseTotal").val();
        expenseTotal = parseInt(eTotal)+parseInt(editItem.amount);
        expenseDetails = snapshot.child("expenseDetails").val();
        objLength = Object.keys(expenseDetails).length;
      })
    }
    for (const key in listData) {
      if (Object.hasOwnProperty.call(orderData, key)) {
        const element = orderData[key];
        const path = storeID+"/inventory/"+storeID+"/stock/"+element.key+"/stock/transit"
        updateObject[path] = element.order;
      }
    }
    if(objLength){
      var keylength = objLength+1
      expenseDetails.push({[keylength]:{key: keylength,  storeKey:'', storeName:'', name:name, description: name, amount:amount}});
    }else{
      expenseTotal = parseInt(editItem.amount);
      expenseDetails = {0:{key: 0,  storeKey:'', storeName:'', name:name, description: name, amount:amount}}
    }

    

    
    if(editStatus === "new"){
      setEditStatusModal(true);
    }else{
      if(
      ((multipathUpdate)&&(editStatus === "cancel"))||
      ((!multipathUpdate)&&(editStatus === "cancel"))||
      ((!multipathUpdate)&&(editStatus === "billed"))||
      ((!multipathUpdate)&&(editStatus === "confirmed"))
      ){
        set(dref(dbr, storeID+'/order/' + storeID +"/"+key), {
          key:key,
          poNumber:poNumber, 
          Date:editDate, 
          status:editStatus,
          amount:amount,
          orderData:orderData,
          vendorData:vendorData
        }).then(()=>{
          setEditLoader(false);
          setEditItem('');
          setOrderListInit(true);
        })
      }else if(((multipathUpdate)&&(editStatus === "paid"))){
        const mpUpdate = update(dref(dbr), updateObject);
        const stUpdate = set(dref(dbr, storeID+'/order/' + storeID +"/"+key), {
          key:key,
          poNumber:poNumber, 
          Date:editDate, 
          status:editStatus,
          amount:amount,
          orderData:orderData,
          vendorData:vendorData
        });
        const accUpdate = update(dref(dbr, storeID+'/accounts/'+storeID+"/"+year+"/"+month+"/"+day), {
          date: isodate,
          expenseTotal: expenseTotal,
          expenseDetails: expenseDetails
        });
        const final = [mpUpdate, stUpdate, accUpdate];
        const finalPromise = Promise.all(final);
        finalPromise.then(()=>{
          setEditLoader(false);
          setEditItem('');
          setOrderListInit(true);
        })
      }else if(((!multipathUpdate)&&(editStatus === "paid"))){
        const stUpdate = set(dref(dbr, storeID+'/order/' + storeID +"/"+key), {
          key:key,
          poNumber:poNumber, 
          Date:editDate, 
          status:editStatus,
          amount:amount,
          orderData:orderData,
          vendorData:vendorData
        });
        const accUpdate = update(dref(dbr, storeID+'/accounts/'+storeID+"/"+year+"/"+month+"/"+day), {
          date: isodate,
          expenseTotal: expenseTotal,
          expenseDetails: expenseDetails
        });
        const final = [stUpdate, accUpdate];
        const finalPromise = Promise.all(final);
        finalPromise.then(()=>{
          setEditLoader(false);
          setEditItem('');
          setOrderListInit(true);
        })
      }else if(
        ((multipathUpdate)&&(editStatus === "billed"))||
        ((multipathUpdate)&&(editStatus === "confirmed"))
      ){
        const mpUpdate = update(dref(dbr), updateObject);
        const stUpdate = set(dref(dbr, storeID+'/order/' + storeID +"/"+key), {
          key:key,
          poNumber:poNumber, 
          Date:editDate, 
          status:editStatus,
          amount:amount,
          orderData:orderData,
          vendorData:vendorData
        });
        const final = [mpUpdate, stUpdate];
        const finalPromise = Promise.all(final);
        finalPromise.then(()=>{
          setEditLoader(false);
          setEditItem('');
          setOrderListInit(true);
        })
      }
    }
  }

  function handleSearch(e){
    setQuery(e);
    const elow = e.toLowerCase();
    
    const fData = listOrderData.filter(item => {
      const dt = moment(item.ptime).format("DD/MMM/YYYY")
      const yr = moment(item.ptime).format("YYYY")
      const month = moment(item.ptime).format("MMM")
      
      return (
        (item.poNumber && item.poNumber.toLowerCase().includes(elow)) ||
        (item.status && item.status.toLowerCase().includes(elow)) ||
        (item.amount && item.amount.toLowerCase().includes(elow)) ||
        (item.vendorData.vendorName && item.vendorData.vendorName.toLowerCase().includes(elow)) ||
        (month && month.toLowerCase().includes(elow)) ||
        (dt && dt.toLowerCase().includes(elow)) ||
        (yr && yr.toLowerCase().includes(elow))

        );
    }
    );
    setFilterListData(fData);
  };
  

  return (
    <div>
      <nav class="flex mb-5" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <Link to='/' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
              Home
            </Link>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <Link to='/inventory' class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              Inventory
            </Link>
            </div>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
              <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Order</span>
            </div>
          </li>
        </ol>
      </nav>
      {listLoader && 
        <>
          {listOrderDataExist? 
            
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
                  <div class="flex items-center justify-between">
                  <p class="mb-5 text-xl text-gray-900 dark:text-white">Order List</p>
                  <button onClick={()=>newOrderCreator()} type="button" class="text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">New Order</button>
                  </div>
                  <div class="relative z-0 mb-6 w-1/2 ml-5 mr-5 group ">
                    <input value={query} onChange={(e)=>handleSearch(e.target.value)} type="text" name="sku" id="sku" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                    <label htmlFor="sku" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Search</label>
                  </div>
                  <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                              <th scope="col" class="py-3 px-6">
                                  PO Number
                              </th>
                              <th scope="col" class="py-3 px-6">
                                  Date
                              </th>
                              <th scope="col" class="py-3 px-6">
                                  Vendor
                              </th>
                              <th scope="col" class="py-3 px-6">
                                  Action
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                      {filterListData.map((item, key)=>(
                          <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                              <td class="py-4 px-6">
                                <p>{item.poNumber}</p>
                              </td>
                              <td class="py-4 px-6">
                              
                                <p>{moment(item.Date).format("DD/MMM/YYYY")}</p>
                                
                              </td>
                              <td class="py-4 px-6">
                                <p>{item.vendorData.vendorName}</p>
                              </td>
                              <td class="py-4 px-6">
                                <button onClick={()=>editOrder(item)} type="button" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center mr-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-blue-800">
                                  Edit
                                </button>
                              </td>
                          </tr>
                      ))}    
                      </tbody>
                  </table>
              </div>
              
            :
            <div id="alert" class="p-4 mb-4 text-yellow-700 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800" role="alert">
              <div class="flex items-center">
                <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                <h3 class="text-lg font-medium">You do not have order list</h3>
              </div>
              <div class="mt-2 mb-4 text-sm">
                Do you wish to create new order?
              </div>
              <div class="flex">
              {inventorycreate &&
                <button onClick={()=>newOrderCreator()} type="button" class="text-white bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800">
                  <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
                  Yes
                </button>
              }
                <button onClick={()=>navigate("/inventory")} type="button" class="text-yellow-700 bg-transparent border border-yellow-700 hover:bg-yellow-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-yellow-500 dark:border-yellow-500 dark:text-yellow-400 dark:hover:text-white dark:focus:ring-yellow-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                  Dismiss
                </button>
              </div>
            </div>
          } 
        </>
      }
      {newOrderLoader &&
        <>
          <div class="mt-2 mb-2 text-lg">New Order</div>
          <div class="relative z-0 mb-6 w-full group">
          {listDataExist? 
            
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
                  <div class="flex items-center justify-between">
                  <p class="mb-5 text-xl text-gray-900 dark:text-white">Stock Order</p>
                  </div>
                  <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                              <th scope="col" class="px-6 py-3">
                                  Item Name
                              </th>
                              <th scope="col" class="px-6 py-3">
                                  Stock
                              </th>
                              <th scope="col" class="px-6 py-3">
                                  transit
                              </th>
                              <th scope="col" class="px-6 py-3">
                                  total
                              </th>
                              <th scope="col" class="px-6 py-3">
                                  Order Value
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                      {listData.map((item, key)=>(
                          <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                              <td class="py-4 px-6">
                                <p class="text-xs text-gray-900 dark:text-white">{item.sku}</p>
                                <p>{item.itemname} {item.brand}</p>
                              </td>
                              <td class="py-4 px-6">
                                <p>{item.stock}</p>
                              </td>
                              <td class="py-4 px-6">
                                <p>{item.transit}</p>
                              </td>
                              <td class="py-4 px-6">
                                <p>{item.total}</p>
                              </td>
                              <td class="py-4 px-6">
                              <input value={item.order} onChange={(e)=>orderValue(e, key)} type="number" id="second_product" class="bg-gray-50 w-14 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                              </td>
                          </tr>
                      ))}    
                      </tbody>
                  </table>
                  {vendorDataExist ? 
                  <>
                  <h3 class="m-5 font-semibold text-gray-900 dark:text-white">Select Vendor</h3>
                  <ul class="m-5 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                    {listVendorData.map((vitem, vkey)=>(
                      <li key={vkey} class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                          <div class="flex items-center pl-3 mt-2 mb-2">
                              <input onClick={()=>setSelectedVendor(vkey)} id="list-radio-license" type="radio" value="" name="list-radio" class="mr-5 ml-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                              <div>
                                <p htmlFor="list-radio-license" class="w-full ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{vitem.vendorName}</p>
                                <p htmlFor="list-radio-license" class="w-full ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Address: {vitem.address}</p>
                                <p htmlFor="list-radio-license" class="w-full ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email: {vitem.email}</p>
                                <p htmlFor="list-radio-license" class="w-full ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Phonenumber:</p>
                                <p htmlFor="list-radio-license" class="w-full ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                {vitem.phonenumber.map((ph, ckey)=>( <label key={ckey} htmlFor="list-radio-license" class="w-full text-sm font-medium text-gray-900 dark:text-gray-300">+{ph.code}{ph.value}</label>))}
                                </p>
                                <p htmlFor="list-radio-license" class="w-full ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Whatsapp: +{vitem.whatsappNumber.code}{vitem.whatsappNumber.value}</p>
                                
                              </div>
                          </div>
                      </li>
                    ))}
                      
                  </ul>
                  <div class="relative z-0 mb-6 mt-6 w-full group">
                  {inventorycreate && <button onClick={()=>onSubmitNewOrder()} type="button" class="ml-5 mt-2 mr-2 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Submit</button>}
                  <button onClick={()=>cancelNewOrder()} type="button" class="mt-2 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
                  </div>
                  </>
                  :
                  <div id="alert" class="p-4 mb-4 text-yellow-700 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800" role="alert">
                    <div class="flex items-center">
                      <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                      <h3 class="text-lg font-medium">You do not have vendors</h3>
                    </div>
                  </div>
                  }
                  

                  
                  
              </div>
              
            :
            <div id="alert" class="p-4 mb-4 text-yellow-700 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800" role="alert">
              <div class="flex items-center">
                <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                <h3 class="text-lg font-medium">You have not added stocks</h3>
              </div>
              
              <div class="flex">
                <button onClick={()=>navigate("/inventory")} type="button" class="text-yellow-700 bg-transparent border border-yellow-700 hover:bg-yellow-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-yellow-500 dark:border-yellow-500 dark:text-yellow-400 dark:hover:text-white dark:focus:ring-yellow-800" data-dismiss-target="#alert-additional-content-3" aria-label="Close">
                  ok
                </button>
              </div>
            </div>
          } 
          
          
          </div>
        </>
      }
      {showModal && 
        <div id="alert" class="p-4 mb-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium">Order created successfully</h3>
          </div>
          <div class="flex">
            <button onClick={()=>afterNewOrderCreation()} type="button" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
              ok
            </button>
          </div>
        </div>
      }
      {editLoader &&
        <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
        {editStatusModal &&
        <div id="toast-danger" class="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
          <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
              <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
              <span class="sr-only">Error icon</span>
          </div>
          <div class="ml-3 text-sm font-normal">Please change status</div>
          <button onClick={()=>setEditStatusModal(false)} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-danger" aria-label="Close">
              <span class="sr-only">Close</span>
              <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
          </button>
        </div>
        }
          <div class="flex items-center justify-between">
          <p class="mb-5 text-xl text-gray-900 dark:text-white">Stock Order</p>
          </div>
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <caption class="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                Order Details
                <div class="flex justify-between">
                  <div>
                    <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">PO Number: {editItem.poNumber}</p>
                    <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">Date: {moment(editItem.Date).format("DD/MMM/YYYY")}</p>
                    <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">Bill Amount: <input value={editItem.amount} onChange={(e)=>editamounthandler(e.target.value)} id="second_product" class="bg-gray-50 w-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" /></p>
                    <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">Status</p>
                    <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                        <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                            <div class="flex items-center pl-3 pr-3">
                                <input disabled={editStatus === "confirmed" || editStatus === "billed" || editStatus === 'paid'} onChange={()=>setEditStatus("cancel")} id="horizontal-list-radio-license" type="radio" checked={editStatus === "cancel"} name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                <label htmlFor="horizontal-list-radio-license" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Cancel</label>
                            </div>
                        </li>
                        <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                            <div class="flex items-center pl-3 pr-3">
                            <input disabled={editStatus === "cancel" || editStatus === "billed" || editStatus === 'paid'} onChange={()=>setEditStatus("confirmed")} id="horizontal-list-radio-license" type="radio" checked={editStatus === "confirmed"} name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                <label htmlFor="horizontal-list-radio-id" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Confirmed</label>
                            </div>
                        </li>
                        <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                            <div class="flex items-center pl-3 pr-3">
                            <input disabled={editStatus === "cancel" || editStatus === 'paid'} onChange={()=>setEditStatus("billed")} id="horizontal-list-radio-license" type="radio" checked={editStatus === "billed"} name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                <label htmlFor="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Billed</label>
                            </div>
                        </li>
                        <li class="w-full dark:border-gray-600">
                            <div class="flex items-center pl-3 pr-3">
                            <input disabled={editStatus === "cancel"} onChange={()=>setEditStatus("paid")} id="horizontal-list-radio-license" type="radio" checked={editStatus === "paid"} name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                <label htmlFor="horizontal-list-radio-passport" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Paid</label>
                            </div>
                        </li>
                    </ul>
                    
                  </div>
                  <div>
                  <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">{editItem.vendorData.vendorName}</p>
                  <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">Address: {editItem.vendorData.address}</p>
                  <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">Email: {editItem.vendorData.email}</p>
                  <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">Phonenumber:</p>
                  <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">
                  {editItem.vendorData.phonenumber.map((ph, ckey)=>( <label key={ckey} class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">{ph.value}</label>))}
                  </p>
                  <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">Whatsapp: {editItem.vendorData.whatsappNumber}</p>
                
                </div>
                </div>
                
              </caption>
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                          Item Name
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Stock
                      </th>
                      <th scope="col" class="px-6 py-3">
                          transit
                      </th>
                      <th scope="col" class="px-6 py-3">
                          total
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Order Value
                      </th>
                  </tr>
              </thead>
              <tbody>
              {editOrderItem.map((item, key)=>(
                  <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td class="py-4 px-6">
                        <p class="text-xs text-gray-900 dark:text-white">{item.sku}</p>
                        <p>{item.itemname} {item.brand}</p>
                      </td>
                      <td class="py-4 px-6">
                        <p>{item.stock}</p>
                      </td>
                      <td class="py-4 px-6">
                        <p>{item.transit}</p>
                      </td>
                      <td class="py-4 px-6">
                        <p>{item.total}</p>
                      </td>
                      <td class="py-4 px-6">
                      <input disabled value={item.order} onChange={(e)=>orderEditValue(e, key)} type="number" id="second_product" class="bg-gray-50 w-14 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                      </td>
                  </tr>
              ))}    
              </tbody>
          </table>
          <div class="relative z-0 mb-6 mt-6 w-full group">
          {orderCancel? <h6>Order Cancelled </h6>:<button onClick={()=>onSubmitEditOrder()} type="button" class="mt-2 mr-2 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Save</button>}
          <button onClick={()=>cancelEditOrder()} type="button" class="mt-2 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">Cancel</button>
          
          </div>
        </div>
      }
      

    </div>

  );
}

